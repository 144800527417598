import { Country } from "./country";
import { UserRole } from "./user-role";
import { AOWGroupUserMapping } from "./aowgroup-user-mapping";

export class User {
    id: number;
    name: string;
    lastName: string;
    country: Country;
    countryId: number;
    userRole: UserRole;
    userRoleId: number;
    email: string;
    modifiedUserId: number;
    createdUserId: number;
    aowGroupId: number;
    isNotificationEnable : boolean;
    aowGroupUserMapping: AOWGroupUserMapping;
    constructor(obj?: User) {
      if (obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.lastName = obj.lastName;
        this.country = new Country(obj.country);
        this.countryId = obj.countryId;
        this.userRole = new UserRole(obj.userRole);
        this.userRoleId = obj.userRoleId;
        this.email = obj.email;
        this.modifiedUserId = obj.modifiedUserId;
        this.createdUserId = obj.createdUserId;
        this.aowGroupId = obj.aowGroupId;
        this.isNotificationEnable = obj.isNotificationEnable;
        this.aowGroupUserMapping = new AOWGroupUserMapping(obj.aowGroupUserMapping);
      }
    }
}
