import { User } from "./user";

export class UserList {
    users: User[] = [];
    count: number;
    constructor(obj?: UserList) {
      if (obj) {
        this.users = obj.users? obj.users.map(o => new User(o)): null;
        this.count = obj.count;
      }
    }
}
