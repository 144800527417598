<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >View Upcoming Standard</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content nrbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li>
            <a
              routerLink="/view-all-upcoming-standards-list"
              routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">></li>
          <li class="active">{{ grsDetail.standard.title }}</li>
          <li style="float: right">
            <a
              style="color: #0b59ca; text-decoration: underline;cursor: pointer"
              (click)="downloadExcel()"
              >Download as Excel</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div *ngIf="!loading" class="nrbes-detail-view">
      <!-- Dynamic Wrapper -->
      <div class="hash-tag">
        {{ grsDetail.standard.category?.name }}
      </div>

      <div class="nrbes-detail-item">
        <div class="nrbes-detail-item">
          <h1>{{ grsDetail.standard.title }}</h1>
          <div class="nrbes-description">
            <h2>New Restaurant Upcoming Standard</h2>
          </div>
        </div>

        <!--<div *ngIf="grsDetail.standard.imageAttachment" class="mcd-operations-image">
      <img [src]="grsDetail?.standard?.imageAttachment?.image">
    </div>-->

        <div class="nrbes-detail-item">
          <div
            *ngIf="grsDetail.standard.newRestaurantsEffectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Effective Date</label>
            <div class="mcd-date-box">
              <div class="mcd-date-box-header">
                {{
                  grsDetail.standard.newRestaurantsEffectiveFrom
                    | date : "MMM | yyyy"
                }}
              </div>
              <div class="mcd-date-box-day">
                {{
                  grsDetail.standard.newRestaurantsEffectiveFrom | date : "dd"
                }}
              </div>
            </div>
          </div>

          <div
            *ngIf="!grsDetail.standard.newRestaurantsEffectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Effective Date - N/A </label>
          </div>
        </div>

        <div class="nrbes-description">
          <p>
            {{ grsDetail.standard.newRestaurantDescription }}
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />

        <div class="nrbes-detail-item">
          <div class="nrbes-description">
            <h2>Existing Restaurant Upcoming Standard</h2>
          </div>
        </div>
        <div class="nrbes-detail-item">
          <div
            *ngIf="grsDetail.standard.effectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Effective Date</label>
            <div class="mcd-date-box">
              <div class="mcd-date-box-header">
                {{ grsDetail.standard.effectiveFrom | date : "MMM | yyyy" }}
              </div>
              <div class="mcd-date-box-day">
                {{ grsDetail.standard.effectiveFrom | date : "dd" }}
              </div>
            </div>
          </div>

          <div
            *ngIf="!grsDetail.standard.effectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Effective Date - N/A </label>
          </div>
        </div>
        <div class="nrbes-description">
          <p>
            {{ grsDetail.standard.description }}
          </p>
        </div>

        <br />
        <br />
        <br />
        <br />

        <div class="nrbes-detail-item">
          <div class="nrbes-Benefit">
            <h2>Upcoming Standard Details</h2>

            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="this.notes"
              ></div>
            </div>
          </div>
        </div>

        <div class="nrbes-detail-item">
          <div class="nrbes-Benefit">
            <h2>Additional References</h2>

            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="this.additionalInformation"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Dynamic Wrapper -->
      <!-- Next Previous Buttons-->
      <div class="row">
        <div class="col"></div>
        <div class="col text-right">
          <div class="btn-wrapper">
            <div class="prev-button" *ngIf="!grsDetail.isFirst">
              <a
                href="javascript:void(0)"
                (click)="slide(grsDetail.standard.id, false)"
              >
                <img src="/assets/images/back.svg" />
              </a>
            </div>
            <div class="next-button" *ngIf="!grsDetail.isLast">
              <a
                href="javascript:void(0)"
                (click)="slide(grsDetail.standard.id, true)"
              >
                <img src="/assets/images/next.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
