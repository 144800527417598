<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View Equipment Standard</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div *ngIf="!isComingFromLocation" class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/view-equipments-standard-list" routerLinkActive="active">All</a></li>
          <li class="separator"> &gt; </li>
          <li class="active">{{grsDetail.standard.equipmentStandard.model}}</li>
        </ul>
      </div>
      <div *ngIf="isComingFromLocation" class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/locations" routerLinkActive="active">Location</a></li>
          <li class="separator"> &gt; </li>
          <li><a [routerLink]="'/equipment-list/' + grsDetail?.standard?.locationTypeId" routerLinkActive="active">{{grsDetail?.standard?.locationType?.name}}</a></li>
          <li class="separator"> &gt; </li>
          <li class="active">{{grsDetail.standard.equipmentStandard.model}}</li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="col-4 image-wrapper">
        <h1 class="d-md-none d-none">{{grsDetail.standard.title}}</h1>
        <h2 class="d-md-none sub-title">{{grsDetail.standard.equipmentStandard.equipmentClassTypeName}}</h2>
        <div class="mcd-supplier-logo">
          <img [src]="grsDetail?.standard?.equipmentStandard?.supplier?.supplierLogoAttachment?.image" class="img-fluid">
        </div>
        <div class="mcd-equipment-image">
          <img [src]="grsDetail?.standard?.equipmentStandard?.imageAttachment?.image">
        </div>
        <div class="mcd-eq-attachement">
          <a href="javascript:void(0);" *ngIf="grsDetail?.standard?.equipmentStandard?.equipmentAttachment?.id != null"
             (click)=" onDownloadSelectedEquipmentAttachment(grsDetail.standard.equipmentStandard.equipmentAttachment)">
            <img src="/assets/images/attachement.svg"> Attachment
          </a>
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div>
          <h1>{{grsDetail.standard.title}}</h1>
          <h2 class="sub-title">{{grsDetail.standard.equipmentStandard.equipmentType.name}}</h2>
          <div class="grbes-description">
            <h2>Description</h2>
            <p>{{grsDetail.standard.description}}</p>
          </div>
          <div>
            <p>Obsolete Date - {{grsDetail.standard.equipmentStandard.obsoleteDate | date: 'MM.dd.yyyy'}}</p>
          </div>
          <div>
            <p>Effective Date - {{grsDetail.standard.effectiveFrom | date: 'MM.dd.yyyy' }}</p>
          </div>
          <div class="row">
            <div class=" col">
              <div class="mcd-spec">
                <span class="icon float-left">
                  <img alt="location" src="/assets/images/location-gray.svg">
                </span>
                <span class="label float-left">{{grsDetail.standard.locationType?.name}}</span>
              </div>
            </div>


          </div>
        </div>
        <!-- End Dynamic Wrapper -->
        <!-- Next Previous Buttons-->
        <div class="row">
          <div class="col text-right">
            <div class="btn-wrapper">
              <div class="prev-button" *ngIf="!grsDetail.isFirst">
                <a href="javascript:void(0)" (click)="slide(grsDetail.standard.id, false)">
                  <img src="/assets/images/back.svg">
                </a>
              </div>
              <div class="next-button" *ngIf="!grsDetail.isLast">
                <a href="javascript:void(0)" href="javascript:void(0)" (click)="slide(grsDetail.standard.id, true)">
                  <img src="/assets/images/next.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
