import { Component, OnInit, Inject } from "@angular/core";
import { NotificationModel } from "../models/notification-model.model";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { NotificationService } from "../services/notification.service";
import { AddendumService } from "../services/addendum.service";
import { Router } from "@angular/router";
import { EventEmitterService } from "../services/event-emitter.service";
import { Constant } from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";

@Component({
  selector: "app-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrls: ["./notification-list.component.scss"],
})
export class NotificationListComponent implements OnInit {
  loading: boolean = false;
  public notificationHistory: NotificationModel[] =
    new Array<NotificationModel>();

  page = 1;
  pageSize = 5;
  collectionSize: number = 0;

  pageSizes: any[] = Constant.PageSizes;

  constructor(
    private notificationHubService: NotificationService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private addendumService: AddendumService,
    private router: Router,
    private cryptographyService: CryptographyService,
    private eventEmitterService: EventEmitterService
  ) {}

  ngOnInit() {
    let notificationGroup = this.cryptographyService.decryptData(
      this.storage.get("notificationGroup")
    );

    this.getAllUserNotifications(notificationGroup);
  }

  private getAllUserNotifications(notificationGroup: string) {
    this.loading = true;
    this.notificationHistory = new Array<NotificationModel>();
    this.notificationHubService
      .getAllUserNotifications(
        notificationGroup,
        (this.page - 1) * this.pageSize,
        this.pageSize
      )
      .subscribe((data) => {
        this.collectionSize = data.count;
        data.notifications.forEach((item) => {
          this.notificationHistory.push(new NotificationModel(item));
        });
        this.loading = false;
      });
  }

  rowSelected(item: NotificationModel) {
    item.isSeen = true;
    this.loading = true;
    this.notificationHubService.UpdateNotification(item).subscribe(() => {
      this.loading = false;
      this.eventEmitterService.getAlreadyReservedUserNotificationsEmit();
    });
    this.router.navigate(["/addendum/" + item.addendumId]);
  }

  selectedItemsPerPageChange(event) {
    this.pageSize = event.value;
    this.page = 1;
    let notificationGroup = this.cryptographyService.decryptData(
      this.storage.get("notificationGroup")
    );
    this.getAllUserNotifications(notificationGroup);
  }

  loadNotificationsForPageChange() {
    let notificationGroup = this.cryptographyService.decryptData(
      this.storage.get("notificationGroup")
    );
    this.getAllUserNotifications(notificationGroup);
  }
}
