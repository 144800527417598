import { Component, OnInit, Inject } from "@angular/core";
import { StandardService } from "../services/standard.service";
import {
  StandardTypeEnum,
  UserRoleEnum,
  StandardListType,
} from "../core/constant";
import { Router, ActivatedRoute } from "@angular/router";
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SelectedStandard } from "../models/selected-standard";

@Component({
  selector: "app-equipment-detail-view",
  templateUrl: "./equipment-detail-view.component.html",
  styleUrls: ["./equipment-detail-view.component.scss"],
})
export class EquipmentDetailViewComponent implements OnInit {
  grsId: number;
  loading: boolean = false;
  grsDetail: SelectedStandard = new SelectedStandard();
  userRole: number;
  isComingFromLocation: boolean = false;

  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private router: Router,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
    this.isComingFromLocation = standardService.isComingFromLocation;
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
  }

  /**
   * Get GRBES detail by grbes id
   * @return void
   */
  getDetailByGrbesId(grsId: number) {
    this.loading = true;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        null,
        this.standardService.equipmentStandardFiltration.filterSet,
        StandardListType.Location,
        null
      )
      .subscribe((data: SelectedStandard) => {
        this.grsDetail = new SelectedStandard(data);

        this.loading = false;
        () => {
          this.loading = false;
        };
      });
  }

  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }

  slide(grsID: number, isForward: boolean) {
    this.loading = true;
    if (this.userRole != this.userRoleEnum.MarketAdmin) {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          null,
          this.standardService.equipmentStandardFiltration.filterSet,
          StandardListType.Location
        )
        .subscribe((data: SelectedStandard) => {
          debugger;
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.grsDetail = new SelectedStandard(data);
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          null,
          this.standardService.equipmentStandardFiltration.filterSet,
          StandardListType.Location
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.grsDetail = new SelectedStandard(data);
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    }
  }

  onDownloadSelectedEquipmentAttachment(e) {
    fetch(e.attachmentContent ? e.mime + e.attachmentContent : e.image)
      .then((res) => res.blob())
      .then((blob) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveBlob(blob, e.fileName);
        } else {
          var a = document.createElement("a");
          a.href = e.attachmentContent
            ? e.mime + e.attachmentContent
            : e.attachmentContentAsBase64;
          a.setAttribute("download", e.fileName);
          a.click();
        }
      });
  }
}
