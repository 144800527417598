import { Component, OnInit, Inject } from "@angular/core";
import { Addendum } from "../models/addendum";
import { AddendumService } from "../services/addendum.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import {
  UserRoleEnum,
  AddendumStatusEnum,
  Constant,
  StandardTypeEnum,
} from "../core/constant";
import { Standard } from "../models/standard";
import { StandardService } from "../services/standard.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { AddendumAttachment } from "../models/addendum-attachment";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CryptographyService } from "../services/cryptography.service";
import { SelectedStandard } from "../models/selected-standard";
import { FailModuleComponent } from "../fail-module/fail-module.component";

@Component({
  selector: "app-update-addendum",
  templateUrl: "./update-addendum.component.html",
  styleUrls: ["./update-addendum.component.scss"],
})
export class UpdateAddEndumComponent implements OnInit {
  countryId: number = 0;
  addEndumId: any = parseInt(
    this.activeRoute.snapshot.paramMap.get("standardId")
  );
  loading: any = false;
  addendum: Addendum;
  invalidFile: boolean = false;
  invalidFileMessage: string = "";
  attachment: any = null;

  constructor(
    private addendumService: AddendumService,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    private router: Router,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    // debugger;

    this.loading = true;
    this.addendumService
      .getAddendumInformationByAddendumId(this.addEndumId)
      .subscribe((data: Addendum) => {
        this.addendum = data;
        this.loading = false;
        console.log(this.addendum);
        // this.updateAddendum();
        this.attachment = this.getLatestRequestedAttachment();
        console.log("approvalArray", this.addendum.addendumApproval);
        console.log("ATTACHMENT", this.attachment);
      });
  }

  getLatestRequestedAttachment() {
    let attachment = null;
    for (let i = 0; i < this.addendum.addendumAttachment.length; i++) {
      if (this.addendum.addendumAttachment[i].isRequestedFile) {
        attachment = this.addendum.addendumAttachment[i];
      }
    }
    return attachment;
  }

  isEmpty(a) {
    if (a == null || a == undefined || a == "") return true;
    return false;
  }
  updateAddendum(description, note) {
    this.addendum.addendumAttachment = [this.attachment];
    this.loading = true;
    this.addendum.addendumStatusId = AddendumStatusEnum.Requested;
    this.addendum.description = description;
    this.addendum.note = note;
    let modifiedDate = new Date();
    this.addendum.modified = modifiedDate;
    modifiedDate.setFullYear(modifiedDate.getFullYear() + 2);
    this.addendum.expiryDate = modifiedDate;
    this.addendumService
      .updateAddendum(this.addendum)
      .subscribe((data: any) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully renewed an exception.";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            this.router.navigate(["/addendum/" + this.addendum.id]);
          }
        );
        // console.log(data);
      });
  }
  onDownloadSelectedAddendumDocument(id) {
    if (id) {
      return this.standardService.getAttachmentUrl(id, true).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.addendum.addendumAttachment[0].attachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.addendum.addendumAttachment[0].fileName
            );
          } else {
            var a = document.createElement("a");
            a.href =
              this.addendum.addendumAttachment[0].attachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.addendum.addendumAttachment[0].fileName
            );
            a.click();
          }
        });
    }
  }
  onRemoveAddendumDocument() {
    // debugger;
    this.attachment = null;
    this.invalidFile = false;
  }
  validateFileType(fileName: string) {
    let fileTypeArr = ["docx", "doc"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        this.invalidFileMessage = "Invalid file type.";
        return true;
      }
    }
  }

  validateFileSize(file: any) {
    if (file.size > 10485760) {
      this.invalidFileMessage = "File size exceeds maximum limit 10 MB.";
      return true;
    }
  }

  onSelectAAddendumDocument(event) {
    this.invalidFileMessage = "";
    let selectedFile = event.target.files[0];
    this.invalidFile =
      this.validateFileType(selectedFile.name) ||
      this.validateFileSize(selectedFile);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.attachment = new AddendumAttachment();
      this.attachment.fileName = selectedFile.name;
      this.attachment.addendumId = this.addendum.id;
      this.attachment.isRequestedFile = true;
      this.attachment.attachmentContentAsBase64 = result;
      event.target.value = null;
    });
  }
  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }
}
