export class AddendumStatus {
    id: number;
    name: string;
    description: string;
    labelForGlobal: string;
    labelForMarket: string;
    constructor(obj?: AddendumStatus) {
        if (obj) {
            this.id = obj.id;
            this.name = obj.name;
            this.description = obj.description;
            this.labelForGlobal = obj.labelForGlobal;
            this.labelForMarket = obj.labelForMarket;
        }
    }
}
