import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FilteringGuide } from "../models/filteringGuide";
import { StandardService } from "../services/standard.service";
import {
  StandardListType,
  UserRoleUserOperation,
  StandardTypeEnum,
  Constant,
} from "../core/constant";
import { Standard } from "../models/standard";
import { StandardList } from "../models/standard-list";
import { Router, ActivatedRoute } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { EquipmentStandardService } from "../services/equipment -standard.service";
import { Observable } from "rxjs";
import { EquipmentStandard } from "../models/equipment-standard";
import { environment } from "../../environments/environment";
import { CryptographyService } from "../services/cryptography.service";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { Sort } from "@angular/material/sort";
import { FilterList } from "../models/filterList";

@Component({
  selector: "app-view-all-equipment-standard-list",
  templateUrl: "./view-all-equipment-standard-list.component.html",
  styleUrls: ["./view-all-equipment-standard-list.component.scss"],
})
export class ViewAllEquipmentStandardListComponent implements OnInit {
  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  isDeleted: boolean = false;
  loading: boolean = false;
  panelOpenState: boolean = false;
  standards: Standard[] = [];
  filterList: FilteringGuide[] = [];
  canEditEquipmentStandard: boolean;
  canDeleteEquipmentStandard: boolean;
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string = "";
  isDownloading: any = false;
  get standardListType() {
    return StandardListType;
  }
  equipmentStandard: EquipmentStandard = new EquipmentStandard();
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  modalOption: NgbModalOptions = {};
  standardFiltrationForChipSet: FilterList = new FilterList();

  constructor(
    private standardService: StandardService,
    private router: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private equipmentStandardService: EquipmentStandardService,
    private cryptographyService: CryptographyService,
    private ngbModal: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.standardFiltrationForChipSet.standardListType =
      StandardListType.GlobalEquipment;
    this.setUserPrivilages();
    this.filterList =
      this.standardService.equipmentStandardFiltration.filterSet;
    this.pageSize = this.standardService.equipmentStandardFiltration.pageSize;
    this.searchKey = this.standardService.equipmentStandardFiltration.searchKey;
    this.getAllEquipmentStandards();
  }

  receivedFilteration($event) {
    this.page = 1;
    this.filterList = $event;
    this.standardService.equipmentStandardFiltration.filterSet =
      this.filterList;
    this.getAllEquipmentStandards();
  }

  setUserPrivilages() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.canEditEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditEquipmentStandard
          ) !== -1;
        this.canDeleteEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanDeleteEquipmentStandard
          ) !== -1;
      }
    } else {
      this.canEditEquipmentStandard = true;
      this.canDeleteEquipmentStandard = true;
    }
  }

  getAllEquipmentStandards() {
    this.loading = true;
    this.standardService.equipmentStandardFiltration.pageSize = this.pageSize;
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.GlobalEquipment,
        this.filterList,
        null,
        this.searchKey
      )
      .subscribe((data: StandardList) => {
        this.standards = [];
        this.standards = data.standardDetailList.map(
          (obj) => new Standard(obj)
        );
        console.log("this.standards", this.standards);
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.loading = false;
        };
      });
  }

  openDeletePopup(item, event) {
    event.stopPropagation();
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.equipmentStandard != null;
    const modalRef = this.ngbModal.open(
      DeleteDialogComponent,
      this.modalOption
    );
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    //if (item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard) {
    //modalRef.componentInstance.message = 'All the dependencies will be removed along with this standard';
    //}
    modalRef.componentInstance.deleteItem = item.id;
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItemId) => {
      if (deleteItemId != null) {
        let equipmentStandard = new EquipmentStandard();
        equipmentStandard.id = deleteItemId;
        equipmentStandard.isDeleted = true;
        this.loading = true;
        this.equipmentStandardService
          .deleteEquipmentStandard(equipmentStandard)
          .subscribe((data: any) => {
            this.loading = false;
            this.page = 1;
            this.getAllEquipmentStandards();
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  onEdit(id) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    this.router.navigate(["/edit-eq-standards-global", id]);
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeletePopup(item, event);
    }, 100);
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.equipmentStandardFiltration.pageSize = this.pageSize;
    this.getAllEquipmentStandards();
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.standardService.equipmentStandardFiltration.searchKey = this.searchKey;
    this.getAllEquipmentStandards();
  }

  rowSelected(item: Standard): void {
    this.standardService.fromLocationGRS = false;

    this.router.navigate(["/view-equipments-standard-detail/" + item.id]);
  }

  downloadExcel() {
    this.isDownloading = true;
    this.standardService
      .downloadExcel(
        0,
        this.collectionSize,
        StandardListType.GlobalEquipment,
        this.filterList,
        null,
        this.searchKey
      )
      .subscribe(
        (response: any) => {
          this.isDownloading = false;
          let filename =
            "Equipment_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === "") {
      return;
    }

    this.loading = true;
    this.standardService.equipmentStandardFiltration.pageSize = this.pageSize;
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.GlobalEquipment,
        this.filterList,
        null,
        this.searchKey
      )
      .subscribe((data: StandardList) => {
        this.standards = [];
        this.standards = data.standardDetailList
          .map((obj) => new Standard(obj))
          .sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
              case "equipmentType":
                return compare(
                  a.equipmentStandard.equipmentType.name,
                  b.equipmentStandard.equipmentType.name,
                  isAsc
                );
              case "supplier":
                return compare(
                  a.equipmentStandard.supplier.name,
                  b.equipmentStandard.supplier.name,
                  isAsc
                );
              case "title":
                return compare(a.title, b.title, isAsc);
              case "model":
                return compare(
                  a.equipmentStandard.model,
                  b.equipmentStandard.model,
                  isAsc
                );
              case "equipmentClass":
                return compare(
                  a.equipmentStandard.equipmentClassType.name,
                  b.equipmentStandard.equipmentClassType.name,
                  isAsc
                );

              default:
                return 0;
            }
          });
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.loading = false;
        };
      });

    //this.standards = this.standards.sort((a, b) => {
    //  const isAsc = sort.direction === 'asc';
    //  switch (sort.active) {
    //    case 'equipmentType': return compare(a.equipmentStandard.equipmentType.name, b.equipmentStandard.equipmentType.name, isAsc);
    //    //case 'createdUserName': return compare(a.createdUserName, b.createdUserName, isAsc);
    //    //case 'modified': return compare(a.modified, b.modified, isAsc);
    //    //case 'productsSyncedDate': return compare(a.productsSyncedDate, b.productsSyncedDate, isAsc);
    //    //case 'created': return compare(a.created, b.created, isAsc);
    //    //case 'storeType': return compare(a.storeType, b.storeType, isAsc);
    //    //case 'marketName': return compare(a.marketName, b.marketName, isAsc);
    //    default: return 0;
    //  }
    //});
  }
  getLocationNames(item): any {
    if (item.standardLocation && item.standardLocation.length > 0) {
      return item.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.locationType.name;
    }
  }
}
function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
