import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditNrbesComponent } from "../edit-nrbes/edit-nrbes.component";
import { AddNrbesComponent } from "../add-nrbes/add-nrbes.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { UserRoleEnum, UserRoleUserOperation } from "../core/constant";
import { environment } from "../../environments/environment";
import { CryptographyService } from "../services/cryptography.service";
import { StandardService } from "../services/standard.service";
import { FilterList } from "../models/filterList";
import { UsefulLinkList } from "../models/usefullink-list";
import { UsefulLink } from "../models/usefullink";
import { AdminService } from "../services/admin.service";
import { FailModuleComponent } from "../fail-module/fail-module.component";

declare var $: any;
@Component({
  selector: "app-left-nav",
  templateUrl: "./left-nav.component.html",
  styleUrls: ["./left-nav.component.scss"],
})
export class LeftNavComponent implements OnInit {
  viewAllAddendum: boolean;
  viewAllGrsStandard: boolean;
  viewAllNotification: boolean;
  viewAllNrbesStandard: boolean;
  viewFaq: boolean;
  viewKeyContacts: boolean;
  showLocation: boolean;
  canAddNewNrbes: boolean;
  canAddNewGrs: boolean;
  showAddNewEquipment: boolean;
  showBuildingCapacityTool: boolean;
  viewFutureStandard: boolean;
  canAddEquipmentStandard: boolean;
  canEditEquipmentStandard: boolean;
  canDeleteEquipmentStandard: boolean;
  viewEquipmentGlobalStandard: boolean;
  viewEquipmentNationalStandard: boolean;
  viewOperationsStandard: boolean;
  viewEquipmentObsoleteStandard: boolean;
  viewUpcomingStandard: boolean;
  CanAddNationalEquipmentStandard: boolean;
  viewGlobalAdminPanel: boolean;
  viewMarketAdminPanel: boolean;
  viewAllSharedLearningsBestPractices: boolean;
  canAddABUStandards: boolean;
  canEditABUStandard: boolean;
  viewABUStandard: boolean;
  //viewAllGlobalRecomendations: boolean;
  usefulLinkList: UsefulLinkList = new UsefulLinkList();

  previousRoute: string;
  constructor(
    private route: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    public standardService: StandardService,
    private cryptographyService: CryptographyService,
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal
  ) {}
  userRole: number;
  get userRoleEnum() {
    return UserRoleEnum;
  }

  ngOnInit(): void {
    this.userRole = this.storage.get("userRole")
      ? this.cryptographyService.decryptData(this.storage.get("userRole"))
      : this.storage.get("userRole");
    this.getUsefulLinks();
    //userRoleUserOperation
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.viewAllAddendum =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllAddendum
          ) !== -1;
        this.viewAllGrsStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllGrsStandard
          ) !== -1;
        //this.viewAllGlobalRecomendations = (userRoleUserOperation.indexOf(UserRoleUserOperation.ViewAllGrsStandard) !== -1);
        this.viewAllNotification =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllNotification
          ) !== -1;
        this.viewAllNrbesStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllNrbesStandard
          ) !== -1;
        this.viewFaq =
          userRoleUserOperation.indexOf(UserRoleUserOperation.ViewFaq) !== -1;
        this.viewKeyContacts =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewKeyContacts
          ) !== -1;
        this.showLocation =
          userRoleUserOperation.indexOf(UserRoleUserOperation.ShowLocation) !==
          -1;
        this.canAddNewNrbes =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanAddNewNrbes
          ) !== -1;
        this.canAddNewGrs =
          userRoleUserOperation.indexOf(UserRoleUserOperation.CanAddNewGrs) !==
          -1;
        this.showAddNewEquipment =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ShowAddNewEquipment
          ) !== -1;
        this.showBuildingCapacityTool =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ShowBuildingCapacityTool
          ) !== -1;
        this.viewFutureStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewFutureStandard
          ) !== -1;

        this.canAddEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanAddEquipmentStandard
          ) !== -1;
        this.CanAddNationalEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanAddNationalEquipmentStandard
          ) !== -1;
        this.canEditEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditEquipmentStandard
          ) !== -1;
        this.canDeleteEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanDeleteEquipmentStandard
          ) !== -1;
        this.viewEquipmentGlobalStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewEquipmentGlobalStandard
          ) !== -1;
        this.viewEquipmentNationalStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewEquipmentNationalStandard
          ) !== -1;
        this.viewEquipmentObsoleteStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewEquipmentObsoleteStandard
          ) !== -1;
        this.viewUpcomingStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewUpcomingStandard
          ) !== -1;
        this.viewGlobalAdminPanel =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewGlobalAdminPanel
          ) !== -1;
        this.viewMarketAdminPanel =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewMarketAdminPanel
          ) !== -1;
        this.viewAllSharedLearningsBestPractices =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllSharedLearningsBestPractices
          ) !== -1;
        this.canAddABUStandards =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanAddABUStandard
          ) !== -1;
        this.canEditABUStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditABUStandard
          ) !== -1;
        this.viewABUStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewABUStandard
          ) !== -1;
        this.viewOperationsStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewOperationsStandard
          ) !== -1;
      }
    } else {
      this.viewAllAddendum = true;
      this.viewAllGrsStandard = true;
      //this.viewAllGlobalRecomendations = true;
      this.viewAllNotification = true;
      this.viewAllNrbesStandard = true;
      this.viewFaq = true;
      this.viewKeyContacts = true;
      this.showLocation = true;
      this.canAddNewNrbes = true;
      this.canAddNewGrs = true;
      this.showAddNewEquipment = true;
      this.showBuildingCapacityTool = true;
      this.viewFutureStandard = true;
      this.canAddEquipmentStandard = true;
      this.CanAddNationalEquipmentStandard = true;
      this.canEditEquipmentStandard = true;
      this.canDeleteEquipmentStandard = true;
      this.viewEquipmentGlobalStandard = true;
      this.viewEquipmentNationalStandard = true;
      this.viewEquipmentObsoleteStandard = true;
      this.viewUpcomingStandard = true;
      this.viewGlobalAdminPanel = true;
      this.viewMarketAdminPanel = true;
      this.viewAllSharedLearningsBestPractices = true;
      this.canAddABUStandards = true;
      this.canEditABUStandard = true;
      this.viewABUStandard = true;
      this.viewOperationsStandard = true;
    }

    $(document).ready(() => {
      $(".toggle-menu-button").click(function () {
        $("body").addClass("freeze");
        $(".mcd-left-panel").animate({ left: 0 });
        $(".col-left-nav").addClass("mcd-visible mcd-animate");
      });
      $(".side-nav.close-button").click(function () {
        $(".mcd-left-panel").animate(
          { left: "-300px" },
          {
            duration: 600,
            complete: function () {
              $(".col-left-nav").removeClass("mcd-animate");
              $(".col-left-nav").removeClass("mcd-visible ");
              $("body").removeClass("freeze");
            },
          }
        );
      });
      $(".mcd-nav-list li a").click(function () {
        $(".side-nav.close-button").trigger("click");
      });
    });
  }
  RedirectToFaq() {
    this.route.navigate(["/faq"]);
  }

  resetFilteration(route: string) {
    if (this.previousRoute != route) {
      let existCountryId;
      this.standardService.grsFiltration = new FilterList();
      this.standardService.minimumStandardFiltration = new FilterList();
      this.standardService.sharedLearingsFiltration = new FilterList();
      this.standardService.equipmentStandardFiltration = new FilterList();
      this.standardService.obsoleteStandardFiltration = new FilterList();
      this.standardService.locationGrsFiltration = new FilterList();
      this.standardService.upcomingStandardFiltration = new FilterList();
      this.standardService.operationsStandardFiltration = new FilterList();

      existCountryId =
        this.standardService.nationalEquipmentStandardFiltration.countryId;
      this.standardService.nationalEquipmentStandardFiltration =
        new FilterList();
      this.standardService.nationalEquipmentStandardFiltration.countryId =
        existCountryId;

      existCountryId = this.standardService.nrbesFiltrations.countryId;
      this.standardService.nrbesFiltrations = new FilterList();
      this.standardService.nrbesFiltrations.countryId = existCountryId;

      existCountryId =
        this.standardService.equipmentAddendumFiltration.countryId;
      this.standardService.equipmentAddendumFiltration = new FilterList();
      this.standardService.equipmentAddendumFiltration.countryId =
        existCountryId;

      existCountryId = this.standardService.addendumFiltration.countryId;
      this.standardService.addendumFiltration = new FilterList();
      this.standardService.addendumFiltration.countryId = existCountryId;

      this.standardService.isComingFromLocation = false;
      this.standardService.isGrs = false;
      this.standardService.locationId = 0;
    }
    this.previousRoute = route;
  }
  RedirectToKeycontact() {
    this.route.navigate(["/keycontact"]);
  }

  redirectToAddNrbes() {
    this.route.navigate(["/add-nrbes"]);
  }

  redirectToAddABU() {
    this.route.navigate(["/add-abustandard"]);
  }

  getUsefulLinks(): void {
    this.adminService.GetUsefulLinkList().subscribe((data: UsefulLinkList) => {
      this.usefulLinkList.usefullinks = data.usefullinks.map(
        (o) => new UsefulLink(o)
      );
    });
  }

  onDownloadSelectedUsefulLinkAttachment(e) {
    //debugger;
    return this.adminService.GetAttachmentUrl(e.attachment.id, false).subscribe(
      (response: any) => {
        const blob = new Blob([response], {
          type: response.type,
        });
        if (
          (window.navigator as any) &&
          (window.navigator as any).msSaveOrOpenBlob
        ) {
          (window.navigator as any).msSaveOrOpenBlob(
            blob,
            e.attachment.fileName
          );
          return;
        }
        var a = document.createElement("a");
        document.body.appendChild(a);

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = e.attachment.fileName;
        a.click();
        window.URL.revokeObjectURL(url);

        //window.open(url);
      },
      (error: any) => {
        const modalRef = this.ngbModal.open(FailModuleComponent);
        modalRef.componentInstance.errorMessage = error.error
          ? error.error.Message
          : error.message;
      }
    );
  }
}
