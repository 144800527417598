<mat-card *ngIf="!loading" class="mcd-card add-nrbes">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >Add New National Standard</mat-card-title
    >
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addNrbesForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Title"
                  name="title"
                  formControlName="title"
                  required
                />
                <mat-error
                  *ngIf="
                    addNrbesForm.get('title').touched &&
                    addNrbesForm.get('title').hasError('required')
                  "
                >
                  Title is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field *ngIf="this.userRole == 4">
                <mat-label>Country</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addNrbesForm['controls'].countryId.value"
                  #country
                  (selectionChange)="
                    addNrbesForm['controls'].countryId.setValue($event.value)
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of countries">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-5">
              <mat-form-field>
                <mat-label>Location</mat-label>
                <mat-select
                  name="dropCategory"
                  formArrayName="locationType"
                  [value]="addNrbesForm['controls'].locationTypeId.value"
                  #locationType
                  (selectionChange)="setSelectedLocationType(locationType)"
                  required
                  multiple
                >
                  <!-- <mat-option *ngFor="let item of addNrbesForm['controls'].locationType['controls']; let j=index" [value]="item.controls.id.value">{{item.controls.name.value}}</mat-option> -->
                  <mat-option
                    [value]="item.id"
                    *ngFor="let item of locationTypes"
                  >
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-5 offset-md-2">
              <mat-form-field>
                <mat-label>Category</mat-label>
                <mat-select
                  name="dropCategory"
                  [value]="addNrbesForm['controls'].categoryId.value"
                  (selectionChange)="
                    addNrbesForm['controls'].categoryId.setValue($event.value)
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of categories">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="addNrbesForm.get('categoryId').hasError('required')"
                  >Category is required</mat-error
                >
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Existing Restaurant Description"
                  name="description"
                  formControlName="standardDescription"
                  required
                />
                <mat-error
                  *ngIf="
                    addNrbesForm.get('standardDescription').touched &&
                    addNrbesForm.get('standardDescription').hasError('required')
                  "
                >
                  Description is required
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="New Restaurant Effective Date"
                  formControlName="newRestaurantEffectiveFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <!--<mat-error *ngIf="addGrsForm.get('newRestaurantEffectiveFrom').touched && addGrsForm.get('newRestaurantEffectiveFrom').hasError('required')">
        Effective date is required
      </mat-error>-->
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Description for New Restaurants"
                  name="description"
                  formControlName="newrestaurantdescription"
                  required
                />
                <mat-error
                  *ngIf="
                    addNrbesForm.get('newrestaurantdescription').touched &&
                    addNrbesForm
                      .get('newrestaurantdescription')
                      .hasError('required')
                  "
                >
                  Description is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Existing Restaurant Effective Date"
                  formControlName="effectiveFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <!--<mat-error *ngIf="addGrsForm.get('effectiveFrom').touched && addGrsForm.get('effectiveFrom').hasError('required')">
        Effective date is required
      </mat-error>-->
              </mat-form-field>
            </div>

          

            <div class="col-12">
              <mat-label>Notes *</mat-label>
              <quill-editor name="note" formControlName="note" class="quill-editor-css"> </quill-editor>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div class="col-12">
              <br />
              <br />
              <br />
              <br />
              <mat-label>Additional Information *</mat-label>
              <quill-editor
                name="additionalInformation"
                formControlName="additionalInformation"
                class="quill-editor-css"
              >
              </quill-editor>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div class="col-12">
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </form>
      <br />
      <br />
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button
        class="mcd-btn mcd-btn-primary"
        mat-raised-button
        (click)="reset()"
      >
        Reset
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        (click)="submit()"
        [disabled]="addNrbesForm.invalid"
      >
        Add
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
