import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { BasicInformationService } from "../services/basic-information.service";
import { BasicInformation } from "../models/basicInformation";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { QuestionAndAnswer } from "../models/questionsAndAnswers";
import { MatStepper } from "@angular/material/stepper";
import { DeleteBasicInformation } from "../models/deleteBasicInformation";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddFaqComponent } from "../add-faq/add-faq.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { environment } from "../../environments/environment";
import { UserRoleUserOperation } from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";

@Component({
  selector: "app-faq-module",
  templateUrl: "./faq-module.component.html",
  styleUrls: ["./faq-module.component.scss"],
})
export class FaqModuleComponent implements OnInit {
  loading: boolean = false;
  currentPageRecords: BasicInformation[];
  showAddQuestionWizard: boolean;
  needAnotherQuestion: boolean;
  titleForm: FormGroup;
  questionForm: FormGroup;
  questionsFormArray: FormArray;
  postData: BasicInformation;
  createNewWizard: boolean = true;
  deleteThread: boolean = false;
  selectedFalse: number = 2;
  editThread: boolean;
  isSavedModel: boolean;
  selectedFaqThread: any;
  questionsCountTobeEdited: number = -1;
  questionsAndAnswerListTobeEdited: QuestionAndAnswer[] = [];
  isAnotherQYesNoselected: boolean = false;
  isRedirectBack: boolean = true;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  canAddFaq: boolean;
  canDeleteFaq: boolean;
  canEditFaq: boolean;

  constructor(
    private basicInformationService: BasicInformationService,
    public fb: FormBuilder,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.cryptographyService.decryptData(
        this.storage.get("userRoleUserOperation")
      );
      this.canAddFaq =
        userRoleUserOperation.indexOf(UserRoleUserOperation.CanAddFaq) !== -1;
      this.canEditFaq =
        userRoleUserOperation.indexOf(UserRoleUserOperation.CanEditFaq) !== -1;
      this.canDeleteFaq =
        userRoleUserOperation.indexOf(UserRoleUserOperation.CanDeleteFaq) !==
        -1;
    } else {
      this.canAddFaq = true;
      this.canEditFaq = true;
      this.canDeleteFaq = true;
    }
    this.getAllBasicInformation();
  }

  openAddEditQuestionsModal(id?) {
    let modalRef: any;
    if (id) {
      this.getBasicInformation(id).subscribe((o) => {
        modalRef = this.ngbModal.open(AddFaqComponent);
        modalRef.componentInstance.postData = new BasicInformation(o);
        modalRef.componentInstance.addFaqPopupEmit.subscribe(() => {
          this.getAllBasicInformation();
        });
        modalRef.componentInstance.deleteWholePopupEmit.subscribe((item) => {
          this.postData = item;
          this.deleteFAQ();
        });
      });
    } else {
      modalRef = this.ngbModal.open(AddFaqComponent);
      modalRef.componentInstance.addFaqPopupEmit.subscribe(() => {
        this.getAllBasicInformation();
      });
    }
  }

  getBasicInformation(id: number) {
    return this.basicInformationService.getBasicInformation(id);
  }

  getAllBasicInformation() {
    this.loading = true;
    this.basicInformationService
      .getAllBasicInformation()
      .subscribe((data: BasicInformation[]) => {
        this.currentPageRecords = data.map((o) => new BasicInformation(o));
        this.loading = false;
      });
  }

  openDeletePopup(content, item) {
    this.postData = item;
    this.ngbModal.open(content);
  }

  deleteFAQ() {
    var deleteBasicInfo = new DeleteBasicInformation();
    deleteBasicInfo.id = this.postData.id;
    deleteBasicInfo.isInfoSetDeleted = true;
    this.basicInformationService
      .updateBasicInformation(deleteBasicInfo)
      .subscribe(() => {
        this.ngbModal.dismissAll();
        this.getAllBasicInformation();
      });
  }
}
