import { Component, OnInit, Inject } from "@angular/core";
import { KeyContactService } from "../services/key-contact.service";
import { KeyContactModel } from "../models/key-contact-model";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { AddContactComponent } from "../add-contact/add-contact.component";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { Observable } from "rxjs";
import { UserRoleUserOperation } from "../core/constant";
import { environment } from "../../environments/environment";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { CryptographyService } from "../services/cryptography.service";

@Component({
  selector: "app-key-contacts",
  templateUrl: "./key-contacts.component.html",
  styleUrls: ["./key-contacts.component.scss"],
})
export class KeyContactsComponent implements OnInit {
  loading: boolean = false;
  keyContacts: KeyContactModel[] = [];
  isSuccess: boolean = false;
  error: string = "";
  selectedItemForDelete: KeyContactModel;
  canAddKeyContacts: boolean;
  modalOption: NgbModalOptions = {};
  canEditKeyContacts: boolean;
  canDeleteKeyContacts: boolean;

  constructor(
    private keyContactService: KeyContactService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    this.getKeyContacts();
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.cryptographyService.decryptData(
        this.storage.get("userRoleUserOperation")
      );
      this.canAddKeyContacts =
        userRoleUserOperation.indexOf(
          UserRoleUserOperation.CanAddKeyContacts
        ) !== -1;
      this.canEditKeyContacts =
        userRoleUserOperation.indexOf(
          UserRoleUserOperation.CanEditKeyContacts
        ) !== -1;
      this.canDeleteKeyContacts =
        userRoleUserOperation.indexOf(
          UserRoleUserOperation.CanDeleteKeyContacts
        ) !== -1;
    } else {
      this.canAddKeyContacts = true;
      this.canEditKeyContacts = true;
      this.canDeleteKeyContacts = true;
    }
  }

  /**
   * Open add edit popup
   * @return void
   */
  openAddEditModal(keyContactId: number = 0) {
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    const modalRef = this.ngbModal.open(AddContactComponent, this.modalOption);
    modalRef.componentInstance.keyContactModel = new KeyContactModel();
    if (keyContactId) {
      this.loading = true;
      this.getAKeyContact(keyContactId).subscribe((data: KeyContactModel) => {
        modalRef.componentInstance.keyContactModel = data;
        modalRef.componentInstance.keyContactForm.controls.name.setValue(
          data.name
        );
        modalRef.componentInstance.keyContactForm.controls.email.setValue(
          data.email
        );
        modalRef.componentInstance.keyContactForm.controls.contentArea.setValue(
          data.contentArea
        );
        this.loading = false;
        (error) => (this.error = error);
      });
    }

    // Add edit popup save trigger
    modalRef.componentInstance.contactAddEditPopupEmit.subscribe(
      (receivedEntry) => {
        this.loading = true;
        if (receivedEntry.id > 0) {
          this.updateAKeyContact(receivedEntry).subscribe(() => {
            this.getKeyContacts();
            this.ngbModal.dismissAll();
          });
        } else
          this.addAKeyContact(receivedEntry).subscribe(() => {
            this.getKeyContacts();
            this.ngbModal.dismissAll();
          });
      }
    );
  }

  /**
   * Open delete popup
   * @return void
   */
  openDeletePopup(content, item) {
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.selectedItemForDelete = item;
    this.ngbModal.open(content, this.modalOption);
  }

  /**
   * Delete The Selected Item
   * @return void
   */
  deleteTheSelectedItem() {
    this.loading = true;
    this.deleteAKeyContact(this.selectedItemForDelete).subscribe(() => {
      this.getKeyContacts();
      this.ngbModal.dismissAll();
    });
  }

  /**
   * Get key contacts
   * @return void
   */
  getKeyContacts(): void {
    this.loading = true;
    this.keyContactService
      .getKeyContacts()
      .subscribe((data: KeyContactModel[]) => {
        this.keyContacts = data.map((o) => new KeyContactModel(o));
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  /**
   * Get a key contact
   * @return void
   */
  getAKeyContact(keyContactId: number): Observable<KeyContactModel> {
    return this.keyContactService.getKeyContactInfomationByKeyContactId(
      keyContactId
    );
  }

  /**
   * Add a key contact
   * @return void
   */
  addAKeyContact(keyContactmdoel: KeyContactModel): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.keyContactService.addKeyContact(keyContactmdoel).subscribe(
        (data: boolean) => {
          this.isSuccess = data;
          observer.next(true);

          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.message =
            "You have successfully added the key contact.";

          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
          });
        },
        (error) => {
          this.error = error;
          observer.next(false);
        }
      );
    });
  }

  /**
   * Update a key contact
   * @return void
   */
  updateAKeyContact(keyContactmdoel: KeyContactModel): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.keyContactService.updateKeyContact(keyContactmdoel).subscribe(
        (data: boolean) => {
          this.isSuccess = data;
          observer.next(true);

          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.message =
            "You have successfully updated key contact.";

          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
          });
        },
        (error) => {
          this.error = error;
          observer.next(false);
        }
      );
    });
  }

  /**
   * delete a key contact
   * @return void
   */
  deleteAKeyContact(keyContactId: KeyContactModel): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.keyContactService.deleteKeyContact(keyContactId).subscribe(
        (data: boolean) => {
          this.isSuccess = data;
          observer.next(true);
        },
        (error) => {
          this.error = error;
          observer.next(false);
        }
      );
    });
  }
}
