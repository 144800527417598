export class Constant {
  public static AddendumTemplateName: string = "AddendumTemplate.docx";
  public static GlobalNotificationGroupName: string = "GlobalNotificationGroup";

  public static UserId: number;
  public static PageSizes = [
    { name: "Show 10 Items", value: 10 },
    { name: "Show 15 Items", value: 15 },
    { name: "Show 20 Items", value: 20 },
    { name: "Show 25 Items", value: 25 },
    { name: "Show 50 Items", value: 50 },
    { name: "Show 100 Items", value: 100 },
  ];
}

export enum UserRoleUserOperation {
  ShowLocation = 1,
  CanAddNewGrs = 2,
  CanEditGrs = 3,
  ShowAddNewEquipment = 4,
  ViewAllGrsStandard = 5,

  //Nrbes
  ShowAddNewNrbes = 6,
  CanAddNewNrbes = 7,
  CanUpdateNrbes = 8,
  ViewAllNrbesStandard = 9,

  //Notification
  ViewAllNotification = 10,

  //BuildingCapacityTool
  ShowBuildingCapacityTool = 11,

  //Faq
  ViewFaq = 12,
  CanAddFaq = 13,
  CanEditFaq = 14,
  CanDeleteFaq = 15,

  //KeyContacts
  ViewKeyContacts = 16,
  CanAddKeyContacts = 17,
  CanEditKeyContacts = 18,
  CanDeleteKeyContacts = 19,

  //Addendum
  ViewAllAddendum = 20,
  CanAddAddendum = 21,
  CanUpdateAddendum = 22,
  ViewFutureStandard = 23,

  //Equipment Standard
  CanAddEquipmentStandard = 24,
  CanEditEquipmentStandard = 25,
  CanDeleteEquipmentStandard = 26,
  ViewEquipmentGlobalStandard = 27,
  ViewEquipmentNationalStandard = 28,
  ViewEquipmentObsoleteStandard = 29,
  CanAddNationalEquipmentStandard = 30,
  ViewUpcomingStandard = 31,
  CanEditNationalEquipmentStandard = 32,
  CanDeleteNationalEquipmentStandard = 33,
  CanRemoveEquipmentStandard = 34,
  ViewGlobalAdminPanel = 35,
  ViewMarketAdminPanel = 36,
  ViewManageSuppliers = 37,
  ViewAllSuppliers = 38,
  CanAddSupplier = 39,
  CanEditSupplier = 40,
  CanDeleteSupplier = 41,
  ViewAllEquipmentType = 42,
  CanAddEquipmentType = 43,
  CanEditEquipmentType = 44,
  ViewAllCategory = 45,
  CanAddCategory = 46,
  CanEditCategory = 47,
  CanDeleteCategory = 48,
  ViewAllUser = 49,
  CanAddUser = 50,
  CanEditUser = 51,
  CanDeleteUser = 52,
  ViewUserDetail = 53,
  ViewSupplierDetail = 54,
  ViewAllNationalSuppliers = 55,
  CanAddNationalSupplier = 56,
  CanEditNationalSupplier = 57,
  ViewNationalSupplierDetail = 58,
  CanDeleteNationalSupplier = 59,
  CanDeleteEquipmentType = 60,
  ViewAllSharedLearningsBestPractices = 61,
  CanAddABUStandard = 62,
  CanEditABUStandard = 63,
  CanDeleteABUStandard = 64,
  ViewABUStandard = 65,
  CanAddOperationsStandard = 66,
  CanEditOperationsStandard = 67,
  CanDeleteOperationsStandard = 68,
  ViewOperationsStandard = 69,
  ViewAllUsefulLinks = 70,
  CanAddUsefulLink = 71,
  CanEditUsefulLink = 72,
  CanDeleteUsefulLink = 73,
  ViewUsefulLinkDetail = 74,
  CanUpdateApplicationStatement = 75,
}

export enum AddendumStatusEnum {
  Requested = 1,
  Approved = 2,
  Rejected = 3,
  Expired = 4,
  DraftAfterRequest = 5,
  DraftBeforeRequest = 6,
}

export enum StandardType {
  GlobalRecommendations = 1,
  MarketBestPractices = 2,
  LocalStandard = 3,
  GlobalMinimumRestaurantStandard = 4,
  EquipmentStandard = 5,
  OperationsStandard = 6,
}

export enum UserRoleEnum {
  GlobalAdmin = 1,
  MarketAdmin = 2,
  BasicUser = 3,
  AOWAdmin = 4,
}

export enum UserRole {
  GlobalAdmin = 1,
  MarketAdmin = 2,
  BasicUser = 3,
}

export enum FilteringEnum {
  FilterByLocationType = 1,
  FilterByCategory = 2,
  FilterByStandardType = 3,
  FilterByEquipmentType = 4,
  FilterBySubLocationType = 5,
}

export enum StandardTypeEnum {
  GlobalRecommendations = 1,
  MarketBestPractices = 2,
  LocalStandard = 3,
  GlobalMinimumRestaurantStandard = 4,
  GlobalEquipmentStandard = 5,
  LocalEquipmentStandard = 6,
  ABUStandard = 7,
  OperationsStandards = 8,
}

export enum RygStandardEnum {
  Red = 1,
  Yellow = 2,
  Green = 3,
}

export enum CategoryEnum {
  BuildingAndSite = 1,
  OperatingPlatforms = 2,
  PeopleAndTraining = 3,
  SafetyAndSecurity = 4,
  Sustainability = 5,
  Technology = 6,
  FoodSafety = 7,
  Equipment = 8,
  GlobalDevelopment = 9,
  Operations = 10,
}

export enum EquipmentClassEnum {
  Core = 1,
  Local = 2,
}

export enum LocationTypeEnum {
  ParkingLot = 1,
  DriveThru = 2,
  ManagerSpace = 3,
  CrewSpace = 4,
  BackRoom = 5,
  RestRooms = 6,
  Storage = 7,
  Kitchen = 8,
  Lobby = 9,
  FrontCounter = 10,
  PlayPlace = 11,
  BuildingExterior = 12,
  EntireBuilding = 13,
  Beverage = 14,
  McCafe = 15,
  Delivery = 16,
  Curbside = 17,
}

export enum StandardListType {
  Obsolete = 1,
  Minimum = 2,
  Upcoming = 3,
  GlobalEquipment = 4,
  NationalEquipment = 5,
  National = 6,
  Location = 7,
  DependentStandard = 8,
  GlobalRecomendation = 9,
  SharedLearningsAndRecomendations = 10,
  OperationsStandard = 11,
  AllStandards = 12,
}

export enum MasterDataRequestedType {
  AddGlobalStandard = 1,
  AddNationalStandard = 2,
  AddNationalEquipmentStandard = 3,
  AddGlobalEquipmentStandard = 4,
}

export enum AllStandardsTabs {
  GlobalRecommendations = 1,
  OperationsStandard = 2,
  BuildingStandards = 3,
  EquipmentStandards = 4,
}
