import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FilteringGuide } from "../models/filteringGuide";
import { StandardService } from "../services/standard.service";
import {
  StandardListType,
  FilteringEnum,
  CategoryEnum,
} from "../core/constant";
import { Standard } from "../models/standard";
import { StandardList } from "../models/standard-list";
import { Router, ActivatedRoute } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Location } from "@angular/common";
import { LocationType } from "../models/location-type";

@Component({
  selector: "app-equipment-list",
  templateUrl: "./equipment-list.component.html",
  styleUrls: ["./equipment-list.component.scss"],
})
export class EquipmentListComponent implements OnInit {
  page: number = 1;
  locationId: number = 0;
  pageSize: number = 4;
  collectionSize: number = 0;
  isDeleted: boolean = false;
  loading: boolean = false;
  standards: Standard[] = [];
  filterList: FilteringGuide[] = [];

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  locationName: string;
  constructor(
    private standardService: StandardService,
    private route: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private activeRoute: ActivatedRoute
  ) {
    this.locationId = parseInt(
      this.activeRoute.snapshot.paramMap.get("locationId")
    );
  }
  ngOnInit() {
    this.filterList =
      this.standardService.equipmentStandardFiltration.filterSet;
    this.setDefaultFiltration();
    this.getAllEquipmentStandards();
  }

  receivedFilteration($event) {
    this.page = 1;
    this.filterList = $event;
    this.standardService.equipmentStandardFiltration.filterSet =
      this.filterList;
    this.getAllEquipmentStandards();
  }

  getAllEquipmentStandards() {
    this.loading = true;
    this.standardService
      .GetLocationTypeByLocationTypeId(this.locationId)
      .subscribe((data: LocationType) => {
        this.locationName = data.name;
      });
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.Location,
        this.filterList
      )
      .subscribe((data: StandardList) => {
        //debugger;
        this.standards = [];
        this.standards = data.standardDetailList.map(
          (obj) => new Standard(obj)
        );
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.loading = false;
        };
      });
  }

  redirectToEdit(standardId) {
    this.route.navigate(["/edit-eq-standards-global", standardId]);
  }

  setDefaultFiltration() {
    this.standardService.equipmentStandardFiltration.filterSet = null;
    let categoryFilteringGuide = new FilteringGuide();
    let locationFilteringGuide = new FilteringGuide();
    let filteringGuides = new Array<FilteringGuide>();
    locationFilteringGuide.type = FilteringEnum.FilterByLocationType;
    locationFilteringGuide.ids.push(this.locationId);
    categoryFilteringGuide.type = FilteringEnum.FilterByCategory;
    categoryFilteringGuide.ids.push(CategoryEnum.Equipment);
    filteringGuides.push(categoryFilteringGuide);
    filteringGuides.push(locationFilteringGuide);
    this.filterList = filteringGuides;
    this.standardService.equipmentStandardFiltration.filterSet =
      filteringGuides;
    this.storage.set("locationGrsfilterSet", JSON.stringify(this.filterList));
  }

  onMoreClick(Id) {
    this.standardService.isComingFromLocation = true;
    this.route.navigate(["/equipment-detail-view/" + Id]);
  }
}
