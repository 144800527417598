<mat-card class="mcd-card">
    <mat-card-header class="mcd-card-header">
      <mat-card-title class="mcd-card-title">Locations</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mcd-card-content">
        <div class="mcd-viewport-wrapper">
            <div class="mcd-view-port" style="background-image: url('/assets/images/locations/guide-i3mage.png'); background-repeat: no-repeat; background-size: contain; background-position: 0px 0px;">
              <img class="main-image" src="/assets/images/locations/png/guide-image.png" style="top: -8px;
              top: 38px;
    left: 47px;
    opacity: 1;position: absolute; width:1000px; z-index: 2;" usemap="#image-map">

          <div class="tooltip-text">
              <span>Storage</span>
          </div>

            <div id="Storage" class="chunk storage d-none">
              <img src="/assets/images/locations/png/storage.png">

            </div>
            <div id="backroom" class="chunk backroom d-none">
              <img src="/assets/images/locations/png/backroom.png">
            </div>
            <div id="kitchen" class="chunk kitchen d-none">
              <img  src="/assets/images/locations/png/kitchen.png">
            </div>
            <div id="Bathroom" class="chunk bathroom d-none">
              <img src="/assets/images/locations/png/bathroom.png">
            </div>
            <div id="CrewSpace" class="chunk crew  d-none">
              <img src="/assets/images/locations/png/crew.png">
            </div>
            <div id="cashier" class="chunk cashier d-none">
              <img  src="/assets/images/locations/png/cashier.png">
            </div>
            <div id="front-counter" class="chunk front-counter d-none">
              <img  src="/assets/images/locations/png/front-counter.png">

            </div>
            <div id="play-area" class="chunk play-area d-none">
              <img  src="/assets/images/locations/png/play-area.svg">

            </div>
            <div id="beverage" class="chunk beverage d-none">
                <img  src="/assets/images/locations/png/beverage.png">

              </div>

              <div id="manager" class="chunk manager d-none">
                  <img  src="/assets/images/locations/png/manager.png">

                </div>
                <div id="drivethru" class="chunk drivethru d-none">
                    <img  src="/assets/images/locations/png/drivethru.png">
                  </div>

              <map id="image-map" class="image-map" name="image-map" style="z-index: 10000;">
                  <area target="#play-area" alt="PlayArea" target="#play-area" title="" href="#" coords="750,743,745,667,518,487,397,556,394,620,448,681,492,716,620,811" shape="poly">
                  <area target="#front-counter" alt="FrontCounter" title="" href="#" coords="860,598,639,418,638,365,607,373,476,275,338,352,388,379,445,427,355,476,422,538,519,489,743,666" shape="poly">
                  <area target="#beverage" alt="Beverage" title="" href="#" coords="748,242,1007,458,1009,522,922,566,900,522,897,464,842,420,823,433,696,326,733,298,702,265" shape="poly">
                  <area target="#kitchen" alt="kitchen" title="" href="#" coords="519,175,515,120,533,133,579,109,611,134,569,163,730,296,690,326,686,387,675,397,656,382,640,392,635,363,608,371,479,271,464,275,463,245,432,223" shape="poly">
                  <area target="#cashier" alt="Cashier" title="" href="#" coords="910,571,907,644,854,673,852,599,640,420,658,386,681,398,690,390,695,326,821,430,843,422,894,463,901,521,919,566" shape="poly">
                  <area target="#Bathroom" alt="Bathroom" title="" href="#" coords="304,309,389,383,443,426,351,479,354,549,332,564,181,447,181,379" shape="poly">
                  <area target="#backroom" alt="Backroom" title="" href="#" coords="140,170,264,267,302,307,182,372,179,440,15,307,17,234" shape="poly">
                  <area target="#Storage" alt="Storage" title="" href="#" coords="418,19,138,168,287,286,298,288,297,258,406,198,433,217,521,177,517,118,469,77,480,67" shape="poly">
                  <area target="#drivethru" alt="DriveThru" title="" href="#" coords="512,50,579,106,538,128,473,76" shape="poly">
                  <area target="#manager" alt="Manager" title="" href="#" coords="616,135,574,159,656,223,693,257,694,200" shape="poly">
                  <area target="#CrewSpace" alt="CrewSpace" title=""  href="#" coords="463,281,463,249,406,203,390,204,369,216,303,256,300,293,325,323,350,343,397,320" shape="poly">
              </map>
            </div>
          </div>
    </mat-card-content>
  </mat-card>
