
export class CreateNewABUStandard {
  standardTypeId: number;
  title: string;
  createdUserId: number;
  categoryId: number;
  locationTypeId: number;
  standardStatusId: number;
  description: string;
  newrestaurantDescription: string;
  note: string;
  additionalInformation: string;
  aowGroupId: number;
  effectiveFrom: string;
  newRestaurantsEffectiveFrom: string;
}
