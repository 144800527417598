import { Injectable, Inject } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  public data: any = [];
  ProjectList = [];
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {}

  saveUserPrivileges(key, val): void {
    var operations = this.storage.get("operation");
    if (operations) {
      operations[key] = val;
    } else {
      operations = [];
      operations[key] = val;
    }
    this.storage.set("operations", operations.operations);
  }

  getFromLocal(key): any {
    this.data[key] = this.storage.get(key);
    return this.data;
  }
  isExist(key): Boolean {
    var s = this.storage.get(key);
    if (s != null) {
      return true;
    } else {
      return false;
    }
  }
}
