<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View All Wish Standard</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content nrbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/wishlist-list" routerLinkActive="active">All</a></li>
          <li class="separator">></li>
          <li class="active">{{grsDetail.standard.title}}</li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div *ngIf="!loading" class="nrbes-detail-view">
      <!-- Dynamic Wrapper -->
      <div class="nrbes-detail-item">
        <h1>{{grsDetail.standard.title}}</h1>
        <div class="nrbes-description">
          <h2>Description</h2>
          <p>
            {{grsDetail.standard.description}}
          </p>
        </div>
        <div class="nrbes-Benefit">
          <h2>Benefit</h2>
          <p>
            {{grsDetail.standard.note}}
          </p>
        </div>
        <div class="hash-tag">
          #{{grsDetail.standard.category?.name}}
        </div>
      </div>
      <!-- End Dynamic Wrapper -->
      <!-- Next Previous Buttons-->
      <div class="row">
        <div class="col">
        </div>
        <div class="col text-right">
          <div class="btn-wrapper">
            <div class="prev-button" *ngIf="!grsDetail.isFirst">
              <a href="javascript:void(0)" (click)="slide(grsDetail.standard.id, false)"> <img
                  src="/assets/images/back.svg"></a>
            </div>
            <div class="next-button" *ngIf="!grsDetail.isLast">
              <a href="javascript:void(0)" (click)="slide(grsDetail.standard.id, true)"> <img
                  src="/assets/images/next.svg"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>