<mat-card  class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Add Exception </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="mcd-addendum">
      <h3 class="mcd-addendum-title">{{addendum.equipmentStandard?.title}}</h3>
  <p class="mcd-medium-text">
    {{addendum.equipmentStandard?.description}}
  </p>
  <div class="mcd-gray-box">
    <ul>
      <li>
        <span class="icon"><img src="/assets/images/location.svg"></span>
        <span>{{addendum.equipmentStandard?.locationType?.name}}</span>
      </li>
      <li>
        <span class="icon"><img src="/assets/images/description.svg"></span>
        <span>{{addendum.equipmentStandard?.note}}</span>
      </li>
    </ul>
  </div>
      <form [formGroup]='addendumForm'>
        <div class="mcd-addendum-notes">
          <div class="row">
            <div class="col-5">
              <div class="row">
                <div class="col-12">
                  <span>{{addendum.equipmentStandard?.model}}</span>
                </div>
                <div class="col-12">
                  <span>{{addendum.equipmentStandard?.equipmentType?.name}}</span>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row">
                <div class="col">
                  <img title="Equipment Image" src="equipmentImage.png">
                </div>
                <div class="col">
                  <img title="supplier Image" src="supplierImage.png">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <mat-form-field>
                <input matInput placeholder="Exception Description" name="description" formControlName="description">
              </mat-form-field>
            </div>
            <div class="col-md-10">
              <mat-form-field>
                <input matInput placeholder="Exception Notes" name="note" formControlName="note">

              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="mcd-addendum-status">
          <div class="row attachements">
            <!--<div class="col">
              <a href="/Templates/AddendumTemplate.docx">
                <span>
                  <img alt="Attached Template"
                       src="/assets/images/download.svg">
                </span>
                Exception Template
              </a>
            </div>-->
            <div class="col ">
              <a href="javascript:void(0);" (click)="fileInput.click()">
                <span>
                  <img alt="Template"
                       src="/assets/images/upload.svg">
                </span>Attach
                Exception File
              </a>
              <div *ngIf="addendum?.addendumAttachment[0]?.fileName" class="ulploaded-file">
                <span>
                  <a href="javascript:void(0);"
                     (click)="onDownloadSelectedAddendumDocument()">{{addendum?.addendumAttachment[0]?.fileName}}</a>
                </span>
                <span>
                  <a href="javascript:void(0);" (click)="onRemoveAddendumDocument()">
                    <span class="delete-icon">
                      <i _ngcontent-c7="" class="material-icons"> cancel</i>
                    </span>
                  </a>
                </span>
              </div>
              <input hidden type="file" (change)="onSelectAAddendumDocument($event)" #fileInput>
              <mat-error *ngIf="invalidFile">
                {{invalidFileMessage}}
              </mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-footer class="mcd-card-footer">
    <button class="mcd-btn mcd-btn-gray" *ngIf="userRole != userRoleEnum.GlobalAdmin" (click)="saveAsADraft()">
      Save as a Draft </button>
    <button class="mcd-btn mcd-btn-secondary"  mat-raised-button (click)="submitTheAddendum()"> Submit </button>

  </mat-card-footer>
</mat-card>
<!-- <app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader> -->
