<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Key Contacts</mat-card-title>
    <button
      *ngIf="canAddKeyContacts"
      class="mcd-card-add-btn"
      mat-button
      (click)="openAddEditModal()"
    >
      <i class="material-icons"> add</i>
    </button>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="key-contacts-wrapper" *ngIf="keyContacts.length > 0">
      <div
        class="panel panel-default panel-horizontal"
        *ngFor="let item of keyContacts"
      >
        <div class="panel-heading">
          <span class="mcd-contacts-avatar">{{ item.avatarLetters }}</span>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-8">
              <div class="contacts-name">{{ item.name }}</div>
              <a [attr.href]="item.outlookEmailHyperLink">
                <span class="contacts-email">{{ item.email }}</span></a
              >
            </div>
            <div class="col-4">
              <span class="contacts-designation">{{ item.contentArea }}</span>
            </div>
          </div>
        </div>
        <div
          class="panel-footer"
          *ngIf="canDeleteKeyContacts || canEditKeyContacts"
        >
          <a
            class="mcd-more-btn"
            [matMenuTriggerFor]="menu"
            href="javascript:void(0);"
            ><i class="material-icons">more_vert</i></a
          >
          <mat-menu #menu="matMenu">
            <button
              *ngIf="canEditKeyContacts"
              mat-menu-item
              (click)="openAddEditModal(item.id)"
            >
              Edit
            </button>
            <button
              *ngIf="canDeleteKeyContacts"
              mat-menu-item
              (click)="openDeletePopup(content, item)"
            >
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div *ngIf="keyContacts.length == 0">
      Sorry you do not have any key contact.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mcd-delete-dialog-content">
      <div class="row">
        <div class="col-3 delete-icon-wrapper">
          <img alt="Delete" src="/assets/images/mcd-trash.svg" />
        </div>
        <div class="col-9">
          <p class="mcd-large-text">Do you want to delete this contact?</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mcd-btn mcd-btn-primary"
      mat-raised-button
      color="accent"
      (click)="deleteTheSelectedItem()"
    >
      Yes
    </button>
    <button
      class="mcd-btn mcd-btn-secondary"
      mat-raised-button
      color="warn"
      (click)="modal.close()"
    >
      No
    </button>
  </div>
</ng-template>
