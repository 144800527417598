
  <div class="modal-dialog mcd-add-contact-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="!keyContactModel.id">Add a Contact</h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="keyContactModel.id > 1">Edit the Contact</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]='keyContactForm'>
          <div class="mcd-add-contacts">
            <div class="row">
              <div class="col-12">
                <h3 class="mcd-faq-contact-title text-center">Please enter the Contact Details</h3>
                <mat-form-field>
                  <input matInput placeholder="Name" name="name" formControlName="name">
                  <mat-error
                    *ngIf="keyContactForm.get('name').touched && keyContactForm.get('name').hasError('required')">
                    Name is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field>
                  <input matInput placeholder="Content Area" name="contentArea" formControlName="contentArea">
                  <mat-error
                    *ngIf="keyContactForm.get('contentArea').touched && keyContactForm.get('contentArea').hasError('required')">
                    Content Area is required</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field>
                  <input matInput placeholder="Email" name="email" formControlName="email">
                  <mat-error *ngIf="keyContactForm.invalid">{{getEmailValidationErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button class="mcd-btn mcd-btn-primary" mat-raised-button (click)="activeModal.close()">Cancel</button>
        <button class="mcd-btn mcd-btn-secondary" mat-raised-button [disabled]="keyContactForm.invalid"
          (click)="saveContact()">Save</button>
      </div>
    </div>
  </div>

