import { Standard } from "./standard";

export class DependentStandard {
    id: number;
    minimumStandadId: number;
    dependentStandardId: number;
    title: string;
    constructor(obj?: DependentStandard) {
      if (obj) {
        this.id = obj.id;
        this.minimumStandadId = obj.minimumStandadId;
        this.dependentStandardId = obj.dependentStandardId;
        this.title = obj.title;
      }
    }
}
