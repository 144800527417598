<div class="row">
  <div *ngIf="standardService.isGrs" class="col-md-12">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Category</div>
      <mat-checkbox *ngFor="let item of categories" [checked]="setCheckedStateForCheckBoxes(2, item.id)" (change)="addFilter($event, item.id, 2)">{{item.name}}</mat-checkbox>
    </div>
  </div>

  <div *ngIf="!standardService.isGrs" class="col-md-6">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Category</div>
      <mat-checkbox *ngFor="let item of categories" [checked]="setCheckedStateForCheckBoxes(2, item.id)" (change)="addFilter($event, item.id, 2)">{{item.name}}</mat-checkbox>
    </div>
  </div>

  <div *ngIf="!standardService.isGrs" class="col-md-6 last-filter-wrapper">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Location</div>
      <mat-checkbox *ngFor="let item of locationTypes" [checked]="setCheckedStateForCheckBoxes(1, item.id)" (change)="addFilter($event, item.id, 1)">{{item.name}}</mat-checkbox>
    </div>
  </div>
</div>
