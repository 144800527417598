import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { StandardService } from "../services/standard.service";
import { NrbesItem } from "../models/nrbesItem";
import { EditNrbesComponent } from "../edit-nrbes/edit-nrbes.component";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Standard } from "../models/standard";
import { NrbesSet } from "../models/nrbesSet";
import { Category } from "../models/category";
import { LocationType } from "../models/location-type";
import { Country } from "../models/country";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Router } from "@angular/router";
import {
  Constant,
  UserRoleEnum,
  AddendumStatusEnum,
  StandardTypeEnum,
  StandardListType,
} from "../core/constant";
import { FilteringGuide } from "../models/filteringGuide";
import { CryptographyService } from "../services/cryptography.service";
import { AddendumService } from "../services/addendum.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { Observable } from "rxjs";
import { Sort } from "@angular/material/sort";
declare var $: any;
// export interface Country {
//   id: number;
//   name: string;
// }
@Component({
  selector: "app-nrbes-list",
  templateUrl: "./nrbes-list.component.html",
  styleUrls: ["./nrbes-list.component.scss"],
})
export class NrbesListComponent implements OnInit {
  loading: boolean = false;
  selectedCountryId: number = 0;
  userRole: number;
  userId: number;
  grses: Standard[] = [];
  isDeleted: boolean = false;
  countries: Country[] = [];
  page = 1;
  pageSize = 20;
  collectionSize: number = 0;
  isDownloading: any = false;
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get addendumStatusEnum() {
    return AddendumStatusEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  pageSizes: any[] = Constant.PageSizes;

  nrbesSet: NrbesSet;
  nrbesItems: NrbesItem[] = [];
  countryList: Country[] = [];
  categoryList: Category[] = [];
  locationTypeList: LocationType[] = [];

  filterList: FilteringGuide[] = [];
  searchKey: string = "";
  constructor(
    private standardService: StandardService,
    private ngbModal: NgbModal,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private addendumService: AddendumService,
    private route: Router,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    //debugger;
    this.addendumService.isCommingFromNotification = false;
    this.filterList = this.standardService.nrbesFiltrations.filterSet;
    this.searchKey = this.standardService.nrbesFiltrations.searchKey;
    this.pageSize = this.standardService.nrbesFiltrations.pageSize;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.getMasterDataForNrbes().subscribe(() => {
      //TODO : All Countries
      this.selectedCountryId =
        this.userRole == this.userRoleEnum.MarketAdmin ||
        this.userRole == this.userRoleEnum.BasicUser
          ? this.cryptographyService.decryptData(this.storage.get("countryId"))
          : this.standardService.nrbesFiltrations.countryId
          ? this.standardService.nrbesFiltrations.countryId
          : this.countries != null
          ? this.countries[0].id
          : this.cryptographyService.decryptData(this.storage.get("countryId"));
      this.storage.set(
        "selectedCountryId",
        this.cryptographyService.encryptData(this.selectedCountryId)
      );
      this.getAllNrbesBySelectedCountryId();
    });
    $(window).scroll(function (event) {
      let st = $(this).scrollTop();
      if (st > 199) {
        $(".filter-panel-row").addClass("freeze");
      } else {
        $(".filter-panel-row").removeClass("freeze");
        $(".filter-panel-row").removeClass("freeze");
      }
    });
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.nrbesFiltrations.pageSize = event.value;
    this.getAllNrbesBySelectedCountryId();
  }

  getMasterDataForNrbes(): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.standardService
        .GetMasterDataForAddNrbes(
          this.cryptographyService.decryptData(this.storage.get("userRole")),
          this.cryptographyService.decryptData(this.storage.get("userId"))
        )
        .subscribe((data: any) => {
          this.countries = data.countries;
          this.categoryList = data.categories;
          this.locationTypeList = data.locationTypes;
          this.loading = false;
          observer.next();
        });
    });
  }

  selectCountry(event) {
    //debugger;
    this.page = 1;
    this.selectedCountryId = event.value;
    this.standardService.nrbesFiltrations.countryId = event.value;
    this.storage.set(
      "selectedCountryId",
      this.cryptographyService.encryptData(this.selectedCountryId)
    );
    this.getAllNrbesBySelectedCountryId();
  }

  getAllNrbesBySelectedCountryId() {
    this.loading = true;
    this.standardService
      .getNrbesByCountryId(
        this.userId,
        this.selectedCountryId,
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.filterList,
        this.standardService.nrbesFiltrations.searchKey
      )
      .subscribe((data: NrbesSet) => {
        this.nrbesSet = data;
        this.nrbesItems = data.nrbes;
        console.log("this.nrbesItems", this.nrbesItems);
        this.collectionSize = data.count;
        this.loading = false;
      });
  }

  redirectToEdit(standardid) {
    this.route.navigate(["edit-nrbes", standardid]);
  }
  redirectToClone(nrbesId) {
    this.route.navigate(["/clone-nrbes", nrbesId]);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    if (
      !sort.active ||
      sort.direction === "" ||
      !(this.nrbesItems.length > 0)
    ) {
      return;
    }

    const isAsc = sort.direction === "asc";
    this.nrbesItems.sort((a, b) => {
      switch (sort.active) {
        case "title":
          return this.compare(a.standard.title, b.standard.title, isAsc);
        case "category":
          return this.compare(
            a.standard.category.name,
            b.standard.category.name,
            isAsc
          );
        case "desc":
          return this.compare(
            a.standard.description,
            b.standard.description,
            isAsc
          );
      }
    });
  }
  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    modalRef.componentInstance.deleteItem = item;

    modalRef.componentInstance.deletePopupEmit.subscribe((receivedEntry) => {
      if (receivedEntry != null) {
        this.isDeleted = true;
        var nrbes = new NrbesItem();
        nrbes.id = receivedEntry.id;
        nrbes.standardId = receivedEntry.standard.id;
        nrbes.isDeleted = true;
        this.loading = true;
        this.standardService
          .removeStandardFromNrbes(nrbes.id)
          .subscribe((data: any) => {
            this.isDeleted = false;
            this.loading = false;
            this.page = 1;
            this.getAllNrbesBySelectedCountryId();
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  actionMenuOpen(event) {
    event.stopPropagation();
  }

  /**
   * Row selected
   * @return void
   */
  rowSelected(nrbesId: number): void {
    this.route.navigate(["/nrbes-view/" + nrbesId]);
  }

  /**
   * Add Addendum
   * @return void
   */
  addAddendum(standardId: number, countryId: number, event): void {
    //debugger;
    this.storage.set(
      "adCountryId",
      this.cryptographyService.encryptData(countryId)
    );
    event.stopPropagation();
    this.route.navigate(["/add-addendum/" + standardId]);
  }

  receivedFilteration($event) {
    this.filterList = $event;
    this.standardService.nrbesFiltrations.filterSet = $event;
    this.pageSize = 10;
    this.page = 1;
    this.getAllNrbesBySelectedCountryId();
    this.standardService.setIsGrs(false);
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  goToAddendum(nrbesItem: NrbesItem): void {
    this.storage.set(
      "adCountryId",
      this.cryptographyService.encryptData(nrbesItem.country.id)
    );
    if (nrbesItem.addendumStatusId == AddendumStatusEnum.DraftBeforeRequest) {
      this.route.navigate(["/add-addendum/" + nrbesItem.standardId]);
    } else {
      this.route.navigate(["/addendum/" + nrbesItem.availabileAddendumId]);
    }
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.standardService.nrbesFiltrations.searchKey = this.searchKey;
    this.page = 1;
    this.getAllNrbesBySelectedCountryId();
  }

  downloadExcel() {
    this.isDownloading = true;
    this.standardService
      .downloadExcel(
        0,
        this.collectionSize,
        StandardListType.National,
        this.filterList,
        this.selectedCountryId,
        null
      )
      .subscribe(
        (response: any) => {
          this.isDownloading = false;
          let filename =
            "National_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }

  getLocationNames(item): any {
    if (
      item.standard.standardLocation &&
      item.standard.standardLocation.length > 0
    ) {
      return item.standard.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.standard.locationType.name;
    }
  }
}
