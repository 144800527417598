import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Inject,
} from "@angular/core";
import { CategoryEnum, LocationTypeEnum } from "../core/constant";
import { Router } from "@angular/router";
import { LocationService } from "../services/location.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { Category } from "../models/category";
import { StandardService } from "../services/standard.service";

@Component({
  selector: "app-right-nav",
  templateUrl: "./right-nav.component.html",
  styleUrls: ["./right-nav.component.scss"],
})
export class RightNavComponent implements OnInit {
  loading: boolean = false;
  @Output() categoryChangeEvent = new EventEmitter<number>();
  @Input() public locationId: number;
  @Input() public isInEquipmentList: boolean;
  get categoryEnum() {
    return CategoryEnum;
  }
  get locationTypeEnum() {
    return LocationTypeEnum;
  }
  locationSelectedCategory: number;
  categories: Category[] = [];
  filteredCategories: Category[] = [];

  constructor(
    private router: Router,
    private locationService: LocationService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService,
    private standardService: StandardService
  ) {}

  ngOnInit() {
    this.locationSelectedCategory = this.cryptographyService.decryptData(
      this.storage.get("locationSelectedCategory")
    );
    this.getAllCategories().then(() => {
      this.setLocationCategoryMapping(this.locationId);
    });
  }

  getStandardsForThisCategory(categoryId: number) {
    this.storage.set(
      "locationSelectedCategory",
      this.cryptographyService.encryptData(categoryId)
    );
    this.locationSelectedCategory = this.cryptographyService.decryptData(
      this.storage.get("locationSelectedCategory")
    );
    if (categoryId == this.categoryEnum.Equipment && !this.isInEquipmentList) {
      this.router.navigate(["/equipment-list", this.locationId]);
    } else if (
      categoryId != this.categoryEnum.Equipment &&
      this.isInEquipmentList
    ) {
      this.router.navigate(["/location-grs-list", this.locationId, categoryId]);
    } else {
      this.categoryChangeEvent.emit(categoryId);
    }
  }

  setLocationCategoryMapping(locationType) {
    if (this.categories != null) {
      this.filteredCategories = [];
      this.categories
        .sort((firstItem, nextItem) =>
          firstItem.name.localeCompare(nextItem.name)
        )
        .forEach((element) => {
          let isFilteredProduct = false;
          for (
            var item = 0;
            item < element.locationCategoryMappings.length;
            item++
          ) {
            if (
              element.locationCategoryMappings[item].locationTypeId ==
              parseInt(locationType)
            ) {
              isFilteredProduct = true;
            }
          }
          if (isFilteredProduct) {
            this.filteredCategories.push(element);
          }
        });
    }
  }

  getAllCategories(): Promise<void> {
    this.loading = true;
    return new Promise<void>((resolve) => {
      this.standardService.getAllCategories().subscribe((data: Category[]) => {
        this.categories = data.map((o) => new Category(o));
        this.loading = false;
        resolve();
      });
    });
  }

  checkThisCategoryIsThere(categoryId: number): boolean {
    if (this.filteredCategories) {
      return this.filteredCategories.find((obj) => obj.id == categoryId)
        ? true
        : false;
    }
  }
}
