export class AOWGroupUserMapping {
    aowGroupId: number;
    userId: number;
    constructor(obj?: AOWGroupUserMapping) {
      if (obj) {
        this.aowGroupId = obj.aowGroupId;
        this.userId = obj.userId;
      }
    }
}
