import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from "@angular/core";
import { BasicInformationService } from "../services/basic-information.service";
import { BasicInformation } from "../models/basicInformation";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { QuestionAndAnswer } from "../models/questionsAndAnswers";
import { MatStepper } from "@angular/material/stepper";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Hashtag } from "../interfaces/Hashtag";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserRoleEnum } from "../core/constant";

@Component({
  selector: "app-add-faq",
  templateUrl: "./add-faq.component.html",
  styleUrls: ["./add-faq.component.scss"],
})
export class AddFaqComponent implements OnInit {
  loading: boolean = false;
  @Output() addFaqPopupEmit: EventEmitter<any> = new EventEmitter();
  @Output() deleteWholePopupEmit: EventEmitter<any> = new EventEmitter();
  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  postData = new BasicInformation();
  questionForm: FormGroup;
  isAnotherQYesNoselected: Boolean;
  createNewWizard: Boolean;
  currentQuestionPosition: number = -1;
  questionsAndAnswerList: QuestionAndAnswer[] = [];
  titleForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private basicInformationService: BasicInformationService,
    public fb: FormBuilder,
    private ngbModal: NgbModal
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.titleForm = this.fb.group({
      title: ["", Validators.required],
    });
    this.questionForm = this.fb.group({
      question: ["", Validators.required],
      answer: ["", Validators.required],
      hashtag: [""],
    });
    if (this.postData.id > 0) this.editFAQ();
  }

  editFAQ() {
    this.titleForm.controls.title.setValue(this.postData.title);
    this.questionForm.controls.answer.setValue(
      this.postData.questionsAndAnswers[0].answer
    );
    this.questionForm.controls.question.setValue(
      this.postData.questionsAndAnswers[0].question
    );
    this.hashtags = [];
    this.hashtags = this.postData.questionsAndAnswers[0].hashtags;
    for (let i = 0; i < this.postData.questionsAndAnswers.length; i++) {
      var questionAndAnswer = new QuestionAndAnswer();
      questionAndAnswer.question =
        this.postData.questionsAndAnswers[i].question;
      questionAndAnswer.answer = this.postData.questionsAndAnswers[i].answer;
      questionAndAnswer.hashtags =
        this.postData.questionsAndAnswers[i].hashtags;
      this.questionsAndAnswerList.push(questionAndAnswer);
    }
  }

  deleteFAQ() {
    if (this.questionsAndAnswerList.length > 1) {
      this.questionsAndAnswerList.splice(this.currentQuestionPosition + 1, 1);
      if (this.currentQuestionPosition == -1) {
        this.stepper.selectedIndex = 0;
        this.currentQuestionPosition++;
        this.redirectToQA();
        this.currentQuestionPosition--;
      } else {
        this.redirectToQA();
        this.currentQuestionPosition--;
      }
    } else {
      this.stepper.selectedIndex = 3;
    }
  }

  deleteWholeFAQ() {
    this.deleteWholePopupEmit.emit(this.postData);
  }

  closeWizard() {
    this.activeModal.dismiss();
  }

  redirectToQA() {
    this.createQuestionToEdit();
    if (this.currentQuestionPosition > -1) {
      this.stepper.selectedIndex = 1;
    }
  }

  redirectToFinal() {
    this.currentQuestionPosition++;
    this.updateCurrentData();
    if (this.questionsAndAnswerList[this.currentQuestionPosition + 1] != null) {
      let forEdit =
        this.questionsAndAnswerList[this.currentQuestionPosition + 1];
      this.questionForm.controls.answer.setValue(forEdit.answer);
      this.questionForm.controls.question.setValue(forEdit.question);
      this.hashtags = [];
      this.hashtags = forEdit.hashtags;
      this.stepper.selectedIndex = 1;
    }
  }

  redirectFromFinal() {
    if (this.createNewWizard == true) {
      this.generateQuestion();
      this.stepper.selectedIndex = 1;
    } else if (this.createNewWizard == false) {
      this.stepper.selectedIndex = 2;
    }
  }

  redirectBack() {
    this.createQuestionToEdit();
    if (this.currentQuestionPosition > -1) {
      this.stepper.selectedIndex = 1;
    }
    if (this.currentQuestionPosition != -1) {
      this.currentQuestionPosition--;
    }
  }

  createQuestionToEdit() {
    if (this.questionsAndAnswerList.length > 0) {
      if (this.questionsAndAnswerList[this.currentQuestionPosition] != null) {
        let forEdit = this.questionsAndAnswerList[this.currentQuestionPosition];
        this.questionForm.controls.answer.setValue(forEdit.answer);
        this.questionForm.controls.question.setValue(forEdit.question);
        this.hashtags = [];
        this.hashtags = forEdit.hashtags;
      }
    }
  }

  updateCurrentData() {
    var questionAndAnswer = new QuestionAndAnswer();
    questionAndAnswer.title = this.titleForm.controls.title.value;
    questionAndAnswer.answer = this.questionForm.controls.answer.value;
    questionAndAnswer.question = this.questionForm.controls.question.value;
    questionAndAnswer.hashtags = this.hashtags;
    if (this.questionsAndAnswerList.length == this.currentQuestionPosition) {
      this.questionsAndAnswerList.push(questionAndAnswer);
    } else {
      this.questionsAndAnswerList[this.currentQuestionPosition] =
        questionAndAnswer;
    }
  }

  generateQuestion() {
    this.questionForm = this.fb.group({
      question: ["", Validators.required],
      answer: ["", Validators.required],
      hashtag: [""],
    });
    this.questionForm.reset();
    this.questionForm.markAsPristine();
    this.hashtags = [];
    this.createNewWizard = null;
  }

  submitChanges() {
    this.loading = true;
    this.generateQuestion();
    this.postData.title = this.titleForm.controls.title.value;
    this.postData.createdUserId = UserRoleEnum.GlobalAdmin;
    this.postData.questionsAndAnswers = [];
    for (let i = 0; i < this.questionsAndAnswerList.length; i++) {
      var questionAndAnswer = new QuestionAndAnswer();

      questionAndAnswer.question = this.questionsAndAnswerList[i].question;
      questionAndAnswer.answer = this.questionsAndAnswerList[i].answer;
      questionAndAnswer.hashtags = this.questionsAndAnswerList[i].hashtags;
      this.postData.questionsAndAnswers.push(
        new QuestionAndAnswer(questionAndAnswer)
      );
    }
    if (this.postData.id) {
      this.basicInformationService
        .updateBasicInformation(this.postData)
        .subscribe(() => {
          this.loading = false;
          this.addFaqPopupEmit.emit();
          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.message =
            "You have successfully updated FAQ.";

          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
          });
        });
    } else {
      this.basicInformationService
        .createBasicInformation(this.postData)
        .subscribe(() => {
          this.loading = false;
          this.addFaqPopupEmit.emit();

          const modalRef = this.ngbModal.open(SuccessDialogComponent);
          modalRef.componentInstance.message =
            "You have successfully added FAQ.";

          modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
            this.ngbModal.dismissAll();
          });
        });
    }
    this.createNewWizard = true;
    this.closeWizard();
  }

  // Hashtag needed set
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  hashtags: Hashtag[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our hashtag
    if ((value || "").trim()) {
      this.hashtags.push({ name: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  remove(hashtag: Hashtag): void {
    const index = this.hashtags.indexOf(hashtag);
    if (index >= 0) {
      this.hashtags.splice(index, 1);
    }
  }
}
