import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { environment } from "../../environments/environment";
import { UserRoleUserOperation } from "../core/constant";

@Component({
  selector: "app-market-admin-panel",
  templateUrl: "./market-admin-panel.component.html",
  styleUrls: ["./market-admin-panel.component.scss"],
})
export class MarketAdminPanelComponent implements OnInit {
  loading: boolean = false;

  viewManageSuppliers: boolean;
  viewAllNationalSuppliers: boolean;
  viewAllEquipmentType: boolean;
  userRole: Number;

  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.viewManageSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewManageSuppliers
          ) !== -1;
        this.viewAllNationalSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllNationalSuppliers
          ) !== -1;
      }
    } else {
      this.viewManageSuppliers = true;
      this.viewAllNationalSuppliers = true;
      this.viewAllEquipmentType = true;
    }
  }

  ngOnInit() {}

  redirectToAssignMarketSupplier() {
    this.router.navigate(["/manage-suppliers"]);
  }

  redirectToManageSuppliers() {
    this.router.navigate(["/view-all-national-supplier-list"]);
  }
  redirectToManageEquipmentTypes() {
    this.router.navigate(["/view-all-equipment-type-list"]);
  }
}
