<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View All National Standard</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">

   <div class="row">
    <div class="col-md-9">
          <!-- <app-equipment-card></app-equipment-card>  -->
          <!-- <app-table-grid></app-table-grid> -->


    </div>
    <div class="col-md-3">
      <app-right-nav></app-right-nav>
    </div>
  </div>
  </mat-card-content>
</mat-card>
