
<mat-card class="mcd-card">
    <mat-card-header class="mcd-card-header">
      <mat-card-title class="mcd-card-title">View All National Standard</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mcd-card-content">

        <div>Some tags before</div>
        <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>Slide 1</ng-template>
        <ng-template carouselSlide>Slide 2</ng-template>
        <ng-template carouselSlide>Slide 3</ng-template>
        </owl-carousel-o>
        <div>Some tags after</div>
    </mat-card-content>
    </mat-card>
