import { Component, Inject } from "@angular/core";
import { enableProdMode } from "@angular/core";
import * as jwt_decode from "jwt-decode";
import { OnInit } from "@angular/core";
import {
  Router,
  RouterStateSnapshot,
  UrlTree,
  PRIMARY_OUTLET,
  ActivatedRoute,
} from "@angular/router";
import { NotificationService } from "../services/notification.service";
import { from } from "rxjs";
import { NotificationModel } from "../models/notification-model.model";
import { UserService } from "../services/user.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment";
import { core } from "@angular/compiler";
import { UserRoleUserOperation, UserRole, Constant } from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";
declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit {
  private canReceiveMessage: boolean = false;
  private notificationHistory: NotificationModel[] = [];

  constructor(
    private userService: UserService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private oauthService: OAuthService,
    private router: Router,
    private cryptographyService: CryptographyService,
    private route: ActivatedRoute
  ) {}

  public token: string;
  userRoleUserOperation: Array<number>;
  countryIso: string;
  Name: string;
  countryId: number;
  userId: number;
  userRole: number;
  notificationGroup: string;
  returnUrl: string;
  /**
   * initialized all data-bound properties.
   */
  ngOnInit() {
    if (environment.skipAuthentication) {
      console.log("AUTH SKIPPED.. SETTING SAMPLE DATA");
      this.storage.set("userId", this.cryptographyService.encryptData(1));
      this.storage.set("userRole", this.cryptographyService.encryptData(1));
      this.storage.set("countryId", this.cryptographyService.encryptData(2));
      this.storage.set(
        "uniqueName",
        this.cryptographyService.encryptData("Developer")
      );
      this.storage.set(
        "email",
        this.cryptographyService.encryptData("wolf@mcd.com")
      );
      let urls = this.router.url;
      if (
        !this.cryptographyService.decryptData(
          this.storage.get("isAuthenticatedUser")
        )
      ) {
        this.setUserDetailByLoggedInUserEmailAndCountryIso(
          "wolf@mcd.com",
          "US"
        );
      } else {
        if (
          this.storage.get("isAuthenticatedUser") &&
          (this.router.url == "/" || this.router.url == "")
        ) {
          this.router.navigateByUrl("/locations");
        }
      }
      //this.storage.set('notificationGroup', this.cryptographyService.encryptData("CANNotificationGroup"));
      //this.storage.set('notificationGroup', "GlobalNotificationGroup");
    } else {
      // console.log("getAccessToken", this.oauthService.getAccessToken());
      this.token = this.oauthService.getAccessToken();
      // this.token = "sample";
      let tokenInfo = this.getDecodedAccessToken(this.token); // decode token
      //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      console.log("email from token");
      let email = tokenInfo.email;
      console.log(email);
      //console.log('email', email);
      let countryIso = tokenInfo.country;
      //console.log('countryIso', countryIso);
      if (
        !(this.storage.get("isAuthenticatedUser")
          ? this.cryptographyService.decryptData(
              this.storage.get("isAuthenticatedUser")
            )
          : this.storage.get("isAuthenticatedUser"))
      ) {
        this.storage.set("email", this.cryptographyService.encryptData(email));
        this.setUserDetailByLoggedInUserEmailAndCountryIso(email, countryIso);
      } else {
        if (
          this.storage.get("isAuthenticatedUser") &&
          (this.router.url == "/" || this.router.url == "")
        ) {
          this.router.navigateByUrl("/locations");
        } else {
          // this.router.navigateByUrl(this.router.url);
        }
      }

      this.userService
        .getUserDetailByLoggedInUserEmailAndCountryIso(email, countryIso)
        .subscribe((data: any) => {
          if (!data.isValidUser) {
            return;
          }
          this.countryId = data.countryId;
          this.Name = String(email).toString().split(".", 1)[0];
          this.userRole = data.userRoleId;
          this.userId = data.id;
          this.notificationGroup = data.notificationGroup;
          this.countryIso = data.countryIso ? data.countryIso : "";
          this.userRoleUserOperation = data.userRoleUserOperation;
          if (
            this.cryptographyService.decryptData(
              this.storage.get("userRole")
            ) != data.userRole ||
            !this.cryptographyService.decryptData(
              this.storage.get("userRoleUserOperation")
            ) != data.userRoleUserOperation ||
            !this.cryptographyService.decryptData(this.storage.get("userId")) !=
              data.id
          ) {
            this.storage.set(
              "countryId",
              this.cryptographyService.encryptData(this.countryId)
            );

            this.storage.set("userName", this.Name);

            //UserId
            this.storage.set(
              "userId",
              this.cryptographyService.encryptData(this.userId)
            );

            //NotificationGroup
            this.storage.set(
              "notificationGroup",
              this.cryptographyService.encryptData(this.notificationGroup)
            );

            //User role
            this.storage.set(
              "userRole",
              this.cryptographyService.encryptData(this.userRole)
            );

            //isAuthenticatedUser
            this.storage.set(
              "isAuthenticatedUser",
              this.cryptographyService.encryptData(true)
            );

            //userRoleUserOperation
            this.storage.set(
              "userRoleUserOperation",
              this.cryptographyService.encryptData(this.userRoleUserOperation)
            );
          }
        });
    }
    $(window).scroll(function (event) {
      let st = $(this).scrollTop();
      if (st > 199) {
        $(".filter-panel-row").addClass("freeze");
        $(".filter-panel-row .filter-panel").addClass("collapsed");
      } else {
        $(".filter-panel-row").removeClass("freeze");
        $(".filter-panel-row .filter-panel").removeClass("expanded");
        $(".filter-panel-row .filter-panel").removeClass("collapsed");
      }
    });

    $(document).on("click", ".btn-collapse", () => {
      $(".filter-panel").removeClass("expanded");
      $(".filter-panel").addClass("collapsed");
    });
    $(document).on("click", ".btn-expand", () => {
      $(".filter-panel").removeClass("collapsed");
      $(".filter-panel").addClass("expanded");
    });
  }

  /**
   * get Decoded Access Token.
   */
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  /**
   * set UserDetail By Logged In User Email And Country Iso.
   */
  setUserDetailByLoggedInUserEmailAndCountryIso(loggedInUserEmail, countryIso) {
    this.userService
      .getUserDetailByLoggedInUserEmailAndCountryIso(
        loggedInUserEmail,
        countryIso
      )
      .subscribe((data: any) => {
        this.countryId = data.countryId;
        this.Name = String(loggedInUserEmail).toString().split(".", 1)[0];
        this.userRole = data.userRoleId;
        this.userId = data.id;
        this.notificationGroup = data.notificationGroup;
        this.countryIso = data.countryIso ? data.countryIso : "";
        this.userRoleUserOperation = data.userRoleUserOperation;

        console.log("data", data);

        //unauthorized
        if (!data.isValidUser) {
          this.router.navigateByUrl("/unauthorized");
        } else {
          //CountryId
          this.storage.set(
            "countryId",
            this.cryptographyService.encryptData(this.countryId)
          );

          this.storage.set("userName", this.Name);

          //UserId
          this.storage.set(
            "userId",
            this.cryptographyService.encryptData(this.userId)
          );

          //NotificationGroup
          this.storage.set(
            "notificationGroup",
            this.cryptographyService.encryptData(this.notificationGroup)
          );

          //User role
          this.storage.set(
            "userRole",
            this.cryptographyService.encryptData(this.userRole)
          );

          //isAuthenticatedUser
          this.storage.set(
            "isAuthenticatedUser",
            this.cryptographyService.encryptData(true)
          );

          //userRoleUserOperation
          this.storage.set(
            "userRoleUserOperation",
            this.cryptographyService.encryptData(this.userRoleUserOperation)
          );

          if (
            this.router.url == "/" ||
            this.router.url == "/locations" ||
            this.router.url == ""
          ) {
            this.router.navigateByUrl("/locations");
            window.location.reload();
          } else {
            this.router.navigateByUrl(this.router.url);
            window.location.reload();
          }
          //this.router.navigateByUrl('/locations');
          //this.router.initialNavigation();
        }
      });
  }
}
