export class UserRole {
    id: number;
    name: string;
    description: string;

    constructor(obj?: UserRole) {
      if (obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
      }
    }
}
