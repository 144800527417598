import { Standard } from "./standard";

export class SelectedStandardDetail {
    standard: Standard = new Standard;
    isFirst: boolean;
    isLast: boolean;
    isInNrbes: boolean;
    dependentStandards: Standard[] = [];
    constructor(obj?: SelectedStandardDetail) {
        if (obj) {
            this.standard = new Standard(obj.standard);
            this.isFirst = obj.isFirst;
            this.isLast = obj.isLast;
            this.isInNrbes = obj.isInNrbes;
            this.dependentStandards =obj.dependentStandards? obj.dependentStandards.map(o => new Standard(o)): [];
        }
    }
}
