import { UsefulLink } from "./usefullink";

export class UsefulLinkList {
  usefullinks: UsefulLink[] = [];
  count: number;
  constructor(obj?: UsefulLinkList) {
    if (obj) {
      this.usefullinks = obj.usefullinks ? obj.usefullinks.map(o => new UsefulLink(o)) : null;
      this.count = obj.count;
    }
  }
}
