
import { Standard } from "./standard";
import { NrbesItem } from "./nrbesItem";
import { Category } from "./category";
import { LocationType } from "./location-type";

export class FilteringGuide {
  type: number;
  ids: Array<number> = [];
  constructor(obj?: FilteringGuide) {
    if (obj) {
      this.type = obj.type;
      this.ids = obj.ids;
    }
  }
}
