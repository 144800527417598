<div class="modal" style="display: block !important;  background: #0000007a;" id="content">
  <div class="modal-dialog">
      <div class="modal-content" >
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit</h4>
  </div>
  <div class="modal-body">
    <form>
     <div class="mcd-edit-form">
       <div class="row">
         <div class="col-6">
        <mat-form-field>
            <input matInput placeholder="Title">
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
            <input matInput placeholder="Notes">
        </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field>
                <mat-select matNativeControl name="Location">
                  <mat-option value="0" selected >Location</mat-option>
                  <mat-option  [value]="1">
                    United States of America
                  </mat-option>
                  <mat-option  [value]="2">
                      United Kingdom
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field>
                <mat-select matNativeControl name="Location">
                  <mat-option value="0" selected >Location</mat-option>
                  <mat-option  [value]="1">
                    United States of America
                  </mat-option>
                  <mat-option  [value]="2">
                      United Kingdom
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col-6">
        <mat-form-field>
            <textarea rows="5" matInput placeholder="Standard Description"></textarea>
          </mat-form-field>
        </div>



      </div>
     </div>
    </form>
  </div>
  <div class="modal-footer">
      <button  mat-raised-button color="accent">Save</button>
      <button mat-raised-button color="warn">Reset</button>
  </div>
</div>
</div>
</div>
