import { Component, OnInit, Inject } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router, ActivatedRoute } from "@angular/router";
import { StandardService } from "../services/standard.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { StandardTypeEnum, StandardListType } from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";
import { LocationService } from "../services/location.service";
import { SelectedStandard } from "../models/selected-standard";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { debug } from "util";
declare var $: any;

@Component({
  selector: "app-view-all-upcoming-standard-detail-view",
  templateUrl: "./view-all-upcoming-standard-detail-view.component.html",
  styleUrls: ["./view-all-upcoming-standard-detail-view.component.scss"],
})
export class ViewAllUpcomingStandardDetailViewComponent implements OnInit {
  loading: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="/assets/images/back.svg">',
      '<img src="/assets/images/next.svg">',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  grsId: number = 0;
  grsDetail: SelectedStandard = new SelectedStandard();
  error: string = "";
  userRole: number;
  notes: SafeHtml = "";
  additionalInformation: SafeHtml = "";
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  constructor(
    private activeRoute: ActivatedRoute,
    private standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private router: Router,
    private cryptographyService: CryptographyService,
    public locationService: LocationService,
    private sanitizer: DomSanitizer
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
    $(document).ready(() => {
      let objId;
      $(".image-map area")
        .mouseenter(function (e) {
          objId = $(this).attr("target");
          $(".main-image").css("opacity", "0");
          $(".main-image").css("z-index", "9999");
          $(objId).find("img").css("z-index", "2");
          $(objId).removeClass("d-none");
          $(objId).addClass("active");
          $(".chunk").removeClass("d-none");
          $(".tooltip-text span").text($(this).attr("alt"));
          $(".tooltip-text").css("left", e.pageX + 2);
          $(".tooltip-text").css("top", e.pageY + 2);
          $(".tooltip-text").fadeIn("slow");
        })
        .mouseleave(function (e) {
          $(".main-image").css("z-index", "1");
          $(".chunk").addClass("d-none");
          $(".chunk").removeClass("active");
          $(".main-image").css("opacity", "1");
        });
    });
  }
  getDetailByGrbesId(grsId: number) {
    this.loading = true;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.upcomingStandardFiltration.filterSet,
        StandardListType.Upcoming,
        null
      )
      .subscribe((data: SelectedStandard) => {
        var options = {
          /* … */
        };
        //debugger;
        this.grsDetail = new SelectedStandard(data);
        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.additionalInformation
        );
        this.loading = false;
        () => {
          this.loading = false;
        };
      });
  }
  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }
  slide(grsID: number, isForward: boolean) {
    //debugger;
    this.loading = true;
    if (this.userRole != 2) {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.upcomingStandardFiltration.filterSet,
          StandardListType.Upcoming
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId ==
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-upcoming-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.router.navigate([
            "view-upcoming-standard-detail",
            data.standard.id,
          ]);
          this.grsDetail = new SelectedStandard(data);
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.upcomingStandardFiltration.filterSet,
          StandardListType.Upcoming
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId ==
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-upcoming-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.router.navigate([
            "view-upcoming-standard-detail",
            data.standard.id,
          ]);
          this.grsDetail = new SelectedStandard(data);
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    }
  }
  downloadExcel() {
    this.standardService.downloadSingleStandardExcel(
      this.grsDetail,
      "upcoming"
    );
    return;
  }
}
