import { Component, OnInit, Inject } from "@angular/core";
import { Supplier } from "../models/supplier";
import { AdminService } from "../services/admin.service";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { UserRoleEnum } from "../core/constant";

@Component({
  selector: "app-national-supplier-detail-view",
  templateUrl: "./national-supplier-detail-view.component.html",
  styleUrls: ["./national-supplier-detail-view.component.scss"],
})
export class NationalSupplierDetailViewComponent implements OnInit {
  error: string = "";
  loading: boolean = false;
  supplier: Supplier = new Supplier();
  userRole: number;
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService
  ) {
    this.supplier.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("supplierId")
    );
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
  }

  ngOnInit() {
    if (this.supplier.id) {
      this.getSupplierBySupplierId(this.supplier.id);
    }
  }

  getSupplierBySupplierId(supplierId: number) {
    this.loading = true;
    this.adminService
      .GetSupplierDetailsById(supplierId)
      .subscribe((data: Supplier) => {
        this.supplier = new Supplier(data);
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }
}
