export class Attachment {
    id: number;
    attachmentTypeId: string;
    fileName: string;
    mime: string;
  attachmentContent: any;
  attachmentContentAsBase64: string;
  get image() {
        if (this.id) {
            return this.mime + this.attachmentContent;
        }
    }

    constructor(obj?: Attachment) {
        if (obj) {
            this.id = obj.id;
            this.attachmentTypeId = obj.attachmentTypeId;
            this.fileName = obj.fileName;
            this.mime = obj.mime;
          this.attachmentContent = obj.attachmentContent;
          this.attachmentContentAsBase64 = obj.attachmentContentAsBase64;
        }
    }
}
