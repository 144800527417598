<div class="mcd-notification-wrapper pull-right">
  <ul>
    <li *ngIf="showNotification">
      <a href="javascript:void(0);" class="mcd-notify-icon"
        ><i data-count="99" class="material-icons"> notifications</i>
        <span>{{ notificationUnseenHistory.length }}</span></a
      >
      <ul class="dropdownMenu" style="display: none">
        <li
          [ngClass]="item.isSeen ? '' : 'mcd-notifications-active'"
          *ngFor="let item of notificationViewHistory"
          (click)="
            rowSelected(item); addendumService.isCommingFromNotification = true
          "
        >
          <a class="clearfix" href="javascript:void(0);">
            <div class="mcd-notify-avatar">
              {{ item.notifiedUser | slice : 0 : 1 }}
            </div>
            <div class="mcd-notifiy-details">
              <div class="mcd-notify-title">
                <span
                  ><strong>{{ item.message }}</strong></span
                >
                <span class="mcd-notify-date float-right">{{
                  item.date | date : "d MMM y"
                }}</span>
              </div>
              <p class="mcd-notify-description">
                {{ item.addendumDescription }}
              </p>
              <div class="mcd-notify-user">
                <span
                  ><strong>{{ item.notifiedUser }}</strong></span
                >
                <small class="mcd-notify-designation float-right">{{
                  item.notifiedUserRole
                }}</small>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a
            class="view-all-text"
            [routerLink]="['/notification-list']"
            (click)="removePopOver()"
            >View All</a
          >
        </li>
      </ul>
    </li>
    <li class="d-flex">
      <a href="javascript:void(0);"
        ><i class="material-icons float-left"> person </i>
        <span class="logon-user float-left">Hi, {{ userName }} </span></a
      >
      <button
        class="mcd-more-btn"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="signOut()" mat-menu-item>Log out</button>
      </mat-menu>
    </li>
  </ul>
</div>
