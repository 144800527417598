import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from "@angular/core";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import { Category } from "../models/category";
import { FilteringGuide } from "../models/filteringGuide";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-view-all-mnimum-sandard-list-filter",
  templateUrl: "./view-all-mnimum-sandard-list-filter.component.html",
  styleUrls: ["./view-all-mnimum-sandard-list-filter.component.scss"],
})
export class ViewAllMnimumSandardListFilterComponent implements OnInit {
  locationTypes: LocationType[] = [];
  buildingCategories: Category[] = [];
  filterList: FilteringGuide[] = [];
  filteringForCheckBox: FilteringGuide[] = [];

  @Output() messageEvent = new EventEmitter<Array<FilteringGuide>>();
  @Input() isFromLocation: boolean;

  constructor(
    public standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService,
    private location: Location
  ) {}

  ngOnInit() {
    this.getMasterDataForFilteration();
    // console.log(this.location.path());
  }

  isLocationGRSList() {
    if (this.location.path().includes("location-grs-list")) {
      return true;
    }
    return false;
  }
  getMasterDataForFilteration() {
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.buildingCategories = data.buildingStandardCategories;

        this.locationTypes = data.locationTypes;
      });
  }

  addFilter($event, id, filterParam) {
    if (this.standardService.minimumStandardFiltration.filterSet != null) {
      this.filterList =
        this.standardService.minimumStandardFiltration.filterSet;
    }
    if ($event.checked) {
      let filterGuide = this.filterList.find((i) => i.type === filterParam);
      if (filterGuide != undefined) {
        if (!filterGuide.ids.includes(id)) {
          filterGuide.ids.push(id);
        }
      } else {
        let guide = new FilteringGuide();
        guide.type = filterParam;
        guide.ids.push(id);
        this.filterList.push(guide);
      }
    } else {
      let selectedFilter = this.filterList.find((i) => i.type === filterParam);
      if (selectedFilter != undefined && selectedFilter.ids.includes(id)) {
        let index = selectedFilter.ids.indexOf(id);
        selectedFilter.ids.splice(index, 1);
      }
      if (selectedFilter.ids.length == 0) {
        let i = this.filterList.findIndex((x) => x.type === filterParam);
        this.filterList.splice(i, 1);
      }
    }
    this.standardService.minimumStandardFiltration.filterSet = this.filterList;
    this.messageEvent.emit(this.filterList);
  }

  setCheckedStateForCheckBoxes(type, id) {
    this.filteringForCheckBox =
      this.standardService.minimumStandardFiltration.filterSet;
    if (this.filteringForCheckBox != null) {
      let t = this.filteringForCheckBox.find((i) => i.type === type);
      if (t != undefined && t.ids.includes(id)) {
        return true;
      }
    }
  }
}
