import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AddendumList } from "../models/addendum-list";
import { Observable } from "rxjs";
import { Country } from "../models/country";
import { Addendum } from "../models/addendum";
import { AddendumStatus } from "../models/addendum-status";
import { AddendumFilterList } from "../models/addendumFilterList";
import { EquipmentAddendum } from "../models/equipment-addendum";

@Injectable({
  providedIn: "root",
})
export class AddendumService {
  public isCommingFromNrbes: boolean;
  public isCommingFromNotification: boolean = false;

  constructor(private http: HttpClient) {}

  getAllAddendumsByCountryId(
    countryId: number,
    isRemoveDraftBeforeRequest: boolean,
    startingIndex: number = 0,
    pageSize = 10,
    filterList: number[],
    userRoleId: number,
    isEquipmentAddendum: boolean
  ): Observable<any> {
    let addendumFilter = new AddendumFilterList();
    addendumFilter.countryId = countryId;
    addendumFilter.isRemoveDraftBeforeRequest = isRemoveDraftBeforeRequest;
    addendumFilter.startingIndex = startingIndex;
    addendumFilter.pageSize = pageSize;
    addendumFilter.filterList = filterList;
    addendumFilter.userRoleId = userRoleId;
    addendumFilter.isEquipmentAddendum = isEquipmentAddendum;

    return this.http.post(
      "/api/AddendumWorkflow/GetAllAddendumsByCountryId",
      addendumFilter
    );
  }

  getAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>("/api/AddendumWorkflow/GetAllCountries");
  }

  getAddendumInformationByAddendumId(addendumId: number): Observable<Addendum> {
    let params = new HttpParams();
    params = params.append("addendumId", addendumId.toString());
    return this.http.get<Addendum>(
      "/api/AddendumWorkflow/GetAddendumInformationByAddendumId",
      { params: params }
    );
  }

  //
  getDraftBeforeRequestAddendumInformationByStandardIdAndCountryId(
    standardId: number,
    countryId: number
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append("standardId", standardId.toString());
    params = params.append("countryId", countryId.toString());
    return this.http.get<any>(
      "/api/AddendumWorkflow/GetDraftBeforeRequestAddendumInformationByStandardIdAndCountryId",
      { params: params }
    );
  }

  addAddendum(addendum: Addendum): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/AddendumWorkflow/AddAddendum",
      addendum
    );
  }

  //
  addEquipmentAddendum(addendum: EquipmentAddendum): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/AddendumWorkflow/AddEquipmentAddendum",
      addendum
    );
  }

  updateAddendum(addendum: Addendum): Observable<boolean> {
    // debugger;
    return this.http.post<boolean>(
      "/api/AddendumWorkflow/UpdateAddendum",
      addendum
    );
  }

  getAllAddendumStatuses(userRoleId): Observable<AddendumStatus[]> {
    let params = new HttpParams();
    params = params.append("userRoleId", userRoleId.toString());
    return this.http.get<AddendumStatus[]>(
      "/api/AddendumWorkflow/GetAllAddendumStatuses",
      { params: params }
    );
  }
  getValidEmail(email): Observable<boolean> {
    return this.http.get<boolean>("api/Admin/IsEmailAllowed/" + email)
  }
  getScript(): Observable<boolean> {
    return this.http.get<boolean> ("api/Admin/RunScript/")
  }

  getMasterDataForAddendum(
    userRoleId: number,
    userId: number = null
  ): Observable<AddendumStatus[]> {
    let params = new HttpParams();
    params = params.append("userId", userId.toString());
    params = params.append("userRole", userRoleId.toString());
    return this.http.get<AddendumStatus[]>(
      "/api/AddendumWorkflow/getMasterDataForAddendumList",
      { params: params }
    );
  }
}
