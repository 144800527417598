import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EquipmentType } from "../models/equipment-type";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { Category } from "../models/category";
import { StandardService } from "../services/standard.service";
import { EquipmentStandardService } from "../services/equipment -standard.service";
import { LocationType } from "../models/location-type";
import { EquipmentStandard } from "../models/equipment-standard";
import { EquipmentClassType } from "../models/equipment-class-type";
import { EquipmentClassEnum, MasterDataRequestedType } from "../core/constant";
import { CategoryEnum } from "../core/constant";
import { Observable } from "rxjs";
import { CryptographyService } from "../services/cryptography.service";
import { Supplier } from "../models/supplier";
import { Country } from "../models/country";
import { ActivatedRoute, Router } from "@angular/router";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { SimpleModalService } from "ngx-simple-modal-eq";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ModalImageCropperComponent } from "../modal-image-cropper/modal-image-cropper.component";
import { SubLocationType } from "../models/sub-location-type";
import { Location } from "@angular/common";

@Component({
  selector: "app-add-equipment-standard-global",
  templateUrl: "./add-equipment-standard-global.component.html",
  styleUrls: ["./add-equipment-standard-global.component.scss"],
})
export class AddEquipmentStandardGlobalComponent implements OnInit {
  promptMessage = "";
  loading: boolean = false;
  addEquipmentStandardForm: FormGroup;
  equipmentTypes: EquipmentType[] = [];
  invalidImage: boolean = false;
  invalidAttachment: boolean = false;
  invalidImageRes: boolean = false;
  isEditMode: boolean = false;
  locationTypes: LocationType[] = [];
  subLocationTypes: SubLocationType[] = [];
  categories: Category[] = [];
  filteredCategories: Category[] = [];
  suppliers: Supplier[] = [];
  allSuppliers: Supplier[] = [];
  countries: Country[] = [];
  equipmentClassTypes: EquipmentClassType[] = [];
  equipmentStandard: EquipmentStandard = new EquipmentStandard();
  get equipmentClassEnum() {
    return EquipmentClassEnum;
  }
  get categoryEnum() {
    return CategoryEnum;
  }
  imageChangedEvent: any = "";

  constructor(
    private fb: FormBuilder,
    private equipmentStandardService: EquipmentStandardService,
    private ngbModal: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private SimpleModalService: SimpleModalService,
    private _location: Location
  ) {
    this.equipmentStandard.standardId = parseInt(
      this.activeRoute.snapshot.paramMap.get("standardId")
    );
  }

  ngOnInit() {
    if (this.equipmentStandard.standardId) {
      this.isEditMode = true;
      this.loading = true;
      this.equipmentStandardService
        .getEquipmentStandardByStandardId(this.equipmentStandard.standardId)
        .subscribe((data: EquipmentStandard) => {
          this.equipmentStandard = new EquipmentStandard(data);
          this.setControlsValue();
          this.loading = false;
        });
    } else {
      this.getMasterData();
    }
    this.createForm();
  }

  getMasterData() {
    this.standardService
      .GetMasterDataForAddEditStandard(
        MasterDataRequestedType.AddGlobalEquipmentStandard
      )
      .subscribe((data: any) => {
        this.locationTypes = data.locationTypes.map(
          (ob) => new LocationType(ob)
        );
        this.equipmentTypes = data.equipmentTypes.map(
          (ob) => new EquipmentType(ob)
        );
        this.allSuppliers = data.suppliers.map((ob) => new Supplier(ob));
        this.locationTypes = data.locationTypes.map(
          (ob) => new LocationType(ob)
        );
        this.countries = data.countries.map((ob) => new Country(ob));
        this.equipmentClassTypes = data.equipmentClassTypes.map(
          (ob) => new EquipmentClassType(ob)
        );
        this.loading = false;
      });

    //Ading AOW Country list
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.countries = data.countries;
      });
  }

  createForm() {
    this.addEquipmentStandardForm = this.fb.group({
      equipmentTypeId: ["", Validators.required],
      model: ["", Validators.required],
      title: ["", Validators.required],
      description: ["", Validators.required],
      effectiveFrom: [""],
      obsoleteDate: [""],
      note: [""],
      locationTypeId: ["", Validators.required],
      subLocationTypeId: [""],
      supplierId: ["", Validators.required],
      countryId: [""],
      classTypeId: ["", Validators.required],
    });
  }

  save() {
    this.loading = true;
    this.equipmentStandard.categoryId = this.categoryEnum.Equipment;
    this.equipmentStandard.description =
      this.addEquipmentStandardForm.controls.description.value;
   

    if (this.addEquipmentStandardForm.controls.effectiveFrom.value == "" || this.addEquipmentStandardForm.controls.effectiveFrom.value == null) {
      this.equipmentStandard.effectiveFrom = null;
    }else {
      this.equipmentStandard.effectiveFrom = this.onBuildDateString(
        new Date(this.addEquipmentStandardForm.controls.effectiveFrom.value)
          .toLocaleDateString("en-US")
          .replace(/\u200E/g, "")
      );
    }

    if (this.addEquipmentStandardForm.controls.obsoleteDate.value == "" || this.addEquipmentStandardForm.controls.obsoleteDate.value == null) {
      this.equipmentStandard.obsoleteDate = null;
    }
    else {
      this.equipmentStandard.obsoleteDate = this.onBuildDateString(
        new Date(this.addEquipmentStandardForm.controls.obsoleteDate.value)
          .toLocaleDateString("en-US")
          .replace(/\u200E/g, "")
      );
      console.log("obselete date",this.equipmentStandard.obsoleteDate)
    }
    this.equipmentStandard.equipmentTypeId =
      this.addEquipmentStandardForm.controls.equipmentTypeId.value;
    this.equipmentStandard.locationTypeId =
      this.addEquipmentStandardForm.controls.locationTypeId.value;
    this.equipmentStandard.subLocationTypeId = this.addEquipmentStandardForm
      .controls.subLocationTypeId
      ? this.addEquipmentStandardForm.controls.subLocationTypeId.value
      : null;
    this.equipmentStandard.supplierId =
      this.addEquipmentStandardForm.controls.supplierId.value;
    this.equipmentStandard.model =
      this.addEquipmentStandardForm.controls.model.value;
    this.equipmentStandard.note =
      this.addEquipmentStandardForm.controls.note.value;
    this.equipmentStandard.title =
      this.addEquipmentStandardForm.controls.title.value;
    this.equipmentStandard.equipmentClassTypeId =
      this.addEquipmentStandardForm.controls.classTypeId.value;
    if (this.addEquipmentStandardForm.controls.countryId.value == 0 || null) {
      this.equipmentStandard.countryId = this.cryptographyService.decryptData(
        this.storage.get("countryId")
      );
    } else {
      this.equipmentStandard.countryId =
        this.addEquipmentStandardForm.controls.countryId.value;
    }
    this.equipmentStandard.createdUserId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.equipmentStandard.imageFileName =
      this.equipmentStandard.imageAttachment.fileName;
    if (this.equipmentStandard.equipmentAttachment != undefined) {
      this.equipmentStandard.attachmentFileName =
        this.equipmentStandard.equipmentAttachment.fileName;
      this.equipmentStandard.attachmentContentAsBase64 =
        this.equipmentStandard.equipmentAttachment.attachmentContentAsBase64;
    }
    if (this.equipmentStandard.standardId) {
      this.loading = true;
      this.updateStandard(this.equipmentStandard).subscribe((data) => {
        this.loading = false;

        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the equipment standard.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.resetForm();
          if (data) {
            this.router.navigate(["/view-all-standards"]);
          }
        });
      });
    } else {
      this.addStandard(this.equipmentStandard).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully added the equipment standard.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.resetForm();
          if (data) {
            this.router.navigate(["/view-all-standards"]);
          }
        });
      });
    }
  }

  addStandard(equipmentStandard: EquipmentStandard): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.equipmentStandardService
        .addEquipmentStandard(equipmentStandard)
        .subscribe(
          (data: any) => {
            this.loading = false;
            observer.next(data);
          },
          (error) => {
            this.loading = false;
            observer.next(false);
          }
        );
    });
  }

  updateStandard(equipmentStandard: EquipmentStandard): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.equipmentStandardService
        .updateEquipmentStandard(equipmentStandard)
        .subscribe(
          (data: any) => {
            this.loading = false;
            observer.next(data);
          },
          (error) => {
            this.loading = false;
            observer.next(false);
          }
        );
    });
  }

  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }

  onRemoveImage() {
    this.equipmentStandard.imageAttachment.id = 0;
    this.equipmentStandard.imageAttachment.fileName = null;
    this.equipmentStandard.imageAttachment.attachmentContentAsBase64 = null;
    this.equipmentStandard.imageFileName = null;
    this.equipmentStandard.imageAttachmentContentAsBase64 = null;
    this.invalidImage = false;
    this.invalidImageRes = false;
  }

  onDownloadSelectedImage(id) {
    if (id) {
      return this.standardService.getAttachmentUrl(id, false).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.equipmentStandard.imageAttachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.equipmentStandard.imageAttachment.fileName
            );
          } else {
            var a = document.createElement("a");
            a.href = this.equipmentStandard.imageAttachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.equipmentStandard.imageAttachment.fileName
            );
            a.click();
          }
        });
    }
  }

  onSelectAttachment(event) {
    let selectedFile = event.target.files[0];
    this.invalidAttachment = this.validateAttachmentType(selectedFile.name);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.equipmentStandard.equipmentAttachment.id = 0;
      this.equipmentStandard.equipmentAttachment.fileName = selectedFile.name;
      this.equipmentStandard.equipmentAttachment.attachmentContentAsBase64 =
        result;
      event.target.value = null;
    });
  }

  validateAttachmentType(fileName: string) {
    let fileTypeArr = ["doc", "docx", "pdf"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  onSelectImage(event) {
    this.invalidImageRes = false;
    let selectedFile = event.target.files[0];
    this.imageChangedEvent = event.target.files[0];
    this.invalidImage = this.validateFileType(selectedFile.name);
    if (!this.invalidImage) {
      this.showPrompt().then((result) => {
        console.log("result", result);
        if (result == null) {
          this.equipmentStandard.imageAttachmentContentAsBase64 = null;
          event.target.value = null;
          this.equipmentStandard.imageAttachment.fileName = null;
        } else {
          this.equipmentStandard.imageAttachmentContentAsBase64 = result;
          event.target.value = null;
          this.equipmentStandard.imageAttachment.fileName = selectedFile.name;
        }
      });
    }
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["jpg", "jpeg", "png", "bmp"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  validateImageResolution(selectedFile, status): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (status == false) {
        var url = URL.createObjectURL(selectedFile);
        var img = new Image();
        img.onload = function () {
          if (img.width != img.height) {
            observer.next(true);
          }
        };
        img.src = url;
      }
    });
  }

  onDownloadSelectedAttachment(e) {
    if (e.id) {
      return this.standardService.getAttachmentUrl(e.id, false).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, e.fileName);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = e.fileName;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(e.attachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            console.log("one");
            (window.navigator as any).msSaveBlob(blob, e.fileName);
          } else {
            console.log("Two");
            var a = document.createElement("a");
            a.href = e.attachmentContentAsBase64;
            a.setAttribute("download", e.fileName);
            a.click();
          }
        });
    }
  }

  onRemoveAttachment() {
    this.equipmentStandard.equipmentAttachment.id = 0;
    this.equipmentStandard.equipmentAttachment.fileName = null;
    this.equipmentStandard.equipmentAttachment.attachmentContentAsBase64 = null;
    this.equipmentStandard.attachmentFileName = null;
    this.equipmentStandard.imageAttachmentContentAsBase64 = null;
    this.invalidAttachment = false;
  }

  checkFormValidity() {
    if (
      this.addEquipmentStandardForm.invalid ||
      this.invalidImage ||
      this.invalidAttachment ||
      this.equipmentStandard.imageAttachment.fileName == null ||
      this.invalidImageRes
    ) {
      return true;
    }
  }

  resetForm() {
    if (this.isEditMode) {
      this.addEquipmentStandardForm.controls.description.setValue(
        this.equipmentStandard.description
      );
      this.addEquipmentStandardForm.controls.effectiveFrom.setValue(
        this.equipmentStandard.effectiveFrom
      );
      this.addEquipmentStandardForm.controls.obsoleteDate.setValue(
        this.equipmentStandard.obsoleteDate == "0001-01-01T00:00:00"
          ? ""
          : this.equipmentStandard.obsoleteDate
      );
      this.addEquipmentStandardForm.controls.note.setValue(
        this.equipmentStandard.note
      );
    } else {
      this.addEquipmentStandardForm.reset();
      this.equipmentStandard.imageAttachment.fileName = null;
      this.equipmentStandard.imageAttachment.attachmentContentAsBase64 = null;
      this.equipmentStandard.imageFileName = null;
      this.equipmentStandard.imageAttachmentContentAsBase64 = null;
      this.invalidImage = false;
      this.equipmentStandard.attachmentFileName = null;
      this.equipmentStandard.imageAttachmentContentAsBase64 = null;
      this.equipmentStandard.equipmentAttachment.fileName = null;
      this.equipmentStandard.equipmentAttachment.attachmentContentAsBase64 =
        null;
      this.invalidAttachment = false;
      this.createForm();
    }
  }

  setClassType(classType) {
    this.addEquipmentStandardForm.controls.classTypeId.setValue(
      parseInt(classType.value)
    );
    if (classType.value == this.equipmentClassEnum.Core) {
      this.addEquipmentStandardForm.controls.effectiveFrom.setValidators(
        Validators.required
      );
      this.addEquipmentStandardForm.controls.effectiveFrom.updateValueAndValidity();
      this.addEquipmentStandardForm.controls.obsoleteDate.setValidators(null);
      this.addEquipmentStandardForm.controls.obsoleteDate.updateValueAndValidity();
      this.addEquipmentStandardForm.controls.countryId.setValidators(null);
      this.addEquipmentStandardForm.controls.countryId.updateValueAndValidity();
      this.suppliers = this.allSuppliers;
    } else {
      this.addEquipmentStandardForm.controls.effectiveFrom.setValidators(null);
      this.addEquipmentStandardForm.controls.effectiveFrom.updateValueAndValidity();
      this.addEquipmentStandardForm.controls.obsoleteDate.setValidators(null);
      this.addEquipmentStandardForm.controls.obsoleteDate.updateValueAndValidity();
      this.addEquipmentStandardForm.controls.countryId.setValidators(
        Validators.required
      );
      this.addEquipmentStandardForm.controls.countryId.updateValueAndValidity();
      this.equipmentStandard.countryId =
        this.addEquipmentStandardForm.controls.countryId.value;
      this.suppliers = [];
    }
  }

  checkEffectiveAndObsoleteDateValidity(): boolean {
    if (
      this.addEquipmentStandardForm.controls.classTypeId.value ==
      this.equipmentClassEnum.Core
    ) {
      if (
        this.addEquipmentStandardForm.controls.obsoleteDate.valid &&
        this.addEquipmentStandardForm.controls.effectiveFrom.valid
      ) {
        if (!this.equipmentStandard.id) {
          if (
            this.addEquipmentStandardForm.controls.effectiveFrom.touched &&
            this.addEquipmentStandardForm.controls.obsoleteDate.touched
          ) {
            if (
              this.addEquipmentStandardForm.controls.effectiveFrom.value <
              this.addEquipmentStandardForm.controls.obsoleteDate.value
            ) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          if (
            Date.parse(
              this.addEquipmentStandardForm.controls.effectiveFrom.value.toLocaleString()
            ) <
            Date.parse(
              this.addEquipmentStandardForm.controls.obsoleteDate.value.toLocaleString()
            )
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        true;
      }
    }
  }

  setControlsValue() {
    if (this.equipmentStandard.standardId) {
      this.equipmentTypes.push(this.equipmentStandard.equipmentType);
      this.addEquipmentStandardForm.controls.equipmentTypeId.setValue(
        this.equipmentStandard.equipmentTypeId
      );
      this.addEquipmentStandardForm.controls.model.setValue(
        this.equipmentStandard.model
      );
      this.addEquipmentStandardForm.controls.title.setValue(
        this.equipmentStandard.title
      );
      this.addEquipmentStandardForm.controls.description.setValue(
        this.equipmentStandard.description
      );
      this.addEquipmentStandardForm.controls.effectiveFrom.setValue(
        this.equipmentStandard.effectiveFrom
      );
      this.addEquipmentStandardForm.controls.obsoleteDate.setValue(
        this.equipmentStandard.obsoleteDate == "0001-01-01T00:00:00"
          ? ""
          : this.equipmentStandard.obsoleteDate
      );
      this.addEquipmentStandardForm.controls.note.setValue(
        this.equipmentStandard.note
      );
      this.locationTypes.push(this.equipmentStandard.locationType);
      this.addEquipmentStandardForm.controls.locationTypeId.setValue(
        this.equipmentStandard.locationTypeId
      );
      if (this.equipmentStandard.locationType.subLocationTypes.length > 0) {
        this.subLocationTypes.push(this.equipmentStandard.subLocationType);
        this.addEquipmentStandardForm.controls.subLocationTypeId.setValue(
          this.equipmentStandard.subLocationTypeId
        );
      }
      this.suppliers.push(this.equipmentStandard.supplier);
      this.addEquipmentStandardForm.controls.supplierId.setValue(
        this.equipmentStandard.supplierId
      );
      this.addEquipmentStandardForm.controls.countryId.setValue(
        this.equipmentStandard.countryId
      );
      this.equipmentClassTypes.push(this.equipmentStandard.equipmentClassType);
      this.addEquipmentStandardForm.controls.classTypeId.setValue(
        this.equipmentStandard.equipmentClassTypeId
      );
    }
  }

  setLocationEquipmentTypeMapping(locationType) {
    this.addEquipmentStandardForm.controls.locationTypeId.reset();
    this.addEquipmentStandardForm.controls.locationTypeId.setValue(
      locationType.value
    );
    this.setSublocationsAndSublocationsvalidations(locationType);
  }

  setSubLocation(subLocationType) {
    this.addEquipmentStandardForm.controls.subLocationTypeId.reset();
    this.addEquipmentStandardForm.controls.subLocationTypeId.setValue(
      subLocationType.value
    );
  }

  setSublocationsAndSublocationsvalidations(locationType) {
    this.subLocationTypes = this.locationTypes.filter(
      (p) => p.id == locationType.value
    )[0].subLocationTypes;
    if (this.subLocationTypes.length > 0) {
      this.addEquipmentStandardForm.controls.subLocationTypeId.setValidators(
        Validators.required
      );
      this.addEquipmentStandardForm.controls.subLocationTypeId.updateValueAndValidity();
    } else {
      this.addEquipmentStandardForm.controls.subLocationTypeId.setValidators(
        null
      );
      this.addEquipmentStandardForm.controls.subLocationTypeId.updateValueAndValidity();
    }
  }

  onBackClickorEditRedirect() {
    this.router.navigate(["/view-all-standards"]);
  }

  onBuildDateString(date: string) {
    let partsArray = date.split("/");
    let buildedDate = "";
    partsArray.forEach((x) => {
      if (x.length == 1 || x.length == 2) {
        if (x.length == 1) {
          buildedDate += ("0" + x).slice(-2) + "/";
        } else {
          buildedDate += x + "/";
        }
      } else {
        buildedDate += x;
      }
    });
    return buildedDate;
  }

  showPrompt(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.SimpleModalService.addModal(ModalImageCropperComponent, {
        title: "Crop your image",
        event: this.imageChangedEvent,
        ratio: 1,
      }).subscribe((image) => {
        console.log("subscribed image", image);
        resolve(image);
      });
    });
  }

  onSelectCountry(countryId: number) {
    this.equipmentStandardService
      .getMarketSuppliersByCountryId(countryId)
      .subscribe((data: Supplier[]) => {
        this.suppliers = data.map((ob) => new Supplier(ob));
      });
  }
}
