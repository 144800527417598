<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View All Wish Standard</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div *ngIf="!isCommingFromEquipments" class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/wishlist-list" routerLinkActive="active">All</a></li>
          <li class="separator">></li>
          <li class="active">{{grsDetail.standard.title}}</li>
        </ul>
      </div>
      <div *ngIf="isCommingFromEquipments" class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/locations" routerLinkActive="active">Locations</a></li>
          <li class="separator">></li>
          <li><a href="javascript:void(0);" [routerLink]="['/equipment-list', existGrs.standard.locationType.id]">
              {{existGrs.standard.locationType?.name}}</a></li>
          <li class="separator">></li>
          <li class="active">{{existGrs.standard.title}}</li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="mcd-tabs d-md-none"></div>
      <div class="col-4 image-wrapper success">
        <h1 class="d-md-none d-none">{{grsDetail.standard.title}}</h1>
        <h2 class="d-md-none sub-title">{{grsDetail.standard.equipmentStandard.equipmentClassTypeName}}</h2>
        <div class="mcd-supplier-logo">
          <img [src]="grsDetail?.standard?.equipmentStandard?.supplierImageAttachment?.image" class="img-fluid">
        </div>
        <div class="mcd-equipment-image">
          <img [src]="grsDetail?.standard?.equipmentStandard?.equipment?.imageAttachment?.image">
        </div>
        <div class="mcd-eq-attachement">
          <a href="javascript:void(0);"
            *ngIf="grsDetail.standard.equipmentStandard.equipment.equipmentAttachment.image != null"
            (click)=" onDownloadSelectedEquipmentAttachment(grsDetail.standard.equipmentStandard.equipment.equipmentAttachment)">
            <img src="/assets/images/attachement.svg"> Attachment</a>
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div class="mcd-tabs">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of equipmentRelatedStandards">
              <div class="btn-tab-wrapper "> <a
                  [ngClass]="grsDetailChild.standard.id == item.id ? 'btn btn-tab active' : 'btn btn-tab'"
                  href="javascript:void(0)" (click)="getDetailByGrbesId(item.id, true)">{{item.name}}</a></div>
            </ng-template>
          </owl-carousel-o>
        </div>
        <div [ngClass]="rygCss">
          <h1>{{grsDetail.standard.title}}</h1>
          <h2 class="sub-title">{{grsDetail.standard.equipmentStandard.equipmentClassTypeName}}</h2>
          <div class="grbes-description">
            <h2>Description</h2>
            <p>{{grsDetail.standard.description}}</p>
          </div>
          <div class="row">
            <div class=" col">
              <div class="mcd-spec">
                <span class="icon float-left">
                  <img alt="location" src="/assets/images/location-gray.svg">
                </span>
                <span class="label float-left">{{grsDetail.standard.locationType?.name}}</span>
              </div>
            </div>
            <div class="hash-tag col text-right">
              #{{grsDetail.standard.category?.name}}
            </div>

          </div>
          <!-- Start-->
          <div class="badge">
            <span>R</span>
            <span>Y</span>
            <span>G</span>
          </div>
          <!-- End -->
        </div>
        <!-- End Dynamic Wrapper -->
        <!-- Next Previous Buttons-->
        <div class="row">
          <div class="col text-right">
            <div class="btn-wrapper">
              <div class="prev-button" *ngIf="!grsDetail.isFirst">
                <a href="javascript:void(0)" (click)="slide(grsDetail.standard.id, false)"> <img
                    src="/assets/images/back.svg"></a>
              </div>
              <div class="next-button" *ngIf="!grsDetail.isLast">
                <a href="javascript:void(0)" (click)="slide(grsDetail.standard.id, true)"> <img
                    src="/assets/images/next.svg"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
