<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Update Exception </mat-card-title>
  </mat-card-header>

  <mat-card-content class="mcd-card-content">
    <div class="mcd-addendum">
      <form>
        <div class="mcd-addendum-notes">
          <div class="row">
            <div class="col-md-10">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Exception Description"
                  #desc
                  required
                  name="description"
                />
                <mat-error *ngIf="isEmpty(desc.value)">
                  Exception Description is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-10">
              <mat-form-field>
                <input
                  matInput
                  #note
                  type="text"
                  name="note"
                  required
                  placeholder="Exception Notes"
                />
                <mat-error *ngIf="isEmpty(desc.value)">
                  Exception Notes are required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- --- -->
        <div class="mcd-addendum-status">
          <div class="row attachements">
            <div class="col">
              <a href="javascript:void(0);" (click)="fileInput.click()"
                ><span
                  ><img alt="Template" src="/assets/images/upload.svg" /></span
                >Attach Exception File</a
              >
              <div *ngIf="attachment != null" class="ulploaded-file">
                <span
                  ><a
                    href="javascript:void(0);"
                    (click)="onDownloadSelectedAddendumDocument(attachment.id)"
                    >{{ attachment?.fileName }}</a
                  ></span
                >
                <span>
                  <a
                    href="javascript:void(0);"
                    (click)="onRemoveAddendumDocument()"
                  >
                    <span class="delete-icon">
                      <i _ngcontent-c7="" class="material-icons"> cancel</i>
                    </span>
                  </a>
                </span>
              </div>
              <input
                hidden
                type="file"
                (change)="onSelectAAddendumDocument($event)"
                #fileInput
              />
              <mat-error *ngIf="invalidFile">
                {{ invalidFileMessage }}
              </mat-error>
            </div>
          </div>
        </div>
        <!-- --- -->
      </form>
    </div>
  </mat-card-content>
  <mat-card-footer class="mcd-card-footer">
    <!-- <button class="mcd-btn mcd-btn-gray" *ngIf="userRole != userRoleEnum.GlobalAdmin" mat-raised-button
    (click)="saveAsADraft()" [disabled]="addendumForm.invalid || invalidFile == true">
    Save as a Draft </button> -->
    <button
      [disabled]="
        !attachment || isEmpty(desc.value) || isEmpty(note.value) || invalidFile
      "
      class="mcd-btn mcd-btn-secondary"
      (click)="updateAddendum(desc.value, note.value)"
      mat-raised-button
    >
      Submit
    </button>
  </mat-card-footer>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
