import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mcd-loader',
  templateUrl: './mcd-loader.component.html',
  styleUrls: ['./mcd-loader.component.scss']
})
export class McdLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
