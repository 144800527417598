<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">{{category.id > 0? "Edit" : "Add New"}} Category</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li><a routerLink="/global-admin-panel" routerLinkActive="active">Global Admin Panel</a></li>
        <li class="separator">></li>
        <li><a routerLink="/view-all-category-list" routerLinkActive="active">Manage Categories</a></li>
        <li class="separator">></li>
        <li class="active">{{category.id > 0? "Edit" : "Add New"}} Category</li>
      </ul>
    </div>
  </div>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEditCategoryForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-5">
              <mat-form-field>
                <input matInput placeholder="Name" name="Name" formControlName="name" pattern="^[a-zA-Z ]*$" required>
                <mat-error *ngIf="addEditCategoryForm.get('name').touched && addEditCategoryForm.get('name').hasError('required')">
                  Name is required
                </mat-error>
                <mat-error *ngIf="addEditCategoryForm.get('name').touched && addEditCategoryForm.get('name').hasError('pattern')">
                  Name is Invalid
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12" >
              <mat-form-field>
                <input matInput placeholder="Description" name="Description"  formControlName="description" required>
                <mat-error *ngIf="addEditCategoryForm.get('description').touched && addEditCategoryForm.get('description').hasError('required')">
                  Description is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button class="mcd-btn mcd-btn-primary" *ngIf="!category.id" mat-raised-button
              (click)="onReset()">
        Reset
      </button>
      <button class="mcd-btn mcd-btn-primary" *ngIf="category.id" mat-raised-button
        (click)="onCancelClick()">Cancel</button>
      <button class="mcd-btn mcd-btn-secondary" mat-raised-button
              [disabled]="addEditCategoryForm.invalid"
              (click)="onSaveCategory()">
        {{category.id > 0? "Update" : "Add"}}
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
