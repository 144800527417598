import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Standard } from "../models/standard";
import { FilteringGuide } from "../models/filteringGuide";
import { StandardService } from "../services/standard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { StandardList } from "../models/standard-list";
import {
  StandardListType,
  UserRoleUserOperation,
  StandardTypeEnum,
  Constant,
} from "../core/constant";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { environment } from "../../environments/environment.prod";
import { CryptographyService } from "../services/cryptography.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EquipmentStandard } from "../models/equipment-standard";
import { EquipmentStandardService } from "../services/equipment -standard.service";
import { Observable } from "rxjs";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { SelectedStandard } from "../models/selected-standard";
import { MatMenuTrigger } from "@angular/material/menu";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FilterList } from "../models/filterList";
import { Sort } from "@angular/material/sort";

@Component({
  selector: "app-view-all-obsolete-standard-list",
  templateUrl: "./view-all-obsolete-standard-list.component.html",
  styleUrls: ["./view-all-obsolete-standard-list.component.scss"],
})
export class ViewAllObsoleteStandardListComponent implements OnInit {
  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string = "";
  loading: boolean = false;
  standards: Standard[] = [];
  filterList: FilteringGuide[] = [];
  canEditEquipmentStandard: boolean;
  canDeleteEquipmentStandard: boolean;
  modalOption: NgbModalOptions = {};
  equipmentStandard: EquipmentStandard = new EquipmentStandard();
  get standardListType() {
    return StandardListType;
  }
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  standardFiltrationForChipSet: FilterList = new FilterList();

  constructor(
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    private router: Router,
    @Inject(LOCAL_STORAGE) public storage: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    private equipmentStandardService: EquipmentStandardService
  ) {}

  ngOnInit() {
    this.standardFiltrationForChipSet.standardListType =
      StandardListType.Obsolete;
    this.setUserPrivilages();
    this.filterList = this.standardService.obsoleteStandardFiltration.filterSet;
    this.pageSize = this.standardService.obsoleteStandardFiltration.pageSize;
    this.searchKey = this.standardService.obsoleteStandardFiltration.searchKey;
    this.getAllEquipmentStandards();
  }

  receivedFilteration($event) {
    this.page = 1;
    this.filterList = $event;
    this.standardService.obsoleteStandardFiltration.filterSet = this.filterList;
    this.getAllEquipmentStandards();
  }
  setUserPrivilages() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.canEditEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanEditEquipmentStandard
          ) !== -1;
        this.canDeleteEquipmentStandard =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.CanDeleteEquipmentStandard
          ) !== -1;
      }
    } else {
      this.canEditEquipmentStandard = true;
      this.canDeleteEquipmentStandard = true;
    }
  }

  //compare
  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === "" || !(this.standards.length > 0)) {
      return;
    }
    console.log("GRSES-dort", this.standards);

    const isAsc = sort.direction === "asc";
    this.standards.sort((a, b) => {
      switch (sort.active) {
        case "title":
          return this.compare(a.title, b.title, isAsc);
        case "model":
          return this.compare(
            a.equipmentStandard.model,
            b.equipmentStandard.model,
            isAsc
          );
        case "equipment":
          return this.compare(
            a.equipmentStandard.equipmentType.name,
            b.equipmentStandard.equipmentType.name,
            isAsc
          );
      }
    });
  }
  getAllEquipmentStandards() {
    this.loading = true;
    this.standardService.obsoleteStandardFiltration.pageSize = this.pageSize;
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.Obsolete,
        this.filterList,
        null
      )
      .subscribe((data: StandardList) => {
        this.standards = [];
        this.standards = data.standardDetailList.map(
          (obj) => new Standard(obj)
        );
        console.log("this.standrads", this.standards);
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.loading = false;
        };
      });
  }

  onEdit(id) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    this.router.navigate(["/edit-eq-standards-global", id]);
  }

  openDeletePopup(item, event) {
    event.stopPropagation();
    this.modalOption.backdrop = "static";
    this.modalOption.keyboard = false;
    this.equipmentStandard.id = item.equipmentStandard.id;
    const modalRef = this.ngbModal.open(
      DeleteDialogComponent,
      this.modalOption
    );
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    if (
      item.standardTypeId == StandardTypeEnum.GlobalMinimumRestaurantStandard ||
      item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard
    ) {
      modalRef.componentInstance.message =
        "All the dependencies will be removed along with this standard";
    }
    modalRef.componentInstance.deleteItem = item.id;
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItemId) => {
      if (deleteItemId != null) {
        let equipmentStandard = new EquipmentStandard();
        equipmentStandard.id = deleteItemId;
        equipmentStandard.isDeleted = true;
        this.loading = true;
        this.equipmentStandardService
          .deleteEquipmentStandard(equipmentStandard)
          .subscribe((data: any) => {
            this.loading = false;
            this.page = 1;
            this.getAllEquipmentStandards();
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeletePopup(item, event);
    }, 100);
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.obsoleteStandardFiltration.pageSize = this.pageSize;
    this.getAllEquipmentStandards();
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.standardService.obsoleteStandardFiltration.searchKey = this.searchKey;
    this.getAllEquipmentStandards();
  }

  rowSelected(item: Standard): void {
    this.standardService.fromLocationGRS = false;

    this.router.navigate([
      "/view-obsolete-equipments-standard-detail/" + item.id,
    ]);
  }

  downloadExcel() {
    this.standardService
      .downloadExcel(
        0,
        this.collectionSize,
        StandardListType.Obsolete,
        this.filterList,
        null,
        null
      )
      .subscribe(
        (response: any) => {
          let filename =
            "Obsolete_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }
  getLocationNames(item): any {
    if (item.standardLocation && item.standardLocation.length > 0) {
      return item.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.locationType.name;
    }
  }
}
