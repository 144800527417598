import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { debug } from "util";
import { EctRows } from "../models/ectRows ";

@Injectable({
  providedIn: "root",
})
export class EctService {
  constructor(private http: HttpClient) {}

  GetSheet(sheetId, markertID) {
    let params = new HttpParams();
    params = params.append("sheetID", sheetId);
    params = params.append("markertID", markertID);
    return this.http.get("/api/Ect/GetSheet", {
      params: params,
    });
  }

  ProcessSheet(ectTable: EctRows) {
    return this.http.post("/api/Ect/ProcessSheet", ectTable);
  }

  GetSheetMetaDatas(isRegular) {
    let params = new HttpParams();
    params = params.append("isRegular", isRegular);
    return this.http.get("/api/Ect/GetSheetMetaDatas", {
      params: params,
    });
  }

  GetCountryList() {
    return this.http.get("/api/Ect/GetAllCountries");
  }
}
