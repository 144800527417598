import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserDetailByLoggedInUserEmailAndCountryIso(loggedInUserEmail, countryIso) {
    let params = new HttpParams();
    params = params.append("loggedInUserEmail", loggedInUserEmail);
    params = params.append("countryIso", countryIso);
    return this.http.get(
      "/api/User/GetUserDetailByLoggedInUserEmailAndCountryIso",
      { params: params }
    );
  }
}
