import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NrbesItem } from "../models/nrbesItem";
import { Country } from "../models/country";
import { Category } from "../models/category";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Standard } from "../models/standard";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { StandardTypeEnum } from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CreateNewNrbes } from "../models/createNewNrbes";
import { StandardType } from "../models/standardType";
@Component({
  selector: "app-clone-nrbes",
  templateUrl: "./clone-nrbes.component.html",
  styleUrls: ["./clone-nrbes.component.scss"],
})
export class CloneNrbesComponent implements OnInit {
  loading: boolean = false;
  editNrbesForm: FormGroup;
  selected = "option2";
  standardId: any = null;
  selectedStandard: Standard = new Standard();
  newNrbesData: CreateNewNrbes;

  @Input() public editNrbesModel: NrbesItem = new NrbesItem();
  @Input() public categories: Category[] = [];
  @Input() public locationTypes: LocationType[] = [];

  @Output() nrbesEditPopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private standardService: StandardService,
    private route: Router,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.standardId = this.activeRoute.snapshot.paramMap.get("standardid");

    this.createForm();
  }

  createForm(): void {
    this.loading = true;
    let categoriesArray = [];

    let locationTypesArray = [];

    this.editNrbesForm = this.fb.group({
      title: ["", Validators.required],
      note: [""],
      category: categoriesArray,
      locationType: locationTypesArray,
      categoryId: ["", Validators.required],
      locationTypeId: ["", Validators.required],
      standardDescription: ["", Validators.required],
      newrestaurantdescription: ["", Validators.required],
      additionalInformation: [""],
      effectiveFrom: [""],
      newRestaurantEffectiveFrom: [""],
    });
    // this.editNrbesForm.controls["title"].disable();
    this.standardService
      .getStandardInfomationByStandardId(this.standardId)
      .subscribe((data: any) => {
        this.standardService
          .GetMasterDataForAddNrbes(
            this.cryptographyService.decryptData(this.storage.get("userRole")),
            this.cryptographyService.decryptData(this.storage.get("userId"))
          )
          .subscribe((data2: any) => {
            this.categories = data2.categories;
            this.locationTypes = data2.locationTypes;
            console.log("location types", this.locationTypes);

            this.selectedStandard.id = data.standard.id;
            this.selectedStandard.title = data.standard.title;
            this.selectedStandard.note = data.standard.note;
            this.selectedStandard.description = data.standard.description;
            this.selectedStandard.categoryId = data.standard.categoryId;
            this.selectedStandard.locationTypeId = data.standard.locationTypeId;
            this.selectedStandard.additionalInformation =
              data.standard.additionalInformation;
            this.selectedStandard.note = data.standard.note;
            this.selectedStandard.newRestaurantsEffectiveFrom =
              data.standard.newRestaurantsEffectiveFrom;
            this.selectedStandard.effectiveFrom = data.standard.effectiveFrom;

            if (this.locationTypes != null) {
              this.locationTypes
                .sort((firstItem, nextItem) =>
                  firstItem.name.localeCompare(nextItem.name)
                )
                .forEach((element) => {
                  locationTypesArray.push(
                    this.fb.group({
                      id: new FormControl(element.id),
                      name: new FormControl(element.name),
                    })
                  );
                });
            }

            if (this.categories != null) {
              this.categories
                .sort((firstItem, nextItem) =>
                  firstItem.name.localeCompare(nextItem.name)
                )
                .forEach((element) => {
                  categoriesArray.push(
                    this.fb.group({
                      id: new FormControl(element.id),
                      name: new FormControl(element.name),
                    })
                  );
                });
            }

            this.editNrbesForm.controls.title.setValue(data.standard.title);
            this.editNrbesForm.controls.note.setValue(data.standard.note);
            this.editNrbesForm.controls.standardDescription.setValue(
              data.standard.description
            );
            this.editNrbesForm.controls.categoryId.setValue(
              data.standard.categoryId
            );
            this.editNrbesForm.controls.locationTypeId.setValue(
              data.standard.locationTypeId
            );
            this.editNrbesForm.controls.newrestaurantdescription.setValue(
              data.standard.newRestaurantDescription
            );

            this.editNrbesForm.controls.additionalInformation.setValue(
              data.standard.additionalInformation
            );

            if (data.standard.effectiveFrom != null) {
              this.editNrbesForm.controls.effectiveFrom.setValue(
                new Date(data.standard.effectiveFrom)
              );
            } else {
              this.editNrbesForm.controls.effectiveFrom.setValue(
                data.standard.effectiveFrom
              );
            }

            if (data.standard.newRestaurantsEffectiveFrom != null) {
              this.editNrbesForm.controls.newRestaurantEffectiveFrom.setValue(
                new Date(data.standard.newRestaurantsEffectiveFrom)
              );
            } else {
              this.editNrbesForm.controls.newRestaurantEffectiveFrom.setValue(
                data.standard.newRestaurantsEffectiveFrom
              );
            }
            this.loading = false;
          });
      });
  }

  // edit(): void {
  //   this.loading = true;
  //   var standard = new Standard();
  //   standard.id = this.selectedStandard.id;
  //   standard.title = this.editNrbesForm.controls.title.value;
  //   standard.note = this.editNrbesForm.controls.note.value;
  //   standard.categoryId = this.editNrbesForm.controls.categoryId.value;
  //   standard.locationTypeId = this.editNrbesForm.controls.locationTypeId.value;
  //   standard.description =
  //     this.editNrbesForm.controls.standardDescription.value;
  //   standard.newRestaurantDescription =
  //     this.editNrbesForm.controls.newrestaurantdescription.value;
  //   standard.additionalInformation =
  //     this.editNrbesForm.controls.standardDescription.value;
  //   standard.newRestaurantsEffectiveFrom =
  //     this.editNrbesForm.controls.newRestaurantEffectiveFrom.value != null
  //       ? this.onBuildDateString(
  //           new Date(
  //             this.editNrbesForm.controls.newRestaurantEffectiveFrom.value
  //           )
  //             .toLocaleDateString("en-US")
  //             .replace(/\u200E/g, "")
  //         )
  //       : null;
  //   standard.effectiveFrom =
  //     this.editNrbesForm.controls.effectiveFrom.value != null
  //       ? this.onBuildDateString(
  //           new Date(this.editNrbesForm.controls.effectiveFrom.value)
  //             .toLocaleDateString("en-US")
  //             .replace(/\u200E/g, "")
  //         )
  //       : null;
  //   standard.standardTypeId = StandardTypeEnum.LocalStandard;
  //   standard.userId = this.cryptographyService.decryptData(
  //     this.storage.get("userId")
  //   );

  // this.standardService.updateStandard(standard).subscribe((data: any) => {
  //   const modalRef = this.ngbModal.open(SuccessDialogComponent);
  //   modalRef.componentInstance.message =
  //     "You have successfully cloned and added the Standard.";
  //   this.loading = false;
  //   modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
  //     (receivedEntry) => {
  //       this.ngbModal.dismissAll();
  //       this.route.navigate(["/nrbeslist"]);
  //     }
  //   );
  // });
  // }

  onBuildDateString(date: string) {
    let partsArray = date.split("/");
    let buildedDate = "";
    partsArray.forEach((x) => {
      if (x.length == 1 || x.length == 2) {
        if (x.length == 1) {
          buildedDate += ("0" + x).slice(-2) + "/";
        } else {
          buildedDate += x + "/";
        }
      } else {
        buildedDate += x;
      }
    });
    return buildedDate;
  }

  reset() {
    this.editNrbesForm.controls.title.reset();
    this.editNrbesForm.controls.note.reset();
    this.editNrbesForm.controls.standardDescription.reset();
    this.editNrbesForm.controls.categoryId.reset();
    this.editNrbesForm.controls.locationTypeId.reset();
    this.editNrbesForm.controls.newrestaurantdescription.reset();
    this.editNrbesForm.controls.effectiveFrom.reset();
    this.editNrbesForm.controls.newRestaurantEffectiveFrom.reset();
  }

  setSelectedCategory(category) {
    console.log("selected category", category);
  }

  setSelectedLocationType(locationType) {
    console.log("selected locationType", locationType);
  }

  redirectBack() {
    this.route.navigate(["/nrbeslist"]);
  }
}
