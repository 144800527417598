import { FilteringGuide } from "./filteringGuide";
import { StandardListType } from "../core/constant";

export class StandardFiltration {
  standardId: number;
  countryId: number = null;
  isForward: boolean;
  searchkey: string;
  standardListType: StandardListType;
  filterSet: FilteringGuide[] = [];
  AppliedFilters: any = [];
}
