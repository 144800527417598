<div class="card mcd-equipment-card">
    <img class="card-img-top" src="https://4.imimg.com/data4/IH/MB/MY-31015415/beverage-tower-dispenser-500x500.jpg" alt="Card image cap">
    <div class="card-body">
      <div class="card-body-inner">
      <h5 class="card-title">Automated Beverages</h5>
      <p class="card-text">Some quick example text to build on the card title.</p>
      <div class="footer-item">
        <div class="row">
        <div class="col">
          <img alt="Honda" title="Honda" width="20" src="https://www.iconsdb.com/icons/preview/red/honda-xxl.png">
        </div>
        <div class="col text-right">
          <a href=""> More </a>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
