import { Component, OnInit, Inject } from "@angular/core";
import { StandardService } from "../services/standard.service";
import {
  StandardTypeEnum,
  UserRoleEnum,
  StandardListType,
  FilteringEnum,
  AllStandardsTabs,
} from "../core/constant";
import { Router, ActivatedRoute } from "@angular/router";
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SelectedStandard } from "../models/selected-standard";
import { Attachment } from "../models/attachment";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-view-all-equipments-standard-detail-view",
  templateUrl: "./view-all-equipments-standard-detail-view.component.html",
  styleUrls: ["./view-all-equipments-standard-detail-view.component.scss"],
})
export class ViewAllEquipmentsStandardDetailViewComponent implements OnInit {
  grsId: number;
  loading: boolean = false;
  grsDetail: SelectedStandard = new SelectedStandard();
  userRole: number;
  notes: SafeHtml = "";
  additionalInformation: SafeHtml = "";
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private router: Router,
    private standardService: StandardService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
  }

  getDetailByGrbesId(grsId: number) {
    this.loading = true;
    //debugger;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.equipmentStandardFiltration.filterSet,
        StandardListType.AllStandards,
        this.standardService.equipmentStandardFiltration.searchKey,
        this.getAppliedFilters()
      )
      .subscribe((data: SelectedStandard) => {
        console.log("DATA", data);
        this.grsDetail = new SelectedStandard(data);
        console.log("this.grsdetailsssss", this.grsDetail);
        var options = {
          /* … */
        };
        //debugger;
        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.additionalInformation
        );

        this.loading = false;
        () => {
          this.loading = false;
        };
      });
  }

  addToNrbes(standardId, standardTypeId) {
    this.loading = true;
    this.standardService
      .addStandardToNrbes(
        standardId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        (standardTypeId === 0 ? 6 : 3),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe(() => {
        this.loading = false;
        this.getDetailByGrbesId(this.grsDetail.standard.id);
      });
  }

  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }

  getAppliedFilters() {
    let idx =
      this.standardService.equipmentStandardFiltration.filterSet.findIndex(
        (i) => i.type == FilteringEnum.FilterByStandardType
      );
    if (idx == -1) return [];
    return this.standardService.equipmentStandardFiltration.filterSet[idx].ids;
  }
  slide(grsID: number, isForward: boolean) {
    this.loading = true;
    if (this.userRole != this.userRoleEnum.MarketAdmin) {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.equipmentStandardFiltration.filterSet,
          StandardListType.AllStandards,
          this.standardService.equipmentStandardFiltration.searchKey,
          this.getAppliedFilters()
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.navigateAccToType(
            data.standard.standardTypeId,
            data.standard.id
          );
          this.grsDetail = new SelectedStandard(data);
          var options = {
            /* … */
          };
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousGrsInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.equipmentStandardFiltration.filterSet,
          StandardListType.AllStandards,
          this.standardService.equipmentStandardFiltration.searchKey,
          this.getAppliedFilters()
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate([
              "/view-equipments-standard-detail/" + data.standard.id,
            ]);
          }
          this.navigateAccToType(
            data.standard.standardTypeId,
            data.standard.id
          );
          this.grsDetail = new SelectedStandard(data);
          var options = {
            /* … */
          };
          this.notes = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.note
          );
          this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
            this.grsDetail.standard.additionalInformation
          );
          this.loading = false;
          () => {
            this.loading = false;
          };
        });
    }
  }

  onDownloadSelectedEquipmentAttachment(e) {
    //debugger;
    return this.standardService.getAttachmentUrl(e.id, false).subscribe(
      (response: any) => {
        const blob = new Blob([response], {
          type: response.type,
        });
        if (
          (window.navigator as any) &&
          (window.navigator as any).msSaveOrOpenBlob
        ) {
          (window.navigator as any).msSaveOrOpenBlob(blob, e.fileName);
          return;
        }
        var a = document.createElement("a");
        document.body.appendChild(a);

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = e.fileName;
        a.click();
        window.URL.revokeObjectURL(url);

        //window.open(url);
      },
      (error: any) => {
        const modalRef = this.ngbModal.open(FailModuleComponent);
        modalRef.componentInstance.errorMessage = error.error
          ? error.error.Message
          : error.message;
      }
    );
  }

  downloadExcel() {
    this.standardService.downloadSingleStandardExcel(
      this.grsDetail,
      "equipment"
    );
    return;
  }
  getLocationNames(item): any {
    if (
      item.standard.standardLocation &&
      item.standard.standardLocation.length > 0
    ) {
      return item.standard.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.standard.locationType.name;
    }
  }
}
