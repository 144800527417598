import { LocationCategoryMappings } from "./locationCategoryMappings";
import { SubLocationType } from "./sub-location-type";
import { EquipmentType } from "./equipment-type";

export class LocationType {
  id: number;
  name: string;
  subLocationTypes: SubLocationType[] = [];
  equipmentTypes: EquipmentType[] = [];
  constructor(obj?: LocationType) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.subLocationTypes = obj.subLocationTypes ? obj.subLocationTypes.map(x => new SubLocationType(x)) : [];
      this.equipmentTypes = obj.equipmentTypes ? obj.equipmentTypes.map(x => new EquipmentType(x)) : [];
    }
  }
}
