import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { environment } from "../../environments/environment";
import { UserRoleUserOperation } from "../core/constant";
import { AddendumService } from "../services/addendum.service";
import { Addendum } from "../models/addendum";
import { delay } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

@Component({
  selector: "app-run-view-script",
  templateUrl: "./run-view-script.component.html",
  styleUrls: ["./run-view-script.component.scss"],
})
export class RunViewScript implements OnInit {
  loading: boolean = false;
  response: boolean;
  dynamicEmail: string = "vipul.kumar@cloudeq.com";
  run_script: boolean = false;
  view_script: boolean = false;
  showFrame: boolean = false;
  htmlFilePath: string;
  viewManageSuppliers: boolean;
  viewAllSuppliers: boolean;
  viewAllEquipmentType: boolean;
  viewAllCategory: boolean;
  viewAllUser: boolean;
  viewAllUsefulLinks: boolean;
  addendum: Addendum;

  constructor(
    private addendumService: AddendumService,
    private router: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.viewManageSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewManageSuppliers
          ) !== -1;
        this.viewAllSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllSuppliers
          ) !== -1;
        this.viewAllEquipmentType =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllEquipmentType
          ) !== -1;
        this.viewAllCategory =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllCategory
          ) !== -1;
        this.viewAllUser =
          userRoleUserOperation.indexOf(UserRoleUserOperation.ViewAllUser) !==
          -1;
        this.viewAllUsefulLinks =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllUsefulLinks
          ) !== -1;
      }
    } else {
      this.viewManageSuppliers = true;
      this.viewAllSuppliers = true;
      this.viewAllEquipmentType = true;
      this.viewAllCategory = true;
      this.viewAllUser = true;
      this.viewAllUsefulLinks = true;
    }
  }

  async runscript(email: string) {
    this.run_script = true;
    this.view_script = true;
    this.loading = true;

    // alert(email)
    // await new Promise(resolve => setTimeout(resolve, 10000));
    try {
      const data: boolean = await this.addendumService.getScript().toPromise();
      this.response = data;
      // alert("completed")
      console.log(this.response);
      this.run_script = false;
      this.view_script = false;
      if (this.response) {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "Links tested successfully. You can now view the report.";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            // this.router.navigate(["/nrbeslist"]);
          }
        );
      } else {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);

        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message =
          "Some error occurred. Please try again after some time";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            // this.router.navigate(["/nrbeslist"]);
          }
        );
      }

      // console.log(this.addendum);
      // this.updateAddendum();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  openHtmlFile() {
    const path1 = "assets/Report.html";
    // const path = "https://localhost:5001/" + "../../../../Report.html";
    window.open(path1, "_blank");
  }
}
