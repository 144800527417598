<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Equipment Capacity Tool</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content clearfix">
    <form [formGroup]="ButtonList">
      <div class="row">
        <div class="col mb-2">
          <label class="check-container">
            Regular Menu
            <input type="checkbox" formControlName="isRegularMenu" (input)="resetForm()">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="col">
          <label class="check-container">
            Breakfast Menu
            <input type="checkbox" formControlName="isBreakfast" (input)="resetForm()">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="mcd-drop-country col" *ngIf="this.userRole == 1">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select name="dropCategory" [value]="selectedCountryId" #country
                        (selectionChange)="selectCountry(country)">
              <mat-option *ngFor="let item of countries ; let j=index" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row btn-list" *ngIf="ButtonList.get('isRegularMenu').value">
        <div *ngFor="let button of regularButtonList;let i = index">
          <button id="button.sheetID" class="sheet-btn d-inline-block float-left" [ngClass]="isClick && button.sheetID == sheetId ? 'sheet-btn-active' : ''" (click)="GetSheet(button.sheetID)">{{button.sheetCode }}</button>
        </div>
      </div>

      <div class="row btn-list" *ngIf="ButtonList.get('isBreakfast').value">
        <div *ngFor="let button of nonRegularButtonList;let i = index">
          <button id="button.sheetID" class="sheet-btn d-inline-block float-left" [ngClass]="isClick && button.sheetID == sheetId ? 'sheet-btn-active' : ''" (click)="GetSheet(button.sheetID)">{{button.sheetCode }}</button>
        </div>
      </div>
    </form>

    <span class="main-title pt-4 d-inline-block w-100 text-center font-weight-bold"
          *ngIf="this.Ect.controls.tablesArray['controls'].length > 0 && (ButtonList.get('isRegularMenu').value || ButtonList.get('isBreakfast').value)">{{sheetName}}</span>


    <form [formGroup]="EctGeneralInput" *ngIf="this.Ect.controls.tablesArray['controls'].length > 0 && (ButtonList.get('isRegularMenu').value || ButtonList.get('isBreakfast').value)">
      <div class="capacity-tool-main-wrapper">
        <div class="row">

          <div class="col-md-6 col-sm-12 col-12">
            <div class="information-card information-card-red">
              <span class="information-number"><input #peakTCHour formControlName="peakTCHour" class="information-number-input" /></span>
              <span class="information-summary">Peak TC Hour</span>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="information-card information-card-yellow float-right">
              <span class="information-number"><input formControlName="peakHourlyTCDaytime" class="information-number-input" /></span>
              <span class="information-summary">Peak Hourly TCs</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form [formGroup]="Ect" *ngIf="this.Ect.controls.tablesArray['controls'].length > 0 && (ButtonList.get('isRegularMenu').value || ButtonList.get('isBreakfast').value)">
      <div formArrayName="tablesArray">
        <div *ngFor="let table of tableList;let i = index">
          <br />
          <div class="table-lg-sub-wrapper">
            <h3 class="sub-title font-weight-bold">{{table.modelName}}</h3>
          </div>

          <div class="table-responsive" [ngStyle]="tableList[i].isFinalOutput ? {'background-color':'#fdecc8'} : ''">
            <table border="1" [formGroupName]="i" class="table-lg">
              <thead>
                <tr>
                  <th *ngFor="let header of table.columnHeaderList">{{header.header}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of table.rowList">
                  <td title="{{column.value}}" *ngFor="let column of row.columnList; let j = index" [ngStyle]="!table.columnHeaderList[j].isEditable ? {'background-color':'transparent'} : ''">
                    <input class="table-input" matInput [formControlName]="table.modelID + '_'+ row.rowID + '_' + column.columnID"
                           readonly="{{!table.columnHeaderList[j].isEditable}}"
                           [ngStyle]="this.Ect.get('tablesArray')?.controls[i]?.controls[table.modelID + '_'+ row.rowID + '_' + column.columnID]?.invalid ? {'color':'red'} : ''">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </form>
    <div class="d-inline-block w-100" *ngIf="this.Ect.controls.tablesArray['controls'].length > 0 && (ButtonList.get('isRegularMenu').value || ButtonList.get('isBreakfast').value)">
      <button class="mcd-btn mcd-btn-primary mt-5 mb-5 btn-process float-right" mat-raised-button
              (click)="ProcessSheet()"
              [disabled]="Ect.invalid || this.Ect.controls.tablesArray['controls'].length == 0 || EctGeneralInput.invalid">

        Process
      </button>
    </div>

  </mat-card-content>

</mat-card>
<app-mcd-loader *ngIf="loading"></app-mcd-loader>
