import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Category } from "../models/category";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { StandardTypeEnum, UserRoleEnum } from "../core/constant";
import { Country } from "../models/country";
import { AOWGroup } from "../models/aowgroup";
import { CreateNewABUStandard } from "../models/CreateNewABUStandard";

@Component({
  selector: "app-add-abustandard",
  templateUrl: "./add-abustandard.component.html",
  styleUrls: ["./add-abustandard.component.scss"],
})
/** add-abustandard component*/
export class AddAbuStandardComponent implements OnInit {
  loading: boolean = false;
  userRole: number;
  addABUStandardForm: FormGroup;
  selected = "option2";
  categories: Category[] = [];
  locationTypes: LocationType[] = [];
  countries: Country[] = [];
  aows: AOWGroup[] = [];
  createNewABUStandard: CreateNewABUStandard;

  @Output() abustandardEditPopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private standardService: StandardService,
    private ngbModal: NgbModal,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    //debugger;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    let locationTypesArray = [];
    let categoriesArray = [];
    if (this.userRole == UserRoleEnum.AOWAdmin) {
      this.addABUStandardForm = this.fb.group({
        title: ["", Validators.required],
        note: [""],
        category: categoriesArray,
        locationType: locationTypesArray,
        categoryId: ["", Validators.required],
        locationTypeId: ["", Validators.required],
        standardDescription: ["", Validators.required],
        newrestaurantdescription: ["", Validators.required],
        additionalInformation: [""],
        effectiveFrom: [""],
        newRestaurantEffectiveFrom: [""],
        aowId: ["", Validators.required],
      });
    } else {
      this.addABUStandardForm = this.fb.group({
        title: ["", Validators.required],
        note: [""],
        category: categoriesArray,
        locationType: locationTypesArray,
        categoryId: ["", Validators.required],
        locationTypeId: ["", Validators.required],
        standardDescription: ["", Validators.required],
        newrestaurantdescription: ["", Validators.required],
        additionalInformation: [""],
        countryId: [""],
        effectiveFrom: [""],
        newRestaurantEffectiveFrom: [""],
      });
    }

    this.loading = true;
    this.standardService
      .GetMasterDataForAddABUs(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.aows = data.aows;
        this.categories = data.categories;
        this.locationTypes = data.locationTypes;
        console.log(this.locationTypes);

        if (this.locationTypes != null) {
          this.locationTypes
            .sort((firstItem, nextItem) =>
              firstItem.name.localeCompare(nextItem.name)
            )
            .forEach((element) => {
              locationTypesArray.push(
                this.fb.group({
                  id: new FormControl(element.id),
                  name: new FormControl(element.name),
                })
              );
            });
        }

        if (this.categories != null) {
          this.categories
            .sort((firstItem, nextItem) =>
              firstItem.name.localeCompare(nextItem.name)
            )
            .forEach((element) => {
              categoriesArray.push(
                this.fb.group({
                  id: new FormControl(element.id),
                  name: new FormControl(element.name),
                })
              );
            });
        }
        this.loading = false;
      });
  }

  reset() {
    this.addABUStandardForm.controls.title.reset();
    this.addABUStandardForm.controls.note.reset();
    this.addABUStandardForm.controls.additionalInformation.reset();
    this.addABUStandardForm.controls.standardDescription.reset();
    this.addABUStandardForm.controls.newrestaurantdescription.reset();
    this.addABUStandardForm.controls.locationTypeId.reset();
    this.addABUStandardForm.controls.effectiveFrom.reset();
    this.addABUStandardForm.controls.newRestaurantEffectiveFrom.reset();
    this.addABUStandardForm.controls.categoryId.setValue(null);
    this.addABUStandardForm.controls.locationTypeId.setValue(null);
  }

  setSelectedLocationType(locationType) {
    this.addABUStandardForm.controls.locationTypeId.setValue(
      locationType.value
    );
  }

  setSelectedCategory(category) {
    this.addABUStandardForm.controls.categoryId.setValue(
      parseInt(category.value)
    );
  }

  close() {
    this.reset();
    this.abustandardEditPopupEmit.emit();

    this.activeModal.dismiss();
  }

  onBuildDateString(date: string) {
    let partsArray = date.split("/");
    let buildedDate = "";
    partsArray.forEach((x) => {
      if (x.length == 1 || x.length == 2) {
        if (x.length == 1) {
          buildedDate += ("0" + x).slice(-2) + "/";
        } else {
          buildedDate += x + "/";
        }
      } else {
        buildedDate += x;
      }
    });
    return buildedDate;
  }

  submit() {
    this.loading = true;
    var abuStandard = new CreateNewABUStandard();
    abuStandard.title = this.addABUStandardForm.controls.title.value;
    abuStandard.note = this.addABUStandardForm.controls.note.value;
    abuStandard.locationTypeId =
      this.addABUStandardForm.controls.locationTypeId.value;
    abuStandard.categoryId = parseInt(
      this.addABUStandardForm.controls.categoryId.value
    );
    abuStandard.description =
      this.addABUStandardForm.controls.standardDescription.value;
    abuStandard.additionalInformation =
      this.addABUStandardForm.controls.additionalInformation.value;
    abuStandard.newrestaurantDescription =
      this.addABUStandardForm.controls.newrestaurantdescription.value;
    abuStandard.effectiveFrom =
      this.addABUStandardForm.controls.effectiveFrom.value != null
        ? this.onBuildDateString(
            new Date(this.addABUStandardForm.controls.effectiveFrom.value)
              .toLocaleDateString("en-US")
              .replace(/\u200E/g, "")
          )
        : null;
    abuStandard.newRestaurantsEffectiveFrom =
      this.addABUStandardForm.controls.newRestaurantEffectiveFrom.value != null
        ? this.onBuildDateString(
            new Date(
              this.addABUStandardForm.controls.newRestaurantEffectiveFrom.value
            )
              .toLocaleDateString("en-US")
              .replace(/\u200E/g, "")
          )
        : null;

    abuStandard.standardStatusId = 6;
    abuStandard.standardTypeId = StandardTypeEnum.ABUStandard;
    abuStandard.createdUserId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    //nrbes.countryId = this.cryptographyService.decryptData(this.storage.get('countryId'));

    abuStandard.aowGroupId = this.addABUStandardForm.controls.aowId.value;
    this.standardService
      .createNewABUStandard(abuStandard)
      .subscribe((data: any) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully added the standard.";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            this.router.navigate(["/nrbeslist"]);
          }
        );
        this.reset();
      });
  }
}
