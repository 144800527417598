import { GlobalMarketSupplier } from "./global-market-supplier";
export class SupplierList {
    globalMarketSuppliers: GlobalMarketSupplier[] = [];
    count: number;
    constructor(obj?: SupplierList) {
      if (obj) {
        this.globalMarketSuppliers = obj.globalMarketSuppliers? obj.globalMarketSuppliers.map(o => new GlobalMarketSupplier(o)): null;
        this.count = obj.count;
      }
    }
}
