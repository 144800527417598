import { Component, OnInit } from '@angular/core';
import { Supplier } from '../models/supplier';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FailModuleComponent } from '../fail-module/fail-module.component';

@Component({
  selector: 'app-supplier-detail-view',
  templateUrl: './supplier-detail-view.component.html',
  styleUrls: ['./supplier-detail-view.component.scss']
})
export class SupplierDetailViewComponent implements OnInit {

  error: string = '';
  loading: boolean = false;
  supplier: Supplier = new Supplier();

  constructor(private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal) { 
    this.supplier.id = parseInt(this.activeRoute.snapshot.paramMap.get('supplierId'));
  }

  ngOnInit() {
    if (this.supplier.id) {
      this.getSupplierBySupplierId(this.supplier.id);
    }
  }

  getSupplierBySupplierId(supplierId: number) {
    this.loading = true;
    this.adminService.GetSupplierDetailsById(supplierId)
      .subscribe(
        (data: Supplier) => {
          this.supplier = new Supplier(data);
          this.loading = false;
          (error: string) => {
            this.error = error;
            const modalRef = this.ngbModal.open(FailModuleComponent);
            this.error = error;
            modalRef.componentInstance.errorMessage = error;
            this.loading = false;
          }
        });
  }
}
