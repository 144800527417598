<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >Equipment Exception list</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row" *ngIf="userRole != 3">
      <div class="col-md-12 filter-panel">
        <div class="mcd-filter-tag-wrapper">
          <div class="mcd-tag-title">Filter By Exception Status</div>
          <mat-checkbox
            *ngFor="let item of addendumStatuses"
            [checked]="setCheckedStateForCheckBoxes(item.id)"
            (change)="addOrRemoveFilter($event, item.id)"
            >{{ item.name }}</mat-checkbox
          >
        </div>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons"> expand_less </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!loading" class="table-wrapper">
      <div>
        <div class="row">
          <div class="col-6">
            <mat-form-field *ngIf="addendums.length > 0">
              <mat-select
                [value]="pageSize"
                name="nOfRows"
                #items
                (selectionChange)="selectedItemsPerPageChange($event)"
              >
                <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="col-6 text-right"
            style="display: flex; flex-direction: row-reverse"
          >
            <mat-form-field
              *ngIf="
                userRole == userRoleEnum.GlobalAdmin ||
                userRole == userRoleEnum.AOWAdmin
              "
            >
              <mat-label>Country</mat-label>
              <mat-select
                [value]="countryId"
                name="dropCategory"
                #country
                (selectionChange)="selectCountry($event)"
              >
                <mat-option
                  *ngFor="let item of countries; let j = index"
                  [value]="item.id"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="addendums.length > 0">
          <table class="table nrbes-grid">
            <thead class="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col-4" width="30%">Description</th>
                <th scope="col-2">Notes</th>
                <th scope="col" width="110">Country</th>
                <th scope="col" width="120">Approved / Rejected on</th>
                <th scope="col" width="100">Expired on</th>
                <th scope="col" width="105">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of addendums"
                (click)="
                  rowSelected(item); addendumService.isCommingFromNrbes = false
                "
              >
                <td class="title-cell">{{ item.standard.title }}</td>
                <td class="description">
                  <span title="{{ item.shortDescription }}" class="">
                    {{ item.description }}
                  </span>
                </td>
                <td class="note-cell">
                  {{ item.note }}
                </td>
                <td class="note-cell">
                  {{ item.country.name }}
                </td>
                <td class="approval-cell">
                  <span class="approval-label">Approved / Rejected On</span>
                  <span class="approval-value"
                    >{{ item.approvedOrRejectedDate | date : "dd MMMM yyyy" }}
                  </span>
                </td>
                <td class="approval-cell">
                  <span class="approval-label">Expiring On</span>
                  <span class="approval-value"
                    >{{ item.expiryDate | date : "dd MMMM yyyy" }}
                  </span>
                </td>
                <td class="status-cell">
                  <span [ngClass]="item.addendumStatusCss">{{
                    item.userRoleWiseAddendumStatusName
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col">
              <ngb-pagination
                class="float-right"
                [(page)]="page"
                [pageSize]="pageSize"
                [maxSize]="4"
                [rotate]="true"
                [ellipses]="true"
                [collectionSize]="collectionSize"
                (pageChange)="getAddendums()"
              ></ngb-pagination>
            </div>
          </div>
        </div>
        <div *ngIf="addendums.length == 0">
          Sorry you do not have any Exception.
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
  <mat-card-actions> </mat-card-actions>
</mat-card>
