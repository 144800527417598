import { Constant } from "../core/constant";

export class ApplicationStatementModel {
  id: number;
  statementDescription: string;
  userId: number;
  modifiedUserId: number;

  constructor(obj?: ApplicationStatementModel) {
    if (obj) {
      this.id = obj.id;
      this.statementDescription = obj.statementDescription;
      
    }
  }
}
