<!-- tab filter -->
<div class="row">
  <section class="standards-tabs">
    <!-- <mat-checkbox
      name="global"
      [color]="tabColor"
      class="tabCheckbox"
      (change)="tabClick($event)"
      [checked]="setGlobalCheck()"
      [(ngModel)]="globaltabcheck"
      >Global Recommendations</mat-checkbox
    > -->
    <mat-checkbox
      class="tabCheckbox"
      [color]="tabColor"
      (change)="tabClick($event)"
      name="building"
      [checked]="setBuildingCheck()"
      [(ngModel)]="buildingtabcheck"
      >Building Standards</mat-checkbox
    >

    <mat-checkbox
      name="operations"
      class="tabCheckbox"
      [color]="tabColor"
      (change)="tabClick($event)"
      [checked]="setOperationsCheck()"
      [(ngModel)]="operationstabcheck"
      >Operations Standards</mat-checkbox
    >

    <mat-checkbox
      name="equipment"
      [color]="tabColor"
      class="tabCheckbox"
      (change)="tabClick($event)"
      [checked]="setEquipmentCheck()"
      [(ngModel)]="equipmenttabcheck"
      >Equipment Standards*</mat-checkbox
    >

    <mat-checkbox
      name="global"
      [color]="tabColor"
      class="tabCheckbox"
      (change)="tabClick($event)"
      [checked]="setGlobalCheck()"
      [(ngModel)]="globaltabcheck"
      >Global Approved Solutions</mat-checkbox
    >
  </section>
</div>

<div
  style="
    margin-bottom: 8px;
    background: rgba(128, 128, 128, 0.367);
    width: auto;
    font-weight: 400;
  "
>
  *Equipment Standards is an independent view.
</div>
<!-- attribute filter -->
<div class="row">
  <div
    *ngIf="
      !standardService.isGrs &&
      (displayAllTabs() || !displayEquipmentTypeFilter())
    "
    class="{{ filtersWidth }}"
  >
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Category</div>
      <mat-checkbox
        *ngFor="let item of categories"
        [checked]="setCheckedStateForCheckBoxes(2, item.id)"
        (change)="addFilter($event, item.id, 2)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div>
  <!-- <div
    *ngIf="
      !standardService.isGrs &&
      (displayAllTabs() || displayEquipmentTypeFilter())
    "
    class="{{ filtersWidth }}"
  >
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Equipment Types</div>
      <mat-checkbox
        *ngFor="let item of equipmentTypes"
        [checked]="setCheckedStateForCheckBoxes(4, item.id)"
        (change)="addFilter($event, item.id, 4)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div> -->

  <div
    *ngIf="!standardService.isGrs"
    class="{{ filtersWidth }} last-filter-wrapper"
  >
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Location</div>
      <mat-checkbox
        *ngFor="let item of locationTypes"
        [checked]="setCheckedStateForCheckBoxes(1, item.id)"
        (change)="addFilter($event, item.id, 1)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div>
  <div
    *ngIf="
      !standardService.isGrs &&
      (displayAllTabs() || displayEquipmentTypeFilter())
    "
    class="{{ filtersWidth }} last-filter-wrapper"
  >
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Equipment Type</div>
      <mat-checkbox
        *ngFor="let item of equipmentTypes"
        [checked]="setCheckedStateForCheckBoxes(4, item.id)"
        (change)="addFilter($event, item.id, 4)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div>
</div>
