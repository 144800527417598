import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import {
  OAuthService,
  JwksValidationHandler,
  OAuthErrorEvent,
} from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { AuthConfiguration } from "./core/auth.configuration";
import { environment } from "../environments/environment";
import { slideInAnimation } from "./route-animation";
import { AuthProdConfiguration } from "./core/auth.prod.configuration";
import Quill from "quill";
import { NotificationService } from "./services/notification.service";
import { A } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit {
  get token() {
    return this.oauthService.getAccessToken();
  }
  get claims() {
    return this.oauthService.getIdentityClaims();
  }

  title = "McD.GRBES.Web";
  // public token: string;

  constructor(
    private oauthService: OAuthService,
    private _router: Router,
    public notificationService: NotificationService,
    private location: Location
  ) {
    //   // alert(oauthService.hasValidAccessToken());
    //   // if (!oauthService.hasValidAccessToken()) oauthService.logOut();
    //   // if(_router.url=="")
    //   oauthService.events.subscribe((e) =>
    //     e instanceof OAuthErrorEvent ? console.error(e) : console.warn(e)
    //   );
    //   oauthService.configure(AuthProdConfiguration);
    //   // Load information from Auth0 (could also be configured manually)
    //   // this.oauthService.logOut();
    //   // //debugger;
    //   oauthService
    //     .loadDiscoveryDocument()
    //     // See if the hash fragment contains tokens (when user got redirected back)
    //     .then(() => oauthService.tryLogin())
    //     // If we're still not logged in yet, try with a silent refresh:
    //     .then(() => {
    //       // alert(success);
    //       // alert(oauthService.hasValidAccessToken());
    //       if (!oauthService.hasValidAccessToken())
    //         oauthService.initImplicitFlow();
    //       // alert(oauthS)
    //       console.log("ACCESS TOKEN", this.oauthService.getAccessToken());
    //     })
    //     // Get username, if possible.
    //     .then(() => {});
  }

  ngOnInit(): void {
    console.error = (a) => {};
    console.log(this.location.path());
    if (environment.skipAuthentication) {
      this._router.initialNavigation();
      this._router.navigateByUrl(this.location.path());
      return;
    }
    if (environment.useProdConfiguration) {
      this.oauthService.configure(AuthProdConfiguration);
    } else {
      // debugger;
      this.oauthService.configure(AuthConfiguration);
    }
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // this.oauthService.initImplicitFlow();
    this.oauthService.tryLogin().then(() => {
      if (!this.oauthService.hasValidAccessToken()) {
        this.oauthService.initImplicitFlow();
        return;
      }
      this._router.initialNavigation();
      this._router.navigateByUrl(this.location.path());
    });

    // this.oauthService.events.subscribe((e) =>
    //   e instanceof OAuthErrorEvent ? console.error(e) : console.warn(e)
    // );

    // this.oauthService.configure(AuthProdConfiguration);

    // // Load information from Auth0 (could also be configured manually)
    // // this.oauthService.logOut();
    // // //debugger;
    // this.oauthService
    //   .loadDiscoveryDocument()

    //   // See if the hash fragment contains tokens (when user got redirected back)
    //   .then(() => this.oauthService.tryLogin())
    //   // If we're still not logged in yet, try with a silent refresh:
    //   .then(() => {
    //     if (!this.oauthService.hasValidAccessToken()) {
    //       // return this.oauthService.silentRefresh();
    //       alert(this.oauthService.hasValidAccessToken());
    //       this.oauthService.initImplicitFlow();
    //     }
    //   })

    //   // Get username, if possible.
    //   .then(() => {});

    // // this.oauthService.setupAutomaticSilentRefresh();
  }

  // ngOnInit(): void {
  //   this._router.initialNavigation();
  //   this._router.navigateByUrl("/locations");
  // }
}
