import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  Input,
} from "@angular/core";
import { FilteringGuide } from "../models/filteringGuide";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { StandardService } from "../services/standard.service";
import { StandardTypeEnum } from "../core/constant";
import { ActivatedRoute } from "@angular/router";
import { EquipmentType } from "../models/equipment-type";

@Component({
  selector: "app-equipment-list-filter",
  templateUrl: "./equipment-list-filter.component.html",
  styleUrls: ["./equipment-list-filter.component.scss"],
})
export class EquipmentListFilterComponent implements OnInit {
  equipmentTypeList: EquipmentType[] = [];
  filterList: FilteringGuide[] = [];
  filteringForCheckBox: FilteringGuide[] = [];
  @Input() public locationId: number;
  @Input() public categoryId: number;

  @Output() messageEvent = new EventEmitter<Array<FilteringGuide>>();
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  constructor(
    private standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getAllStandardTypes();
  }

  getAllStandardTypes(): void {
    this.locationId = parseInt(
      this.activeRoute.snapshot.paramMap.get("locationId")
    );
    debugger;
    this.standardService
      .getAllEquipmentTypesByLocationId(this.locationId)
      .subscribe((data) => {
        this.equipmentTypeList = data.map((o) => new EquipmentType(o));
      });
  }

  addFilter($event, id, filterParam) {
    this.storage.set("allLocationGrsFilters" + filterParam, false);
    if (this.storage.get("locationGrsfilterSet") != null) {
      this.filterList = JSON.parse(this.storage.get("locationGrsfilterSet"));
    }
    if ($event.checked) {
      let filterGuide = this.filterList.find((i) => i.type === filterParam);
      if (filterGuide != undefined) {
        if (!filterGuide.ids.includes(id)) {
          filterGuide.ids.push(id);
        }
      } else {
        let guide = new FilteringGuide();
        guide.type = filterParam;
        guide.ids.push(id);
        this.filterList.push(guide);
      }
    } else {
      let selectedFilter = this.filterList.find((i) => i.type === filterParam);
      if (selectedFilter != undefined && selectedFilter.ids.includes(id)) {
        let index = selectedFilter.ids.indexOf(id);
        selectedFilter.ids.splice(index, 1);
      }
      if (selectedFilter.ids.length == 0) {
        let i = this.filterList.findIndex((x) => x.type === filterParam);
        this.filterList.splice(i, 1);
      }
    }
    this.storage.set("locationGrsfilterSet", JSON.stringify(this.filterList));
    this.standardService.locationGrsFiltration.filterSet = this.filterList;
    this.messageEvent.emit(this.filterList);
  }

  setCheckedStateForCheckBoxes(type, id) {
    if (this.storage.get("allLocationGrsFilters" + type) == true) {
      let guide = new FilteringGuide();
      guide.type = type;
      let selectedFilter = this.filterList.find((i) => i.type === type);
      if (selectedFilter != undefined && !selectedFilter.ids.includes(id)) {
        guide.ids.push(id);
        this.filterList.push(guide);
      }
      return true;
    } else {
      this.filteringForCheckBox = JSON.parse(
        this.storage.get("locationGrsfilterSet")
      );
      if (this.filteringForCheckBox != null) {
        let t = this.filteringForCheckBox.find((i) => i.type === type);
        if (t != undefined && t.ids.includes(id)) {
          return true;
        }
      }
    }
  }
}
