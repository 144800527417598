import { Injectable, Inject } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { environment } from "../../environments/environment";
import * as jwt_decode from "jwt-decode";
import { CryptographyService } from "./cryptography.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private cryptographyService: CryptographyService,
    private oauthService: OAuthService,
    private router: Router
  ) {}

  expirationTimeInMilliseconds: number;
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return true;
    // if (environment.skipAuthentication) {
    //   // this.router.navigateByUrl("/unauthorized");
    //   console.log("returns even after navigating");
    //   return true;
    // }
    // if (!this.oauthService.hasValidAccessToken()) {
    //   this.oauthService.initImplicitFlow();
    // }
    //console.log(state.url + " Activated URL");
    //console.log(route.url + " Snapshot URL");
    //this.router.navigate(['locations'], { queryParams: { returnUrl: state.url } });
    return (
      this.oauthService.hasValidAccessToken() &&
      (this.storage.get("isAuthenticatedUser")
        ? this.cryptographyService.decryptData(
            this.storage.get("isAuthenticatedUser")
          )
        : this.storage.get("isAuthenticatedUser"))
    );
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }
}
