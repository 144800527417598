import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-mcd-location-module',
  templateUrl: './mcd-location-module.component.html',
  styleUrls: ['./mcd-location-module.component.scss']
})
export class McdLocationModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      let  objId;
      $('.image-map area').mouseenter(function(e) {
        objId =  $(this).attr('target');
        $('.main-image').css('opacity', '0');
        $('.main-image').css('z-index', '9999');
        $(objId).find('img').css('z-index', '2');
        $(objId).removeClass('d-none');
        $(objId).addClass('active');
        $('.chunk').removeClass('d-none');
        $('.tooltip-text span').text($(this).attr('alt'));
        $('.tooltip-text').css('left', e.pageX +2);
        $('.tooltip-text').css('top', e.pageY + 2);
        $('.tooltip-text').fadeIn('slow');


     //   $('.main-image').fadeOut('slow');
      }).mouseleave(function(e) {
        $('.main-image').css('z-index', '1');
        $('.chunk').addClass('d-none');
        $('.chunk').removeClass('active');
        $('.main-image').css('opacity', '1');
       // $('.main-image').fadeTo('1');
      // $('.tooltip-text').hide();
    });




    });
  }

}
