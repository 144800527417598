import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private isAuthenticated: boolean;
  constructor(private http: HttpClient) {}

  public SetIsAuthenticated() {
    this.isAuthenticated = true;
  }

  public getUserRoleOperationIdsByLoggedInUserEmail(loggedInUserEmail) {
    let params = new HttpParams();

    params = params.append("loggedInUserEmail", loggedInUserEmail);
    return this.http.get(
      "/api/Authentication/GetUserRoleOperationIdsByLoggedInUserEmail",
      { params: params }
    );
  }

  public getIsAuthenticatedByLoggedInUserEmail(loggedInUserEmail) {
    let params = new HttpParams();
    params = params.append("loggedInUserEmail", loggedInUserEmail);
    return this.http.get("/api/Authentication/IsAuthenticated", {
      params: params,
    });
  }

  public getIsAuthenticated() {
    return this.isAuthenticated;
  }

  public getIsDeletedByLoggedInUserEmail(loggedInUserEmail) {
    let params = new HttpParams();
    params = params.append("loggedInUserEmail", loggedInUserEmail);
    return this.http.get(
      "/api/Authentication/GetIsDeletedByLoggedInUserEmail",
      { params: params }
    );
  }
}
