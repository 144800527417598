import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EquipmentType } from "../models/equipment-type";
import { Equipment } from "../models/equipment";
import { Supplier } from "../models/supplier";
@Injectable({
  providedIn: "root",
})
export class EquipmentService {
  constructor(private http: HttpClient) {}

  getAllEquipmentTypes(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(
      "/api/equipmentStandard/GetAllEquipmentTypes"
    );
  }

  getAllSuppliers(): Observable<Supplier[]> {
    return this.http.get<Supplier[]>("/api/equipmentStandard/GetAllSuppliers");
  }

  addEquipment(equipment: Equipment) {
    return this.http.post("/api/equipmentStandard/AddEquipment", equipment);
  }
}
