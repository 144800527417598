import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { FilteringGuide } from "../models/filteringGuide";
import { EquipmentFiltration } from "../models/equipment-filtration";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  constructor(private http: HttpClient) {}

  getEquipmentsByLocationTypeId(
    locationTypeId: number,
    filterList: Array<FilteringGuide>
  ): Observable<any> {
    let equipmentFiltration = new EquipmentFiltration();
    equipmentFiltration.locationTypeId = locationTypeId;
    equipmentFiltration.filterSet = filterList;
    return this.http.post<any>(
      "/api/location/GetEquipmentsByLocationTypeId",
      equipmentFiltration
    );
  }
}
