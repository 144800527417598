import { Component, OnInit } from "@angular/core";
import { LocationTypeEnum } from "../core/constant";
import { StandardService } from "../services/standard.service";
import { AdminService } from "../services/admin.service";
import { ApplicationStatementModel } from "../models/application-statement";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

declare var $: any;
@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
  get locationTypeEnum() {
    return LocationTypeEnum;
  }
  isFromLocation: boolean = true;
  applicationStatement: ApplicationStatementModel =
    new ApplicationStatementModel();
  statement: SafeHtml = "";
  statementId: number = 1;
  constructor(
    public standardService: StandardService,
    private adminService: AdminService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.standardService.locationId = 0;
    $(document).ready(() => {
      let objId;
      let position;
      $(".image-map area")
        .mouseenter(function (e) {
          objId = $(this).attr("target");
          if (objId == "#curbside") {
            $(".curbside").css({
              transition: "transform 1s ease",
              transform: "scale(1.1)",
            });
          } else {
            $(".curbside-img").addClass("curbside-img-filter");
          }
          console.log(objId);
          $(".main-image").css("opacity", "0");
          $(".main-image").css("z-index", "9999");
          $(objId).find("img").css("z-index", "2");
          $(objId).removeClass("d-none");
          $(objId).addClass("active");
          $(".chunk").removeClass("d-none");
          $(".tooltip-text span").text($(this).attr("alt"));
          position = $(objId).position();
          $(".tooltip-text").css(
            "left",
            position.left + $(objId).find("img").width()
          );
          $(".tooltip-text").css("top", position.top + $(objId).height() / 2);
          $(".tooltip-text").fadeIn("fast");
        })
        .mouseleave(function (e) {
          $(".curbside-img").removeClass("curbside-img-filter");

          $(".curbside").css({ transform: "scale(1)" });
          $(".main-image").css("z-index", "1");
          $(".chunk").addClass("d-none");
          $(".curbside").removeClass("d-none");
          $(".chunk").removeClass("active");
          $(".curbside").addClass("active");

          $(".main-image").css("opacity", "1");
          $(".tooltip-text").hide();
        });
    });

    this.adminService
      .GetApplicationStatementDetailsById(this.statementId)
      .subscribe((data: ApplicationStatementModel) => {
        this.applicationStatement = new ApplicationStatementModel(data);
        this.statement = this.sanitizer.bypassSecurityTrustHtml(
          this.applicationStatement.statementDescription
        );
      });
  }
}
