import { Attachment } from "./attachment";
import { EquipmentType } from "./equipment-type";
import { Supplier } from "./supplier";
import { LocationType } from "./location-type";
import { EquipmentClassType } from "./equipment-class-type";
import { SubLocationType } from "./sub-location-type";

export class EquipmentStandard {
  id: number;
  standardId: number;
  equipmentName: string;
  equipmentTypeId: number;
  model: string;
  title: string;
  description: string;
  effectiveFrom: string;
  newrestaurantseffectiveFrom: string;
  obsoleteDate: string;
  note: string;
  equipmentClassTypeId: number;
  locationTypeId: number;
  subLocationTypeId: number;
  categoryId: number;
  countryId: number;
  createdUserId: number;

  imageFileName: string;
  imageMime: string;
  imageAttachmentContent: any;
  imageAttachmentContentAsBase64: string;

  attachmentFileName: string;
  attachmentMime: string;
  attachmentContent: any;
  attachmentContentAsBase64: string;

  supplierFileName: string;
  supplierMime: string;
  supplierContent: any;
  supplierContentAsBase64: string;

  imageAttachment: Attachment = new Attachment();
  equipmentAttachment: Attachment = new Attachment();
  supplierAttachment: Attachment = new Attachment();
  supplierId: number;
  supplier: Supplier;
  equipmentType: EquipmentType;
  locationType: LocationType;
  subLocationType: SubLocationType;
  equipmentClassType: EquipmentClassType;

  isDeleted: boolean;

  constructor(obj?: EquipmentStandard) {
    if (obj) {
      this.id = obj.id;
      this.standardId = obj.standardId;
      this.equipmentName = obj.equipmentName;
      this.equipmentTypeId = obj.equipmentTypeId;
      this.model = obj.model;
      this.title = obj.title;
      this.description = obj.description;
      this.effectiveFrom = obj.effectiveFrom;
      this.obsoleteDate = obj.obsoleteDate;
      this.note = obj.note;
      this.equipmentClassTypeId = obj.equipmentClassTypeId;
      this.locationTypeId = obj.locationTypeId;
      this.subLocationTypeId = obj.subLocationTypeId;
      this.categoryId = obj.categoryId;
      this.countryId = obj.countryId;
      this.createdUserId = obj.createdUserId;

      this.imageFileName = obj.imageFileName;
      this.imageMime = obj.imageMime;
      this.imageAttachmentContent = obj.imageAttachmentContent;
      this.imageAttachmentContentAsBase64 = obj.imageAttachmentContentAsBase64;

      this.attachmentFileName = obj.attachmentFileName;
      this.attachmentMime = obj.attachmentMime;
      this.attachmentContent = obj.attachmentContent;
      this.attachmentContentAsBase64 = obj.attachmentContentAsBase64;

      this.supplierFileName = obj.attachmentFileName;
      this.supplierMime = obj.attachmentMime;
      this.supplierContent = obj.attachmentContent;
      this.supplierContentAsBase64 = obj.attachmentContentAsBase64;

      this.imageAttachment = new Attachment(obj.imageAttachment);
      this.equipmentAttachment = new Attachment(obj.equipmentAttachment);
      this.supplierAttachment = new Attachment(obj.supplierAttachment);

      this.supplierId = obj.supplierId;
      this.supplier = new Supplier(obj.supplier);
      this.equipmentType = new EquipmentType(obj.equipmentType);
      this.locationType = new LocationType(obj.locationType);
      this.equipmentClassType = new EquipmentClassType(obj.equipmentClassType);
      this.subLocationType = new SubLocationType(obj.subLocationType);
    }
  }
}

