import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
  @Input() public message: string = '';
  @Input() public title: string = '';
  @Output() nrbesSuccessPopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.title == '') {
      this.title = 'Success';
    }
  }

  closeDialog() {
    this.nrbesSuccessPopupEmit.emit(null);
  }

}
