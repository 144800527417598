import { EquipmentType } from "./equipment-type";

export class EquipmentTypeList {
    equipmentTypes: EquipmentType[] = [];
    count: number;
    constructor(obj?: EquipmentTypeList) {
      if (obj) {
        this.equipmentTypes = obj.equipmentTypes? obj.equipmentTypes.map(o => new EquipmentType(o)): null;
        this.count = obj.count;
      }
    }
}
