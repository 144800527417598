import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { StandardService } from "../services/standard.service";
import { Standard } from "../models/standard";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Constant,
  StandardTypeEnum,
  StandardListType,
  UserRoleEnum,
  FilteringEnum,
  AllStandardsTabs,
} from "../core/constant";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { FilteringGuide } from "../models/filteringGuide";
import { CryptographyService } from "../services/cryptography.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { StandardList } from "../models/standard-list";
import { SelectedStandard } from "../models/selected-standard";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { parse } from "path";
import { Sort } from "@angular/material/sort";
import { threadId } from "worker_threads";
declare var $: any;

@Component({
  selector: "app-view-all-minimum-standard-list",
  templateUrl: "./view-all-standards.component.html",
  styleUrls: ["./view-all-standards.scss"],
})
export class AllStandardsComponent implements OnInit {
  loading: boolean = false;
  selected = "option2";
  grses: Standard[] = [];
  error: string = "";
  pageSizes: any[] = Constant.PageSizes;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;
  userRole: number;

  filterList: FilteringGuide[] = [];

  isPageEnabled: boolean = true;
  isFromLocation: boolean = false;
  searchKey: string = "";
  isDownloading: any = false;
  get userRoleEnum() {
    return UserRoleEnum;
  }
  constructor(
    private standardService: StandardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngbModal: NgbModal,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {
    //debugger;
    standardService.locationId =
      standardService.locationId == 0
        ? parseInt(this.activatedRoute.snapshot.paramMap.get("locationId"))
        : standardService.locationId;
  }

  ngOnInit() {
    // debugger;
    this.filterList = this.standardService.allStandardsFiltration.filterSet;
    console.log(this.filterList);
    if (!Number.isNaN(this.standardService.locationId)) {
      //debugger;
      this.setDefaultFiltration();
    }
    this.searchKey = this.standardService.allStandardsFiltration.searchKey;
    this.pageSize = this.standardService.allStandardsFiltration.pageSize;
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getStandards();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.standardService.allStandardsFiltration.pageSize = this.pageSize;
    this.getStandards();
  }
  index = 0;
  getAppliedFilters() {
    let idx = this.standardService.allStandardsFiltration.filterSet.findIndex(
      (i) => i.type == FilteringEnum.FilterByStandardType
    );
    if (idx == -1) {
      this.filterList.push(
        new FilteringGuide({
          type: FilteringEnum.FilterByStandardType,
          ids: [
            AllStandardsTabs.OperationsStandard,
            AllStandardsTabs.BuildingStandards,
          ],
        })
      );
      this.standardService.allStandardsFiltration.filterSet = this.filterList;
      // this.standardService.minimumStandardFiltration.filterSet =
      //   this.filterList;
      // this.standardService.sharedLearingsFiltration.filterSet = this.filterList;
      // this.standardService.equipmentStandardFiltration.filterSet =
      //   this.filterList;
      // this.standardService.operationsStandardFiltration.filterSet =
      //   this.filterList;
      return [
        AllStandardsTabs.OperationsStandard,
        AllStandardsTabs.BuildingStandards,
      ];
    }
    return this.standardService.allStandardsFiltration.filterSet[idx].ids;
  }

  getStandards(): void {
    this.loading = true;
    this.standardService
      .getAllStandards(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        StandardListType.AllStandards,
        this.filterList,
        null,
        this.searchKey,
        this.getAppliedFilters()
      )
      .subscribe((data: StandardList) => {
        console.log("GRSES", data);
        if (data.standardDetailList != null)
          this.grses = data.standardDetailList.map((o) => new Standard(o));

          console.log("grsers", this.grses)
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }

  setDefaultFiltration() {
    this.standardService.allStandardsFiltration.filterSet = null;
    let locationFilteringGuide = new FilteringGuide();
    let filteringGuides = Array<FilteringGuide>();
    locationFilteringGuide.type = FilteringEnum.FilterByLocationType;
    locationFilteringGuide.ids.push(this.standardService.locationId);
    filteringGuides.push(locationFilteringGuide);
    this.filterList = filteringGuides;
    this.standardService.allStandardsFiltration.filterSet = filteringGuides;
  }
  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // sort function for equipment table
  sortEquipment(sort: Sort) {
    if (!sort.active || sort.direction === "" || !(this.grses.length > 0)) {
      return;
    }

    const isAsc = sort.direction === "asc";
    this.grses.sort((a, b) => {
      switch (sort.active) {
        case "equipmentType":
          return this.compare(
            a.equipmentStandard.equipmentType.name,
            b.equipmentStandard.equipmentType.name,
            isAsc
          );
        case "supplier":
          return this.compare(
            a.equipmentStandard.supplier.name,
            b.equipmentStandard.supplier.name,
            isAsc
          );
        case "title":
          return this.compare(a.title, b.title, isAsc);
        case "model":
          return this.compare(
            a.equipmentStandard.model,
            b.equipmentStandard.model,
            isAsc
          );
        case "equipmentClass":
          return this.compare(
            a.equipmentStandard.equipmentClassType.name,
            b.equipmentStandard.equipmentClassType.name,
            isAsc
          );
      }
    });
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === "" || !(this.grses.length > 0)) {
      return;
    }
    console.log("GRSES-dort", this.grses);

    const isAsc = sort.direction === "asc";
    this.grses.sort((a, b) => {
      switch (sort.active) {
        case "title":
          return this.compare(a.title, b.title, isAsc);
        case "category":
          return this.compare(a.category.name, b.category.name, isAsc);
        case "desc":
          return this.compare(a.shortDescription, b.shortDescription, isAsc);
      }
    });
  }
  rowSelected(item: Standard): void {
    this.standardService.fromLocationGRS = false;

    console.log("ITEM", item);
    if (
      item.standardTypeId == StandardTypeEnum.GlobalMinimumRestaurantStandard
    ) {
      this.router.navigate(["/minimum-standard-detail-view/" + item.id]);
    } else if (item.standardTypeId == StandardTypeEnum.OperationsStandards) {
      this.router.navigate(["/operations-standards-detail-view/" + item.id]);
    } else if (
      item.standardTypeId == StandardTypeEnum.GlobalRecommendations ||
      item.standardTypeId == StandardTypeEnum.MarketBestPractices
    ) {
      this.router.navigate(["/global-recommendations-detail-view/" + item.id]);
    } else if (item.standardTypeId == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + item.id]);
    }
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this standard from the list? ";
    if (
      item.standardTypeId == StandardTypeEnum.GlobalMinimumRestaurantStandard
    ) {
      modalRef.componentInstance.message =
        "All the dependencies will be removed along with this standard";
    }
    modalRef.componentInstance.deleteItem = item.id;
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItemId) => {
      if (deleteItemId != null) {
        let grs = new SelectedStandard();
        grs.standard.id = deleteItemId;
        grs.standard.isDeleted = true;
        this.loading = true;
        this.standardService
          .removeGrbes(grs.standard)
          .subscribe((data: any) => {
            if (!data.status) {
              const modalRefd = this.ngbModal.open(FailModuleComponent);
              modalRefd.componentInstance.title =
                "You can't delete this standard!";
              modalRefd.componentInstance.errorMessage = data.message;
            }
            this.loading = false;
            this.page = 1;
            this.getStandards();
          });
      }
      this.ngbModal.dismissAll();
    });
  }

  actionMenuOpen(event) {
    event.stopPropagation();
  }

  receivedFilteration($event) {
    this.isPageEnabled = false;
    this.filterList = $event;
    this.standardService.allStandardsFiltration.filterSet = this.filterList;
    // this.standardService.grsFiltration.filterSet = this.filterList;
    // this.standardService.minimumStandardFiltration.filterSet = this.filterList;
    // this.standardService.sharedLearingsFiltration.filterSet = this.filterList;
    // this.standardService.equipmentStandardFiltration.filterSet =
    //   this.filterList;
    // this.standardService.operationsStandardFiltration.filterSet =
    //   this.filterList;
    // this.standardService.nationalEquipmentStandardFiltration.filterSet =
    // this.filterList;
    // this.standardService.obsoleteStandardFiltration.filterSet = this.filterList;
    // this.standardService.locationGrsFiltration.filterSet = this.filterList;
    // this.standardService.upcomingStandardFiltration.filterSet = this.filterList;
    // this.standardService.nrbesFiltrations.filterSet = this.filterList;
    // this.standardService.equipmentAddendumFiltration.filterSet =
    //   this.filterList;

    // this.standardService.addendumFiltration.filterSet = this.filterList;

    this.pageSize = 10;
    this.page = 1;
    this.getStandards();
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.standardService.allStandardsFiltration.searchKey = this.searchKey;
    this.getStandards();
  }

  onEdit(id) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    this.router.navigate(["/add-grs", id]);
  }

  onEditEquipment(id) {
    this.standardService.standardEditPreviousRoute =
      this.activatedRoute.snapshot.routeConfig.path;
    this.router.navigate(["/edit-eq-standards-global", id]);
  }

  downloadExcel() {
    this.isDownloading = true;
    this.standardService
      .downloadAllExcel(
        this.getAppliedFilters(),
        this.collectionSize,
        this.standardService.allStandardsFiltration.filterSet,
        this.searchKey
      )
      .subscribe(
        (response: any) => {
          this.isDownloading = false;
          let filename = "Minimum_Standard_List " + new Date().toLocaleString();
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, filename);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);

          //window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
  }

  getLocationNames(item): any {
    if (item.standardLocation && item.standardLocation.length > 0) {
      return item.standardLocation
        .map((loc) => loc.locationType.name)
        .join(", ");
    } else {
      return item.locationType.name;
    }
  }
}
