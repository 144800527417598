import { Component, OnInit, Inject } from "@angular/core";
import { Supplier } from "../models/supplier";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "../services/admin.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SimpleModalService } from "ngx-simple-modal-eq";
import { ModalImageCropperComponent } from "../modal-image-cropper/modal-image-cropper.component";
import { Attachment } from "../models/attachment";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Country } from "../models/country";
import { StandardService } from "../services/standard.service";
import { UserRoleEnum } from "../core/constant";

@Component({
  selector: "app-add-national-supplier",
  templateUrl: "./add-national-supplier.component.html",
  styleUrls: ["./add-national-supplier.component.scss"],
})
export class AddNationalSupplierComponent implements OnInit {
  error: string = "";
  modalOption: NgbModalOptions = {};

  loading: boolean = false;
  supplier: Supplier = new Supplier();
  invalidImage: boolean = false;
  imageChangedEvent: any = "";
  countryId: number = 0;
  countries: Country[] = [];
  userRole: number;

  addEditSupplierForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private SimpleModalService: SimpleModalService,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private standardService: StandardService
  ) {
    this.supplier.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("supplierId")
    );
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    if (this.supplier.id) {
      this.getSupplierBySupplierId(this.supplier.id);
    } else {
      this.getMasterData();
    }
    this.createForm();
  }

  createForm(): void {
    if (this.userRole == UserRoleEnum.MarketAdmin) {
      this.addEditSupplierForm = this.fb.group({
        name: ["", Validators.required],
        description: [""],
      });
    } else {
      this.addEditSupplierForm = this.fb.group({
        name: ["", Validators.required],
        countryId: ["", Validators.required],
        description: [""],
      });
    }
  }

  getSupplierBySupplierId(supplierId: number) {
    this.loading = true;
    this.adminService
      .GetSupplierDetailsById(supplierId)
      .subscribe((data: Supplier) => {
        this.supplier = new Supplier(data);
        this.setControlsValue(this.supplier);
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  setControlsValue(supplier: Supplier) {
    if (supplier.id) {
      this.addEditSupplierForm.controls.name.setValue(supplier.name);
      this.addEditSupplierForm.controls.description.setValue(
        supplier.description
      );
    }
  }

  onReset() {
    this.invalidImage = false;
    this.supplier.imageAttachmentContentAsBase64 = null;
    this.supplier.supplierLogoAttachment = new Attachment();
    this.createForm();
  }

  onSaveSupplier() {
    this.loading = true;
    this.supplier.name = this.addEditSupplierForm.controls.name.value;
    this.supplier.description =
      this.addEditSupplierForm.controls.description.value;
    this.supplier.countryId =
      this.userRole == UserRoleEnum.MarketAdmin
        ? this.cryptographyService.decryptData(this.storage.get("countryId"))
        : this.addEditSupplierForm.controls.countryId.value;
    if (this.supplier.id) {
      this.updateSupplier(this.supplier).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the Supplier.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-national-supplier-list"]);
          }
        });
      });
    } else {
      this.addSupplier(this.supplier).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully added the supplier.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-national-supplier-list"]);
          }
        });
      });
    }
  }

  addSupplier(supplier: Supplier): Observable<any> {
    this.loading = true;
    supplier.id = 0;
    return new Observable<any>((observer) => {
      this.adminService.AddSupplier(supplier).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  updateSupplier(supplier: Supplier): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.adminService.UpdateSupplier(supplier).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  onSelectImageAttachment(event) {
    let selectedFile = event.target.files[0];
    this.imageChangedEvent = event.target.files[0];
    this.invalidImage = this.validateFileType(selectedFile.name);
    if (!this.invalidImage) {
      this.showPrompt().then((result) => {
        if (result == null) {
          this.supplier.imageAttachmentContentAsBase64 = null;
          event.target.value = null;
          this.supplier.supplierLogoAttachment.fileName = null;
        } else {
          this.supplier.imageAttachmentContentAsBase64 = result;
          event.target.value = null;
          this.supplier.supplierLogoAttachment.fileName = selectedFile.name;
        }
      });
    }
  }

  onRemoveSupplierAttachment() {
    this.supplier.supplierLogoAttachment.id = 0;
    this.supplier.imageAttachmentContentAsBase64 = null;
    this.supplier.supplierLogoAttachment.fileName = null;
    this.supplier.supplierLogoAttachment.attachmentContentAsBase64 = null;
    this.invalidImage = false;
  }

  onDownloadSelectedSupplierAttachment(id) {
    if (id) {
      return this.adminService.GetAttachmentUrl(id, false).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.supplier.imageAttachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.supplier.supplierLogoAttachment.fileName
            );
          } else {
            var a = document.createElement("a");
            a.href = this.supplier.imageAttachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.supplier.supplierLogoAttachment.fileName
            );
            a.click();
          }
        });
    }
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["jpg", "jpeg", "png", "bmp"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  showPrompt(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.SimpleModalService.addModal(ModalImageCropperComponent, {
        title: "Crop your image",
        ratio: 2,
        event: this.imageChangedEvent,
      }).subscribe((image) => {
        resolve(image);
      });
      // resolve("true");
    });
  }

  onCancelClick() {
    this.router.navigate(["/view-all-national-supplier-list"]);
  }

  getMasterData() {
    this.loading = true;
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.countries = data.countries;
        this.loading = false;
      });
  }
}
