<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Edit National Standard</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <form [formGroup]="editNrbesForm">
      <div class="mcd-edit-form mcd-grbes-form">
        <div class="row">
          <div class="col-5">

            <mat-form-field>
              <input matInput placeholder="Title" formControlName="title" required>
            </mat-form-field>

          </div>

          <div class="col-5 offset-md-2">
            <mat-form-field>
              <mat-label>Location</mat-label>
              <mat-select name="dropCategory" [disabled]="selectedStandard.id > 0" formArrayName="locationType" [value]="editNrbesForm['controls'].locationTypeId.value" #locationType (selectionChange)="setSelectedLocationType(locationType)" required>
                <mat-option *ngFor="let item of editNrbesForm['controls'].locationType['controls']; let j=index" [value]="item.controls.id.value">{{item.controls.name.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-5 ">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select name="dropCategory" [disabled]="selectedStandard.id > 0" formArrayName="category" [value]="editNrbesForm['controls'].categoryId.value" #category (selectionChange)="setSelectedCategory(category)" required>
                <mat-option *ngFor="let item of editNrbesForm['controls'].category['controls']; let j=index" [value]="item.controls.id.value">{{item.controls.name.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <mat-label>Existing Restaurant Description</mat-label>
              <input matInput placeholder="Existing Restaurant Description" name="description" formControlName="standardDescription" required>
              <mat-error *ngIf="editNrbesForm.get('standardDescription').touched && editNrbesForm.get('standardDescription').hasError('required')">
                Description is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-5">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" placeholder="Existing Restaurant Effective Date" formControlName="effectiveFrom">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <!--<mat-error *ngIf="addGrsForm.get('effectiveFrom').touched && addGrsForm.get('effectiveFrom').hasError('required')">
        Effective date is required
      </mat-error>-->
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field>
              <mat-label>New Restaurant Description</mat-label>
              <input matInput placeholder="Description for New Restaurants" name="description" formControlName="newrestaurantdescription" required>
              <mat-error *ngIf="editNrbesForm.get('newrestaurantdescription').touched && editNrbesForm.get('newrestaurantdescription').hasError('required')">
                Description is required
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-5">
            <mat-form-field>
              <input matInput [matDatepicker]="picker2" placeholder="New Restaurant Effective Date" formControlName="newRestaurantEffectiveFrom">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <!--<mat-error *ngIf="addGrsForm.get('newRestaurantEffectiveFrom').touched && addGrsForm.get('newRestaurantEffectiveFrom').hasError('required')">
        Effective date is required
      </mat-error>-->
            </mat-form-field>
          </div>


          <div class="col-12">
            <mat-label>Notes *</mat-label>
            <quill-editor name="note" formControlName="note" class="quill-editor-css"> </quill-editor>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div class="col-12">
            <br />
            <br />
            <br />
            <br />
            <mat-label>Additional Information *</mat-label>
            <quill-editor name="additionalInformation" formControlName="additionalInformation" class="quill-editor-css"> </quill-editor>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="col-12">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
         

        </div>
      </div>
    </form>

  </mat-card-content>
  <mat-card-footer class="mcd-card-footer">
    <button class="mcd-btn mcd-btn-primary" mat-raised-button (click)="redirectBack()">Back</button>
    <button class="mcd-btn mcd-btn-secondary" mat-raised-button (click)="edit()" [disabled]="editNrbesForm.invalid">Update</button>
  </mat-card-footer>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>

