import { SimpleModalComponent } from "ngx-simple-modal-eq";
import { Component } from "@angular/core";
import { ImageCroppedEvent, base64ToFile } from "ngx-image-cropper";
import { NgxImageCompressService } from "ngx-image-compress";
import { Observable } from "rxjs";
import { resolve } from "q";

export interface CropperModel {
  title: string;
  event: Event;
  ratio: number;
}

@Component({
  selector: "app-modal-image-cropper.component",
  templateUrl: "./modal-image-cropper.component.html",
  styleUrls: ["./modal-image-cropper.component.scss"],
})
export class ModalImageCropperComponent
  extends SimpleModalComponent<CropperModel, any>
  implements CropperModel
{
  title: string;
  event: Event;
  imageAsBase64String: string = "";
  // declaring valiables used
  id: any;
  name: "Image";
  imageChangedEvent: any = "";
  croppedImage: any = "";
  url: string = "";
  imagedata: ImageResource[];
  public currentCount = 0;
  showCropper: boolean = false;
  ratio: number = 1;

  constructor(private imageCompress: NgxImageCompressService) {
    super();
  }

  ngOnInit() {
    this.fileChangeEvent(event);
  }

  apply() {
    console.log("entered in apply");
    if (300000 < this.imageCompress.byteCount(this.croppedImage)) {
      this.compress(this.croppedImage);
    } else {
      this.result = this.croppedImage;
      // console.log("compressed", this.result);
      this.close();
    }
  }

  closewindow() {
    this.result = null;
    this.close();
  }

  compress(image): Promise<string> {
    return new Promise<string>((resolve) => {
      this.getImageWidth(image).then((res) => {
        this.imageCompress
          .compressFile(image, null, (462 / res) * 100, 100)
          .then((result) => {
            this.result = result;
            this.close();
          });
      });
    });
  }

  getImageWidth(file): Promise<number> {
    return new Promise<number>((resolved) => {
      var i = new Image();
      i.onload = function () {
        resolved(i.width);
      };
      i.src = file;
    });
  }

  public incrementCounter() {
    this.currentCount++;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  async convertBlobToBase64(blobUrl) {
    try {
      // Step 1: Fetch the Blob data from the URL
      const response = await fetch(blobUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch Blob from ${blobUrl}`);
      }

      const blob = await response.blob();

      // Step 2: Convert the Blob to base64
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // 'result' contains the base64 representation of the Blob
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting Blob to base64:", error.message);
      throw error;
    }
  }
  async imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = await this.blobToBase64(event.objectUrl);
    console.log("ImageCroppedEvent: ", event);
    this.croppedImage = await this.convertBlobToBase64(event.objectUrl);
    console.log("Cropped Image: ", this.croppedImage);
  }
  imageLoaded() {
    this.showCropper = true;
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  Crop(event: any): void {
    this.imageChangedEvent = event;
  }
}

interface ImageResource {
  id: number;
  name: string;
  content: string;
}
