import { Attachment } from "./attachment";

export class UsefulLink {
  id: number;
  name: string;
  url: string;

  modifiedUserId: number;
  createdUserId: number;


  attachmentFileName: string;
  attachmentMime: string;
  attachmentContent: any;
  attachmentContentAsBase64: string;

  attachment:  Attachment = new Attachment();
  constructor(obj?: UsefulLink) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.url = obj.url;

      this.attachmentFileName = obj.attachmentFileName;
      this.attachmentMime = obj.attachmentMime;
      this.attachmentContent = obj.attachmentContent;
      this.attachmentContentAsBase64 = obj.attachmentContentAsBase64;

      this.attachment = new Attachment(obj.attachment);
    }
  }
}
