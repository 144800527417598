import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { environment } from "../../environments/environment";
import { UserRoleUserOperation } from "../core/constant";

@Component({
  selector: "app-global-admin-panel",
  templateUrl: "./global-admin-panel.component.html",
  styleUrls: ["./global-admin-panel.component.scss"],
})
export class GlobalAdminPanelComponent implements OnInit {
  loading: boolean = false;

  viewManageSuppliers: boolean;
  viewAllSuppliers: boolean;
  viewAllEquipmentType: boolean;
  viewAllCategory: boolean;
  viewAllUser: boolean;
  viewAllUsefulLinks: boolean;

  constructor(
    private router: Router,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    if (!environment.skipAuthentication) {
      let userRoleUserOperation = this.storage.get("userRoleUserOperation")
        ? this.cryptographyService.decryptData(
            this.storage.get("userRoleUserOperation")
          )
        : this.storage.get("userRoleUserOperation");
      if (userRoleUserOperation) {
        this.viewManageSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewManageSuppliers
          ) !== -1;
        this.viewAllSuppliers =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllSuppliers
          ) !== -1;
        this.viewAllEquipmentType =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllEquipmentType
          ) !== -1;
        this.viewAllCategory =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllCategory
          ) !== -1;
        this.viewAllUser =
          userRoleUserOperation.indexOf(UserRoleUserOperation.ViewAllUser) !==
          -1;
        this.viewAllUsefulLinks =
          userRoleUserOperation.indexOf(
            UserRoleUserOperation.ViewAllUsefulLinks
          ) !== -1;
      }
    } else {
      this.viewManageSuppliers = true;
      this.viewAllSuppliers = true;
      this.viewAllEquipmentType = true;
      this.viewAllCategory = true;
      this.viewAllUser = true;
      this.viewAllUsefulLinks = true;
    }
  }

  redirectToAssignMarketSupplier() {
    this.router.navigate(["/manage-suppliers"]);
  }

  redirectToManageSuppliers() {
    this.router.navigate(["/view-all-supplier-list"]);
  }

  redirectToManageCategories() {
    this.router.navigate(["/view-all-category-list"]);
  }

  redirectToManageEquipmentTypes() {
    this.router.navigate(["/view-all-equipment-type-list"]);
  }

  redirectToManageUsers() {
    this.router.navigate(["/view-all-user-list"]);
  }

  redirectToManageUserfulLinks() {
    this.router.navigate(["/view-all-useful-links"]);
  }

  redirectToManageApplicationStatement() {
    this.router.navigate(["/update-application-statement"]);
  }
  redirectToManageScript() {
    this.router.navigate(["/run/view-script"]);
  }
}
