import { LocationCategoryMappings } from "./locationCategoryMappings";

export class Category {
  id: number;
  name: string;
  description: string;
  locationCategoryMappings: LocationCategoryMappings[];
  constructor(obj?: Category) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.description = obj.description; 
      this.locationCategoryMappings = obj.locationCategoryMappings;
    }
  }
}
