import { Component, Inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationStatementModel } from "../models/application-statement";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { AdminService } from "../services/admin.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { SimpleModalService } from "ngx-simple-modal-eq";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

@Component({
  selector: "app-update-application-statement",
  templateUrl: "./update-application-statement.component.html",
  styleUrls: ["./update-application-statement.component.scss"],
})
/** update-application-statement component*/
export class UpdateApplicationStatementComponent {
  /** update-application-statement ctor */
  updateStatementForm: FormGroup;
  loading: boolean = false;
  applicationStatement: ApplicationStatementModel =
    new ApplicationStatementModel();

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    private SimpleModalService: SimpleModalService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit(): void {
    this.applicationStatement.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.applicationStatement.id = 1;
    this.adminService
      .GetApplicationStatementDetailsById(this.applicationStatement.id)
      .subscribe((data: ApplicationStatementModel) => {
        this.loading = true;
        this.applicationStatement = new ApplicationStatementModel(data);
        //
        this.setValue();
      });
    this.createForm();
  }

  createForm(): void {
    this.updateStatementForm = this.fb.group({
      statement: ["", Validators.required],
    });
  }
  setValue() {
    this.updateStatementForm.controls.statement.setValue(
      this.applicationStatement.statementDescription
    );
    this.loading = false;
  }

  updateApplicationStatement(
    statement: ApplicationStatementModel
  ): Observable<any> {
    return new Observable<any>((observer) => {
      this.adminService.UpdateApplicationStatement(statement).subscribe(
        (data: any) => {
          observer.next(data);
        },
        (error) => {
          observer.next(false);
        }
      );
    });
  }

  saveApplicationStatement() {
    this.applicationStatement.modifiedUserId =
      this.cryptographyService.decryptData(this.storage.get("userId"));
    this.applicationStatement.statementDescription =
      this.updateStatementForm.controls.statement.value;
    this.updateApplicationStatement(this.applicationStatement).subscribe(
      (data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the application statement.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.reset();
          if (data) {
            this.router.navigate(["/global-admin-panel"]);
          }
        });
      }
    );
  }

  reset() {
    this.updateStatementForm.controls.statement.reset();
    this.createForm();
  }
}
