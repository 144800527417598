import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-addendum',
  templateUrl: './equipment-addendum.component.html',
  styleUrls: ['./equipment-addendum.component.scss']
})
export class EquipmentAddendumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
