import { Injectable, EventEmitter, Inject } from "@angular/core";
import * as signalR from "@aspnet/signalr";
import { NotificationModel } from "../models/notification-model.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { NotificationList } from "../models/notificationList";
import { UserRoleUserOperation } from "../core/constant";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CryptographyService } from "./cryptography.service";
import { AsyncAction } from "rxjs/internal/scheduler/AsyncAction";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  notificationReceived = new EventEmitter<NotificationModel>();
  connectionEstablished = new EventEmitter<Boolean>();

  private connectionIsEstablished = false;
  private _hubConnection: signalR.HubConnection;

  constructor(
    private http: HttpClient,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService
  ) {
    let userRoleUserOperation = this.storage.get("userRoleUserOperation")
      ? this.cryptographyService.decryptData(
          this.storage.get("userRoleUserOperation")
        )
      : this.storage.get("userRoleUserOperation");
    if (
      userRoleUserOperation &&
      userRoleUserOperation.indexOf(
        UserRoleUserOperation.ViewAllNotification
      ) !== -1
    ) {
      try {
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
      } catch (e) {
        console.log("notification error", e);
      }
    }
  }

  /**
   * Create a connection with the server hub.
   * @return void
   */
  private createConnection() {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/notification", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();
  }

  /**
   * Start the connection with the server hub.
   * @return void
   */
  private startConnection(): void {
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log("Hub connection started");
        this.connectionEstablished.emit(true);
      })
      .catch((err) => {
        console.log(
          "Error occurred while connection establishment, retrying...",
          err
        );
        setTimeout(() => {
          this.startConnection();
        }, 5000);
      });
  }

  /**
   * Listen to server events and trigger.
   * @return void
   */
  private registerOnServerEvents(): void {
    this._hubConnection.on("ReceiveNotification", (data: any) => {
      //debugger;
      this.notificationReceived.emit(new NotificationModel(JSON.parse(data)));
    });
  }

  /**
   * Connected client joing to notification group;
   * @param {string} groupId The id of the notification group to invoke.
   * @return void
   */
  public joingToNotificationGroup(groupId: string) {
    this._hubConnection.invoke("JoinGroup", groupId);
  }

  /**
   * Already Reserved User Notifications
   * @param {string} email User email.
   * @return void
   */
  public getAlreadyReservedUserNotifications(notificationGroup: string) {
    return this.http.get<NotificationModel[]>(
      "/api/Notification/GetUserNotifications/" + notificationGroup
    );
  }

  public getAllUserNotifications(
    notificationGroup: string,
    startingIndex: number = 0,
    pageSize = 10
  ) {
    let params = new HttpParams();
    params = params.append("notificationGroup", notificationGroup);
    params = params.append("pageSize", pageSize.toString());
    params = params.append("startingIndex", startingIndex.toString());
    return this.http.get<NotificationList>(
      "/api/Notification/GetAllUserNotifications/",
      { params: params }
    );
  }

  public UpdateNotification(
    notificationModel: NotificationModel
  ): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/Notification/UpdateNotification",
      notificationModel
    );
  }

  public getAllUserNotificationsCount(notificationGroup: string) {
    //debugger;
    let params = new HttpParams();
    params = params.append("notificationGroup", notificationGroup.toString());
    return this.http.get<number>(
      "/api/Notification/GetUserNotificationCount/",
      {
        params: params,
      }
    );
  }
}
