import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fail-module',
  templateUrl: './fail-module.component.html',
  styleUrls: ['./fail-module.component.scss']
})
export class FailModuleComponent implements OnInit {

  closeResult: string;

  @Input() public errorMessage: string = '';
  @Input() public title: string = '';
  @Input() public redirectRoute: string = null;

  constructor(public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit() {
  }


  onRedirectAndClose() {
    this.activeModal.dismiss();
    if (this.redirectRoute) {
      this.router.navigate([this.redirectRoute]);
    }
  }
}
