<div class="row">
  <div class="col-md-4">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Standard Type</div>
      <mat-checkbox
        *ngFor="let item of standardTypes"
        [checked]="setCheckedStateForCheckBoxes(3, item.id)"
        (change)="addFilter($event, item.id, 3)"
        >{{
          item.name == "Local Standards"
            ? "Market Standards"
            : item.name == "Global Recommendations"
            ? "Global Approved Solutions"
            : item.name
        }}</mat-checkbox
      >
    </div>
  </div>
  <div class="col-md-4">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Category</div>
      <mat-checkbox
        *ngFor="let item of categories"
        [checked]="setCheckedStateForCheckBoxes(2, item.id)"
        (change)="addFilter($event, item.id, 2)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div>
  <div class="col-md-4 last-filter-wrapper">
    <div class="mcd-filter-tag-wrapper">
      <div class="mcd-tag-title">Location</div>
      <mat-checkbox
        *ngFor="let item of locationTypes"
        [checked]="setCheckedStateForCheckBoxes(1, item.id)"
        (change)="addFilter($event, item.id, 1)"
        >{{ item.name }}</mat-checkbox
      >
    </div>
  </div>
</div>
