<div class="mcd-loader-overlay">
<div class="loader">
    <div class="topbun"></div>
    <div class="tomato"></div>
    <div class="lettuce"></div>
    <div class="cheese"></div>
    <div class="burger"></div>
    <div class="bottombun"></div>
  </div>
</div>
