import { FilteringGuide } from "./filteringGuide";

export class FilterList {
  userId;
  number;
  countryId: number;
  startingIndex: number;
  pageSize: number = 10;
  standardListType: number;
  IsEquipmentStandard: boolean;
  searchKey: string = "";
  filterSet: FilteringGuide[] = [];
  filter: number[] = [];
  AppliedFilters: any = [];
}
