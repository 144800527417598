<div class="container-fluid">
    <header>
      <div class="row margin-right-0">
        <div class="col-md-2 col-sm-2 padding-right-0 col-left">
          <div class="mcd-logo-wrapper"></div>
        </div>
        <div class="col-md-6 col-sm-6 mcd-center-bg padding-left-0 col-center"></div>
        <div class="col-md-4 col-sm-4 bg-center col-right">
          <app-notifications></app-notifications>
        </div>
      </div>
      <button class="toggle-menu-button material-icons">menu</button>
    </header>
    <div class="row">
      <div class="col-12">
        <h1 class="unauthorized">You're Not Authorized to  Perform this Operation</h1>
      </div>
    </div>
  </div>

