export class UsefulLinkFilter {
  startingIndex: number;
  pageSize: number;
  searchKey: string;
  constructor(obj?: UsefulLinkFilter) {
    if (obj) {
      this.startingIndex = obj.startingIndex;
      this.pageSize = obj.pageSize;
      this.searchKey = obj.searchKey;
    }
  }
}
