<div class="mcd-nav-right">
    <div class="mcd-nav-right-title">
      <h4>View Standards for</h4>
    </div>
    <div class="location-map">
      <img *ngIf="locationId == locationTypeEnum.ParkingLot" src="/assets/images/right-panel/locations/parking-lot.svg">
      <img *ngIf="locationId == locationTypeEnum.DriveThru" src="/assets/images/right-panel/locations/drive-thru.svg">
      <img *ngIf="locationId == locationTypeEnum.ManagerSpace" src="/assets/images/right-panel/locations/manager-space.svg">
      <img *ngIf="locationId == locationTypeEnum.CrewSpace" src="/assets/images/right-panel/locations/crew-space.svg">
      <img *ngIf="locationId == locationTypeEnum.BackRoom" src="/assets/images/right-panel/locations/backroom.svg">
      <img *ngIf="locationId == locationTypeEnum.RestRooms" src="/assets/images/right-panel/locations/rest-rooms.svg">
      <img *ngIf="locationId == locationTypeEnum.Storage" src="/assets/images/right-panel/locations/storage.svg">
      <img *ngIf="locationId == locationTypeEnum.Kitchen" src="/assets/images/right-panel/locations/kitchen.svg">
      <img *ngIf="locationId == locationTypeEnum.Lobby" src="/assets/images/right-panel/locations/lobby.svg">
      <img *ngIf="locationId == locationTypeEnum.FrontCounter" src="/assets/images/right-panel/locations/front-counter.svg">
      <img *ngIf="locationId == locationTypeEnum.PlayPlace" src="/assets/images/right-panel/locations/play-place.svg">
      <img *ngIf="locationId == locationTypeEnum.BuildingExterior" src="/assets/images/right-panel/locations/building-exterior.svg">
      <img *ngIf="locationId == locationTypeEnum.EntireBuilding" src="/assets/images/right-panel/locations/entire-building.png">
      <img *ngIf="locationId == locationTypeEnum.Beverage" src="/assets/images/right-panel/locations/beverage.svg">

    </div>
    <div class="mcd-nav-right-list">
      <ul>
        <li><a [ngClass]="this.locationSelectedCategory == categoryEnum.Equipment? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.Equipment);"><span><i class="material-icons">extension</i></span> <span>Equipment</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.FoodSafety)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.FoodSafety? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.FoodSafety);"><span><i class="material-icons">album</i></span> <span>Food Safety</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.Sustainability)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.Sustainability? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.Sustainability)"><span><i class="material-icons">language</i></span> <span>Sustainability</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.Technology)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.Technology? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.Technology)"><span><i class="material-icons">nfc</i></span> <span>Technology</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.SafetyAndSecurity)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.SafetyAndSecurity? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.SafetyAndSecurity)"><span><i class="material-icons">devices</i></span> <span>Safety & Security</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.OperatingPlatforms)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.OperatingPlatforms? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.OperatingPlatforms)"><span><i class="material-icons">extension</i></span> <span>Operating Platforms</span> </a>    </li>
        <li *ngIf="checkThisCategoryIsThere(categoryEnum.BuildingAndSite)"><a [ngClass]="this.locationSelectedCategory == categoryEnum.BuildingAndSite? 'active':''" href="javascript:void(0);" (click)="getStandardsForThisCategory(categoryEnum.BuildingAndSite)"><span><i class="material-icons">business</i></span> <span>Building & Site</span> </a>    </li>
      </ul>
    </div>
    </div>
