import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { StandardService } from "../services/standard.service";
import { EctService } from "../services/ect.service";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { EctRows } from "../models/ectRows ";
import { EctRow } from "../models/ectRow";
import { Country } from "../models/country";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-ect",
  templateUrl: "./ect.component.html",
  styleUrls: ["./ect.component.scss"],
})
export class EctComponent implements OnInit {
  userRole: number;
  selectedCountryId: number = 0;
  countries: Country[] = [];
  sheetName: string;
  sheetCode: string;
  ButtonList: FormGroup;
  EctGeneralInput: FormGroup;
  Ect: FormGroup;
  tablesArray: FormArray;
  sheetId: number;
  tableList: any[];
  regularButtonList: any[];
  nonRegularButtonList: any[];
  loading: boolean = false;
  isdisableCol: boolean = true;
  isClick: boolean = false;

  @ViewChild("peakTCHour", { static: false }) namefield: ElementRef;

  constructor(
    private ectService: EctService,
    private fb: FormBuilder,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private standardService: StandardService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.GetCountryList();
    this.selectedCountryId = this.cryptographyService.decryptData(
      this.storage.get("countryId")
    );

    this.ButtonList = this.fb.group({
      isRegularMenu: "yes",
      isBreakfast: "yes",
    });

    this.EctGeneralInput = this.fb.group(
      {
        peakTCHour: new FormControl(0),
        peakHourlyTCDaytime: new FormControl(0),
      },
      { validator: this.isGeneralInputsValid }
    );

    this.Ect = this.fb.group({
      tablesArray: [],
    });

    this.ectService.GetSheetMetaDatas(true).subscribe((data: any) => {
      this.regularButtonList = data;
    });
    this.ectService.GetSheetMetaDatas(false).subscribe((data: any) => {
      this.nonRegularButtonList = data;
    });
  }

  isGeneralInputsValid(formGroup: FormGroup) {
    if (!formGroup.pristine) {
      let peakTCHour = formGroup.controls["peakTCHour"].value;
      let peakHourlyTCDaytime = formGroup.controls["peakHourlyTCDaytime"].value;

      if (
        peakTCHour !== undefined &&
        !isNaN(peakTCHour) &&
        Number(peakTCHour) != null &&
        peakTCHour >= 0 &&
        peakHourlyTCDaytime !== undefined &&
        !isNaN(peakHourlyTCDaytime) &&
        Number(peakHourlyTCDaytime) != null &&
        peakHourlyTCDaytime >= 0
      ) {
        return null;
      }
      return { invalidInput: true };
    }
    return null;
  }

  GetCountryList() {
    this.ectService.GetCountryList().subscribe((data: any) => {
      this.countries = data;
    });
  }

  GetSheet(sheetId: number) {
    this.loading = true;
    this.Ect.controls.tablesArray["controls"] = [];

    this.ectService
      .GetSheet(sheetId, this.selectedCountryId)
      .subscribe((data: any) => {
        if (data.errorMessage == null) {
          this.sheetId = data.sheetID;

          this.tableList = data.tableList;
          this.sheetName = data.sheetName;
          this.sheetCode = data.sheetCode;

          this.tablesArray = this.Ect.get("tablesArray") as FormArray;

          this.EctGeneralInput.controls.peakTCHour.setValue(data.peakTcHour);
          this.EctGeneralInput.controls.peakHourlyTCDaytime.setValue(
            data.peakHourlyTcDaytime
          );

          for (let table of this.tableList) {
            var tableGroup = new FormGroup({});
            for (let row of table.rowList) {
              var columnIndex = 0;
              for (let column of row.columnList) {
                let data = new FormControl(column.value, [
                  this.dataValidation(
                    table.columnHeaderList[columnIndex].dataType
                  ),
                ]);
                tableGroup.addControl(
                  table.modelID + "_" + row.rowID + "_" + column.columnID,
                  data
                );
                columnIndex = columnIndex + 1;
              }
            }
            this.tablesArray.push(tableGroup);
          }
          this.loading = false;
          this.isClick = true;
        } else {
          this.sheetId = data.sheetID;
          this.loading = false;
          const modalRef = this.modalService.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = data.errorMessage;
          return;
        }
      });
  }

  ProcessSheet() {
    this.loading = true;
    var ectRows = new EctRows();
    ectRows.sheetID = this.sheetId;
    ectRows.marketID = this.selectedCountryId;

    ectRows.peakHourlyTCDaytime = this.EctGeneralInput.get(
      "peakHourlyTCDaytime"
    ).value;
    ectRows.peakTCHour = this.EctGeneralInput.get("peakTCHour").value;

    if (this.Ect.controls["tablesArray"]["controls"].length > 0) {
      for (
        var formGroupIndex = 0;
        formGroupIndex < this.Ect.controls["tablesArray"]["controls"].length;
        formGroupIndex++
      ) {
        var tableFormGroup =
          this.Ect.controls["tablesArray"]["controls"][formGroupIndex].value;
        Object.keys(tableFormGroup).forEach(function (key, index) {
          var splitted = key.split("_");
          var ectRow = new EctRow();
          ectRow.modelID = parseInt(splitted[0]);
          ectRow.rowID = parseInt(splitted[1]);
          ectRow.columnID = parseInt(splitted[2]);
          ectRow.value = tableFormGroup[key];
          ectRows.ectRows.push(ectRow);
        });
      }

      this.ectService.ProcessSheet(ectRows).subscribe((data: any) => {
        if (data.errorMessage == null) {
          this.Ect.controls.tablesArray["controls"] = [];
          this.sheetId = data.sheetID;

          this.tableList = data.tableList;

          this.tablesArray = this.Ect.get("tablesArray") as FormArray;

          for (let table of this.tableList) {
            var tableGroup = new FormGroup({});
            for (let row of table.rowList) {
              var columnIndex = 0;
              for (let column of row.columnList) {
                let data = new FormControl(column.value, [
                  this.dataValidation(
                    table.columnHeaderList[columnIndex].dataType
                  ),
                ]);
                tableGroup.addControl(
                  table.modelID + "_" + row.rowID + "_" + column.columnID,
                  data
                );
                columnIndex = columnIndex + 1;
              }
            }
            this.tablesArray.push(tableGroup);
          }
          this.loading = false;
          setTimeout(() => {
            this.namefield.nativeElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 1000);
        } else {
          this.loading = false;
          const modalRef = this.modalService.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = data.errorMessage;
          return;
        }
      });
    }
  }

  dataValidation(dataType: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (dataType == 0) {
        return null;
      }
      if (
        dataType == 1 &&
        control.value !== undefined &&
        !isNaN(control.value) &&
        Number(control.value) != null &&
        control.value >= 0
      ) {
        return null;
      }
      if (
        dataType == 2 &&
        control.value !== undefined &&
        (control.value.toString().toUpperCase() == "TRUE" ||
          control.value.toString().toUpperCase() == "FALSE")
      ) {
        return null;
      }
      return { invalidInput: true };
    };
  }

  selectCountry(event) {
    this.selectedCountryId = event.value;
    this.storage.set(
      "countryId",
      this.cryptographyService.encryptData(this.selectedCountryId)
    );
    if (this.sheetId > 0) {
      this.GetSheet(this.sheetId);
    }
  }

  resetForm() {
    this.Ect.controls.tablesArray["controls"] = [];
    this.isClick = false;
    this.sheetId = 0;
  }
}
