import { Category } from "./category";

export class CategoryList {
    categories: Category[] = [];
    count: number;
    constructor(obj?: CategoryList) {
      if (obj) {
        this.categories = obj.categories? obj.categories.map(o => new Category(o)): null;
        this.count = obj.count;
      }
    }
}
