import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CryptographyService {

  encryptSecretKey: string = '30610440-d358-48c2-89b6-ba2bf8ddb44d';

   /**
  * Creates an instance of CryptographyService.
  */
  constructor(private http: HttpClient) { }

   /**
  * encrypt Data.
  */
  encryptData(value) {

    if (value != null) {
      try {
        return CryptoJS.AES.encrypt(JSON.stringify(value), this.encryptSecretKey).toString();
      } catch (e) {
        console.log(e);
      }
    }
    
  }

  /**
  * decrypt Data.
  */
  decryptData(value) {

    if (value != null) {
      try {
        const bytes = CryptoJS.AES.decrypt(value, this.encryptSecretKey);
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return value;
      } catch (e) {
        console.log(e);
      }
    }
  
  }

}
