import { FilteringGuide } from "./filteringGuide";

export class NrbesFiltration {
    nrbesId: number;
  countryId: number;
  isEquipmentStandard: boolean;
  isForward: boolean;
  searchKey: string;
    filterSet: FilteringGuide[] = [];
}
