import { Component, OnInit, Inject } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { StandardService } from "../services/standard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { NrbeStandard } from "../models/nrbestandard";
import { CryptographyService } from "../services/cryptography.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-nrbes-detail-view",
  templateUrl: "./nrbes-detail-view.component.html",
  styleUrls: ["./nrbes-detail-view.component.scss"],
})
export class NrbesDetailViewComponent implements OnInit {
  loading: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="/assets/images/back.svg">',
      '<img src="/assets/images/next.svg">',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  nrbesId: number = 0;
  nrbesDetail: NrbeStandard = new NrbeStandard();
  error: string = "";
  notes: SafeHtml = "";
  additionalInformation: SafeHtml = "";
  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private standardService: StandardService,
    private cryptographyService: CryptographyService,
    private sanitizer: DomSanitizer
  ) {
    this.nrbesId = parseInt(this.activeRoute.snapshot.paramMap.get("nrbesId"));
  }

  ngOnInit() {
    this.getDetailByNrbesId(this.nrbesId);
  }

  /**
   * Get National Standard detail by standard id
   * @return void
   */
  getDetailByNrbesId(grsId: number) {
    this.loading = true;
    this.standardService
      .getNrbesDetailInfomationByNrbesId(
        grsId,
        this.cryptographyService.decryptData(
          this.storage.get("selectedCountryId")
        ),
        this.standardService.nrbesFiltrations.filterSet,
        this.standardService.nrbesFiltrations.searchKey
      )
      .subscribe((data: NrbeStandard) => {
        this.nrbesDetail = new NrbeStandard(data);
        var options = {
          /* … */
        };
        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.nrbesDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.nrbesDetail.standard.additionalInformation
        );
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }
  navigateAccToType(type, id) {
    if (type == 4) {
      this.route.navigate(["/minimum-standard-detail-view/" + id]);
      return;
    }
    if (type == 8) {
      this.route.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.route.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.route.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }
  //need to redirect correct component with checking standard type
  slide(sID: number, isForward: boolean) {
    this.loading = true;

    this.standardService
      .getNextPreviousNrbsInfomationByCurrentNrbesId(
        sID,
        isForward,
        this.cryptographyService.decryptData(
          this.storage.get("selectedCountryId")
        ),
        this.standardService.nrbesFiltrations.filterSet,
        this.standardService.nrbesFiltrations.searchKey
      )
      .subscribe((data: NrbeStandard) => {
        this.route.navigate(["nrbes-view", data.id]);
        this.nrbesDetail = new NrbeStandard(data);
        var options = {
          /* … */
        };
        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.nrbesDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.nrbesDetail.standard.additionalInformation
        );
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
      });
  }
  downloadExcel() {
    this.standardService.downloadSingleStandardExcel(
      this.nrbesDetail,
      "market"
    );
    return;
  }
}
