<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Global Admin Panel</mat-card-title>
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <div class="mcd-edit-form mcd-grbes-form">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6">
            <div class="list-group">
              <button *ngIf="viewManageSuppliers" class="list-group-item list-group-item-action"
                (click)="redirectToAssignMarketSupplier()">
                Assign market suppliers
              </button>
              <button *ngIf="viewAllSuppliers" class="list-group-item list-group-item-action"
                (click)="redirectToManageSuppliers()">Manage
                suppliers</button>
              <button *ngIf="viewAllCategory" class="list-group-item list-group-item-action"
                (click)="redirectToManageCategories()">Manage
                categories</button>
              <button *ngIf="viewAllEquipmentType" class="list-group-item list-group-item-action"
                (click)="redirectToManageEquipmentTypes()">Manage
                equipment types</button>
               <button *ngIf="viewAllUser" class="list-group-item list-group-item-action"
                (click)="redirectToManageUsers()">Manage
                users</button>
              <button *ngIf="viewAllUsefulLinks" class="list-group-item list-group-item-action"
                (click)="redirectToManageUserfulLinks()">Manage
                useful links</button>
              <button *ngIf="viewAllUsefulLinks" class="list-group-item list-group-item-action"
                (click)="redirectToManageApplicationStatement()">Manage
                application statement</button>
              <button class="list-group-item list-group-item-action" (click)="redirectToManageScript()"
                >Run Links Analysis </button>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
