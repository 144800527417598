<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Assign Market Suppliers</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li *ngIf="userRole == this.userRoleEnum.GlobalAdmin"><a routerLink="/global-admin-panel" routerLinkActive="active">Global Admin Panel</a></li>    
        <li *ngIf="userRole == this.userRoleEnum.MarketAdmin"><a routerLink="/market-admin-panel" routerLinkActive="active">Market Admin Panel</a></li>
        <li *ngIf="userRole == this.userRoleEnum.AOWAdmin"><a routerLink="/market-admin-panel" routerLinkActive="active">AOW Admin Panel</a></li>
        <li class="separator">></li>
        <li class="active">Assign Market Suppliers</li>
      </ul>
    </div>
  </div>
  <mat-card-content class="mcd-card-content">
    <div class="row">
      <div class="col-6">
        <div>
          <div *ngIf="countryId">
            <mat-form-field>
              <input matInput placeholder="Search by Name or Description" value="{{searchKey}}"
                (keyup.enter)="addSearchKey(key)" name="search" #key>
                <button mat-button *ngIf="searchKey" matSuffix mat-icon-button aria-label="Clear" (click)="searchKey=''; addSearchKey(searchKey);">
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
          </div>
          <div *ngIf="supplierList.globalMarketSuppliers.length > 0">
            <mat-form-field>
              <mat-select [value]="pageSize" name="nOfRows" #items
                (selectionChange)="selectedItemsPerPageChange(items)">
                <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-6 text-right">
        <mat-form-field *ngIf="userRole == this.userRoleEnum.GlobalAdmin || userRole == this.userRoleEnum.AOWAdmin">
          <mat-label>Country</mat-label>
          <mat-select [value]="countryId" name="dropCategory" #country (selectionChange)="getSuppliers($event);">
            <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="table-wrapper" *ngIf="supplierList.globalMarketSuppliers.length > 0">
      <table class="table nrbes-grid">
        <thead class="thead-light">
          <tr>
            <th scope="col" width="10%">Logo</th>
            <th scope="col" width="15%">Name</th>
            <th scope="col" width="67%">Description</th>
            <th scope="col" width="8%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of supplierList.globalMarketSuppliers">
            <td class="title-cell">
              <div class="mcd-supplier-logo">
                <img [src]="item?.supplier?.supplierLogoAttachment?.image" class="img-fluid">
              </div>
            </td>
            <td class="location-cell">{{item.supplier.name}}</td>
            <td class="description">
              <span title="{{item.supplier.description}}" class="description">
                {{item.supplier.description}}
              </span>
            </td>
            <td>
              <a *ngIf="item.isAdded" (click)="onUpdateMarketSupplier(item, false)">
                Remove
              </a>
              <a *ngIf="!item.isAdded" (click)="onUpdateMarketSupplier(item, true)">
                Add
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <ngb-pagination class="float-right" [(page)]="page" [pageSize]="pageSize" [maxSize]="4" [rotate]="true"
            [ellipses]="true" [collectionSize]="collectionSize" (pageChange)="getSuppliers()"></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="!countryId">
      Please select a country.
    </div>
    <div *ngIf="supplierList.globalMarketSuppliers.length == 0 && countryId">
      Sorry you do not have any supplier.
    </div>
  </mat-card-content>



</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>