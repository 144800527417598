<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >{{ supplier.id > 0 ? "Edit" : "Add New" }} Market
      Supplier</mat-card-title
    >
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li>
          <a routerLink="/market-admin-panel" routerLinkActive="active"
            >Market Admin Panel</a
          >
        </li>
        <li class="separator">></li>
        <li>
          <a
            routerLink="/view-all-national-supplier-list"
            routerLinkActive="active"
            >Manage Market Suppliers</a
          >
        </li>
        <li class="separator">></li>
        <li class="active">
          {{ supplier.id > 0 ? "Edit" : "Add New" }} Market Supplier
        </li>
      </ul>
    </div>
  </div>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEditSupplierForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-5">
              <mat-form-field *ngIf="this.userRole == 4">
                <mat-label>Country</mat-label>
                <mat-select
                  name="dropLocation"
                  [value]="addEditSupplierForm['controls'].countryId.value"
                  #country
                  (selectionChange)="
                    addEditSupplierForm['controls'].countryId.setValue(
                      $event.value
                    )
                  "
                  required
                >
                  <mat-option [value]="item.id" *ngFor="let item of countries">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2"></div>
            <div class="col-5">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Name"
                  name="Name"
                  formControlName="name"
                  required
                />
                <mat-error
                  *ngIf="
                    addEditSupplierForm.get('name').touched &&
                    addEditSupplierForm.get('name').hasError('required')
                  "
                >
                  Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5 offset-md-2"></div>

            <div class="col-12">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Description"
                  name="Description"
                  formControlName="description"
                />
              </mat-form-field>
            </div>
            <div class="col-12"></div>
            <div class="col-12"></div>

            <div class="col-5"></div>
            <div class="col-5 offset-md-2"></div>
            <div class="col-5">
              <div class="mcd-upload-images">
                <div class="row attachements">
                  <div class="col">
                    <a
                      href="javascript:void(0);"
                      (click)="supplierLogo.click()"
                    >
                      <span>
                        <img
                          alt="Upload Image"
                          src="/assets/images/attachement.svg"
                        />
                      </span>
                      Upload Image (JPEG, PNG, BMP)
                    </a>
                    <div class="uploaded-file">
                      <span class="file-name"
                        ><a
                          href="javascript:void(0);"
                          (click)="
                            onDownloadSelectedSupplierAttachment(
                              supplier.supplierLogoAttachment.id
                            )
                          "
                          >{{ supplier.supplierLogoAttachment?.fileName }}</a
                        ></span
                      >
                      <span *ngIf="supplier.supplierLogoAttachment?.fileName">
                        <a
                          href="javascript:void(0);"
                          (click)="onRemoveSupplierAttachment()"
                        >
                          <i class="material-icons delete-icon"> cancel </i>
                        </a>
                      </span>
                    </div>
                    <input
                      hidden
                      type="file"
                      (change)="onSelectImageAttachment($event)"
                      #supplierLogo
                    />
                    <mat-error
                      class="mat-error-left-label"
                      *ngIf="invalidImage"
                    >
                      Invalid file type.
                    </mat-error>
                    <mat-error
                      class="mat-error-left-label"
                      *ngIf="invalidImageRes"
                    >
                      Invalid image resolution.
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="!supplier.id"
        mat-raised-button
        (click)="onReset()"
      >
        Reset
      </button>
      <button
        class="mcd-btn mcd-btn-primary"
        *ngIf="supplier.id"
        mat-raised-button
        (click)="onCancelClick()"
      >
        Cancel
      </button>
      <button
        class="mcd-btn mcd-btn-secondary"
        mat-raised-button
        [disabled]="
          addEditSupplierForm.invalid ||
          !supplier.supplierLogoAttachment.fileName
        "
        (click)="onSaveSupplier()"
      >
        {{ supplier.id > 0 ? "Update" : "Add" }}
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
