
export class Country {
  id: number;
  name: string;
  countryIso: string;
  constructor(obj?: Country) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.countryIso = obj.countryIso;
    }
  }
}
