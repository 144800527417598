import { Component, OnInit, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { EquipmentService } from "../services/equipment.service";
import { EquipmentType } from "../models/equipment-type";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { Equipment } from "../models/equipment";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Observable } from "rxjs";
import { CryptographyService } from "../services/cryptography.service";
import { Supplier } from "../models/supplier";

@Component({
  selector: "app-add-equipment",
  templateUrl: "./add-equipment.component.html",
  styleUrls: ["./add-equipment.component.scss"],
})
export class AddEquipmentComponent implements OnInit {
  loading: boolean = false;
  addEquipmentForm: FormGroup;
  equipmentTypes: EquipmentType[] = [];
  suppliers: EquipmentType[] = [];
  equipment: Equipment = new Equipment();
  supplier: Supplier = new Supplier();
  invalidImage: boolean = false;
  invalidAttachment: boolean = false;
  invalidImageRes: boolean = false;

  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private ngbModal: NgbModal,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    let equipmentTypesArray = [];
    let suppliersArray = [];

    this.addEquipmentForm = this.fb.group({
      model: ["", Validators.required],
      equipmentType: equipmentTypesArray,
      equipmentTypeId: ["", Validators.required],
      supplier: suppliersArray,
      supplierId: ["", Validators.required],
    });

    this.equipmentService.getAllEquipmentTypes().subscribe((data: any) => {
      this.equipmentTypes = data;
      if (this.equipmentTypes != null) {
        this.equipmentTypes
          .sort((firstItem, nextItem) =>
            firstItem.name.localeCompare(nextItem.name)
          )
          .forEach((element) => {
            equipmentTypesArray.push(
              this.fb.group({
                id: new FormControl(element.id),
                name: new FormControl(element.name),
              })
            );
          });
      }
    });

    //this.equipmentService.getAllSuppliers().subscribe((data: Supplier[]) => {
    //  this.suppliers = data.map(obj => new Supplier(obj));
    //  if (this.suppliers != null) {
    //    this.suppliers.sort((firstItem, nextItem) =>
    //      firstItem.name.localeCompare(nextItem.name)).forEach(element => {
    //        suppliersArray.push(this.fb.group({
    //          id: new FormControl(element.id),
    //          name: new FormControl(element.name)
    //        }));
    //      });
    //  }
    //});
  }

  setSelectedEquipmentType(type) {
    this.addEquipmentForm.controls.equipmentTypeId.setValue(
      parseInt(type.value)
    );
  }

  setSelectedSupplier(supplier) {
    this.addEquipmentForm.controls.supplierId.setValue(
      parseInt(supplier.value)
    );
  }

  saveEquipment(): void {
    this.loading = true;
    this.equipment.model = this.addEquipmentForm.controls.model.value;
    this.equipment.equipmentTypeId =
      this.addEquipmentForm.controls.equipmentTypeId.value;
    this.equipment.supplierId = this.addEquipmentForm.controls.supplierId.value;
    this.equipment.createdUserId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );

    this.equipmentService
      .addEquipment(this.equipment)
      .subscribe((data: any) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully added the equipment.";
        this.loading = false;
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(
          (receivedEntry) => {
            this.ngbModal.dismissAll();
            this.resetForm();
            this.createForm();
          }
        );
      });
  }

  onRemoveEquipmentAttachment() {
    this.equipment.imageFileName = null;
    this.equipment.imageAttachmentContentAsBase64 = null;
    this.invalidImage = false;
    this.invalidImageRes = false;
  }

  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }

  onDownloadSelectedEquipmentAttachment() {
    fetch(
      this.equipment.imageAttachmentContent
        ? this.equipment.imageMime + this.equipment.imageAttachmentContent
        : this.equipment.imageAttachmentContentAsBase64
    )
      .then((res) => res.blob())
      .then((blob) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveBlob(
            blob,
            this.equipment.imageFileName
          );
        } else {
          var a = document.createElement("a");
          a.href = this.equipment.imageAttachmentContent
            ? this.equipment.imageMime + this.equipment.imageAttachmentContent
            : this.equipment.imageAttachmentContentAsBase64;
          a.setAttribute("download", this.equipment.imageFileName);
          a.click();
        }
      });
  }

  onSelectAttachment(event) {
    let selectedFile = event.target.files[0];
    this.invalidAttachment = this.validateAttachmentType(selectedFile.name);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.equipment.attachmentFileName = selectedFile.name;
      this.equipment.attachmentContentAsBase64 = result;
      event.target.value = null;
    });
  }

  onSelectImageAttachment(event) {
    this.invalidImageRes = false;
    let selectedFile = event.target.files[0];
    this.invalidImage = this.validateFileType(selectedFile.name);
    this.validateImageResolution(selectedFile, this.invalidImage).subscribe(
      (result) => {
        this.invalidImageRes = result;
      }
    );
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.equipment.imageFileName = selectedFile.name;
      this.equipment.imageAttachmentContentAsBase64 = result;
      event.target.value = null;
    });
  }

  onRemoveAttachment() {
    this.equipment.attachmentFileName = null;
    this.equipment.imageAttachmentContentAsBase64 = null;
    this.invalidAttachment = false;
  }

  onDownloadSelectedAttachment() {
    fetch(
      this.equipment.attachmentContent
        ? this.equipment.attachmentMime + this.equipment.attachmentContent
        : this.equipment.attachmentContentAsBase64
    )
      .then((res) => res.blob())
      .then((blob) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveBlob(
            blob,
            this.equipment.attachmentFileName
          );
        } else {
          var a = document.createElement("a");
          a.href = this.equipment.attachmentContent
            ? this.equipment.attachmentMime + this.equipment.attachmentContent
            : this.equipment.attachmentContentAsBase64;
          a.setAttribute("download", this.equipment.attachmentFileName);
          a.click();
        }
      });
  }

  resetForm() {
    this.addEquipmentForm.reset();
    this.equipment.imageFileName = null;
    this.equipment.imageAttachmentContentAsBase64 = null;
    this.invalidImage = false;
    this.equipment.attachmentFileName = null;
    this.equipment.imageAttachmentContentAsBase64 = null;
    this.invalidAttachment = false;
    this.createForm();
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["jpg", "jpeg", "png", "bmp"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  validateAttachmentType(fileName: string) {
    let fileTypeArr = ["doc", "docx", "pdf"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  validateImageResolution(selectedFile, status): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (status == false) {
        var url = URL.createObjectURL(selectedFile);
        var img = new Image();
        img.onload = function () {
          if (img.width != img.height) {
            observer.next(true);
          }
        };
        img.src = url;
      }
    });
  }

  checkFormValidity() {
    if (
      this.addEquipmentForm.invalid ||
      this.invalidImage ||
      this.invalidAttachment ||
      this.equipment.imageFileName == null ||
      this.invalidImageRes
    ) {
      return true;
    }
  }
}
