<div class="modal" style="display: block !important;  background: #0000007a;" id="content">
  <div class="modal-dialog">
      <div class="modal-content" >
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Warning</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
   <div class="mcd-delete-dialog-content">
     <div class="row">
       <div class="col-3 delete-icon-wrapper">
         <img alt="Delete"  src="/assets/images/mcd-trash.svg">
       </div>
       <div class="col-9">
         <p class="mcd-large-text">{{title}}</p>
         <p class="mcd-small-text">{{message}}</p>
       </div>
     </div>
   </div>
  </div>
  <div class="modal-footer">
      <button class="mcd-btn mcd-btn-primary"  mat-raised-button color="accent" #yes (click)="onDoTheAction()">Yes</button>
      <button  class="mcd-btn mcd-btn-secondary" mat-raised-button color="warn" (click)="closeDialog()">No</button>
  </div>
</div>
</div>
</div>