export class AddendumAttachment {
    id: number;
    addendumId: number;
    fileName: string;
    mime: string;
    attachmentContent: any;
    attachmentContentAsBase64: string;
    isRequestedFile: boolean;
    constructor(obj?: AddendumAttachment) {
        if (obj) {
            this.id = obj.id;
            this.addendumId = obj.addendumId;
            this.fileName = obj.fileName;
            this.mime = obj.mime;
            this.attachmentContent = obj.attachmentContent;
            this.isRequestedFile = obj.isRequestedFile;
            this.attachmentContentAsBase64 = obj.attachmentContentAsBase64;
        }
    }
}

