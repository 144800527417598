<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Update Application Statement</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li><a routerLink="/global-admin-panel" routerLinkActive="active">Global Admin Panel</a></li>
        <li class="separator">></li>
              
        <li class="active">Update Application Statement</li>
      </ul>
    </div>
  </div>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="updateStatementForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-12">
              <mat-label>Application Statement *</mat-label>
              <quill-editor name="statement" formControlName="statement" class="quill-editor-css"> </quill-editor>
            </div>

          </div>
        </div>
      </form>
      <br />
      <br />

      <br />
      <br />

    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">

      <button class="mcd-btn mcd-btn-primary" *ngIf="applicationStatement.id" mat-raised-button
              (click)="onCancelClick()">
        Cancel
      </button>
      <button class="mcd-btn mcd-btn-secondary" mat-raised-button 
              (click)="saveApplicationStatement()">
        Update
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
