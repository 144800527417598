import { Supplier } from "./supplier";

export class GlobalMarketSupplier {
    supplier: Supplier;
    isAdded: boolean;
    hasStandard: boolean;
    constructor(obj?: GlobalMarketSupplier) {
      if (obj) {
        this.supplier = new Supplier(obj.supplier);
        this.isAdded = obj.isAdded;
        this.hasStandard = obj.hasStandard;
      }
    }
}
