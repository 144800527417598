import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  Input,
} from "@angular/core";
import { LocationType } from "../models/location-type";
import { StandardService } from "../services/standard.service";
import { Category } from "../models/category";
import { FilteringGuide } from "../models/filteringGuide";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { CryptographyService } from "../services/cryptography.service";
import { Form, FormControl } from "@angular/forms";
import { source } from "@angular-devkit/schematics";
import {
  AllStandardsTabs,
  FilteringEnum,
  StandardListType,
} from "../core/constant";
import { ThemePalette } from "@angular/material/core";
import { timeStamp } from "console";
import { FilterList } from "../models/filterList";

@Component({
  selector: "app-view-all-standards-filter",
  templateUrl: "./view-all-standards-filter.component.html",
  styleUrls: ["./view-all-standards-filter.scss"],
})
export class ViewAllStandardsFilter implements OnInit {
  locationTypes: LocationType[] = [];
  categories: Category[] = [];
  equipmentTypes: any = [];
  filterList: FilteringGuide[] = [];
  filteringForCheckBox: FilteringGuide[] = [];
  selectedTabs: FilteringGuide = new FilteringGuide({
    type: FilteringEnum.FilterByStandardType,
    ids: [],
  });
  filtersWidth: string = "col-md-6";

  //styling
  tabColor: ThemePalette = "warn";
  globaltabcheck = false;
  equipmenttabcheck = false;
  buildingtabcheck = false;
  operationstabcheck = false;

  @Output() messageEvent = new EventEmitter<Array<FilteringGuide>>();
  @Input() isFromLocation: boolean;

  constructor(
    public standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    public storage: WebStorageService,
    private cryptographyService: CryptographyService
  ) {}

  ngOnInit() {
    console.log("ngoninit trigger");
    // debugger;
    this.getMasterDataForFilteration();
    this.filterList = this.standardService.allStandardsFiltration.filterSet;
    if (
      this.filterList.find((i) => i.type === FilteringEnum.FilterByStandardType)
    ) {
      this.selectedTabs = this.filterList.find(
        (i) => i.type === FilteringEnum.FilterByStandardType
      );
      console.log("SELECTED TABS", this.selectedTabs);
    } else {
      this.filterList.push(
        new FilteringGuide({
          type: FilteringEnum.FilterByStandardType,
          ids: [
            AllStandardsTabs.BuildingStandards,
            AllStandardsTabs.OperationsStandard,
          ],
        })
      );
      localStorage.setItem(
        "selectedTabs",
        JSON.stringify([
          AllStandardsTabs.BuildingStandards,
          AllStandardsTabs.OperationsStandard,
        ])
      );
      this.standardService.allStandardsFiltration.filterSet = this.filterList;
      this.selectedTabs = this.filterList.find(
        (i) => i.type === FilteringEnum.FilterByStandardType
      );
    }
  }

  arraysEqual(a1, a2) {
    return JSON.stringify(a1) == JSON.stringify(a2);
  }

  displayEquipmentTypeFilter() {
    if (
      !this.standardService.allStandardsFiltration.filterSet.find(
        (i) => i.type == FilteringEnum.FilterByStandardType
      )
    )
      return true;
    if (
      this.standardService.allStandardsFiltration.filterSet.find(
        (i) =>
          i.type == FilteringEnum.FilterByStandardType &&
          (i.ids.includes(AllStandardsTabs.EquipmentStandards) ||
            i.ids.length == 0)
      )
    ) {
      // this.filtersWidth = "col-md-4";
      return true;
    } else {
      // this.filtersWidth = "col-md-6";
      return false;
    }
  }

  displayAllTabs() {
    if (
      !this.standardService.allStandardsFiltration.filterSet.find(
        (i) => i.type == FilteringEnum.FilterByStandardType
      )
    ) {
      this.filtersWidth = "col-md-4";
      return true;
    } else if (
      this.standardService.allStandardsFiltration.filterSet.find(
        (i) => i.type == FilteringEnum.FilterByStandardType && i.ids.length == 0
      )
    ) {
      this.filtersWidth = "col-md-4";
      return true;
    }
    this.filtersWidth = "col-md-6";
    return false;
  }
  setTabChecked(name) {
    let a;
    if (
      this.filterList.find((i) => i.type == FilteringEnum.FilterByStandardType)
    ) {
      a = this.filterList.find(
        (i) => i.type == FilteringEnum.FilterByStandardType
      ).ids;
    } else return;
    {
      this.globaltabcheck = false;
      this.buildingtabcheck = false;
      this.operationstabcheck = false;
      this.equipmenttabcheck = false;
      false;
    }
    switch (name) {
      case "global":
        if (a.includes(AllStandardsTabs.GlobalRecommendations)) {
          this.globaltabcheck = true;
          return true;
        }
        break;
      case "equipment":
        if (a.includes(AllStandardsTabs.EquipmentStandards)) {
          this.equipmenttabcheck = true;
          return true;
        }
        break;
      case "operations":
        if (a.includes(AllStandardsTabs.OperationsStandard)) {
          this.operationstabcheck = true;
          return true;
        }
        break;
      case "building":
        if (a.includes(AllStandardsTabs.BuildingStandards)) {
          this.buildingtabcheck = true;
          return true;
        }
        break;
      default:
        break;
    }

    this.globaltabcheck = false;
    this.buildingtabcheck = false;
    this.operationstabcheck = false;
    this.equipmenttabcheck = false;
    false;

    return false;
  }

  setBuildingCheck() {
    return this.setTabChecked("building");
  }
  setOperationsCheck() {
    return this.setTabChecked("operations");
  }
  setGlobalCheck() {
    return this.setTabChecked("global");
  }
  setEquipmentCheck() {
    return this.setTabChecked("equipment");
  }
  getMasterDataForFilteration() {
    this.standardService
      .GetMasterDataForAddNrbes(
        this.cryptographyService.decryptData(this.storage.get("userRole")),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe((data: any) => {
        this.categories = data.categories;

        this.locationTypes = data.locationTypes;
        // this.equipmentTypes = data.equipmentTypes;

        let xfilterList = new FilterList();
        xfilterList.standardListType = StandardListType.GlobalEquipment;

        this.standardService
          .getEquipmentStandardChipset(xfilterList)
          .subscribe((data: any) => {
            this.equipmentTypes = data.equipmentTypes;
          });
      });
  }

  addFilter($event, id, filterParam) {
    if (this.standardService.allStandardsFiltration.filterSet != null) {
      this.filterList = this.standardService.allStandardsFiltration.filterSet;
    }
    if ($event.checked) {
      let filterGuide = this.filterList.find((i) => i.type === filterParam);
      if (filterGuide != undefined) {
        if (!filterGuide.ids.includes(id)) {
          filterGuide.ids.push(id);
        }
      } else {
        let guide = new FilteringGuide();
        guide.type = filterParam;
        guide.ids.push(id);
        this.filterList.push(guide);
      }
    } else {
      let selectedFilter = this.filterList.find((i) => i.type === filterParam);
      if (selectedFilter != undefined && selectedFilter.ids.includes(id)) {
        let index = selectedFilter.ids.indexOf(id);
        selectedFilter.ids.splice(index, 1);
      }
      if (selectedFilter.ids.length == 0) {
        let i = this.filterList.findIndex((x) => x.type === filterParam);
        this.filterList.splice(i, 1);
      }
    }
    this.standardService.allStandardsFiltration.filterSet = this.filterList;
    this.messageEvent.emit(this.filterList);
  }

  setCheckedStateForCheckBoxes(type, id) {
    this.filteringForCheckBox =
      this.standardService.allStandardsFiltration.filterSet;
    if (this.filteringForCheckBox != null) {
      let t = this.filteringForCheckBox.find((i) => i.type === type);
      if (t != undefined && t.ids.includes(id)) {
        return true;
      }
    }
  }

  // tabs filter

  tabClick(event): void {
    if (event.checked == false) {
      if (this.selectedTabs.ids.length == 1) {
        alert("Cannot de-select all tabs");
        this.setGlobalCheck();
        location.reload();
        return;
      }
      switch (event.source.name) {
        case "building":
          if (
            this.selectedTabs.ids.includes(AllStandardsTabs.BuildingStandards)
          ) {
            this.selectedTabs.ids.splice(
              this.selectedTabs.ids.indexOf(AllStandardsTabs.BuildingStandards),
              1
            );
          }
          break;
        case "operations":
          if (
            this.selectedTabs.ids.includes(AllStandardsTabs.OperationsStandard)
          ) {
            this.selectedTabs.ids.splice(
              this.selectedTabs.ids.indexOf(
                AllStandardsTabs.OperationsStandard
              ),
              1
            );
          }
          break;
        case "global":
          if (
            this.selectedTabs.ids.includes(
              AllStandardsTabs.GlobalRecommendations
            )
          ) {
            this.selectedTabs.ids.splice(
              this.selectedTabs.ids.indexOf(
                AllStandardsTabs.GlobalRecommendations
              ),
              1
            );
          }
          break;
        case "equipment":
          if (
            this.selectedTabs.ids.includes(AllStandardsTabs.EquipmentStandards)
          ) {
            this.selectedTabs.ids.splice(
              this.selectedTabs.ids.indexOf(
                AllStandardsTabs.EquipmentStandards
              ),
              1
            );
          }
          break;
        default:
          break;
      }
    } else {
      switch (event.source.name) {
        case "building":
          if (
            this.selectedTabs.ids.includes(AllStandardsTabs.BuildingStandards)
          ) {
          } else {
            if (
              this.selectedTabs.ids.includes(
                AllStandardsTabs.EquipmentStandards
              )
            ) {
              this.selectedTabs.ids.splice(
                this.selectedTabs.ids.indexOf(
                  AllStandardsTabs.EquipmentStandards
                ),
                1
              );
            }
            this.selectedTabs.ids.push(AllStandardsTabs.BuildingStandards);
            localStorage.setItem(
              "selectedTabs",
              JSON.stringify(this.selectedTabs.ids)
            );
          }
          break;
        case "operations":
          if (
            this.selectedTabs.ids.includes(AllStandardsTabs.OperationsStandard)
          ) {
          } else {
            if (
              this.selectedTabs.ids.includes(
                AllStandardsTabs.EquipmentStandards
              )
            ) {
              this.selectedTabs.ids.splice(
                this.selectedTabs.ids.indexOf(
                  AllStandardsTabs.EquipmentStandards
                ),
                1
              );
            }
            this.selectedTabs.ids.push(AllStandardsTabs.OperationsStandard);
            localStorage.setItem(
              "selectedTabs",
              JSON.stringify(this.selectedTabs.ids)
            );
          }
          break;
        case "global":
          if (
            this.selectedTabs.ids.includes(
              AllStandardsTabs.GlobalRecommendations
            )
          ) {
          } else {
            if (
              this.selectedTabs.ids.includes(
                AllStandardsTabs.EquipmentStandards
              )
            ) {
              this.selectedTabs.ids.splice(
                this.selectedTabs.ids.indexOf(
                  AllStandardsTabs.EquipmentStandards
                ),
                1
              );
            }
            this.selectedTabs.ids.push(AllStandardsTabs.GlobalRecommendations);
            localStorage.setItem(
              "selectedTabs",
              JSON.stringify(this.selectedTabs.ids)
            );
          }
          break;
        case "equipment":
          this.selectedTabs.ids = [AllStandardsTabs.EquipmentStandards];
          break;
        default:
          break;
      }
    }
    let indexOfTabsGuideInFilterList = -1;
    let idx = 0;
    for (let a of this.filterList) {
      if (a.type == FilteringEnum.FilterByStandardType) {
        indexOfTabsGuideInFilterList = idx;
      }
      idx = idx + 1;
    }
    if (indexOfTabsGuideInFilterList == -1) {
      this.filterList.push(this.selectedTabs);
    } else {
      this.filterList[indexOfTabsGuideInFilterList] = this.selectedTabs;
    }
    this.standardService.allStandardsFiltration.filterSet = this.filterList;
    console.log("FILTER LIST", this.filterList);
    this.messageEvent.emit(this.filterList);
    // this.messageEvent.emit(this.filterList);
  }
}
