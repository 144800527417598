import { Component, OnInit } from '@angular/core';
import { StandardService } from '../services/standard.service';
import { Constant, StandardTypeEnum } from "../core/constant";
import { WishList } from '../models/wish-list';
import { Standard } from '../models/standard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wishlist-grid',
  templateUrl: './wishlist-grid.component.html',
  styleUrls: ['./wishlist-grid.component.scss']
})
export class WishListGridComponent implements OnInit {
  loading: boolean = false;
  constructor(private standardService: StandardService, private router: Router) { }
  pageSizes: any[] = Constant.PageSizes;
  page: number = 1;
  pageSize: number = 5;
  collectionSize: number = 0;
  standardList: Standard[] = [];
  error: string = '';
  ngOnInit() {
    this.getStandards();
  }

  /**
 * Get standards
 * @return void
 */
  getStandards(): void {
    this.loading = true;
    this.standardService.getAllFutureStandards((this.page - 1) * this.pageSize, this.pageSize)
      .subscribe(
      (data: WishList) => {
        this.standardList = data.standardList.map(o => new Standard(o));
          this.collectionSize = data.count;
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          }
        });
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getStandards();
  }

  /**
* Row selected
* @return void
*/
  rowSelected(item: Standard): void {
    if (item.standardTypeId == StandardTypeEnum.GlobalEquipmentStandard) {
     this.router.navigate(['/wishlist-equipment-detail-view/' + item.id]);
    } else {
     this.router.navigate(['/wishlist-detail-view/' + item.id]);
    }
  }
}
