<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Exception</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li>
            <!--from Equipment Addendum-->
            <a
              *ngIf="
                !addendumService.isCommingFromNrbes &&
                addendum?.standard?.standardTypeId ==
                  standardTypeEnum.GlobalEquipmentStandard
              "
              routerLink="/equipment-addendum-list"
              routerLinkActive="active"
              >All</a
            >
            <!--from Equipment List-->
            <a
              *ngIf="
                addendumService.isCommingFromNrbes &&
                addendum?.standard?.standardTypeId ==
                  standardTypeEnum.GlobalEquipmentStandard
              "
              routerLink="/view-all-national-equipment-standard-list"
              routerLinkActive="active"
              >All</a
            >

            <!--from Nrbes Addendum-->
            <a
              *ngIf="
                !addendumService.isCommingFromNrbes &&
                addendum?.standard.standardTypeId !=
                  standardTypeEnum.GlobalEquipmentStandard
              "
              routerLink="/addendum-list"
              routerLinkActive="active"
              >All</a
            >
            <!--from NRBES List-->
            <a
              *ngIf="
                addendumService.isCommingFromNrbes &&
                addendum?.standard?.standardTypeId !=
                  standardTypeEnum.GlobalEquipmentStandard
              "
              routerLink="/nrbeslist"
              routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">></li>
          <li class="active">{{ addendum.standard?.title }}</li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->
    <div class="mcd-addendum">
      <h3 class="mcd-addendum-title">{{ addendum.standard?.title }}</h3>
      <p class="mcd-medium-text">
        {{ addendum.standard?.description }}
      </p>
      <div class="mcd-gray-box">
        <ul>
          <li>
            <span class="icon"><img src="/assets/images/location.svg" /></span>
            <span>{{ addendum.standard?.locationType?.name }}</span>
          </li>
          <li>
            <span class="icon"
              ><img src="/assets/images/description.svg"
            /></span>
            <span>{{ addendum.standard?.note }}</span>
          </li>
        </ul>
      </div>
      <div class="mcd-addendum-notes">
        <p style="font-weight: bold; font-size: medium; margin-bottom: 0">
          Exception Description
        </p>
        <p>{{ addendum.description }}</p>
        <p style="font-weight: bold; font-size: medium; margin-bottom: 0">
          Exception Notes
        </p>
        <p>{{ addendum.note }}</p>
      </div>
      <div class="mcd-addendum-status">
        <div class="row mb-3">
          <div class="col-md-6 col-sm-7">
            <div
              class="row"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
              "
            >
              <ng-container *ngIf="getApprovalArray().length > 0">
                <div class="col" style="border-right: 1px solid black">
                  <div class="exception">
                    <p style="font-size: 12px">Historical</p>
                  </div>
                  <div class="mcd-date-box-wrapper">
                    <label>First Approval</label>
                    <div class="mcd-date-box">
                      <div
                        [ngClass]="addendum.addendumStatusCssForReqDate"
                        class="historicalbg"
                      >
                        {{ getApprovalArray()[0] | date : "MMM | dd" }}
                      </div>
                      <div class="mcd-date-box-day">
                        {{ getApprovalArray()[0] | date : "yyyy" }}
                      </div>
                    </div>
                  </div>

                  <div class="mcd-date-box-wrapper">
                    <label>Ongoing</label>
                    <div class="mcd-date-box">
                      <div
                        [ngClass]="addendum.addendumStatusCssForReqDate"
                        class="historicalbg"
                      >
                        {{ getApprovalArray()[0] | date : "MMM | dd " }}
                      </div>
                      <div *ngFor="let years of getApprovalArray()">
                        {{ years | date : "yyyy" }}
                      </div>
                    </div>
                  </div>

                  <div *ngIf="addendum.expiryDate" class="mcd-date-box-wrapper">
                    <label>Expired</label>
                    <div class="mcd-date-box">
                      <div
                        [ngClass]="addendum.addendumStatusCssForexpiryDate"
                        class="historicalbg"
                      >
                        {{ addendum.expiryDate | date : "MMM | dd" }}
                      </div>
                      <div
                        class=""
                        *ngFor="let years of addendum.addendumApproval"
                      >
                        <ng-container *ngIf="years.expiryDate !== null">
                          {{ years.expiryDate | date : "yyyy" }}
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="col">
                <div class="exception">
                  <p style="font-size: 12px">Current</p>
                </div>
                <div
                  class="center-align-dateitems"
                  style="
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div class="mcd-date-box-wrapper">
                    <label>Requested On</label>
                    <div class="mcd-date-box">
                      <div [ngClass]="addendum.addendumStatusCssForReqDate">
                        {{ addendum.created | date : "MMM | dd" }}
                      </div>
                      <div class="mcd-date-box-day">
                        {{ addendum.created | date : "yyy" }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      addendum.addendumStatusId != addendumStatusEnum.Requested
                    "
                    class="mcd-date-box-wrapper"
                  >
                    <label>{{
                      addendum.addendumStatus.id == addendumStatusEnum.Rejected
                        ? "Rejected On"
                        : "Approved On"
                    }}</label>
                    <div class="mcd-date-box">
                      <div
                        [ngClass]="
                          addendum.addendumStatusCssForApprovedOrRejectedDate
                        "
                      >
                        {{
                          addendum.approvedOrRejectedDate | date : "MMM | dd"
                        }}
                      </div>
                      <div class="mcd-date-box-day">
                        {{ addendum.approvedOrRejectedDate | date : "yyyy" }}
                      </div>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="
                      addendum.addendumStatusId !=
                        addendumStatusEnum.Requested &&
                      addendum.addendumStatusId != addendumStatusEnum.Rejected
                    "
                  >
                    <div class="mcd-date-box-wrapper">
                      <label>Expires</label>
                      <div class="mcd-date-box">
                        <div
                          [ngClass]="addendum.addendumStatusCssForexpiryDate"
                        >
                          {{ addendum.expiryDate | date : "MMM | dd" }}
                        </div>
                        <div class="mcd-date-box-day">
                          {{ addendum.expiryDate | date : "yyyy" }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-5">
            <div
              *ngIf="
                (addendum.addendumStatusId == addendumStatusEnum.Requested ||
                  addendum.addendumStatusId ==
                    addendumStatusEnum.DraftAfterRequest) &&
                userRole == userRoleEnum.GlobalAdmin
              "
              class="mcd-status-approval"
            >
              <label>Change Status</label>
              <div class="mcd-approval-buttons mt-2">
                <button
                  [disabled]="attachmentFinal == null || invalidFile == true"
                  class="btn-approve"
                  (click)="approveOrRejectOrDraft(addendumStatusEnum.Approved)"
                  mat-stroked-button
                >
                  Approve
                </button>
                <button
                  class="btn-reject"
                  [disabled]="invalidFile"
                  (click)="approveOrRejectOrDraft(addendumStatusEnum.Rejected)"
                  mat-stroked-button
                  color="primary"
                >
                  Reject
                </button>
              </div>
            </div>
            <div
              class="mcd-status-approval"
              *ngIf="
                (addendum.addendumStatusId == addendumStatusEnum.Expired ||
                  addendum.addendumStatusId == addendumStatusEnum.Rejected) &&
                (userRole == userRoleEnum.MarketAdmin ||
                  userRole == userRoleEnum.AOWAdmin)
              "
            >
              <div class="mcd-approval-buttons">
                <button
                  (click)="redirectToRenew()"
                  class="btn-approve mt-2"
                  mat-stroked-button
                >
                  Renew Exception
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row attachments">
          <!--<div class="col">
            <a href="/Templates/AddendumTemplate.docx"> <span><img alt="Attached Template"
                  src="/assets/images/download.svg"></span>
              Exception Template</a>
          </div>-->
          <div class="col">
            <a
              href="javascript:void(0);"
              *ngIf="
                attachment != null &&
                addendum.addendumStatusId == addendumStatusEnum.Requested
              "
              (click)="
                onDownloadAddendumDocument(attachment?.id, attachment?.fileName)
              "
              ><span
                ><img alt="Template" src="/assets/images/attachement.svg"
              /></span>
              {{ attachment?.fileName }}</a
            >
          </div>
          <div
            *ngIf="
              (addendum.addendumStatusId == addendumStatusEnum.Requested ||
                addendum.addendumStatusId ==
                  addendumStatusEnum.DraftAfterRequest) &&
              userRole == userRoleEnum.GlobalAdmin
            "
            class="col text-right"
          >
            <a href="javascript:void(0);" (click)="fileInput.click()"
              ><span
                ><img alt="Template" src="/assets/images/upload.svg" /></span
              >Final Document</a
            >
            <div *ngIf="attachmentFinal != null" class="ulploaded-file">
              <span
                ><a
                  href="javascript:void(0);"
                  (click)="
                    onDownloadSelectedAddendumDocument(
                      attachmentFinal?.id,
                      attachmentFinal?.fileName
                    )
                  "
                  >{{ attachmentFinal?.fileName }}</a
                ></span
              >
              <span>
                <a
                  href="javascript:void(0);"
                  (click)="onRemoveAddendumDocument()"
                >
                  <span class="delete-icon">
                    <i _ngcontent-c7="" class="material-icons"> cancel</i>
                  </span>
                </a>
              </span>
            </div>
            <input
              hidden
              type="file"
              (change)="onSelectAAddendumDocument($event)"
              #fileInput
            />
            <div>
              <mat-error *ngIf="invalidFile">
                {{ invalidFileMessage }}
              </mat-error>
            </div>
          </div>
        </div>

        <div
          class="attachments-left-right"
          style="display: flex; flex-direction: row"
        >
          <div
            class="attachments mt-3"
            style="display: flex; flex-direction: column; margin-right: 20px"
            *ngIf="getApprovedAttachmentsList().length > 0"
          >
            <bold class="mb-3">Requested Documents</bold>
            <div class="col" *ngFor="let att of getRequestedAttachmentsList()">
              <a
                href="javascript:void(0);"
                (click)="onDownloadAddendumDocument(att?.id, att?.fileName)"
                class="mt-3"
                ><span
                  ><img
                    alt="Template"
                    src="/assets/images/attachement.svg" /></span
                >{{ att?.fileName }}</a
              >
            </div>
          </div>
          <div
            class="attachments mt-3"
            style="display: flex; flex-direction: column"
            *ngIf="getApprovedAttachmentsList().length > 0"
          >
            <bold class="mb-3">Approved Documents</bold>
            <div class="col" *ngFor="let att of getApprovedAttachmentsList()">
              <a
                href="javascript:void(0);"
                (click)="onDownloadAddendumDocument(att?.id, att?.fileName)"
                class="mt-3"
                ><span
                  ><img
                    alt="Template"
                    src="/assets/images/attachement.svg" /></span
                >{{ att?.fileName }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer class="mcd-card-footer">
    <!-- <button
      class="mcd-btn mcd-btn-gray"
      [disabled]="invalidFile"
      *ngIf="
        (addendum.addendumStatusId == addendumStatusEnum.Requested ||
          addendum.addendumStatusId == addendumStatusEnum.DraftAfterRequest) &&
        userRole == userRoleEnum.GlobalAdmin
      "
      mat-raised-button
      (click)="approveOrRejectOrDraft(addendumStatusEnum.DraftAfterRequest)"
    >
      Save as a Draft
    </button> -->
    <button
      (click)="onOk()"
      class="mcd-btn mcd-btn-secondary"
      mat-raised-button
    >
      OK
    </button>
  </mat-card-footer>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
