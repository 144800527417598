import { Injectable } from "@angular/core";
import { KeyContactModel } from "../models/key-contact-model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KeyContactService {
  constructor(private http: HttpClient) {}

  /**
   * Get Key Contacts
   * @return KeyContactModel[]
   */
  getKeyContacts(): Observable<KeyContactModel[]> {
    return this.http.get<KeyContactModel[]>(
      "/api/keycontact/getallkeycontacts"
    );
  }

  /**
   * Get a Key Contact Infomation By giveb Key Contact Id
   * @return KeyContactModel
   */
  getKeyContactInfomationByKeyContactId(
    keyContactId: number
  ): Observable<KeyContactModel> {
    return this.http.get<KeyContactModel>(
      "/api/keycontact/GetKeyContactInfomationByKeyContactId/" + keyContactId
    );
  }

  /**
   * Add a Key Contact
   * @return boolean
   */
  addKeyContact(keyContactModel: KeyContactModel): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/keycontact/addkeycontact",
      keyContactModel
    );
  }

  /**
   * Update a Key Contact
   * @return boolean
   */
  updateKeyContact(keyContactModel: KeyContactModel): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/keycontact/updatekeycontact",
      keyContactModel
    );
  }

  /**
   * Delete a Key Contact
   * @return boolean
   */
  deleteKeyContact(keyContactModel: KeyContactModel): Observable<boolean> {
    return this.http.post<boolean>(
      "/api/keycontact/DeleteKeyContact",
      keyContactModel
    );
  }
}
