<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Add Exception </mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="mcd-addendum">
      <h3 class="mcd-addendum-title">{{addendum.standard?.title}}</h3>
      <p class="mcd-medium-text">
        {{addendum.standard?.description}}
      </p>
      <div class="mcd-gray-box">
        <ul>
          <li><span class="icon"><img src="/assets/images/location.svg"></span>
            <span>{{addendum.standard?.locationType?.name}}</span></li>
          <li><span class="icon"><img src="/assets/images/description.svg"></span>
              <span>
                <div class="ql-container ql-snow" style="border-width: 0;">
                  <div class="ql-editor nrbes-description" [innerHTML]="addendum.standard?.note">
                  </div>
                </div>
               
              </span></li>
        </ul>
      </div>
      <form [formGroup]='addendumForm'>
        <div class="mcd-addendum-notes">
          <div class="row">
            <div class="col-md-10">
              <mat-form-field>
                <input matInput placeholder="Exception Description" name="description" formControlName="description">
                <mat-error *ngIf="addendumForm.get('description').touched && addendumForm.get('description').hasError('required')">
                  Exception Description is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-10">
              <mat-form-field>
                <input matInput placeholder="Exception Notes" name="note" formControlName="note">
                <mat-error *ngIf="addendumForm.get('note').touched && addendumForm.get('note').hasError('required')">
                  Exception Notes are required
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
        <div class="mcd-addendum-status">
          <div class="row attachements">
            <!--<div class="col">
              <a href="/Templates/AddendumTemplate.docx"><span><img alt="Attached Template"
                    src="/assets/images/download.svg"></span>
                Exception Template</a>
            </div>-->
            <div class="col">
              <a href="javascript:void(0);" (click)="fileInput.click()"><span><img alt="Template"
                    src="/assets/images/upload.svg"></span>Attach
                Exception File</a>
              <div *ngIf="addendum?.addendumAttachment[0]?.fileName" class="ulploaded-file">
                <span><a href="javascript:void(0);"
                    (click)="onDownloadSelectedAddendumDocument(addendum?.addendumAttachment[0]?.id)">{{addendum?.addendumAttachment[0]?.fileName}}</a></span>
                <span>
                  <a href="javascript:void(0);" (click)="onRemoveAddendumDocument()">
                    <span class="delete-icon">
                      <i _ngcontent-c7="" class="material-icons"> cancel</i>
                    </span>
                  </a>
                </span>
              </div>
              <input hidden type="file" (change)="onSelectAAddendumDocument($event)" #fileInput>
              <mat-error *ngIf="invalidFile">
                {{invalidFileMessage}}
              </mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-footer class="mcd-card-footer">
    <!-- <button class="mcd-btn mcd-btn-gray" *ngIf="userRole != userRoleEnum.GlobalAdmin" mat-raised-button
      (click)="saveAsADraft()" [disabled]="addendumForm.invalid || invalidFile == true">
      Save as a Draft </button> -->
    <button class="mcd-btn mcd-btn-secondary" (click)="submitTheAddendum()" mat-raised-button
      [disabled]="addendumForm.invalid || !addendum.addendumAttachment[0] || invalidFile == true"> Submit </button>

  </mat-card-footer>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
