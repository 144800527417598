<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >Frequently Asked Questions</mat-card-title
    >

    <button
      *ngIf="canAddFaq"
      class="mcd-card-add-btn"
      mat-button
      (click)="openAddEditQuestionsModal()"
    >
      <i class="material-icons"> add</i>
    </button>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="mcd-faq-outer-wrapper" *ngIf="currentPageRecords.length > 0">
      <div class="row">
        <div class="col-12 text-right">
          <mat-form-field class="d-none">
            <input matInput placeholder="Search Text" />
          </mat-form-field>
        </div>
      </div>

      <div *ngFor="let basicInformation of currentPageRecords">
        <div class="mcd-faq-wrapper">
          <div class="mcd-faq-inner-wrapper">
            <div class="panel-footer" *ngIf="canEditFaq || canDeleteFaq">
              <a
                class="mcd-more-btn"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="canEditFaq"
                  mat-menu-item
                  (click)="openAddEditQuestionsModal(basicInformation.id)"
                >
                  Edit
                </button>
                <button
                  *ngIf="canDeleteFaq"
                  mat-menu-item
                  (click)="openDeletePopup(content, basicInformation)"
                >
                  Delete
                </button>
              </mat-menu>
            </div>
            <h3 class="mcd-faq-title">{{ basicInformation.title }}</h3>

            <div *ngFor="let item of basicInformation.questionsAndAnswers">
              <div class="mcd-faq-item">
                <p class="mcd-faq-question">
                  {{ item.question }}
                </p>
                <p class="mcd-faq-answer">
                  {{ item.answer }}
                </p>
                <p class="mcd-faq-tags">
                  <span
                    ><a href="javascript:void(0)">{{ item.hashtag }} </a></span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="currentPageRecords.length == 0">
      Sorry you do not have any FAQ.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mcd-delete-dialog-content">
      <div class="row">
        <div class="col-3 delete-icon-wrapper">
          <img alt="Delete" src="/assets/images/mcd-trash.svg" />
        </div>
        <div class="col-9">
          <p class="mcd-large-text">Do you want to delete this FAQ?</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="mcd-btn mcd-btn-primary"
      mat-raised-button
      color="accent"
      (click)="deleteFAQ()"
    >
      Yes
    </button>
    <button
      class="mcd-btn mcd-btn-secondary"
      mat-raised-button
      color="warn"
      (click)="modal.close()"
    >
      No
    </button>
  </div>
</ng-template>
