import { Component, OnInit, Inject } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Router, ActivatedRoute } from "@angular/router";
import { StandardService } from "../services/standard.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import {
  StandardTypeEnum,
  FilteringEnum,
  CategoryEnum,
} from "../core/constant";
import { IdNameModel } from "../models/id-name-model";
import { Standard } from "../models/standard";
import { CryptographyService } from "../services/cryptography.service";
import { SelectedStandard } from "../models/selected-standard";

@Component({
  selector: "app-wishlist-equipment-detail-view",
  templateUrl: "./wishlist-equipment-detail-view.component.html",
  styleUrls: ["./wishlist-equipment-detail-view.component.scss"],
})
export class WishlistEquipmentDetailViewComponent implements OnInit {
  loading: boolean = false;
  existGrs: SelectedStandard = new SelectedStandard();
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
  };
  grsId: number = 0;
  grsDetail: SelectedStandard = new SelectedStandard();
  grsDetailChild: SelectedStandard = new SelectedStandard();
  error: string = "";
  userRole: number = 2;
  equipmentRelatedStandards: IdNameModel[] = [];
  isCommingFromEquipments: boolean = false;
  get standardTypeEnum() {
    return StandardTypeEnum;
  }

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private router: Router,
    private cryptographyService: CryptographyService
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
  }

  setBreadCrumb(): boolean {
    if (this.standardService.grsFiltration.filterSet) {
      if (
        this.standardService.grsFiltration.filterSet.find(
          (x) =>
            x.type == FilteringEnum.FilterByCategory &&
            x.ids.filter((o) => o == CategoryEnum.Equipment) != null
        )
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  getDetailByGrbesId(grsId: number, isNestedGrs: boolean = false) {
    this.loading = true;
    this.standardService
      .getFutureStandardDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.grsFiltration.filterSet
      )
      .subscribe((data: SelectedStandard) => {
        if (isNestedGrs) {
          this.grsDetailChild = new SelectedStandard(data);
          let grsID = this.grsDetail.standard.id;
          this.grsDetail.standard = new Standard(this.grsDetailChild.standard);
          this.grsDetail.standard.id = grsID;
        } else {
          this.existGrs = new SelectedStandard(data);
          this.grsDetail = new SelectedStandard(data);
          this.grsDetailChild = new SelectedStandard(data);
        }
        this.loading = false;
        (error: string) => {
          this.error = error;
          this.loading = false;
        };
        this.isCommingFromEquipments = this.setBreadCrumb();
      });
  }
  navigateAccToType(type, id) {
    if (type == 4) {
      this.router.navigate(["/view-minimum-standard-detail/" + id]);
      return;
    }
    if (type == 8) {
      this.router.navigate(["/operations-standards-detail-view/" + id]);
      return;
    }
    if (type == 0) {
      this.router.navigate(["/view-equipments-standard-detail/" + id]);
      return;
    }
    if (type == 1 || type == 2) {
      this.router.navigate(["/global-recommendations-detail-view/" + id]);
      return;
    }
  }
  slide(grsID: number, isForward: boolean) {
    this.loading = true;
    if (this.userRole != 2) {
      this.standardService
        .getNextPreviousFutureStandardInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.grsFiltration.filterSet
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate(["/wishlist-detail-view/" + data.standard.id]);
          }
          this.grsDetail = new SelectedStandard(data);
          this.existGrs = new SelectedStandard(data);
          this.grsDetailChild = new SelectedStandard(data);
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    } else {
      this.standardService
        .getNextPreviousFutureStandardInfomationByCurrentStandardId(
          grsID,
          isForward,
          this.cryptographyService.decryptData(this.storage.get("countryId")),
          this.standardService.grsFiltration.filterSet
        )
        .subscribe((data: SelectedStandard) => {
          if (
            data.standard.standardTypeId !=
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            this.router.navigate(["/wishlist-detail-view/" + data.standard.id]);
          }
          this.grsDetail = new SelectedStandard(data);
          this.existGrs = new SelectedStandard(data);
          this.grsDetailChild = new SelectedStandard(data);
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
        });
    }
  }

  onDownloadSelectedEquipmentAttachment(e) {
    fetch(e.attachmentContent ? e.mime + e.attachmentContent : e.image)
      .then((res) => res.blob())
      .then((blob) => {
        if ((window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveBlob(blob, e.fileName);
        } else {
          var a = document.createElement("a");
          a.href = e.attachmentContent
            ? e.mime + e.attachmentContent
            : e.attachmentContentAsBase64;
          a.setAttribute("download", e.fileName);
          a.click();
        }
      });
  }
}
