import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "../services/admin.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { User } from "../models/user";
import { Country } from "../models/country";
import { UserRole } from "../models/user-role";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { AOWGroup } from "../models/aowgroup";
import { UserRoleEnum } from "../core/constant";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  error: string = "";
  modalOption: NgbModalOptions = {};
  get userRoleEnum() {
    return UserRoleEnum;
  }

  countries: Country[] = [];
  userRoles: UserRole[] = [];
  aowGroups: AOWGroup[] = [];

  loading: boolean = false;
  user: User = new User();
  isEmailAllowed: boolean = true;

  addEditUserForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.user.id = parseInt(this.activeRoute.snapshot.paramMap.get("userId"));
  }

  ngOnInit() {
    this.getMasterData();
    if (this.user.id) {
      this.getUserByUserId(this.user.id);
    }
    this.createForm();
  }

  createForm(): void {
    this.addEditUserForm = this.fb.group({
      name: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      userRoleId: ["", Validators.required],
      countryId: ["", Validators.required],
      isNotificationEnable: [false],
      aowGroupId: [""],
    });
  }

  getMasterData() {
    this.adminService.getMasterDataForAddEditUser().subscribe((data: any) => {
      this.userRoles = data.userRoles
        ? data.userRoles.map((userRole) => new UserRole(userRole))
        : null;
      this.countries = data.countries
        ? data.countries.map((country) => new Country(country))
        : null;
      this.aowGroups = data.aowGroups
        ? data.aowGroups.map((aowGroup) => new AOWGroup(aowGroup))
        : null;
      this.loading = false;
      (error: string) => {
        this.error = error;
        const modalRef = this.ngbModal.open(FailModuleComponent);
        this.error = error;
        modalRef.componentInstance.errorMessage = error;
        this.loading = false;
      };
    });
  }

  getUserByUserId(userId: number) {
    this.loading = true;
    this.adminService.GetUserDetailsById(userId).subscribe((data: User) => {
      this.user = new User(data);
      this.setControlsValue(this.user);
      this.loading = false;
      (error: string) => {
        this.error = error;
        const modalRef = this.ngbModal.open(FailModuleComponent);
        this.error = error;
        modalRef.componentInstance.errorMessage = error;
        this.loading = false;
      };
    });
  }

  setControlsValue(user: User) {
    if (user.id) {
      this.addEditUserForm.controls.name.setValue(user.name);
      this.addEditUserForm.controls.lastName.setValue(user.lastName);
      this.addEditUserForm.controls.email.setValue(user.email);
      this.addEditUserForm.controls.userRoleId.setValue(user.userRole.id);
      this.addEditUserForm.controls.countryId.setValue(user.country.id);
      this.addEditUserForm.controls.isNotificationEnable.setValue(
        user.isNotificationEnable
      );
      if (user.userRoleId == this.userRoleEnum.AOWAdmin) {
        this.addEditUserForm.controls.aowGroupId.setValue(
          user.aowGroupUserMapping.aowGroupId
        );
      }
    }
  }

  onReset() {
    this.createForm();
  }

  onSaveUser() {
    this.checkIsEmailAllowed(
      this.addEditUserForm.controls.email.value,
      this.user.id
    ).subscribe((data) => {
      this.isEmailAllowed = data;

      if (this.isEmailAllowed) {
        this.loading = true;
        this.user.name = this.addEditUserForm.controls.name.value;
        this.user.lastName = this.addEditUserForm.controls.lastName.value;
        this.user.email = this.addEditUserForm.controls.email.value;
        this.user.countryId = this.addEditUserForm.controls.countryId.value;
        this.user.userRoleId = this.addEditUserForm.controls.userRoleId.value;
        this.user.isNotificationEnable =
          this.addEditUserForm.controls.isNotificationEnable.value;
        if (this.user.userRoleId == this.userRoleEnum.AOWAdmin) {
          this.user.aowGroupId = this.addEditUserForm.controls.aowGroupId.value;
        }
        if (this.user.id) {
          this.user.modifiedUserId = this.cryptographyService.decryptData(
            this.storage.get("userId")
          );
          this.updateUser(this.user).subscribe((data) => {
            const modalRef = this.ngbModal.open(SuccessDialogComponent);
            modalRef.componentInstance.message =
              "You have successfully updated the user.";
            if (data == false) {
              modalRef.componentInstance.title = "Error";
              modalRef.componentInstance.message =
                "Some error occurred. Please try again after some time";
            }
            if (data.status == false) {
              modalRef.componentInstance.title = "Error";
            }
            modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
              this.ngbModal.dismissAll();
              this.onReset();
              if (data) {
                this.router.navigate(["/view-all-user-list"]);
              }
            });
          });
        } else {
          this.user.createdUserId = this.cryptographyService.decryptData(
            this.storage.get("userId")
          );
          this.addUser(this.user).subscribe((data) => {
            const modalRef = this.ngbModal.open(SuccessDialogComponent);
            modalRef.componentInstance.message =
              "You have successfully added the user.";
            if (data == false) {
              modalRef.componentInstance.title = "Error";
              modalRef.componentInstance.message =
                "Some error occurred. Please try again after some time";
            }
            if (data.status == false) {
              modalRef.componentInstance.title = "Error";
            }
            modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
              this.ngbModal.dismissAll();
              this.onReset();
              if (data) {
                this.router.navigate(["/view-all-user-list"]);
              }
            });
          });
        }
      } else {
        this.addEditUserForm.controls.email.markAsTouched();
        this.addEditUserForm.controls.email.setErrors({
          error: "This email is taken,try another",
        });
      }
    });
  }

  addUser(user: User): Observable<any> {
    this.loading = true;
    user.id = 0;
    return new Observable<any>((observer) => {
      this.adminService.AddUser(user).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  updateUser(user: User): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.adminService.UpdateUser(user).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  onCancelClick() {
    this.router.navigate(["/view-all-user-list"]);
  }

  getEmailValidationErrorMessage() {
    return this.addEditUserForm.get("email").hasError("required")
      ? "Email is required"
      : this.addEditUserForm.get("email").hasError("email")
      ? "Not a valid email"
      : this.addEditUserForm.get("email").hasError("pattern")
      ? "Not a valid email"
      : "";
  }

  onRoleChange(roleId: number) {
    if (roleId == this.userRoleEnum.AOWAdmin) {
      this.addEditUserForm.controls.aowGroupId.setValidators(
        Validators.required
      );
      this.addEditUserForm.controls.aowGroupId.updateValueAndValidity();
    } else {
      this.addEditUserForm.controls.aowGroupId.setValidators(null);
      this.addEditUserForm.controls.aowGroupId.updateValueAndValidity();
    }
  }

  checkIsEmailAllowed(email: string, userId?: number): Observable<any> {
    {
      return new Observable<any>((observer) => {
        this.adminService
          .isEmailAllowed(email, userId ? userId : null)
          .subscribe(
            (data: any) => {
              this.loading = false;
              observer.next(data);
            },
            (error) => {
              this.loading = false;
              observer.next(false);
            }
          );
      });
    }
  }
}
