import { Hashtag } from "../interfaces/Hashtag";

export class QuestionAndAnswer {
  id: number;
  title: string;
  question: string;
  answer: string;
  hashtags: Hashtag[] = [];
  hashtag: string;

  constructor(obj?: QuestionAndAnswer) {
    if (obj) {
      this.id = obj.id;
      this.title = obj.title;
      this.question = obj.question;
      this.answer = obj.answer

      //Brackdown concatenated string to hashtags
      if (obj.id && obj.hashtag) {
        let hashArray = obj.hashtag.split(",");
        if (hashArray.length > 1) {
          obj.hashtag.split(",").forEach(element => { this.hashtags.push({ name: element.trim() }) });
        }
        else {
          this.hashtags.push({ name: obj.hashtag.trim() });
        }
      } else {
        this.hashtags = obj.hashtags?obj.hashtags : [];
      }
      
      //For save FAQ with concatenated hashtags
      this.hashtag = obj.hashtag ? obj.hashtag : this.hashtags? this.hashtags.map(s => s.name).join(","): '';
    }
  }
}
