<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View Supplier Details</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li><a routerLink="/global-admin-panel" routerLinkActive="active">Global Admin Panel</a></li>
          <li class="separator">></li>
          <li><a routerLink="/view-all-supplier-list" routerLinkActive="active">Manage Suppliers</a></li>
          <li class="separator">></li>
          <li class="active">{{supplier.name}}</li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="col-4 image-wrapper">
        <h1 class="d-md-none d-none">{{supplier.name}}</h1>
        <div class="mcd-equipment-image">
          <img [src]="supplier?.supplierLogoAttachment?.image">
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div>
          <h2>Name</h2>
          <h1>{{supplier.name}}</h1>
          <div class="grbes-description">
            <h2>Description</h2>
            <p>{{supplier.description}}</p>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
