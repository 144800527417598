export class AOWGroup {
    id: number;
    groupName: string;
    constructor(obj?: AOWGroup) {
      if (obj) {
        this.id = obj.id;
        this.groupName = obj.groupName;
      }
    }
}
