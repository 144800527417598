import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  HostListener,
} from "@angular/core";
import { NotificationService } from "../services/notification.service";
import { UserService } from "../services/user.service";
import { NotificationModel } from "../models/notification-model.model";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Router } from "@angular/router";
import { Constant, UserRoleEnum } from "../core/constant";
import { EventEmitterService } from "../services/event-emitter.service";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment";
declare var $: any;
import { CryptographyService } from "../services/cryptography.service";
import { AddendumService } from "../services/addendum.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  loading: boolean = false;
  userRole: number = 2; // 1 -> Global Admin , 2 -> Market Admin
  private canReceiveMessage: boolean = false;
  public notificationHistory: NotificationModel[] =
    new Array<NotificationModel>();
  public notificationViewHistory: NotificationModel[] =
    new Array<NotificationModel>();
  public notificationUnseenHistory: NotificationModel[] =
    new Array<NotificationModel>();
  showNotification: boolean;

  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private notificationHubService: NotificationService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private oauthService: OAuthService,
    private router: Router,
    private cryptographyService: CryptographyService,
    private eventEmitterService: EventEmitterService,
    private addendumService: AddendumService
  ) {}

  userName: string;
  ngOnInit() {
    this.userRole = this.storage.get("userRole")
      ? this.cryptographyService.decryptData(this.storage.get("userRole"))
      : this.storage.get("userRole");

    let notificationGroup = this.storage.get("notificationGroup")
      ? this.cryptographyService.decryptData(
          this.storage.get("notificationGroup")
        )
      : this.storage.get("notificationGroup");
    this.showNotification = this.userRole != this.userRoleEnum.BasicUser;
    this.userName = this.storage.get("userName");

    if (notificationGroup) {
      this.getAlreadyReservedUserNotifications(notificationGroup);
      this.subscribeToEvents();
      if (this.eventEmitterService.subsVar == undefined) {
        this.eventEmitterService.subsVar =
          this.eventEmitterService.invokeGetAlreadyReservedUserNotificationsEmit.subscribe(
            () => {
              this.getAlreadyReservedUserNotifications(notificationGroup);
            }
          );
      }
    }

    $(document).on("focusout", ".mcd-notify-icon", function () {
      setTimeout(() => {
        $(".dropdownMenu").slideUp();
      }, 50);
    });

    $(document).on("click", ".mcd-notify-icon", () => {
      $(".dropdownMenu").slideToggle();
    });
  }

  /**
   * Subscribe to receive notification.
   * @return void
   */
  private subscribeToEvents(): void {
    this.notificationHubService.connectionEstablished.subscribe(() => {
      this.canReceiveMessage = true;
      //Get NotificationGroups By Email

      if (this.userRole == this.userRoleEnum.MarketAdmin) {
        let notificationGroup = this.cryptographyService.decryptData(
          this.storage.get("notificationGroup")
        );
        this.joingToNotificationGroup(notificationGroup);
      } else if (this.userRole == this.userRoleEnum.GlobalAdmin) {
        this.joingToNotificationGroup(Constant.GlobalNotificationGroupName);
      }
    });
    this.notificationHubService.notificationReceived.subscribe(
      (notification: NotificationModel) => {
        if (notification) {
          //debugger;
          if (this.notificationViewHistory.length >= 5) {
            this.notificationViewHistory.splice(4, 1);
          }
          this.notificationViewHistory.unshift(notification);
          this.notificationHistory.unshift(notification);
          this.notificationUnseenHistory.unshift(notification);
        }
      }
    );
  }

  /**
   * Joing to notification group;
   * @param {string} groupId The id of the notification group to invoke.
   * @return void
   */
  private joingToNotificationGroup(groupId: string) {
    if (this.canReceiveMessage) {
      this.notificationHubService.joingToNotificationGroup(groupId);
    }
  }

  clearStorageAndCookiesAndInitLogout() {
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }

    if (window.localStorage) {
      window.localStorage.clear();
    }

    document.cookie.split(";").forEach(function (cookie) {
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });

    this.oauthService.logOut();
  }

  signOut() {
    this.clearStorageAndCookiesAndInitLogout();
  }

  /**
   * Get Already Reserved User Notifications;
   * @param {string} email User email.
   * @return void
   */
  public getAlreadyReservedUserNotifications(notificationGroup: string) {
    this.loading = true;
    this.notificationHubService
      .getAlreadyReservedUserNotifications(notificationGroup)
      .subscribe((data) => {
        this.notificationHistory = [];
        this.notificationViewHistory = [];
        this.notificationUnseenHistory = [];
        data.forEach((item) => {
          this.notificationHistory.push(new NotificationModel(item));
        });
        this.notificationViewHistory = this.notificationHistory.map(
          (o) => new NotificationModel(o)
        );
        this.notificationViewHistory = this.notificationViewHistory.splice(
          0,
          5
        );
        this.notificationUnseenHistory = this.notificationHistory.filter(
          (obj) => obj.isSeen == false
        );
        this.loading = false;
      });
  }

  rowSelected(item: NotificationModel) {
    item.isSeen = true;
    this.removePopOver();
    this.notificationHubService.UpdateNotification(item).subscribe(() => {
      let notificationGroup = this.cryptographyService.decryptData(
        this.storage.get("notificationGroup")
      );
      this.getAlreadyReservedUserNotifications(notificationGroup);
    });
    this.router.navigate(["/addendum/" + item.addendumId]);
  }

  removePopOver() {
    $(".dropdownMenu").slideUp();
  }
}
