<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Notifications</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="row">
      <div class="col-6">
        <mat-form-field>
          <mat-select [value]="pageSize" name="nOfRows" #items (selectionChange)="selectedItemsPerPageChange(items)">
            <mat-option [value]="item.value" *ngFor="let item of pageSizes">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="mcd-notifications-wrapper" *ngIf="notificationHistory.length > 0">
      <table>
        <tr *ngFor="let item of notificationHistory" (click)="rowSelected(item); addendumService.isCommingFromNotification = true">
          <td>
            <ul>
              <li>
                <a [ngClass]="item.isSeen? '': 'mcd-notifications-active'" class="clearfix" href="javascript:void(0);">
                  <div class="mcd-notify-avatar">{{item.avatarLetters}}</div>
                  <div class="mcd-notifiy-details">
                    <div class="mcd-notify-title">
                      <span><strong>{{item.message}}</strong></span>
                      <span class="mcd-notify-date float-right">{{item.date | date:'d MMM y'}}</span>
                    </div>
                    <p class="mcd-notify-description">{{item.addendumDescription}}</p>
                    <div class="mcd-notify-user">
                      <span><strong>{{item.notifiedUser}}</strong></span>
                      <small class="mcd-notify-designation float-right">{{item.notifiedUserRole}}</small>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <div class="row" *ngIf="notificationHistory.length > 0">
        <div class="col">
          <ngb-pagination class="float-right" [(page)]="page" [pageSize]="pageSize" [maxSize]="4" [rotate]="true"
            [ellipses]="true" [collectionSize]="collectionSize" (pageChange)="loadNotificationsForPageChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="notificationHistory.length == 0">
      Sorry you do not have any notification.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>