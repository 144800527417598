import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
// import { RequestOptions, Request, RequestMethod } from "@angular/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { BasicInformation } from "../models/basicInformation";
// import { AssertNotNull } from '@angular/compiler';

@Injectable({
  providedIn: "root",
})
export class BasicInformationService {
  constructor(private http: HttpClient) {}

  getAllBasicInformation() {
    return this.http.get("/api/BasicInformation/GetAllBasicInformation");
  }

  getBasicInformation(id: number) {
    return this.http.get<BasicInformation>(
      "/api/BasicInformation/GetBasicInformation/" + id
    );
  }

  createBasicInformation(createBasicInformation: any) {
    return this.http.post(
      "/api/BasicInformation/CreateBasicInformation",
      createBasicInformation
    );
  }

  updateBasicInformation(updateBasicInfo: any) {
    return this.http.post(
      "/api/BasicInformation/UpdateBasicInformation",
      updateBasicInfo
    );
  }
}
