export class SubLocationType {
    id: number;
  name: string;
  locationTypeId: number;
    constructor(obj?: SubLocationType) {
      if (obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.locationTypeId = obj.locationTypeId;
      }
    }
}
