<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">View All Standard</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li>
          <a routerLink="/locations" routerLinkActive="active">Locations</a>
        </li>
        <li class="separator">></li>
        <li class="active">{{ locationName }}</li>
      </ul>
    </div>
  </div>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col filter-panel">
        <app-view-all-mnimum-sandard-list-filter
          [isFromLocation]="isFromLocation"
          (messageEvent)="receivedFilteration($event)"
        ></app-view-all-mnimum-sandard-list-filter>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons"> expand_less </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>

    <div class="table-wrapper">
      <div class="row">
        <div class="col-6">
          <div *ngIf="grses.length > 0">
            <mat-form-field>
              <mat-select
                [value]="pageSize"
                name="nOfRows"
                #items
                (selectionChange)="selectedItemsPerPageChange(items)"
              >
                <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                  {{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6 text-right">
          <mat-form-field>
            <input
              matInput
              placeholder="Search by Title or Description"
              value="{{ searchKey }}"
              (keyup.enter)="addSearchKey(key)"
              name="search"
              #key
            />
            <button
              mat-button
              *ngIf="searchKey"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchKey = ''; addSearchKey(searchKey)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <table
        class="table nrbes-grid"
        *ngIf="grses.length > 0"
        matSort
        (matSortChange)="sortData($event)"
      >
        <thead class="thead-light">
          <tr>
            <th width="180" scope="col" mat-sort-header="title">Title</th>
            <th scope="col" width="110">Location</th>
            <th width="140" mat-sort-header="category" scope="col-2">
              Category
            </th>
            <th scope="col-4" mat-sort-header="desc " width="30%">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of grses" (click)="rowSelected(item)">
            <td class="title-cell">{{ item.title }}</td>
            <td class="location-cell">{{ getLocationNames(item) }}</td>
            <td class="category-cell">{{ item.category.name }}</td>
            <td class="description">
              <span title="{{ item.shortDescription }}" class="description">
                {{ item.shortDescription }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row" *ngIf="grses.length > 0">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getStandards()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="grses.length == 0 && !searchKey">
      Sorry you do not have any minimum standard.
    </div>
    <div *ngIf="grses.length == 0 && searchKey">
      Your search -"{{ searchKey }}" - did not match any standard.<a
        href="javascript:void(0);"
        (click)="addSearchKey(''); getStandards()"
        ><span> Go Back</span></a
      >
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
