import { NgModule } from "@angular/core";
import { Router, RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { FaqModuleComponent } from "./faq-module/faq-module.component";
import { KeyContactsComponent } from "./key-contacts/key-contacts.component";
import { NrbesListComponent } from "./nrbes-list/nrbes-list.component";
import { AddendumListComponent } from "./addendum-list/addendum-list.component";
import { AddendumComponent } from "./addendum/addendum.component";
import { EditNrbesComponent } from "./edit-nrbes/edit-nrbes.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { MinimumStandardListComponent } from "./view-all-minimum-standard-list/view-all-minimum-standard-list.component";
import { GlobalRecomendationsListComponent } from "./view-all-global-recomentations-list/view-all-global-recomendations-list.component";
import { AddGrsComponent } from "./add-grs/add-grs.component";
import { NrbesDetailViewComponent } from "./nrbes-detail-view/nrbes-detail-view.component";
import { AddNrbesComponent } from "./add-nrbes/add-nrbes.component";
import { AddAbuStandardComponent } from "./add-abustandard/add-abustandard.component";
import { NotificationListComponent } from "./notification-list/notification-list.component";
import { McdLocationModuleComponent } from "./mcd-location-module/mcd-location-module.component";
import { AuthGuardService } from "./services/auth.guard.service";
import { AddAddendumComponent } from "./add-addendum/add-addendum.component";
import { RoleGuardService } from "./services/role.guard.service";
import { UserRoleUserOperation } from "./core/constant";
import { NotificationsComponent } from "./notifications/notifications.component";
import { LeftNavComponent } from "./left-nav/left-nav.component";
import { AddEquipmentComponent } from "./add-equipment/add-equipment.component";
import { EquipmentListComponent } from "./equipment-list/equipment-list.component";
import { EquipmentDetailViewComponent } from "./equipment-detail-view/equipment-detail-view.component";
import { LocationsComponent } from "./locations/locations.component";
import { LocationGrsListComponent } from "./location-grs-list/location-grs-list.component";
import { WishListGridComponent } from "./wishlist-grid/wishlist-grid.component";
import { WishlistDetailViewComponent } from "./wishlist-detail-view/wishlist-detail-view.component";
import { WishlistEquipmentDetailViewComponent } from "./wishlist-equipment-detail-view/wishlist-equipment-detail-view.component";
import { AddEquipmentStandardComponent } from "./add-equipment-standard/add-equipment-standard.component";

import { EctComponent } from "./ect/ect.component";
import { ViewAllEquipmentStandardListComponent } from "./view-all-equipment-standard-list/view-all-equipment-standard-list.component";
import { AddEquipmentStandardGlobalComponent } from "./add-equipment-standard-global/add-equipment-standard-global.component";
import { MinimumStandardDetailViewComponent } from "./view-all-minimum-standard-detail-view/view-all-minimum-standard-detail-view.component";
import { GlobalRecomendationsDetailViewComponent } from "./view-all-global-recomendations-detail-view/view-all-global-recomendations-detail-view.component";
import { ViewAllUpcomingStandardListComponent } from "./view-all-upcoming-standard-list/view-all-upcoming-standard-list.component";
import { ViewAllObsoleteStandardListComponent } from "./view-all-obsolete-standard-list/view-all-obsolete-standard-list.component";
import { ViewAllNationalEquipmentStandardListComponent } from "./view-all-national-equipment-standard-list/view-all-national-equipment-standard-list.component";
import { ViewAllEquipmentsStandardDetailViewComponent } from "./view-all-equipments-standard-detail-view/view-all-equipments-standard-detail-view.component";
import { ViewAllObsoleteStandardEquipmentDetailViewComponent } from "./view-all-obsolete-standard-list-detail-view/view-all-obsolete-standard-detail-view.component";
import { ViewAllNationalEquipmentsStandardDetailViewComponent } from "./view-all-national-equipment-standard-detail-view/view-all-national-equipments-standard-detail-view.component";
import { ViewAllUpcomingStandardDetailViewComponent } from "./view-all-upcoming-standard-detail-view/view-all-upcoming-standard-detail-view.component";
import { ViewAllUpcomingEquipmentDetailViewComponent } from "./view-all-upcoming-equipment-detail-view/view-all-upcoming-equipment-detail-view.component";
import { ViewAllStandardDetailsViewComponent } from "./view-all-standard-details-view/view-all-standard-details-view.component";
import { AddEquipmentAddendumComponent } from "./add-equipment-addendum/add-equipment-addendum.component";
import { EquipmentAddendumListComponent } from "./equipment-addendum-list/equipment-addendum-listcomponent";
import { MarketAdminPanelComponent } from "./market-admin-panel/market-admin-panel.component";
import { GlobalAdminPanelComponent } from "./global-admin-panel/global-admin-panel.component";
import { ManageSuppliersComponent } from "./manage-suppliers/manage-suppliers.component";
import { ViewAllSupplierListComponent } from "./view-all-supplier-list/view-all-supplier-list.component";
import { AddSupplierComponent } from "./add-supplier/add-supplier.component";
import { AddEquipmentTypeComponent } from "./add-equipment-type/add-equipment-type.component";
import { AddCategoryComponent } from "./add-category/add-category.component";
import { ViewAllEquipmentTypeListComponent } from "./view-all-equipment-type-list/view-all-equipment-type-list.component";
import { ViewAllCategoryListComponent } from "./view-all-category-list/view-all-category-list.component";
import { ViewAllUserListComponent } from "./view-all-user-list/view-all-user-list.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { UserDetailViewComponent } from "./user-detail-view/user-detail-view.component";
import { SupplierDetailViewComponent } from "./supplier-detail-view/supplier-detail-view.component";
import { ViewAllNationalSupplierListComponent } from "./view-all-national-supplier-list/view-all-national-supplier-list.component";
import { AddNationalSupplierComponent } from "./add-national-supplier/add-national-supplier.component";
import { NationalSupplierDetailViewComponent } from "./national-supplier-detail-view/national-supplier-detail-view.component";
import { ViewOperationsStandardListComponent } from "./view-all-operations-standards-list/view-all-operations-standards-list.component";
import { ViewAllOperationsStandardsDetailViewComponent } from "./view-all-operations-standards-detail-view/view-all-operations-standards-detail-view.component";
import { AddUsefulLinkComponent } from "./add-useful-link/add-useful-link.component";
import { ViewAllUsefulLinksComponent } from "./view-all-useful-links/view-all-useful-links.component";
import { UpdateApplicationStatementComponent } from "./update-application-statement/update-application-statement.component";
import { AllStandardsComponent } from "./view-all-standards/view-all-standards.component";
import { CloneNrbesComponent } from "./clone-nrbes/clone-nrbes.component";
import { UpdateAddEndumComponent } from "./update-addendum/update-addendum.component";
import { RunViewScript } from "./run-view-script/run-view-script.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "/",
    pathMatch: "full",
  },
  {
    path: "",
    component: HomeComponent,
    children: [
      { path: "", redirectTo: "locations", pathMatch: "full" },
      {
        path: "faq",
        component: FaqModuleComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ViewFaq },
      },
      {
        path: "keycontact",
        component: KeyContactsComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewKeyContacts,
        },
      },
      {
        path: "nrbeslist",
        component: NrbesListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllNrbesStandard,
        },
      },
      {
        path: "nrbes-view/:nrbesId",
        component: NrbesDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllNrbesStandard,
        },
      },
      {
        path: "addendum-list",
        component: AddendumListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllAddendum,
        },
      },
      {
        path: "addendum/:addendumId",
        component: AddendumComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllAddendum,
        },
      },
      {
        path: "edit-nrbes/:standardid",
        component: EditNrbesComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanUpdateNrbes,
        },
      },
      {
        path: "clone-nrbes/:standardid",
        component: CloneNrbesComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanUpdateNrbes,
        },
      },
      {
        path: "carousel",
        component: CarouselComponent,
      },
      {
        path: "add-nrbes",
        component: AddNrbesComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddNewNrbes,
        },
      },
      {
        path: "add-abustandard",
        component: AddAbuStandardComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddABUStandard,
        },
      },
      {
        path: "view-all-standards",
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
        },
        component: AllStandardsComponent,
      },
      // {
      //   path: "view-all-minimum-standard",
      //   component: MinimumStandardListComponent,
      //   canActivate: [AuthGuardService, RoleGuardService],
      //   data: {
      //     userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
      //   },
      // },
      // {
      //   path: "view-all-global-recommendations",
      //   component: GlobalRecomendationsListComponent,
      //   canActivate: [AuthGuardService, RoleGuardService],
      //   data: {
      //     userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
      //   },
      // },
      // {
      //   path: "view-all-global-recommendations",
      //   component: GlobalRecomendationsDetailViewComponent,
      //   canActivate: [AuthGuardService, RoleGuardService],
      //   data: {
      //     userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
      //   },
      // },
      {
        path: "nrbes-view",
        component: NrbesDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllNrbesStandard,
        },
      },
      {
        path: "add-grs",
        component: AddGrsComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.CanAddNewGrs },
      },
      {
        path: "add-equipment",
        component: AddEquipmentComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ShowAddNewEquipment,
        },
      },
      {
        path: "add-grs/:grsId",
        component: AddGrsComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.CanEditGrs },
      },
      {
        path: "minimum-standard-detail-view/:grsId",
        component: MinimumStandardDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
        },
      },
      {
        path: "global-recommendations-detail-view/:grsId",
        component: GlobalRecomendationsDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
        },
      },
      {
        path: "add-addendum/:standardId",
        component: AddAddendumComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddAddendum,
        },
      },
      {
        path: "update-addendum/:standardId",
        component: UpdateAddEndumComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddAddendum,
        },
      },
      {
        path: "run/view-script",
        component: RunViewScript,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddAddendum,
        },
      },
      {
        path: "add-equipment-addendum/:standardId",
        component: AddEquipmentAddendumComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddAddendum,
        },
      },
      {
        path: "notification-list",
        component: NotificationListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllNotification,
        },
      },
      {
        path: "equipment-detail-view/:grsId",
        component: EquipmentDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
        },
      },
      {
        path: "equipment-list/:locationId",
        component: EquipmentListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "left-nav",
        component: LeftNavComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ViewFaq },
      },
      {
        path: "locations",
        component: LocationsComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "location-grs-list/:locationId",
        component: LocationGrsListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "wishlist-list",
        component: WishListGridComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "wishlist-detail-view/:grsId",
        component: WishlistDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "wishlist-equipment-detail-view/:grsId",
        component: WishlistEquipmentDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "etc",
        component: EctComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "add-eq-standards",
        component: AddEquipmentStandardComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "add-eq-standards/:standardId",
        component: AddEquipmentStandardComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "add-eq-standards-global",
        component: AddEquipmentStandardGlobalComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "edit-eq-standards-global/:standardId",
        component: AddEquipmentStandardGlobalComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      // {
      //   path: "view-equipments-standard-list",
      //   component: ViewAllEquipmentStandardListComponent,
      //   canActivate: [AuthGuardService, RoleGuardService],
      //   data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      // },
      {
        path: "view-all-upcoming-standards-list",
        component: ViewAllUpcomingStandardListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-all-obsolete-standards-list",
        component: ViewAllObsoleteStandardListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-all-national-equipment-standard-list",
        component: ViewAllNationalEquipmentStandardListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-obsolete-equipments-standard-detail/:grsId",
        component: ViewAllObsoleteStandardEquipmentDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-equipments-standard-detail/:grsId",
        component: ViewAllEquipmentsStandardDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-national-equipments-standard-detail/:grsId",
        component: ViewAllNationalEquipmentsStandardDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-upcoming-standard-detail/:grsId",
        component: ViewAllUpcomingStandardDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-upcoming-equipments-standard-detail/:grsId",
        component: ViewAllUpcomingEquipmentDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "view-standard-detail/:grsId",
        component: ViewAllStandardDetailsViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ShowLocation },
      },
      {
        path: "equipment-addendum-list",
        component: EquipmentAddendumListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllAddendum,
        },
      },
      {
        path: "market-admin-panel",
        component: MarketAdminPanelComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewMarketAdminPanel,
        },
      },
      {
        path: "global-admin-panel",
        component: GlobalAdminPanelComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewGlobalAdminPanel,
        },
      },
      {
        path: "manage-suppliers",
        component: ManageSuppliersComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewManageSuppliers,
        },
      },
      {
        path: "view-all-supplier-list",
        component: ViewAllSupplierListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllSuppliers,
        },
      },
      {
        path: "add-supplier",
        component: AddSupplierComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddSupplier,
        },
      },
      {
        path: "add-supplier/:supplierId",
        component: AddSupplierComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanEditSupplier,
        },
      },
      {
        path: "supplier-detail-view/:supplierId",
        component: SupplierDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewSupplierDetail,
        },
      },
      {
        path: "view-all-equipment-type-list",
        component: ViewAllEquipmentTypeListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllEquipmentType,
        },
      },
      {
        path: "view-all-category-list",
        component: ViewAllCategoryListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllCategory,
        },
      },
      {
        path: "add-equipment-type",
        component: AddEquipmentTypeComponent,
        canActivate: [AuthGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddEquipmentType,
        },
      },
      {
        path: "add-category",
        component: AddCategoryComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddCategory,
        },
      },
      {
        path: "add-equipment-type/:equipmentTypeId",
        component: AddEquipmentTypeComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanEditEquipmentType,
        },
      },
      {
        path: "add-category/:categoryId",
        component: AddCategoryComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanEditCategory,
        },
      },
      {
        path: "view-all-user-list",
        component: ViewAllUserListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.ViewAllUser },
      },
      {
        path: "add-user",
        component: AddUserComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.CanAddUser },
      },
      {
        path: "add-user/:userId",
        component: AddUserComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: { userRoleUserOperationId: +UserRoleUserOperation.CanEditUser },
      },
      {
        path: "user-detail-view/:userId",
        component: UserDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewUserDetail,
        },
      },
      {
        path: "view-all-national-supplier-list",
        component: ViewAllNationalSupplierListComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId:
            +UserRoleUserOperation.ViewAllNationalSuppliers,
        },
      },
      {
        path: "add-national-supplier",
        component: AddNationalSupplierComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId:
            +UserRoleUserOperation.CanAddNationalSupplier,
        },
      },
      {
        path: "add-national-supplier/:supplierId",
        component: AddNationalSupplierComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId:
            +UserRoleUserOperation.CanEditNationalSupplier,
        },
      },
      {
        path: "national-supplier-detail-view/:supplierId",
        component: NationalSupplierDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId:
            +UserRoleUserOperation.ViewNationalSupplierDetail,
        },
      },
      // {
      //   path: "view-all-operations-standards",
      //   component: ViewOperationsStandardListComponent,
      //   canActivate: [AuthGuardService, RoleGuardService],
      //   data: {
      //     userRoleUserOperationId:
      //       +UserRoleUserOperation.ViewOperationsStandard,
      //   },
      // },
      {
        path: "operations-standards-detail-view/:grsId",
        component: ViewAllOperationsStandardsDetailViewComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllGrsStandard,
        },
      },

      {
        path: "add-useful-link",
        component: AddUsefulLinkComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanAddUsefulLink,
        },
      },
      {
        path: "add-useful-link/:usefulLinkId",
        component: AddUsefulLinkComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.CanEditUsefulLink,
        },
      },
      {
        path: "view-all-useful-links",
        component: ViewAllUsefulLinksComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId: +UserRoleUserOperation.ViewAllUsefulLinks,
        },
      },
      {
        path: "update-application-statement",
        component: UpdateApplicationStatementComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        data: {
          userRoleUserOperationId:
            +UserRoleUserOperation.CanUpdateApplicationStatement,
        },
      },
    ],
  },
  { path: "unauthorized", component: UnauthorizedComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: "enabledNonBlocking" }),

    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
