import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EquipmentType } from "../models/equipment-type";
import { Equipment } from "../models/equipment";
import { Supplier } from "../models/supplier";
import { EquipmentStandard } from "../models/equipment-standard";
import { Country } from "../models/country";
import { EquipmentClassType } from "../models/equipment-class-type";
import { MasterData } from "../models/masterData";
@Injectable({
  providedIn: "root",
})
export class EquipmentStandardService {
  constructor(private http: HttpClient) {}

  addEquipmentStandard(equipmentStandard: EquipmentStandard) {
    return this.http.post(
      "/api/equipmentStandard/AddEquipmentStandard",
      equipmentStandard
    );
  }

  getMarketSuppliersByCountryId(countryId: number): Observable<Supplier[]> {
    return this.http.get<Supplier[]>(
      "/api/equipmentStandard/GetMarketSuppliersByCountryId/" + countryId
    );
  }

  getAllEquipmentTypes(): Observable<EquipmentType[]> {
    return this.http.get<EquipmentType[]>(
      "/api/equipmentStandard/GetAllEquipmentTypes"
    );
  }

  getAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>("/api/AddendumWorkflow/GetAllCountries");
  }

  getAllEquipmentClassTypes(): Observable<EquipmentClassType[]> {
    return this.http.get<EquipmentClassType[]>(
      "/api/equipmentStandard/GetAllEquipmentClassTypes"
    );
  }

  getEquipmentStandardByStandardId(standardId): Observable<EquipmentStandard> {
    let params = new HttpParams();
    params = params.append("standardId", standardId.toString());
    return this.http.get<EquipmentStandard>(
      "/api/equipmentStandard/GetEquipmentStandardById",
      { params: params }
    );
  }

  updateEquipmentStandard(equipmentStandard: EquipmentStandard) {
    return this.http.post(
      "/api/equipmentStandard/UpdateEquipmentStandard",
      equipmentStandard
    );
  }

  deleteEquipmentStandard(equipmentStandard: EquipmentStandard) {
    return this.http.post(
      "/api/equipmentStandard/DeleteEquipmentStandard",
      equipmentStandard
    );
  }
}
