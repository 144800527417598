<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Equipment Standard List</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li><a routerLink="/locations" routerLinkActive="active">Locations</a></li>
        <li class="separator">></li>
        <li class="active">{{locationName}}</li>
      </ul>
    </div>
  </div>
  <mat-card-content class="mcd-card-content">
    <div class="row filter-panel-row">
      <div class="col filter-panel">
        <app-equipment-list-filter (messageEvent)="receivedFilteration($event)"></app-equipment-list-filter>
      </div>
      <div class="collpsible-panel">
        <button class="btn-collapse" mat-icon-button aria-label="Collapse">
          <i class="material-icons">
            expand_less
          </i>
        </button>
        <button class="btn-expand" mat-icon-button aria-label="Expand">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="row">
        <div *ngIf="standards.length > 0" class="col-md-12">
          <div>
            <div class="row">
              <div class="col-md-3 col-sm-6 list-card-wrapper" *ngFor="let item of standards">
                <div class="card mcd-equipment-card">
                  <img class="card-img-top" [src]="item?.equipmentStandard.imageAttachment?.image" alt="Card image cap">
                  <div class="card-body">
                    <div class="card-body-inner">
                      <h5 class="card-title">{{item.title}}</h5>
                      <p class="card-text">{{item.equipmentStandard.model}}</p>
                      <p class="card-text">{{item.equipmentStandard.equipmentType.name}}</p>
                      <p class="card-text">{{item.locationType.name}}</p>
                      <p class="card-text">Effective Date - {{item.effectiveFrom | date: 'MM.dd.yyyy' }}</p>
                      <div class="footer-item">
                        <div class="row">
                          <div class="col">
                            <img *ngIf="item?.equipmentStandard?.supplier?.supplierLogoAttachment?.image" 
                              [src]="item?.equipmentStandard?.supplier?.supplierLogoAttachment?.image">
                          </div>
                          <div class="col text-right">
                            <a (click)="onMoreClick(item.id)"  href="javascript:void(0)">
                              More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="standards.length > 0">
        <div class="col">
          <ngb-pagination class="float-right" [(page)]="page" [pageSize]="pageSize" [maxSize]="4" [rotate]="true"
            [ellipses]="true" [collectionSize]="collectionSize" (pageChange)="getAllEquipmentStandards()">
          </ngb-pagination>
        </div>
      </div>
      <div class="row">
        <div *ngIf="standards.length == 0" class="col-md-9">Currently no any equipment related with this
          location
          type.</div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>

</mat-card>
