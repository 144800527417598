import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  invokeGetAlreadyReservedUserNotificationsEmit = new EventEmitter();    
  subsVar: Subscription;

  constructor() { }

  getAlreadyReservedUserNotificationsEmit() {    
    this.invokeGetAlreadyReservedUserNotificationsEmit.emit();    
  }    
}
