import { EquipmentTypeLocationMapping } from "./equipment-type-location-mapping";

export class EquipmentType {
  id: number;
  name: string;
  description: string;
  equipmentTypeLocationMappings: EquipmentTypeLocationMapping[];
  constructor(obj?: EquipmentType) {
        if (obj) {
          this.id = obj.id;
          this.name = obj.name;
          this.description = obj.description;
          this.equipmentTypeLocationMappings = obj.equipmentTypeLocationMappings;
        }
    }
}
