import { Attachment } from "./attachment";

export class Equipment {
  id: number;
  model: string;
  createdUserId: number;
  created: Date;
  isDeleted: boolean;
  equipmentTypeId: number;

  imageFileName: string;
  imageMime: string;
  imageAttachmentContent: any;
  imageAttachmentContentAsBase64: string;

  attachmentFileName: string;
  attachmentMime: string;
  attachmentContent: any;
  attachmentContentAsBase64: string;

  imageAttachment: Attachment;
  attachment: Attachment;
  supplierId: number;

  equipmentAttachment: Attachment;

  constructor(obj?: Equipment) {
    if (obj) {
      this.id = obj.id;
      this.model = obj.model;
      this.createdUserId = obj.createdUserId;
      this.created = obj.created;
      this.isDeleted = obj.isDeleted;
      this.equipmentTypeId = obj.equipmentTypeId;

      this.imageFileName = obj.imageFileName;
      this.imageMime = obj.imageMime;
      this.imageAttachmentContent = obj.imageAttachmentContent;
      this.imageAttachmentContentAsBase64 = obj.imageAttachmentContentAsBase64;

      this.attachmentFileName = obj.attachmentFileName;
      this.attachmentMime = obj.attachmentMime;
      this.attachmentContent = obj.attachmentContent;
      this.attachmentContentAsBase64 = obj.attachmentContentAsBase64;
      this.imageAttachment = new Attachment(obj.imageAttachment);
      this.attachment = new Attachment(obj.attachment);
      this.supplierId = obj.supplierId;
      this.equipmentAttachment = new Attachment(obj.equipmentAttachment);      
    }
  }
}
