import { Constant } from "../core/constant";

export class KeyContactModel {
    id: number;
    contentArea: string;
    name: string;
    email: string;
    userId: number;

    //build outlook hyper link
    get outlookEmailHyperLink() {
        return "mailto:" + this.email;
    }

    get avatarLetters() {
        let pieces = this.name.split(' ');
        if (pieces.length > 1) {
            return pieces[0].substring(0, 1).toUpperCase() + pieces[1].substring(0, 1).toUpperCase();
        } else {
            return this.name.substring(0, 1).toUpperCase();
        }
    }

    constructor(obj?: KeyContactModel) {
        if (obj) {
            this.id = obj.id;
            this.contentArea = obj.contentArea;
            this.name = obj.name;
            this.email = obj.email;
            this.userId = obj.userId
        }
    }
}
