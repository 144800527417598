import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NrbesItem } from '../models/nrbesItem';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  @Input() public title: string = '';
  @Input() public message: string = '';
  @Input() public deleteItem: any = null;
  @Output() deletePopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  delete() {
    debugger
    this.deletePopupEmit.emit(this.deleteItem);
  }

  closeDialog() {
    this.deletePopupEmit.emit(null);
  }
}
