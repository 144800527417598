import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "../services/admin.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

import { UsefulLink } from "../models/usefullink";
import { Attachment } from "../models/attachment";

import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";

import { UserRoleEnum } from "../core/constant";

@Component({
  selector: "app-add-useful-link",
  templateUrl: "./add-useful-link.component.html",
  styleUrls: ["./add-useful-link.component.scss"],
})
/** add-useful-link component*/
export class AddUsefulLinkComponent {
  error: string = "";
  modalOption: NgbModalOptions = {};
  get userRoleEnum() {
    return UserRoleEnum;
  }

  loading: boolean = false;
  invalidAttachment: boolean = false;
  isEmailAllowed: boolean = true;
  usefulLink: UsefulLink = new UsefulLink();
  addEditUsefulLinkForm: FormGroup;

  linkTypes: string[] = ["Attachment", "URL"];

  /** add-useful-link ctor */
  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.usefulLink.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("usefulLinkId")
    );
  }

  ngOnInit() {
    if (this.usefulLink.id) {
      this.getUserByUsefulLinkById(this.usefulLink.id);
    }

    this.createForm();
  }

  createForm(): void {
    this.addEditUsefulLinkForm = this.fb.group({
      name: ["", Validators.required],
      url: [""],
      linkType: ["", Validators.required],
    });
  }

  getUserByUsefulLinkById(usefulLinkId: number) {
    this.loading = true;
    this.adminService
      .GetUsefulLinkDetailsById(usefulLinkId)
      .subscribe((data: UsefulLink) => {
        this.usefulLink = new UsefulLink(data);
        this.setControlsValue(this.usefulLink);
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  setControlsValue(link: UsefulLink) {
    if (link.id) {
      this.addEditUsefulLinkForm.controls.name.setValue(link.name);
      if (link.url != "") {
        this.addEditUsefulLinkForm.controls.linkType.setValue("URL");
        this.addEditUsefulLinkForm.controls.url.setValue(link.url);
      } else {
        this.addEditUsefulLinkForm.controls.linkType.setValue("Attachment");
      }
    }
  }
  onSelectAttachment(event) {
    let selectedFile = event.target.files[0];
    this.invalidAttachment = this.validateAttachmentType(selectedFile.name);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.usefulLink.attachmentFileName = selectedFile.name;
      this.usefulLink.attachmentContentAsBase64 = result;
      this.usefulLink.attachment.attachmentContentAsBase64 = result;
      this.usefulLink.attachment.fileName = selectedFile.name;
      event.target.value = null;
    });
  }

  onRemoveAttachment() {
    this.usefulLink.attachment.id = 0;
    this.usefulLink.attachment.fileName = null;
    this.usefulLink.attachment.attachmentContentAsBase64 = null;
    this.usefulLink.attachmentFileName = null;

    this.invalidAttachment = false;
  }

  onDownloadSelectedAttachment(id) {
    if (id) {
      return this.adminService.GetAttachmentUrl(id, false).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
            return;
          }
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.usefulLink.attachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.usefulLink.attachment.fileName
            );
          } else {
            var a = document.createElement("a");
            a.href = this.usefulLink.attachmentContentAsBase64;
            a.setAttribute("download", this.usefulLink.attachment.fileName);
            a.click();
          }
        });
    }
  }

  validateAttachmentType(fileName: string) {
    let fileTypeArr = ["doc", "docx", "pdf"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        return true;
      }
      return false;
    }
  }

  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }

  onSaveUsefulLink() {
    this.loading = true;
    this.usefulLink.name = this.addEditUsefulLinkForm.controls.name.value;
    this.usefulLink.url = this.addEditUsefulLinkForm.controls.url.value;
    if (this.usefulLink.attachment != undefined) {
      this.usefulLink.attachmentFileName = this.usefulLink.attachment.fileName;
      this.usefulLink.attachmentContentAsBase64 =
        this.usefulLink.attachment.attachmentContentAsBase64;
    }
    if (this.usefulLink.id) {
      this.usefulLink.modifiedUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.updateUsefulLink(this.usefulLink).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the Link.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-useful-links"]);
          }
        });
      });
    } else {
      this.usefulLink.createdUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.addUsefulLink(this.usefulLink).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully add Useful Link record.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-useful-links"]);
          }
        });
      });
    }
  }

  addUsefulLink(link: UsefulLink): Observable<any> {
    this.loading = true;
    link.id = 0;
    return new Observable<any>((observer) => {
      this.adminService.AddUsefulLink(link).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  updateUsefulLink(link: UsefulLink): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.adminService.UpdateUsefulLink(link).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  onReset() {
    this.invalidAttachment = false;
    this.usefulLink.attachmentContentAsBase64 = null;
    this.usefulLink.attachment = new Attachment();
    this.createForm();
  }
}
