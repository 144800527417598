export class UserFilter {
    startingIndex: number;
    pageSize: number;
    searchKey: string;
      constructor(obj?: UserFilter) {
        if (obj) {
          this.startingIndex = obj.startingIndex;
          this.pageSize = obj.pageSize;
          this.searchKey = obj.searchKey;
        }
      }
}
