import { Standard } from "./standard";
import { Country } from "./country";

export class NrbeStandard {
    
    id: number;
    countryId: number;
    standardId: number;
    country: Country = new Country;
    standard: Standard = new Standard;
    isFirst: boolean;
    isLast: boolean;
    isInNrbes: boolean;
    availabileAddendumId?: number
    addendumStatusId?: number

    constructor(obj?: NrbeStandard) {
        if (obj) {
            this.id = obj.id;
            this.countryId = obj.countryId;
            this.standardId = obj.standardId;
            this.country = new Country(obj.country);
            this.standard = new Standard(obj.standard);
            this.isFirst = obj.isFirst;
            this.isLast = obj.isLast;
            this.isInNrbes = obj.isInNrbes;
            this.availabileAddendumId = obj.availabileAddendumId;
            this.addendumStatusId = obj.addendumStatusId;
        }
    }
}
