import { QuestionAndAnswer } from "./questionsAndAnswers";

export class BasicInformation {
  id: number;
  title: string;
  questionsAndAnswers: QuestionAndAnswer[];
  createdUserId: number;

  constructor(obj?: BasicInformation) {
    if (obj) {
      this.id = obj.id;
      this.title = obj.title;
      this.questionsAndAnswers = obj.questionsAndAnswers.map(x => new QuestionAndAnswer(x));
      this.createdUserId = obj.createdUserId;
    }
  }
}
