<!--Add questions wizard starts here-->

<div class="modal" style="display: block !important;  background:rgba(0, 0, 0, 0.47);" id="content">
  <div class="modal-dialog">
    <div *ngIf="!loading" class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"  *ngIf="!postData.id">Add a Question</h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="postData.id > 1">Edit a Question</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeWizard()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <mat-horizontal-stepper #stepper>
          <mat-step label="Step 1">
            <!-- Step - 1 -->
            <form [formGroup]='titleForm' class="step-1">
              <div class="mcd-faq-ask-question">
                <div class="row">
                  <div class="col-12">
                    <h3 class="mcd-faq-question-title">Please enter the FAQ Title</h3>
                    <mat-form-field>
                      <input matInput placeholder="Title" formControlName="title">
                      <mat-error *ngIf="titleForm.get('title').touched && titleForm.get('title').hasError('required')">
                        Title is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="mcd-btn mcd-btn-primary" (click)="closeWizard()" mat-raised-button>Cancel</button>
                <button class="mcd-btn mcd-btn-secondary" matStepperNext mat-raised-button (click)=redirectToQA()
                  [disabled]="titleForm.invalid">Next</button>
              </div>
            </form>
          </mat-step>
          <!-- Step - 2 -->
          <mat-step>
            <form [formGroup]="questionForm">
              <div class="mcd-faq-ask-question">
                <div class="row">
                  <div class="col-12">
                    <h3 class="mcd-faq-question-title">Please enter the Question & Answer</h3>
                    <mat-form-field>
                      <input matInput placeholder="Question" formControlName="question">
                      <mat-error
                        *ngIf="questionForm.get('question').touched && questionForm.get('question').hasError('required')">
                        Question is required</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                      <input matInput placeholder="Answer" formControlName="answer">
                      <mat-error
                        *ngIf="questionForm.get('answer').touched && questionForm.get('answer').hasError('required')">
                        Answer is required</mat-error>
                    </mat-form-field>

                    <mat-form-field class="example-chip-list">
                      <mat-chip-list #chipList aria-label="Hashtag selection">
                        <mat-chip *ngFor="let hashtag of hashtags" [selectable]="selectable" [removable]="removable"
                          (removed)="remove(hashtag)">
                          {{hashtag.name}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Hashtags" [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="add($event)" formControlName="hashtag">
                      </mat-chip-list>
                    </mat-form-field>
                    <div class="mcd-link-button">
                      <a href="javascript:void(0)" *ngIf="postData.id > 0" (click)="deleteFAQ()">
                        <span><img src="/assets/images/trash-edit.svg"></span> Delete this question
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button class="mcd-btn mcd-btn-primary" matStepperPrevious mat-raised-button
                  (click)="redirectBack()">Back</button>
                <button class="mcd-btn mcd-btn-secondary" matStepperNext mat-raised-button (click)="redirectToFinal(); isAnotherQYesNoselected = null"
                  [disabled]="questionForm.invalid">Next</button>
              </div>
            </form>
          </mat-step>

          <!-- Step - 3 -->

          <mat-step>
            <form>
              <div class="mcd-faq-ask-question">
                <div class="row">
                  <div class="col-12">
                    <h3 class="mcd-faq-question-title text-left">Do you want to add another question for this title?
                    </h3>
                    <mat-radio-group aria-label="Select an option">
                      <mat-radio-button class="mat-radio-button" value="1" #yes
                        (change)="isAnotherQYesNoselected = true; createNewWizard = true;"
                        [checked]="createNewWizard == true">Yes
                      </mat-radio-button>
                      <mat-radio-button value="2" #no
                        (change)="isAnotherQYesNoselected = true; createNewWizard = false;"
                        [checked]="createNewWizard == false">No</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="mcd-btn mcd-btn-primary" (click)="redirectBack()" matStepperPrevious
                  mat-raised-button>Back</button>
                <button class="mcd-btn mcd-btn-secondary" matStepperNext mat-raised-button (click)="redirectFromFinal()"
                  *ngIf="createNewWizard == true || createNewWizard == null"
                  [disabled]="!isAnotherQYesNoselected">Next</button>
                <button class="mcd-btn mcd-btn-secondary" matStepperNext mat-raised-button (click)="submitChanges()"
                  *ngIf="createNewWizard == false" [disabled]="!isAnotherQYesNoselected">Complete</button>
              </div>
            </form>
          </mat-step>

          <!-- Step - 4 -->

          <mat-step>
                  <div class="mcd-delete-dialog-content">
                    <div class="row">
                      <div class="col-3 delete-icon-wrapper">
                        <img alt="Delete" src="/assets/images/mcd-trash.svg">
                      </div>
                      <div class="col-9">
                        <p class="mcd-large-text">Do you want to delete this FAQ? </p>
                      </div>
                    </div>
                <div class="modal-footer">
                  <button class="mcd-btn mcd-btn-primary" mat-raised-button color="accent"
                    (click)="deleteWholeFAQ()">Yes</button>
                  <button class="mcd-btn mcd-btn-secondary" mat-raised-button color="warn"
                    (click)="closeWizard()">No</button>
                </div>
              </div>
          </mat-step>

        </mat-horizontal-stepper>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </div>
</div>
