<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title
      *ngIf="
        grsDetail.standard.standardTypeId ==
        standardTypeEnum.GlobalRecommendations
      "
      class="mcd-card-title"
      >View Global Approved Solutions Standard</mat-card-title
    >
    <mat-card-title
      *ngIf="
        grsDetail.standard.standardTypeId ==
        standardTypeEnum.MarketBestPractices
      "
      class="mcd-card-title"
      >View Shared Learning Standard</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content nrbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <div *ngIf="standardService.isGrs" class="breadcrumbs-inner">
          <ul>
            <li>
              <a routerLink="/locations" routerLinkActive="active">Locations</a>
            </li>
            <li class="separator">></li>
            <li>
              <a
                [routerLink]="['/location-grs-list', locationId]"
                routerLinkActive="active"
                >{{ grsDetail.standard.locationType.name }}</a
              >
            </li>
            <li class="separator">></li>
            <li>
              <a
                [routerLink]="
                  '/minimum-standard-detail-view/' +
                  grsDetail?.standard?.dependentStandard?.minimumStandadId
                "
                routerLinkActive="active"
                >{{ grsDetail?.standard?.dependentStandard?.title }}</a
              >
            </li>
            <li class="separator">></li>
            <li class="active">{{ grsDetail.standard.title }}</li>
          </ul>
        </div>
        <div *ngIf="!standardService.isGrs" class="breadcrumbs-inner">
          <ul>
            <li>
              <a
                routerLink="/view-all-minimum-standard"
                routerLinkActive="active"
                >All</a
              >
            </li>
            <li class="separator">></li>
            <li>
              <a
                [routerLink]="
                  '/minimum-standard-detail-view/' +
                  grsDetail?.standard?.dependentStandard?.minimumStandadId
                "
                routerLinkActive="active"
                >{{ grsDetail?.standard?.dependentStandard?.title }}</a
              >
            </li>
            <li class="separator">></li>
            <li class="active">{{ grsDetail.standard.title }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div *ngIf="!loading" class="nrbes-detail-view">
      <!-- Dynamic Wrapper -->
      <div class="nrbes-detail-item">
        <h1>{{ grsDetail.standard.title }}</h1>
        <div class="nrbes-description">
          <h2
            *ngIf="
              grsDetail.standard.standardTypeId ==
              standardTypeEnum.GlobalRecommendations
            "
          >
            Global Approved Solution
          </h2>
          <h2
            *ngIf="
              grsDetail.standard.standardTypeId ==
              standardTypeEnum.MarketBestPractices
            "
          >
            Shared Learnings
          </h2>

          <div class="hash-tag">
            {{ grsDetail.standard.category?.name }}
          </div>

          <div class="nrbes-detail-item">
            <div
              *ngIf="grsDetail.standard.newRestaurantsEffectiveFrom"
              class="mcd-date-box-wrapper"
            >
              <label>New Restaurant Effective Date</label>
              <div class="mcd-date-box">
                <div class="mcd-date-box-header">
                  {{
                    grsDetail.standard.newRestaurantsEffectiveFrom
                      | date : "MMM | yyyy"
                  }}
                </div>
                <div class="mcd-date-box-day">
                  {{
                    grsDetail.standard.newRestaurantsEffectiveFrom | date : "dd"
                  }}
                </div>
              </div>
            </div>

            <div
              *ngIf="!grsDetail.standard.newRestaurantsEffectiveFrom"
              class="mcd-date-box-wrapper"
            >
              <label>New Restaurant Effective Date - N/A </label>
            </div>
          </div>
          <div class="nrbes-description">
            <p>
              {{ grsDetail.standard.newRestaurantDescription }}
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <div class="nrbes-detail-item">
            <div
              *ngIf="grsDetail.standard.effectiveFrom"
              class="mcd-date-box-wrapper"
            >
              <label>Existing Restaurant Effective Date</label>
              <div class="mcd-date-box">
                <div class="mcd-date-box-header">
                  {{ grsDetail.standard.effectiveFrom | date : "MMM | yyyy" }}
                </div>
                <div class="mcd-date-box-day">
                  {{ grsDetail.standard.effectiveFrom | date : "dd" }}
                </div>
              </div>
            </div>

            <div
              *ngIf="!grsDetail.standard.effectiveFrom"
              class="mcd-date-box-wrapper"
            >
              <label>Existing Restaurant Effective Date - N/A </label>
            </div>
          </div>
          <div class="nrbes-description">
            <p>
              {{ grsDetail.standard.description }}
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="nrbes-detail-item">
            <div class="nrbes-Benefit">
              <h2>Standard Notes</h2>

              <div class="ql-container ql-snow" style="border-width: 0">
                <div
                  class="ql-editor nrbes-description"
                  [innerHTML]="this.notes"
                ></div>
              </div>
            </div>
          </div>

          <div class="nrbes-detail-item">
            <div class="nrbes-Benefit">
              <h2>Additional References</h2>

              <div class="ql-container ql-snow" style="border-width: 0">
                <div
                  class="ql-editor nrbes-description"
                  [innerHTML]="this.additionalInformation"
                ></div>
              </div>
            </div>
          </div>

          <div class="col">
            <button
              class="mcd-btn mcd-btn-primary"
              [disabled]="grsDetail.isInNrbes"
              *ngIf="userRole == userRoleEnum.MarketAdmin"
              (click)="
                addToNrbes(
                  grsDetail.standard.id,
                  grsDetail.standard.standardTypeId
                )
              "
              mat-raised-button
              color="accent"
            >
              Add To National Standard
            </button>
          </div>
        </div>
      </div>
      <!-- End Dynamic Wrapper -->
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
