export class NotificationModel {
  id: number;
  addendumDescription: string;
  isSeen: boolean;
  locationType: string;
  date: Date;
  notifiedUser: string;
  notifiedUserRole: string;
  addendumId: number;
  message: string;

  get avatarLetters() {
    let pieces = this.notifiedUser.split(' ');
    if (pieces.length > 1) {
      return pieces[0].substring(0, 1).toUpperCase() + pieces[1].substring(0, 1).toUpperCase();
    } else {
      return this.notifiedUser.substring(0, 1).toUpperCase();
    }
  }

  constructor(obj?: NotificationModel) {
    if (obj) {
      this.id = obj.id;
      this.addendumDescription = obj.addendumDescription;
      this.isSeen = obj.isSeen;
      this.locationType = obj.locationType;
      this.date = new Date(obj.date);
      this.notifiedUser = obj.notifiedUser;
      this.notifiedUserRole = obj.notifiedUserRole;
      this.addendumId = obj.addendumId;
      this.message = obj.message;
    }
  }
}
