<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Wish List</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">
    <div class="table-wrapper" *ngIf="standardList.length > 0">
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-select
              [value]="pageSize"
              name="nOfRows"
              #items
              (selectionChange)="selectedItemsPerPageChange(items)"
            >
              <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <table class="table nrbes-grid">
        <thead class="thead-light">
          <tr>
            <th width="140" scope="col">Title</th>
            <th scope="col" width="110">Location</th>
            <th scope="col-4" width="30%">Description</th>
            <th scope="col-2">Category</th>
            <th scope="col" width="170">Type</th>
            <th scope="col" width="80" *ngIf="userRole == 1">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of standardList" (click)="rowSelected(item)">
            <td class="title-cell">{{ item.title }}</td>
            <td class="location-cell">{{ item.locationType.name }}</td>
            <td class="description">
              <span title="{{ item.shortDescription }}" class="description">
                {{ item.shortDescription }}
              </span>
            </td>
            <td class="category-cell">{{ item.category.name }}</td>
            <td class="hide-sm">{{ item.standardType.name }}</td>
            <td *ngIf="userRole == 1">
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="openDeleteModal(item.id, $event)"
                    routerLinkActive="active"
                    title="clear"
                    ><i class="material-icons"> clear </i></a
                  >
                </div>
                <div class="col-6">
                  <a
                    class="action-controls"
                    [routerLink]="['/add-grs', item.id]"
                    routerLinkActive="active"
                    title="Edit"
                    href="#"
                    ><i class="material-icons"> create </i></a
                  >
                </div>
              </div>

              <a
                (click)="actionMenuOpen($event)"
                class="mcd-more-btn show-sm"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button mat-menu-item [routerLink]="['/add-grs', item.id]">
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="closeMatMenuAndOpenDeleteModal(item.id, $event)"
                  >
                    Remove
                  </button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getStandards()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="standardList.length == 0">
      Sorry you do not have any standard.
    </div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
