<div class="modal-content">
  <div class="modal-body">
    <div>{{ title }}</div>
    <div class="row">
      <div class="col"></div>
      <div>
        <app-inline-loader *ngIf="!showCropper" class="inline-loader"></app-inline-loader>
      </div>
      <div class="col"></div>
    </div>
    <div>
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="ratio" 
        [cropperMinWidth]="462" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded();"
        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
        [style.display]="showCropper ? null : 'none'"></image-cropper>
    </div>
    <div class="modal-footer">
      <button *ngIf="showCropper" class="btn-cancel" type="button" (click)="closewindow()">Cancel</button>
      <button *ngIf="showCropper" class="btn-apply" type="button" (click)="apply()">Apply</button>
    </div>
  </div>
</div>