export class StandardSearchView {
    id: number;
    title: string;
    description: string;
    constructor(obj?: StandardSearchView) {
        if (obj) {
            this.id = obj.id;
            this.title = obj.title;
            this.description = obj.description;
        }
    }
}
