import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from "../services/admin.service";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SimpleModalService } from "ngx-simple-modal-eq";
import { Observable } from "rxjs";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { Category } from "../models/category";

@Component({
  selector: "app-add-category",
  templateUrl: "./add-category.component.html",
  styleUrls: ["./add-category.component.scss"],
})
export class AddCategoryComponent implements OnInit {
  error: string = "";
  modalOption: NgbModalOptions = {};

  loading: boolean = false;
  category: Category = new Category();

  addEditCategoryForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.category.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("categoryId")
    );
  }

  ngOnInit() {
    if (this.category.id) {
      this.getCategoryByCategoryId(this.category.id);
    }
    this.createForm();
  }

  createForm(): void {
    this.addEditCategoryForm = this.fb.group({
      name: ["", Validators.required],
      description: ["", Validators.required],
    });
  }

  getCategoryByCategoryId(categoryId: number) {
    this.loading = true;
    this.adminService
      .GetCategoryDetailsById(categoryId)
      .subscribe((data: Category) => {
        this.category = new Category(data);
        this.setControlsValue(this.category);
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  setControlsValue(category: Category) {
    if (category.id) {
      this.addEditCategoryForm.controls.name.setValue(category.name);
      this.addEditCategoryForm.controls.description.setValue(
        category.description
      );
    }
  }

  onReset() {
    this.createForm();
  }

  onSaveCategory() {
    this.loading = true;
    this.category.name = this.addEditCategoryForm.controls.name.value;
    this.category.description =
      this.addEditCategoryForm.controls.description.value;
    if (this.category.id) {
      this.updateCategory(this.category).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully updated the category.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-category-list"]);
          }
        });
      });
    } else {
      this.addCategory(this.category).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message =
          "You have successfully added the category.";
        if (data == false) {
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message =
            "Some error occurred. Please try again after some time";
        }
        if (data.status == false) {
          modalRef.componentInstance.title = "Error";
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(["/view-all-category-list"]);
          }
        });
      });
    }
  }

  addCategory(category: Category): Observable<any> {
    this.loading = true;
    category.id = 0;
    return new Observable<any>((observer) => {
      this.adminService.AddCategory(category).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  updateCategory(category: Category): Observable<any> {
    this.loading = true;
    return new Observable<any>((observer) => {
      this.adminService.UpdateCategory(category).subscribe(
        (data: any) => {
          this.loading = false;
          observer.next(data);
        },
        (error) => {
          this.loading = false;
          observer.next(false);
        }
      );
    });
  }

  onCancelClick() {
    this.router.navigate(["/view-all-category-list"]);
  }
}
