<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Exception List</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mcd-card-content">

      <div class="row filter-panel-row">
          <div class="col-md-4 filter-panel">
           
          </div>
          <div class="collpsible-panel">
              <button class="btn-collapse" mat-icon-button aria-label="Collapse">
                  <i class="material-icons">
                      expand_less
                      </i>
              </button>            
              <button class="btn-expand" mat-icon-button aria-label="Expand">
                    <mat-icon>expand_more</mat-icon>
              </button>
          </div>
        </div>

    <div class="table-wrapper">
      <div>
        <div class="row">
          <div class="col-6">
            <mat-form-field >
              <mat-select >
                <mat-option>
                Test
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 text-right">
            <mat-form-field>
              <mat-label>Country</mat-label>
              <mat-select  name="dropCategory">
                <mat-option value="">Test</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div>
          <table class="table nrbes-grid">
            <thead class="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col-4" width="30%">Description</th>
                <th scope="col-2">Notes</th>
                <th scope="col" width="120">Approved / Rejected on</th>
                <th scope="col" width="100">Expired on</th>
                <th scope="col" width="105">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="title-cell">Title</td>
               <td class="description">
              <span title="description goes here" class="">  description goes here </span>
            </td>
                <td class="note-cell">
                  Notes
                </td>
                <td class="approval-cell">
                  <span class="approval-label">Approved / Rejected On</span>
                  <span class="approval-value"> 20 12 2018</span>
                </td>
                <td class="approval-cell">
                  <span class="approval-label">Expiring On</span>
                  <span class="approval-value">21 12 2020 </span>
                </td>
                <td class="status-cell">
                  <span></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col">
              <!-- <ngb-pagination class="float-right" [(page)]="page" [pageSize]="pageSize" [maxSize]="4" [rotate]="true"
                              [ellipses]="true" [collectionSize]="collectionSize" (pageChange)="getAddendums()"></ngb-pagination> -->
            </div>
          </div>
        </div>
        <!-- <div *ngIf="addendums.length == 0">
          Sorry you do not have any addendum.
        </div> -->
        <div class="row">
          <div class="col-md-4">
            <div class="mcd-filter-tag-wrapper">
            
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-mcd-loader *ngIf="loading"></app-mcd-loader> -->
  </mat-card-content>
  <mat-card-actions>
  </mat-card-actions>
</mat-card>
