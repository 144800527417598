import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { KeyContactModel } from "../models/key-contact-model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";

@Component({
  selector: "app-add-contact",
  templateUrl: "./add-contact.component.html",
  styleUrls: ["./add-contact.component.scss"],
})
export class AddContactComponent implements OnInit {
  keyContactForm: FormGroup;

  @Input() public keyContactModel: KeyContactModel = new KeyContactModel();
  @Output() contactAddEditPopupEmit: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.keyContactModel.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.createForm();
  }

  /**
   * Create form
   * @return void
   */
  createForm(): void {
    this.keyContactForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      contentArea: ["", Validators.required],
    });
  }

  /**
   * Get Email Validation Error Message
   * @return string
   */
  getEmailValidationErrorMessage() {
    return this.keyContactForm.get("email").hasError("required")
      ? "Email is required"
      : this.keyContactForm.get("email").hasError("email")
      ? "Not a valid email"
      : this.keyContactForm.get("email").hasError("pattern")
      ? "Not a valid email"
      : "";
  }

  /**
   * Save Contact
   * @return void
   */
  saveContact(): void {
    if (this.keyContactForm.valid) {
      this.keyContactModel.name = this.keyContactForm.controls.name.value;
      this.keyContactModel.contentArea =
        this.keyContactForm.controls.contentArea.value;
      this.keyContactModel.email = this.keyContactForm.controls.email.value;
      this.contactAddEditPopupEmit.emit(this.keyContactModel);
    }
  }
}
