import { Component, OnInit, Inject } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute } from "@angular/router";
import { StandardService } from "../services/standard.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import {
  StandardTypeEnum,
  StandardListType,
  UserRoleEnum,
} from "../core/constant";
import { CryptographyService } from "../services/cryptography.service";
import { LocationService } from "../services/location.service";
import { SelectedStandard } from "../models/selected-standard";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
declare var $: any;

@Component({
  selector: "app-view-all-standard-details-view",
  templateUrl: "./view-all-standard-details-view.component.html",
  styleUrls: ["./view-all-standard-details-view.component.scss"],
})
export class ViewAllStandardDetailsViewComponent implements OnInit {
  loading: boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="/assets/images/back.svg">',
      '<img src="/assets/images/next.svg">',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  grsId: number = 0;
  notes: SafeHtml = "";
  additionalInformation: SafeHtml = "";
  grsDetail: SelectedStandard = new SelectedStandard();
  error: string = "";
  userRole: number;
  get standardTypeEnum() {
    return StandardTypeEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  locationId: number = 0;

  constructor(
    private activeRoute: ActivatedRoute,
    public standardService: StandardService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private cryptographyService: CryptographyService,
    public locationService: LocationService,
    private sanitizer: DomSanitizer
  ) {
    this.grsId = parseInt(this.activeRoute.snapshot.paramMap.get("grsId"));
    this.locationId = parseInt(
      this.activeRoute.snapshot.paramMap.get("locationId")
    );
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.getDetailByGrbesId(this.grsId);
    $(document).ready(() => {
      let objId;
      $(".image-map area")
        .mouseenter(function (e) {
          objId = $(this).attr("target");
          $(".main-image").css("opacity", "0");
          $(".main-image").css("z-index", "9999");
          $(objId).find("img").css("z-index", "2");
          $(objId).removeClass("d-none");
          $(objId).addClass("active");
          $(".chunk").removeClass("d-none");
          $(".tooltip-text span").text($(this).attr("alt"));
          $(".tooltip-text").css("left", e.pageX + 2);
          $(".tooltip-text").css("top", e.pageY + 2);
          $(".tooltip-text").fadeIn("slow");
        })
        .mouseleave(function (e) {
          $(".main-image").css("z-index", "1");
          $(".chunk").addClass("d-none");
          $(".chunk").removeClass("active");
          $(".main-image").css("opacity", "1");
        });
    });
  }

  getDetailByGrbesId(grsId: number) {
    //debugger;
    this.loading = true;
    this.standardService
      .getGrbesDetailInfomationByStandardId(
        grsId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        this.standardService.minimumStandardFiltration.filterSet,
        StandardListType.Minimum,
        null
      )
      .subscribe((data: SelectedStandard) => {
        var options = {
          /* … */
        };
        this.grsDetail = new SelectedStandard(data);
        console.log("this. grs detailsss", this.grsDetail)
        this.notes = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.note
        );
        this.additionalInformation = this.sanitizer.bypassSecurityTrustHtml(
          this.grsDetail.standard.additionalInformation
        );
        this.loading = false;
        () => {
          this.loading = false;
        };
      });
  }

  addToNrbes(standardId, standardTypeId) {
    this.loading = true;
    this.standardService
      .addStandardToNrbes(
        standardId,
        this.cryptographyService.decryptData(this.storage.get("countryId")),
        (standardTypeId === 0 ? 6 : 3),
        this.cryptographyService.decryptData(this.storage.get("userId"))
      )
      .subscribe(() => {
        this.loading = false;
        this.getDetailByGrbesId(this.grsDetail.standard.id);
      });
  }
}
