<div class="table-wrapper">
  <div class="row">
    <div class="col-6">
      <mat-form-field>
        <mat-select name="nOfRows">
          <mat-option selected [value]="20">
            Show 20 Items
          </mat-option>
          <mat-option [value]="50">
            Show 50 Items
          </mat-option>
          <mat-option [value]="100">
            Show 100 Items
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-6 text-right">
      <mat-form-field>
        <mat-select name="car">
          <mat-option value="0" selected>All Countries</mat-option>
          <mat-option [value]="1">
            United States of America
          </mat-option>
          <mat-option [value]="2">
            United Kingdom
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <table class="table nrbes-grid">
    <thead class="thead-light">
      <tr>
        <th scope="col">Title</th>
        <th scope="col" width="120">Location</th>
        <th scope="col-4">Description</th>
        <th scope="col-2">Category</th>
        <th scope="col" width="120">Type</th>
        <th scope="col-2" width="140">Addendum</th>
        <th scope="col" width="80">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Fryer Oil Temporature</td>
        <td>Kitchen</td>
        <td> Designated single lane Drive-thru - 2 booths with COD are Food & Safety Minimum Req Add Addendum
          Temporature always required. Based on conditions the configuration may Standard
          differ.
          COD order point with 9 vehicle stacking in the DT lane: minimum 3 vehicle prior to the speaker and 6 vehicle
          (18.3m or 60ft) from the center line of the COD face to the center line of the present booth opening.</td>
        <td>Food & Safety</td>
        <td>Minimum Req Standard</td>
        <td><a href="javascript:void(0);">Add Addendum</a></td>
        <td>
          <div class="row grid-actions">
            <div class="col-6">
              <a class="action-controls" title="clear" href="#"> <i class="material-icons"> clear </i></a>
            </div>
            <div class="col-6">
              <a class="action-controls" title="Edit" href="#"> <i class="material-icons"> create </i></a>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Fryer Oil Temporature</td>
        <td>Kitchen</td>
        <td> Designated single lane Drive-thru - 2 booths with COD are Food & Safety Minimum Req Add Addendum
          Temporature always required.</td>
        <td>Building</td>
        <td>Minimum Req Standard</td>
        <td><a href="javascript:void(0);">Add Addendum</a></td>
        <td>
          <div class="row grid-actions">
            <div class="col-6">
              <a class="action-controls" title="clear" href="#"> <i class="material-icons"> clear </i></a>
            </div>
            <div class="col-6">
              <a class="action-controls" title="Edit" href="#"> <i class="material-icons"> create </i></a>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>Drive Thru Headset</td>
        <td>Drive Thru</td>
        <td> Designated single lane Drive-thru - 2 booths with COD are Food & Safety Minimum Req Add Addendum
          Temporature always required. Based on conditions the configuration may Standard
        </td>
        <td>Technology</td>
        <td>Minimum Req Standard</td>
        <td><a href="javascript:void(0);">Add Addendum</a></td>
        <td>
          <div class="row grid-actions">
            <div class="col-6">
              <a click="open(content)" data-toggle="modal" data-target="#content" class="action-controls" title="clear"
                href="javascript:void(0);"> <i class="material-icons"> clear </i></a>
            </div>
            <div class="col-6">
              <a click="open(content)" data-toggle="modal" data-target="#content" class="action-controls" title="Edit"
                href="javascript:void(0);"> <i class="material-icons"> create </i></a>
            </div>
          </div>
        </td>
      </tr>

    </tbody>
  </table>

  <app-modal-dialog></app-modal-dialog>
</div>
