
export class CreateNewNrbes {
  standardTypeId: number;
  title: string;
  createdUserId: number;
  categoryId: number;
  locationTypeId: any;
  standardStatusId: number;
  description: string;
  newrestaurantDescription: string;
  note: string;
  additionalInformation: string;
  countryId: number;
  effectiveFrom: string;
  newRestaurantEffectiveFrom: string;
}
