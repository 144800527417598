import { Component, OnInit, Inject } from "@angular/core";
import { AddendumService } from "../services/addendum.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { Router, ActivatedRoute } from "@angular/router";
import { Addendum } from "../models/addendum";
import {
  AddendumStatusEnum,
  UserRoleEnum,
  StandardTypeEnum,
} from "../core/constant";
import { Observable } from "rxjs";
import { AddendumAttachment } from "../models/addendum-attachment";
import { CryptographyService } from "../services/cryptography.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { StandardService } from "../services/standard.service";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-addendum",
  templateUrl: "./addendum.component.html",
  styleUrls: ["./addendum.component.scss"],
})
export class AddendumComponent implements OnInit {
  loading: boolean = false;
  userRole: number = 2;
  addendum: Addendum = new Addendum();
  error: string = "";
  invalidFile: boolean = false;
  invalidFileMessage: string = "";
  isExpiredorRejected: boolean = false;
  attachment: any = null;
  attachmentFinal: any = null;

  get addendumStatusEnum() {
    return AddendumStatusEnum;
  }
  get userRoleEnum() {
    return UserRoleEnum;
  }
  get standardTypeEnum() {
    return StandardTypeEnum;
  }

  constructor(
    private addendumService: AddendumService,
    private standardService: StandardService,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private activeRoute: ActivatedRoute,
    private ngbModal: NgbModal
  ) {
    this.addendum.id = parseInt(
      this.activeRoute.snapshot.paramMap.get("addendumId")
    );
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    //this.getAAddendum(this.addendum.id);
    this.activeRoute.params.subscribe((routeParams) => {
      this.getAAddendum(routeParams.addendumId);
    });
  }
  getApprovalArray(): Date[] {
    let a = [];
    for (let i = 0; i < this.addendum.addendumApproval.length; i++) {
      if (this.addendum.addendumApproval[i].approvedDate != null)
        a.push(new Date(this.addendum.addendumApproval[i].approvedDate));
    }
    console.log("all dates", a);
    return a.reverse();
  }

  getLatestRequestedAttachment() {
    let attachment = null;
    for (let a of this.addendum.addendumAttachment) {
      console.log("a", a);
      if (a.isRequestedFile == true) attachment = a;
    }
    console.log("requested attachment", attachment);
    return attachment;
  }
  getLatestApprovedAttachment() {
    let attachment = null;
    for (let i = 0; i < this.addendum.addendumAttachment.length; i++) {
      if (this.addendum.addendumAttachment[i].isRequestedFile == false) {
        attachment = this.addendum.addendumAttachment[i];
      }
    }
    return attachment;
  }
  getApprovedAttachmentsList() {
    let approvedAttachmentsList = [];
    for (let att of this.addendum.addendumAttachment) {
      console.log("att", att);
      if (att.isRequestedFile != null && att.isRequestedFile == false)
        approvedAttachmentsList.push(att);
    }
    console.log(approvedAttachmentsList);
    approvedAttachmentsList.reverse();
    return approvedAttachmentsList;
  }

  getRequestedAttachmentsList() {
    let approvedAttachmentsList = [];
    for (let att of this.addendum.addendumAttachment) {
      console.log("att", att);
      if (att.isRequestedFile != null && att.isRequestedFile == true)
        approvedAttachmentsList.push(att);
    }
    console.log(approvedAttachmentsList);
    approvedAttachmentsList.reverse();
    return approvedAttachmentsList;
  }
  /* Redirects to /update-addendum to renew the exception @return void*/
  redirectToRenew() {
    this.router.navigateByUrl("/update-addendum/" + this.addendum.id);
  }

  /**
   * Get a Addendum
   * @return void
   */
  getAAddendum(addendumId: number): void {
    this.loading = true;
    this.addendumService
      .getAddendumInformationByAddendumId(addendumId)
      .subscribe((data: Addendum) => {
        // console.log("")
        if (!data) {
          this.loading = false;
          this.addendum = new Addendum(new Addendum(), this.userRole);
          console.log("DATA ", this.addendum);
          const modalRefd = this.ngbModal.open(FailModuleComponent);
          modalRefd.componentInstance.title = "No data!";
          modalRefd.componentInstance.errorMessage =
            "This Exception has been deleted from the database or not available for use!";
          modalRefd.componentInstance.redirectRoute = "/locations";
        } else {
          this.addendum = new Addendum(data, this.userRole);
          console.log("DATA", data);
          this.loading = false;
          (error: string) => {
            this.error = error;
            this.loading = false;
          };
          if (data.addendumStatus.id == 3) this.isExpiredorRejected = true;
          if (data.addendumStatus.id == 4) this.isExpiredorRejected = true;
          this.attachment = this.getLatestRequestedAttachment();
          this.attachmentFinal = this.getLatestApprovedAttachment();
        }
      });
  }

  approveOrRejectOrDraft(newAddendumStatusId: number): void {
    if (newAddendumStatusId) {
      this.loading = true;
      this.addendum.addendumStatusId = newAddendumStatusId;
      if (newAddendumStatusId == this.addendumStatusEnum.DraftAfterRequest) {
        // this.attachmentFinal.isRequestedFile = null;
      }
      if (newAddendumStatusId == this.addendumStatusEnum.Approved) {
        this.attachmentFinal.isRequestedFile = false;
      }
      if (this.attachmentFinal != null)
        // this.addendum.addendumAttachment.push(this.attachmentFinal);
        this.addendum.addendumAttachment = [this.attachmentFinal];
      else this.addendum.addendumAttachment = [];
      // if (this.attachmentFinal != null) {
      //   this.attachmentFinal.addendumStatus = newAddendumStatusId;
      // }
      this.addendum.modifiedUserId = this.cryptographyService.decryptData(
        this.storage.get("userId")
      );
      this.addendumService.updateAddendum(this.addendum).subscribe((data) => {
        this.loading = false;
        this.getAAddendum(this.addendum.id);
        if (newAddendumStatusId == this.addendumStatusEnum.DraftAfterRequest) {
          if (
            this.addendum.standard.standardTypeId ==
            this.standardTypeEnum.GlobalEquipmentStandard
          ) {
            if (this.addendumService.isCommingFromNotification) {
              this.router.navigate(["/notification-list"]);
            } else if (this.addendumService.isCommingFromNrbes) {
              this.router.navigate([
                "/view-all-national-equipment-standard-list",
              ]);
            } else {
              this.router.navigate(["/equipment-addendum-list"]);
            }
          } else {
            if (this.addendumService.isCommingFromNotification) {
              this.router.navigate(["/notification-list"]);
            } else if (this.addendumService.isCommingFromNrbes) {
              this.router.navigate(["/nrbeslist"]);
            } else {
              this.router.navigate(["/addendum-list"]);
            }
          }
        }
        (error) => (this.error = error);
      });
    }
  }

  onSelectAAddendumDocument(event) {
    this.invalidFileMessage = "";
    let selectedFile = event.target.files[0];
    this.invalidFile =
      this.validateFileType(selectedFile.name) ||
      this.validateFileSize(selectedFile);
    this.convertToBaseString(selectedFile).subscribe((result) => {
      this.attachmentFinal = new AddendumAttachment();
      this.attachmentFinal.id = 0;
      this.attachmentFinal.fileName = selectedFile.name;
      this.attachmentFinal.addendumId = this.addendum.id;
      this.attachmentFinal.isRequestedFile = false;
      this.attachmentFinal.attachmentContentAsBase64 = result;
      event.target.value = null;
    });
  }

  onRemoveAddendumDocument() {
    this.attachmentFinal = null;
    this.invalidFile = false;
  }

  convertToBaseString(selectedFile): Observable<any> {
    return new Observable<any>((observer) => {
      var fileReader = new FileReader();
      if (fileReader && selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = function () {
          observer.next(fileReader.result.toString());
        };
      }
    });
  }

  onDownloadAddendumDocument(id: number, fileName: string) {
    return this.standardService.getAttachmentUrl(id, true).subscribe(
      (response: any) => {
        const blob = new Blob([response], {
          type: response.type,
        });
        if (
          (window.navigator as any) &&
          (window.navigator as any).msSaveOrOpenBlob
        ) {
          (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
          return;
        }
        var a = document.createElement("a");
        document.body.appendChild(a);

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);

        //window.open(url);
      },
      (error: any) => {
        const modalRef = this.ngbModal.open(FailModuleComponent);
        modalRef.componentInstance.errorMessage = error.error
          ? error.error.Message
          : error.message;
      }
    );
  }

  onDownloadSelectedAddendumDocument(id: number, fileName: string) {
    if (id) {
      return this.standardService.getAttachmentUrl(id, true).subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: response.type,
          });
          if (
            (window.navigator as any) &&
            (window.navigator as any).msSaveOrOpenBlob
          ) {
            (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
            return;
          }
          var a = document.createElement("a");
          document.body.appendChild(a);

          let url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error: any) => {
          const modalRef = this.ngbModal.open(FailModuleComponent);
          modalRef.componentInstance.errorMessage = error.error
            ? error.error.Message
            : error.message;
        }
      );
    } else {
      fetch(this.addendum.addendumAttachment[1].attachmentContentAsBase64)
        .then((res) => res.blob())
        .then((blob) => {
          if ((window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveBlob(
              blob,
              this.addendum.addendumAttachment[1].fileName
            );
          } else {
            var a = document.createElement("a");
            a.href =
              this.addendum.addendumAttachment[1].attachmentContentAsBase64;
            a.setAttribute(
              "download",
              this.addendum.addendumAttachment[1].fileName
            );
            a.click();
          }
        });
    }
  }

  validateFileType(fileName: string) {
    let fileTypeArr = ["docx", "doc", "pdf", "jpg", "png", "bmp", "jpeg"];
    if (fileName != null) {
      let fileNameArr = [];
      let splitedFileName = fileName.toLowerCase().split(".");
      splitedFileName.forEach(function (obj) {
        fileNameArr.push(obj);
      });
      fileNameArr = fileNameArr.reverse();
      if (!fileTypeArr.includes(fileNameArr[0])) {
        this.invalidFileMessage = "Invalid file type.";
        return true;
      }
    }
  }

  validateFileSize(file: any) {
    if (file.size > 10485760) {
      this.invalidFileMessage = "File size exceeds maximum limit 10 MB.";
      return true;
    }
  }

  onOk() {
    if (this.addendumService.isCommingFromNotification) {
      this.router.navigate(["/notification-list"]);
    } else if (
      this.addendumService.isCommingFromNrbes &&
      this.addendum.standard.standardTypeId !=
        this.standardTypeEnum.GlobalEquipmentStandard
    ) {
      this.router.navigate(["/nrbeslist"]);
    } else if (
      !this.addendumService.isCommingFromNrbes &&
      this.addendum.standard.standardTypeId !=
        this.standardTypeEnum.GlobalEquipmentStandard
    ) {
      this.router.navigate(["/addendum-list"]);
    } else if (
      !this.addendumService.isCommingFromNrbes &&
      this.addendum.standard.standardTypeId ==
        this.standardTypeEnum.GlobalEquipmentStandard
    ) {
      this.router.navigate(["/equipment-addendum-list"]);
    } else if (
      this.addendumService.isCommingFromNrbes &&
      this.addendum.standard.standardTypeId ==
        this.standardTypeEnum.GlobalEquipmentStandard
    ) {
      this.router.navigate(["/view-all-national-equipment-standard-list"]);
    }
  }
}
