<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >View Equipment Standard</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content grbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div class="breadcrumbs-inner">
        <ul>
          <li>
            <a routerLink="/view-all-standards" routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">&gt;</li>
          <li class="active">
            {{ grsDetail.standard.equipmentStandard.model }}
          </li>
          <li style="float: right">
            <a
              style="
                color: #0b59ca;
                text-decoration: underline;
                cursor: pointer;
              "
              (click)="downloadExcel()"
              >Download as Excel</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div class="grbes-detail-view row" *ngIf="!loading">
      <div class="col-4 image-wrapper">
        <h1 class="d-md-none d-none">{{ grsDetail.standard.title }}</h1>
        <h2 class="d-md-none sub-title">
          {{ grsDetail.standard.equipmentStandard.equipmentClassTypeName }}
        </h2>
        <div class="mcd-supplier-logo">
          <img
            [src]="
              grsDetail?.standard?.equipmentStandard?.supplier
                ?.supplierLogoAttachment?.image
            "
            class="img-fluid"
          />
        </div>
        <div class="mcd-equipment-image">
          <img
            [src]="
              grsDetail?.standard?.equipmentStandard?.imageAttachment?.image
            "
          />
        </div>
        <div class="mcd-eq-attachement">
          <a
            href="javascript:void(0);"
            *ngIf="
              grsDetail?.standard?.equipmentStandard?.equipmentAttachment?.id !=
              null
            "
            (click)="
              onDownloadSelectedEquipmentAttachment(
                grsDetail.standard.equipmentStandard.equipmentAttachment
              )
            "
          >
            <img src="/assets/images/attachement.svg" />
            {{
              grsDetail.standard.equipmentStandard.equipmentAttachment.fileName
            }}
          </a>
        </div>
      </div>
      <div class="col-8 detail-wrapper">
        <div>
          <h2 class="grbes-titles">Title</h2>
          <h1>{{ grsDetail.standard.title }}</h1>
          <h2 class="grbes-titles">Equipment Type</h2>
          <h2 class="sub-title">
            {{ grsDetail.standard.equipmentStandard.equipmentType.name }}
          </h2>
          <h2 class="grbes-titles">Equipment Class</h2>
          <h2 class="sub-title">
            {{ grsDetail.standard.equipmentStandard.equipmentClassType.name }}
          </h2>
          <h2 class="grbes-titles">Model</h2>
          <h3>{{ grsDetail.standard.equipmentStandard.model }}</h3>
          <br />
          <br />
          <br />
          <br />

          <div class="grbes-description">
            <h2>Description</h2>
            <p>{{ grsDetail.standard.description }}</p>
            <h2>Note</h2>
            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="this.notes"
              ></div>
            </div>
            <br />
            <br />
          </div>
          <div>
            <p
              *ngIf="
                grsDetail?.standard?.equipmentStandard?.obsoleteDate != null;
                else elseBlock
              "
            >
              Obsolete Date -
              {{
                grsDetail.standard.equipmentStandard.obsoleteDate
                  | date : "MM.dd.yyyy"
              }}
            </p>
            <ng-template #elseBlock> <p>Obsolete Date Not Set</p></ng-template>
          </div>
          <div>
            <p>
              Effective Date -
              {{ grsDetail.standard.effectiveFrom | date : "MM.dd.yyyy" }}
            </p>
          </div>
          <div class="row">
            <div class="col">
              <div class="mcd-spec">
                <span class="icon float-left">
                  <img alt="location" src="/assets/images/location-gray.svg" />
                </span>
                <span class="label float-left">{{
                  getLocationNames(grsDetail)
                }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <!--<button class="mcd-btn mcd-btn-primary" [disabled]="grsDetail.isInNrbes"
            *ngIf="userRole == userRoleEnum.MarketAdmin" (click)="addToNrbes(grsDetail.standard.id)"
            mat-raised-button color="accent">
      Add To National  Standard
    </button>-->
            <button
              class="mcd-btn mcd-btn-primary"
              [disabled]="grsDetail.isInNrbes"
              *ngIf="userRole == userRoleEnum.MarketAdmin"
              (click)="addToNrbes(grsDetail.standard.id, grsDetail.standard.standardTypeId)"
              mat-raised-button
              color="accent"
            >
              Add To National Standard
            </button>
          </div>
        </div>
        <!-- End Dynamic Wrapper -->
        <!-- Next Previous Buttons-->
        <div class="row">
          <div class="col text-right">
            <div class="btn-wrapper">
              <div class="prev-button" *ngIf="!grsDetail.isFirst">
                <a
                  href="javascript:void(0)"
                  (click)="slide(grsDetail.standard.id, false)"
                >
                  <img src="/assets/images/back.svg"
                /></a>
              </div>
              <div class="next-button" *ngIf="!grsDetail.isLast">
                <a
                  href="javascript:void(0)"
                  href="javascript:void(0)"
                  (click)="slide(grsDetail.standard.id, true)"
                >
                  <img src="/assets/images/next.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-mcd-loader *ngIf="loading"></app-mcd-loader>
  </mat-card-content>
</mat-card>
