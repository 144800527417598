<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">{{usefulLink.id > 0? "Edit" : "Add New"}} Useful Link</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li><a routerLink="/global-admin-panel" routerLinkActive="active">Global Admin Panel</a></li>
        <li class="separator">></li>
        <li><a routerLink="/view-all-useful-links" routerLinkActive="active">Manage User Links</a></li>
        <li class="separator">></li>
        <li class="active">{{usefulLink.id > 0? "Edit" : "Add New"}} Useful Link</li>
      </ul>
    </div>
  </div>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEditUsefulLinkForm">
        <div class="mcd-edit-form mcd-grbes-form">
          <div class="row">
            <div class="col-5">
              <mat-form-field>
                <input matInput placeholder="Link Name" name="Name" formControlName="name" required>
                <mat-error *ngIf="addEditUsefulLinkForm.get('name').touched && addEditUsefulLinkForm.get('name').hasError('required')">
                  Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-5  offset-md-2">
              <mat-form-field>
                <mat-label>Link Type</mat-label>
                <mat-select name="dropType" [disabled]="usefulLink.id > 0" [value]="addEditUsefulLinkForm['controls'].linkType.value" #linkType
                            (selectionChange)="addEditUsefulLinkForm['controls'].linkType.setValue($event.value)"
                            required >
                  <mat-option [value]="item" *ngFor="let item of linkTypes">
                    {{item}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-5" *ngIf="(addEditUsefulLinkForm.get('linkType').value =='URL')">
              <mat-form-field>
                <input matInput placeholder="Link URL" name="URL" formControlName="url" required>
              </mat-form-field>
            </div>

            <div class="row attachements"  *ngIf="(addEditUsefulLinkForm.get('linkType').value =='Attachment')">
              <div class="col text-right">
                <a href="javascript:void(0);" (click)="eAttachment.click()">
                  <span>
                    <img alt="Upload Image" src="/assets/images/attachement.svg">
                  </span> Attach File (doc, docx, pdf)
                </a>
                <div *ngIf="usefulLink.attachmentFileName  != null" class="uploaded-file">
                  <span class="file-name">
                    <a href="javascript:void(0);"
                       (click)="onDownloadSelectedAttachment(usefulLink.attachment.id)">{{usefulLink.attachmentFileName}}</a>
                  </span>
                  <span>
                    <a href="javascript:void(0);" (click)="onRemoveAttachment()">
                      <i class="material-icons delete-icon">
                        cancel
                      </i>
                    </a>
                  </span>
                </div>
                <input hidden type="file" (change)="onSelectAttachment($event)" #eAttachment>
                <mat-error class="mat-error-right-label" *ngIf="invalidAttachment">
                  Invalid file type.
                </mat-error>
              </div>
            </div>

          </div>
        </div>
      </form>

    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <button class="mcd-btn mcd-btn-primary" *ngIf="!usefulLink.id" mat-raised-button (click)="onReset()">
        Reset
      </button>
      <button class="mcd-btn mcd-btn-primary" *ngIf="usefulLink.id" mat-raised-button
              (click)="onCancelClick()">
        Cancel
      </button>
      <button class="mcd-btn mcd-btn-secondary" mat-raised-button [disabled]="addEditUsefulLinkForm.invalid"
              (click)="onSaveUsefulLink()">
        {{usefulLink.id > 0? "Update" : "Add"}}
      </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
