import { Component, OnInit , Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../services/admin.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FailModuleComponent } from '../fail-module/fail-module.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { EquipmentType } from '../models/equipment-type';
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: 'app-add-equipment-type',
  templateUrl: './add-equipment-type.component.html',
  styleUrls: ['./add-equipment-type.component.scss']
})
export class AddEquipmentTypeComponent implements OnInit {
  userRole: any;
  error: string = '';
  modalOption: NgbModalOptions = {};

  loading: boolean = false;
  equipmentType: EquipmentType = new EquipmentType();

  addEditEquipmentTypeForm: FormGroup;

  constructor(private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService) {
    this.equipmentType.id = parseInt(this.activeRoute.snapshot.paramMap.get('equipmentTypeId'))
  }

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole"))
    if (this.equipmentType.id) {
      this.getEquipmentTypeByEquipmentTypeId(this.equipmentType.id);
    }
    this.createForm();
  }

  createForm(): void {
    this.addEditEquipmentTypeForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  getEquipmentTypeByEquipmentTypeId(equipmentTypeId: number) {
    this.loading = true;
    this.adminService.GetEquipmentTypeDetailsById(equipmentTypeId)
      .subscribe(
        (data: EquipmentType) => {
          this.equipmentType = new EquipmentType(data);
          this.setControlsValue(this.equipmentType);
          this.loading = false;
          (error: string) => {
            this.error = error;
            const modalRef = this.ngbModal.open(FailModuleComponent);
            this.error = error;
            modalRef.componentInstance.errorMessage = error;
            this.loading = false;
          }
        });
  }

  setControlsValue(euipmentType: EquipmentType) {
    if (euipmentType.id) {
      this.addEditEquipmentTypeForm.controls.name.setValue(euipmentType.name);
      this.addEditEquipmentTypeForm.controls.description.setValue(euipmentType.description);
    }
  }

  onReset() {
      this.createForm();
  }

  onSaveEquipmentType() {
    this.loading = true;
    this.equipmentType.name = this.addEditEquipmentTypeForm.controls.name.value;
    this.equipmentType.description = this.addEditEquipmentTypeForm.controls.description.value;
    if (this.equipmentType.id) {
      this.updateEquipmentType(this.equipmentType).subscribe((data) => {
        
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message = 'You have successfully updated the equipment type.'
        if (data == false) {
          modalRef.componentInstance.title = 'Error';
          modalRef.componentInstance.message = 'Some error occurred. Please try again after some time';
        }
        if (data.status == false) {
          modalRef.componentInstance.title = 'Error';
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(['/view-all-equipment-type-list']);
          }
        });
      });
    }
    else {
      this.addEquipmentType(this.equipmentType).subscribe((data) => {
        const modalRef = this.ngbModal.open(SuccessDialogComponent);
        modalRef.componentInstance.message = 'You have successfully added the equipment type.'
        if (data == false) {
          modalRef.componentInstance.title = 'Error';
          modalRef.componentInstance.message = 'Some error occurred. Please try again after some time';
        }
        if (data.status == false) {
          modalRef.componentInstance.title = 'Error';
        }
        modalRef.componentInstance.nrbesSuccessPopupEmit.subscribe(() => {
          this.ngbModal.dismissAll();
          this.onReset();
          if (data) {
            this.router.navigate(['/view-all-equipment-type-list']);
          }
        });
      });
    }
  }

  addEquipmentType(equipmentType: EquipmentType): Observable<any> {
    this.loading = true;
    equipmentType.id = 0;
    return new Observable<any>(observer => {
      this.adminService.AddEquipmentType(equipmentType)
        .subscribe(
          (data: any) => {
            this.loading = false;
            observer.next(data);
          },
          error => {
            this.loading = false;
            observer.next(false);
          }
        );
    });
  }

  updateEquipmentType(equipmentType: EquipmentType): Observable<any> {
    this.loading = true;
    return new Observable<any>(observer => {
      this.adminService.UpdateEquipmentType(equipmentType)
        .subscribe(
          (data: any) => {
            this.loading = false;
            observer.next(data);
          },
          error => {
            this.loading = false;
            observer.next(false);
          }
        );
    });
  }

  onCancelClick() {
    this.router.navigate(['/view-all-equipment-type-list']);
  }
}
