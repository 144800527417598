<div class="modal" style="display: block !important;  background: #0000007a;" id="content">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Error</h4>
        <button type="button" class="close" aria-label="Close" (click)="onRedirectAndClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mcd-delete-dialog-content">
          <div class="row">
            <div class="col-3 success-icon-wrapper">
                <img alt=""  src="/assets/images/fry-bucket.svg">
            </div>
            <div class="col-9">
              <p class="mcd-large-text">{{errorMessage}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">        
        <button class="mcd-btn mcd-btn-secondary" mat-raised-button color="mat-accent" (click)="onRedirectAndClose()">OK</button>
      </div>
    </div>
  </div>
</div>
