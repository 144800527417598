import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {

  @Input() public title: string = '';
  @Input() public message: string = '';
  @Input() public warningItem: any = null;
  @Output() warningPopupEmit: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onDoTheAction() {
    this.warningPopupEmit.emit(this.warningItem);
  }

  closeDialog() {
    this.warningPopupEmit.emit(null);
  }
}
