<mat-card class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title"
      >View Global Approved Solutions</mat-card-title
    >
  </mat-card-header>
  <mat-card-content class="mcd-card-content nrbes-view">
    <!-- Breadcrumb -->
    <div class="breadcrumbs clearfix">
      <div *ngIf="standardService.isGrs" class="breadcrumbs-inner">
        <ul>
          <!-- <li>
            <a routerLink="/locations" routerLinkActive="active">Locations</a>
          </li>
          <li class="separator">></li>
          <li>
            <a
              [routerLink]="['/location-grs-list', locationId]"
              routerLinkActive="active"
              >{{ grsDetail.standard.locationType.name }}</a
            >
          </li>
          <li class="separator">></li>
          <li class="active">{{ grsDetail.standard.title }}</li>
          <li style="float: right">

          </li> -->
          <li>
            <a
              style="
                color: #0b59ca;
                text-decoration: underline;
                cursor: pointer;
              "
              (click)="downloadExcel()"
              >Download as Excel</a
            >
          </li>
        </ul>
      </div>
      <div *ngIf="!standardService.isGrs" class="breadcrumbs-inner">
        <ul>
          <li>
            <a routerLink="/view-all-standards" routerLinkActive="active"
              >All</a
            >
          </li>
          <li class="separator">></li>
          <li class="active">{{ grsDetail.standard.title }}</li>
          <li>
            <a
              style="
                color: #0b59ca;
                text-decoration: underline;
                cursor: pointer;
              "
              (click)="downloadExcel()"
              >Download as Excel</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- End Breadcrumb -->

    <div *ngIf="!loading" class="nrbes-detail-view">
      <!-- Dynamic Wrapper -->

      <div class="hash-tag">
        {{ grsDetail.standard.category?.name }}
      </div>
      <div class="nrbes-detail-item">
        <h1>{{ grsDetail.standard.title }}</h1>

        <div class="nrbes-detail-item">
          <div
            *ngIf="grsDetail.standard.newRestaurantsEffectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>New Restaurant Effective Date</label>
            <div class="mcd-date-box">
              <div class="mcd-date-box-header">
                {{
                  grsDetail.standard.newRestaurantsEffectiveFrom
                    | date : "MMM | yyyy"
                }}
              </div>
              <div class="mcd-date-box-day">
                {{
                  grsDetail.standard.newRestaurantsEffectiveFrom | date : "dd"
                }}
              </div>
            </div>
          </div>

          <div
            *ngIf="!grsDetail.standard.newRestaurantsEffectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>New Restaurant Effective Date - N/A </label>
          </div>
        </div>
        <div class="nrbes-description">
          <p>
            {{ grsDetail.standard.newRestaurantDescription }}
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div class="nrbes-detail-item">
          <div
            *ngIf="grsDetail.standard.effectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Existing Restaurant Effective Date</label>
            <div class="mcd-date-box">
              <div class="mcd-date-box-header">
                {{ grsDetail.standard.effectiveFrom | date : "MMM | yyyy" }}
              </div>
              <div class="mcd-date-box-day">
                {{ grsDetail.standard.effectiveFrom | date : "dd" }}
              </div>
            </div>
          </div>

          <div
            *ngIf="!grsDetail.standard.effectiveFrom"
            class="mcd-date-box-wrapper"
          >
            <label>Existing Restaurant Effective Date - N/A </label>
          </div>
        </div>
        <div class="nrbes-description">
          <p>
            {{ grsDetail.standard.description }}
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div class="nrbes-detail-item">
          <div class="nrbes-Benefit">
            <h2>Note</h2>

            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="this.notes"
              ></div>
            </div>
          </div>
        </div>

        <div class="nrbes-detail-item">
          <div class="nrbes-Benefit">
            <h2>Additional Information</h2>

            <div class="ql-container ql-snow" style="border-width: 0">
              <div
                class="ql-editor nrbes-description"
                [innerHTML]="this.additionalInformation"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Dynamic Wrapper -->
      <!-- Next Previous Buttons-->
      <div class="row">
        <div class="col text-right">
          <div class="btn-wrapper">
            <div class="prev-button" *ngIf="!grsDetail.isFirst">
              <a
                href="javascript:void(0)"
                (click)="slide(grsDetail.standard.id, false)"
              >
                <img src="/assets/images/back.svg" />
              </a>
            </div>
            <div class="next-button" *ngIf="!grsDetail.isLast">
              <a
                href="javascript:void(0)"
                (click)="slide(grsDetail.standard.id, true)"
              >
                <img src="/assets/images/next.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="nrbes-detail-view table-list">
      <h3>Global Recommendations / Shared Learnings</h3>
      <table class="table nrbes-grid" *ngIf="grsDetail.dependentStandards.length > 0">
        <thead class="thead-light">
          <tr>
            <th scope="140">Type</th>
            <th width="140" scope="col">Title</th>
            <th scope="col" width="110">Location</th>
            <th scope="col-4" width="30%">Description</th>
            <th scope="col-2">Category</th>

            <th *ngIf="userRole == userRoleEnum.GlobalAdmin && !standardService.isGrs" scope="col" width="80">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of grsDetail.dependentStandards" (click)="rowSelected(item)">
            <td class="date-cell"> {{item.standardType.name}}</td>
            <td class="title-cell">{{item.title}}</td>
            <td class="location-cell">{{item.locationType.name}}</td>
            <td class="description">
              <span class="description">
                {{item.description}}
              </span>
            </td>
            <td class="category-cell">{{item.category.name}}</td>

            <td *ngIf="userRole == userRoleEnum.GlobalAdmin && !standardService.isGrs">
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a class="action-controls" (click)="openDeleteModal(item.id, $event)" routerLinkActive="active"
                     title="clear">
                    <i class="material-icons">
                      clear
                    </i>
                  </a>
                </div>
                <div class="col-6">
                  <a class="action-controls" (click)="onEdit(item.id)" routerLinkActive="active" title="Edit"
                     href="javascript:void(0);"><i class="material-icons"> create </i></a>
                </div>
              </div>

              <a (click)="actionMenuOpen($event)" class="mcd-more-btn show-sm" [matMenuTriggerFor]="menu"
                 href="javascript:void(0);">
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button mat-menu-item (click)="onEdit(item.id)" routerLinkActive="active"
                          title="Edit">
                    Edit
                  </button>
                  <button mat-menu-item (click)="closeMatMenuAndOpenDeleteModal(item.id, $event);">Remove</button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="grsDetail.dependentStandards.length == 0">Sorry you do not have any Global Recommendations / Shared Learnings.</div>
      <app-mcd-loader *ngIf="loading"></app-mcd-loader>
    </div>-->
    <button
      class="mcd-btn mcd-btn-primary"
      [disabled]="grsDetail.isInNrbes"
      *ngIf="userRole == userRoleEnum.MarketAdmin"
      (click)="addToNrbes(grsDetail.standard.id,grsDetail.standard.standardTypeId)"
      mat-raised-button
      color="accent"
    >
      Add To National Standard
    </button>
  </mat-card-content>
</mat-card>
