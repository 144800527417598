import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Country } from "../models/country";
import { UserRoleEnum, Constant } from "../core/constant";
import { AdminService } from "../services/admin.service";
import { SupplierList } from "../models/supplier-list";
import { Supplier } from "../models/supplier";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { GlobalMarketSupplier } from "../models/global-market-supplier";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { Router } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";

@Component({
  selector: "app-view-all-supplier-list",
  templateUrl: "./view-all-supplier-list.component.html",
  styleUrls: ["./view-all-supplier-list.component.scss"],
})
export class ViewAllSupplierListComponent implements OnInit {
  loading: boolean = false;
  userRole: number;
  userId: number;
  countryId: number = 0;
  countries: Country[] = [];
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;

  page: number = 1;
  pageSize: number = 5;
  collectionSize: number = 0;

  supplierList: SupplierList = new SupplierList();
  error: string = "";
  modalOption: NgbModalOptions = {};

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  get userRoleEnum() {
    return UserRoleEnum;
  }

  constructor(
    private adminService: AdminService,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private ngbModal: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")
    );
    this.userId = this.cryptographyService.decryptData(
      this.storage.get("userId")
    );
    this.getSuppliers();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getSuppliers();
  }

  getSuppliers(): void {
    this.loading = true;
    this.adminService
      .GetGobalSupplierList(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.searchKey
      )
      .subscribe((data: SupplierList) => {
        this.supplierList.globalMarketSuppliers =
          data.globalMarketSuppliers.map((o) => new GlobalMarketSupplier(o));
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  onAddEditSupplier(supplierId: number = null) {
    if (!supplierId) {
      this.router.navigate(["/add-supplier"]);
    } else {
      this.router.navigate(["/add-supplier/" + supplierId]);
    }
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this supplier from the list? ";
    modalRef.componentInstance.deleteItem = item;
    modalRef.componentInstance.message =
      "All the dependencies will be removed along with this Supplier";
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      if (deleteItem != null) {
        let supplier = new Supplier(item.supplier);
        this.loading = true;
        this.adminService.DeleteSupplier(supplier).subscribe((data: any) => {
          this.loading = false;
          this.page = 1;
          this.getSuppliers();
        });
      }
      this.ngbModal.dismissAll();
    });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getSuppliers();
  }

  rowSelected(item) {
    this.router.navigate(["/supplier-detail-view/" + item.supplier.id]);
  }
}
