<div class="modal" style="display: block !important;  background: #0000007a;" id="content">
    <div class="modal-dialog success-dialog">
        <div class="modal-content" >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close"  (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
     <div class="mcd-success-dialog-content">
       <div class="row">
         <div class="col-3 success-icon-wrapper">
           <img alt=""  src="/assets/images/fry-bucket.svg">
         </div>
         <div class="col-9">
           <p class="mcd-large-text">{{message}}</p>
         </div>
       </div>
     </div>
    </div>
    <div class="modal-footer">
        <button class="mcd-btn mcd-btn-primary"  mat-raised-button color="accent" (click)="closeDialog()">OK</button>
         </div>
  </div>
  </div>
  </div>
