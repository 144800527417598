import { Component, OnInit, Inject,ViewChild } from "@angular/core";
import { Constant } from "../core/constant";
import { AdminService } from "../services/admin.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { EquipmentTypeList } from "../models/equipment-type-list";
import { EquipmentType } from "../models/equipment-type";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { CryptographyService } from "../services/cryptography.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-view-all-equipment-type-list",
  templateUrl: "./view-all-equipment-type-list.component.html",
  styleUrls: ["./view-all-equipment-type-list.component.scss"],
})
export class ViewAllEquipmentTypeListComponent implements OnInit {
  loading: boolean = false;
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;
  userRole : any ;

  page: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0;

  equipmentTypeList: EquipmentTypeList = new EquipmentTypeList();
  error: string = "";
  modalOption: NgbModalOptions = {};
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  constructor(
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,

  ) {}

  ngOnInit() {
    this.userRole = this.cryptographyService.decryptData(
      this.storage.get("userRole")),
    this.getEquipmentTypes();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getEquipmentTypes();
  }

  getEquipmentTypes(): void {
    this.loading = true;
    this.adminService
      .GetEquipmentTypeList(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.searchKey
      )
      .subscribe((data: EquipmentTypeList) => {
        this.equipmentTypeList.equipmentTypes = data.equipmentTypes.map(
          (o) => new EquipmentType(o)
        );
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getEquipmentTypes();
  }

  onAddEditEquipmentType(equipmentTypeId: number = null) {
    if (!equipmentTypeId) {
      this.router.navigate(["/add-equipment-type"]);
    } else {
      this.router.navigate(["/add-equipment-type/" + equipmentTypeId]);
    }
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  openDeleteModal(item, event) {
    //debugger;
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this Equipment Type from the list? ";
    modalRef.componentInstance.deleteItem = item;
    modalRef.componentInstance.message =
      "All the dependencies will be removed along with this Equipment Type";
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      //debugger;
      if (deleteItem != null) {
        let equipmentType = new EquipmentType(item);
        this.loading = true;
        this.adminService
          .DeleteEquipmentType(equipmentType)
          .subscribe((data: any) => {
            this.loading = false;
            this.page = 1;
            this.getEquipmentTypes();
          });
      }
      this.ngbModal.dismissAll();
    });
  }
}
