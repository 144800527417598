import { Injectable, Inject } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "./authentication.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { SessionStorageService } from "./session-storage.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";
import { environment } from "../../environments/environment";
import { UserRoleUserOperation } from "../core/constant";
import { log } from "util";
import { CryptographyService } from "./cryptography.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuardService implements CanActivate, CanActivateChild {
  constructor(
    public authenticationService: AuthenticationService,
    private _router: Router,
    @Inject(LOCAL_STORAGE)
    private storage: WebStorageService,
    private cryptographyService: CryptographyService,
    private session: SessionStorageService
  ) {}

  userRoleUserOperation: Array<number>;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.skipAuthentication) {
      return true;
    }

    let userRoleUserOperation = this.storage.get("userRoleUserOperation")
      ? this.cryptographyService.decryptData(
          this.storage.get("userRoleUserOperation")
        )
      : this.storage.get("userRoleUserOperation");

    if (
      userRoleUserOperation.indexOf(next.data.userRoleUserOperationId) !== -1
    ) {
      return true;
    }
    if (this._router.url == "/market-admin-panel") return true;
    if (this._router.url.includes("add-equipment-type")) return true;
    //need to create unauthorize page
    this._router.navigateByUrl("/unauthorized");
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    throw new Error("Method not implemented.");
  }
}
