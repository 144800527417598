<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Add New Equipment</mat-card-title>
  </mat-card-header>
  <div class="mat-card-wrapper">
    <mat-card-content class="mcd-card-content">
      <form [formGroup]="addEquipmentForm">
        <div class="mcd-edit-form mcd-equipment-form">
          <div class="row">
            <div class="col-5">
              <div>
                <mat-form-field>
                  <input matInput placeholder="Model" name="model" formControlName="model" required>
                  <mat-error
                    *ngIf="addEquipmentForm.get('model').touched && addEquipmentForm.get('model').hasError('required')">
                    Model is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-5 offset-md-2">
              <mat-form-field>
                <mat-label>Equipment Type</mat-label>
                <mat-select name="dropEquipmentType" formArrayName="equipmentType"
                  [value]="addEquipmentForm['controls'].equipmentTypeId.value" #equipmentType
                  (selectionChange)="setSelectedEquipmentType(equipmentType)" required>
                  <mat-option *ngFor="let item of addEquipmentForm['controls'].equipmentType['controls']; let j=index"
                    [value]="item.controls.id.value">{{item.controls.name.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5">
              <mat-form-field>
                <mat-label>Supplier</mat-label>
                <mat-select name="dropSupplier" formArrayName="supplier"
                  [value]="addEquipmentForm['controls'].supplierId.value" #supplier
                  (selectionChange)="setSelectedSupplier(supplier)" required>
                  <mat-option *ngFor="let item of addEquipmentForm['controls'].supplier['controls']; let j=index"
                    [value]="item.controls.id.value">{{item.controls.name.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="mcd-upload-images">
          <div class="row attachements">
            <div class="col">

              <a href="javascript:void(0);" (click)="equipmentImage.click()">
                <span>
                  <img alt="Upload Image" src="/assets/images/attachement.svg">
                </span> Upload Image (JPEG, PNG, BMP)
              </a>
              <div class="uploaded-file">
                <span class="file-name"><a href="javascript:void(0);"
                    (click)="onDownloadSelectedEquipmentAttachment()">{{equipment.imageFileName}}</a></span>
                <span>
                  <a href="javascript:void(0);" (click)="onRemoveEquipmentAttachment()">
                    <i class="material-icons delete-icon">
                      cancel
                    </i>
                  </a>
                </span>
              </div>
              <input hidden type="file" (change)="onSelectImageAttachment($event)" #equipmentImage>
              <mat-error class="mat-error-left-label" *ngIf="invalidImage">
                Invalid file type.
              </mat-error>
              <mat-error class="mat-error-left-label" *ngIf="invalidImageRes">
                Invalid image resolution.
              </mat-error>
            </div>

            <div class="col text-right">
              <a href="javascript:void(0);" (click)="eAttachment.click()">
                <span>
                  <img alt="Upload Image" src="/assets/images/attachement.svg">
                </span> Attach File (doc, docx, pdf)
              </a>
              <div *ngIf="equipment.attachmentFileName  != null" class="uploaded-file qr-code">
                <span class="file-name"><a href="javascript:void(0);"
                    (click)="onDownloadSelectedAttachment()">{{equipment.attachmentFileName }}</a></span>
                <span>
                  <a href="javascript:void(0);" (click)="onRemoveAttachment()">
                    <i class="material-icons delete-icon">
                      cancel
                    </i>
                  </a>
                </span>
              </div>
              <input hidden type="file" (change)="onSelectAttachment($event)" #eAttachment>
              <mat-error class="mat-error-right-label" *ngIf="invalidAttachment">
                Invalid file type.
              </mat-error>
            </div>
          </div>
        </div>
      </form>

    </mat-card-content>
    <mat-card-footer class="mcd-card-footer">
      <p class="image-info">* Please upload square image </p>
      <button class="mcd-btn mcd-btn-primary" mat-raised-button (click)="resetForm()">Reset</button>
      <button class="mcd-btn mcd-btn-secondary" mat-raised-button [disabled]="checkFormValidity()"
        (click)="saveEquipment()"> Add </button>
    </mat-card-footer>
  </div>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
