import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Constant } from "../core/constant";
import { AdminService } from "../services/admin.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FailModuleComponent } from "../fail-module/fail-module.component";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { UsefulLinkList } from "../models/usefullink-list";
import { UsefulLink } from "../models/usefullink";
import { DeleteDialogComponent } from "../delete-dialog/delete-dialog.component";
import { CryptographyService } from "../services/cryptography.service";
import { WebStorageService, LOCAL_STORAGE } from "ngx-webstorage-service";

@Component({
  selector: "app-view-all-useful-links",
  templateUrl: "./view-all-useful-links.component.html",
  styleUrls: ["./view-all-useful-links.component.scss"],
})
/** view-all-useful-links component*/
export class ViewAllUsefulLinksComponent {
  loading: boolean = false;
  pageSizes: any[] = Constant.PageSizes;
  searchKey: string;

  page: number = 1;
  pageSize: number = 5;
  collectionSize: number = 0;

  usefulLinkList: UsefulLinkList = new UsefulLinkList();
  error: string = "";
  modalOption: NgbModalOptions = {};
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  /** view-all-useful-links ctor */
  constructor(
    private adminService: AdminService,
    private ngbModal: NgbModal,
    private router: Router,
    private cryptographyService: CryptographyService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit() {
    this.getUsefulLinks();
  }

  selectedItemsPerPageChange(event) {
    this.page = 1;
    this.pageSize = event.value;
    this.getUsefulLinks();
  }

  getUsefulLinks(): void {
    this.loading = true;
    this.adminService
      .GetUsefulLinkList(
        (this.page - 1) * this.pageSize,
        this.pageSize,
        this.searchKey
      )
      .subscribe((data: UsefulLinkList) => {
        this.usefulLinkList.usefullinks = data.usefullinks.map(
          (o) => new UsefulLink(o)
        );
        this.collectionSize = data.count;
        this.loading = false;
        (error: string) => {
          this.error = error;
          const modalRef = this.ngbModal.open(FailModuleComponent);
          this.error = error;
          modalRef.componentInstance.errorMessage = error;
          this.loading = false;
        };
      });
  }

  addSearchKey(searchKey) {
    this.searchKey = searchKey.value;
    this.page = 1;
    this.getUsefulLinks();
  }

  onAddEditUsefulLink(usefulLinkId: number = null) {
    if (!usefulLinkId) {
      this.router.navigate(["/add-useful-link"]);
    } else {
      this.router.navigate(["/add-useful-link/" + usefulLinkId]);
    }
  }

  closeMatMenuAndOpenDeleteModal(item, event) {
    this.trigger.closeMenu();
    setTimeout(() => {
      this.openDeleteModal(item, event);
    }, 100);
  }

  openDeleteModal(item, event) {
    event.stopPropagation();
    const modalRef = this.ngbModal.open(DeleteDialogComponent);
    modalRef.componentInstance.title =
      "Do you want to remove this link from the list? ";
    modalRef.componentInstance.deleteItem = item;
    modalRef.componentInstance.message =
      "This link will not appear on the home page after the deletion";
    this.trigger.closeMenu();
    modalRef.componentInstance.deletePopupEmit.subscribe((deleteItem) => {
      if (deleteItem != null) {
        let usefulLink = new UsefulLink(item);
        this.loading = true;
        usefulLink.modifiedUserId = this.cryptographyService.decryptData(
          this.storage.get("userId")
        );
        this.adminService
          .DeleteUsefulLink(usefulLink)
          .subscribe((data: any) => {
            this.loading = false;
            this.page = 1;
            this.getUsefulLinks();
          });
      }
      this.ngbModal.dismissAll();
    });
  }
}
