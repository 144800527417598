<mat-card *ngIf="!loading" class="mcd-card">
  <mat-card-header class="mcd-card-header">
    <mat-card-title class="mcd-card-title">Manage Users</mat-card-title>
  </mat-card-header>
  <div class="breadcrumbs clearfix">
    <div class="breadcrumbs-inner">
      <ul>
        <li>
          <a routerLink="/global-admin-panel" routerLinkActive="active"
            >Global Admin Panel</a
          >
        </li>
        <li class="separator">></li>
        <li class="active">Manage Users</li>
      </ul>
    </div>
  </div>
  <mat-card-content class="mcd-card-content">
    <div class="row">
      <div class="col-6">
        <div>
          <mat-form-field>
            <input
              matInput
              placeholder="Search by Name or Email"
              value="{{ searchKey }}"
              (keyup.enter)="addSearchKey(key)"
              name="search"
              #key
            />
            <button
              mat-button
              *ngIf="searchKey"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchKey = ''; addSearchKey(searchKey)"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="userList.users.length > 0">
          <mat-form-field>
            <mat-select
              [value]="pageSize"
              name="nOfRows"
              #items
              (selectionChange)="selectedItemsPerPageChange(items)"
            >
              <mat-option [value]="item.value" *ngFor="let item of pageSizes">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-6 text-right">
        <button
          class="mcd-btn mcd-btn-secondary"
          mat-raised-button
          (click)="onAddEditUser()"
        >
          Add User
        </button>
      </div>
    </div>
    <div class="table-wrapper" *ngIf="userList.users.length > 0">
      <table class="table nrbes-grid">
        <thead class="thead-light">
          <tr>
            <th scope="col" width="15%">First Name</th>
            <th scope="col" width="15%">Last Name</th>
            <th scope="col" width="26%">Email</th>
            <th scope="col" width="18%">Country</th>
            <th scope="col" width="18%">Role</th>
            <th scope="col" width="8%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userList.users" (click)="rowSelected(item)">
            <td class="location-cell">{{ item.name }}</td>
            <td class="location-cell">{{ item.lastName }}</td>
            <td class="email">{{ item.email }}</td>
            <td class="email">{{ item.country.name }}</td>
            <td class="email">{{ item.userRole.name }}</td>
            <td>
              <div class="row grid-actions hide-sm">
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="openDeleteModal(item, $event)"
                    routerLinkActive="active"
                    title="clear"
                    ><i class="material-icons"> clear </i></a
                  >
                </div>
                <div class="col-6">
                  <a
                    class="action-controls"
                    (click)="onAddEditUser(item.id)"
                    routerLinkActive="active"
                    title="Edit"
                    href="javascript:void(0);"
                    ><i class="material-icons"> create </i></a
                  >
                </div>
              </div>

              <a
                (click)="actionMenuOpen($event)"
                class="mcd-more-btn show-sm"
                [matMenuTriggerFor]="menu"
                href="javascript:void(0);"
              >
                <i class="material-icons">more_vert</i>
              </a>
              <mat-menu #menu="matMenu" overlapTrigger="false">
                <span>
                  <button mat-menu-item (click)="onAddEditUser(item.id)">
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="closeMatMenuAndOpenDeleteModal(item, $event)"
                  >
                    Remove
                  </button>
                </span>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col">
          <ngb-pagination
            class="float-right"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="4"
            [rotate]="true"
            [ellipses]="true"
            [collectionSize]="collectionSize"
            (pageChange)="getUsers()"
          ></ngb-pagination>
        </div>
      </div>
    </div>
    <div *ngIf="userList.users.length == 0">Doesn't have any user.</div>
  </mat-card-content>
</mat-card>
<app-mcd-loader class="mcd-page-loader" *ngIf="loading"></app-mcd-loader>
